import { request } from 'actions/utils';

export default function createUser(user, onSuccess) {
  return async (dispatch) => {
    let success = await dispatch(
      request({
        action: 'CREATE_USER',
        path: '/api/admin/users',
        method: 'POST',
        body: user,
      })
    );

    if (success && typeof onSuccess === 'function') {
      onSuccess();
    }
  };
}
