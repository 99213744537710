import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import Input from 'components/general/Input';
import translate from 'utils/translate';
import ModalWrapper from 'components/general/ModalWrapper';
import InnerModal from 'components/general/InnerModal';
import Button from 'components/general/Button';
import localReducer from './reducer';
import update from './update';
import LocalFlux from 'components/general/LocalFlux';

export default class EditModal extends Component {
  update = async (e, state, dispatch) => {
    e.preventDefault();
    const success = await update({ data: state }, dispatch);
    if (success) {
      this.props.closeModal();
    }
  };
  render() {
    return (
      <LocalFlux reducer={localReducer} {...this.props}>
        {({ state, changeInput, dispatch }) => (
          <ModalWrapper onClose={this.props.closeModal}>
            <InnerModal onSubmit={(e) => this.update(e, state, dispatch)}>
              <h4>{translate('inspectionModules.edit.header')}</h4>
              <Input
                type='text'
                value={state.name}
                onChange={(e) =>
                  changeInput({ field: 'name', value: e.target.value })
                }
                placeholder={translate('inspectionModules.create.name')}
              />
              <Input
                type='number'
                value={state.ordinal}
                onChange={(e) =>
                  changeInput({ field: 'ordinal', value: e.target.value })
                }
                placeholder={translate('ordinal')}
              />
              <FormControlLabel
                label={translate('inspectionModules.edit.isMandatory')}
                control={
                  <Checkbox
                    name='isMandatory'
                    checked={!!state.isMandatory}
                    onChange={() =>
                      changeInput({
                        field: 'isMandatory',
                        value: !state.isMandatory,
                      })
                    }
                  />
                }
              />
              <FormControlLabel
                label={translate('inspectionModules.edit.isDefaultNA')}
                control={
                  <Checkbox
                    name='isDefaultNA'
                    checked={!!state.isDefaultNA}
                    onChange={() =>
                      changeInput({
                        field: 'isDefaultNA',
                        value: !state.isDefaultNA,
                      })
                    }
                  />
                }
              />
              <FormControlLabel
                label={translate('inspectionModules.edit.useTabs')}
                control={
                  <Checkbox
                    name='useTabs'
                    checked={!!state.useTabs}
                    onChange={() =>
                      changeInput({
                        field: 'useTabs',
                        value: !state.useTabs,
                      })
                    }
                  />
                }
              />
              <Button>{translate('inspectionModules.edit.button')}</Button>
              {state.loading && <Loader />}
              {state.error && <Error>{translate(state.error)}</Error>}
            </InnerModal>
          </ModalWrapper>
        )}
      </LocalFlux>
    );
  }
}
