import React, { useEffect } from 'react';
import translate from 'utils/translate';
import Error from 'components/general/Error';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import CreateUserModal from 'components/admin/User/CreateModal';
import { breakpoints } from 'globals/media';
import { useScreenSize } from 'hooks/useScreenSize';
import LoadingOverlay from 'components/general/LoadingOverlay';
import Zoom from '@material-ui/core/Zoom';
import { List } from 'immutable';

export const Wrapper = styled.div`
  position: relative;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RowButton = styled(IconButton)`
  padding: 4px !important;
  margin: 4px !important;
`;

const StyledRow = styled(TableRow)`
  cursor: pointer;
`;

export default function ListUsers({
  title,
  users = List(),
  openCreateUserModal,
  organizationId,
  onUserCreated,
  openUser,
  deleteUser,
  loading,
  error,
  load,
}) {
  const screenSize = useScreenSize();
  const theme = useTheme();
  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <LoadingOverlay show={loading} />
      <Header>
        <Typography variant='h5' component='h1' gutterBottom>
          {title || translate('users')}
        </Typography>
        <Zoom
          in={true}
          timeout={{
            enter: theme.transitions.duration.enteringScreen,
            exit: theme.transitions.duration.leavingScreen,
          }}
          unmountOnExit
        >
          <Fab
            variant={
              screenSize.width < breakpoints.tablet ? 'round' : 'extended'
            }
            style={{
              position:
                screenSize.width < breakpoints.tablet ? 'fixed' : 'relative',
              bottom:
                screenSize.width < breakpoints.tablet ? theme.spacing(2) : 0,
              right:
                screenSize.width < breakpoints.tablet ? theme.spacing(2) : 0,
            }}
            color='primary'
            onClick={() => {
              openCreateUserModal(organizationId);
            }}
            label={translate('users.create')}
          >
            <AddIcon />
            {screenSize.width > breakpoints.tablet && translate('users.create')}
          </Fab>
        </Zoom>
      </Header>
      {error && <Error>{translate(error)}</Error>}
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>{translate('users.edit.name')}</TableCell>
            <TableCell>{translate('users.edit.email')}</TableCell>
            <TableCell>{translate('users.edit.agentCode')}</TableCell>
            <TableCell>{translate('users.edit.role')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <StyledRow
              key={user.get('id')}
              hover
              onClick={() => openUser(user.get('id'))}
            >
              <TableCell>{user.get('name')}</TableCell>
              <TableCell>{user.get('email')}</TableCell>
              <TableCell>{user.get('agentCode')}</TableCell>
              <TableCell>
                {translate(`users.edit.role.${user.get('role')}`)}
              </TableCell>
              <TableCell style={{ width: '85px' }}>
                <Tooltip title={translate('organizations.edit')}>
                  <RowButton
                    size='medium'
                    onClick={(e) => {
                      e.stopPropagation();
                      openUser(user.get('id'));
                    }}
                  >
                    <EditIcon />
                  </RowButton>
                </Tooltip>
                <Tooltip title={translate('organizations.delete')}>
                  <RowButton
                    size='medium'
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteUser(user);
                    }}
                  >
                    <DeleteOutlineIcon />
                  </RowButton>
                </Tooltip>
              </TableCell>
            </StyledRow>
          ))}
        </TableBody>
      </Table>
      <CreateUserModal
        useMyOrganization={!organizationId}
        organizationId={organizationId}
        onSuccess={onUserCreated}
      />
    </Wrapper>
  );
}
