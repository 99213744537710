import { request } from 'actions/utils';

export default function (
  {
    name,
    isSelfServe,
    hotelId,
    organizationId,
    allowUnansweredQuestions,
    autoStartTime,
    autoCloseTime,
    autoCloseWarningTime,
    autoCloseDelayDays,
    autoStartDelayDays,
    autoWeekdays,
    reportType,
    reminderEmail,
    autoCloseWarningEmail,
    feedbackEmail,
    reportEmail
  },
  onSuccess
) {
  let path = `/api/admin/hotels/${hotelId}/inspection-types`;
  if (!hotelId) {
    path = `/api/admin/inspection-types`;
  }
  return async (dispatch) => {
    let success = await dispatch(
      request({
        action: 'CREATE_INSPECTION_TYPE',
        path,
        method: 'POST',
        body: {
          organizationId,
          name,
          isSelfServe,
          allowUnansweredQuestions,
          autoStartTime,
          autoCloseTime,
          autoCloseWarningTime,
          autoCloseDelayDays,
          autoStartDelayDays,
          autoWeekdays,
          reportType,
          reminderEmail: trimArray(reminderEmail),
          autoCloseWarningEmail: trimArray(autoCloseWarningEmail),
          feedbackEmail: trimArray(feedbackEmail),
          reportEmail: trimArray(reportEmail)
        },
      })
    );
    if (success && typeof onSuccess === 'function') {
      onSuccess();
    }
  };
}

const trimArray = (array) => array ? array.filter(value => value && value !== '') : null;
