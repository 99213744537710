import { SELECTED_LANGUAGE } from 'globals/localStorage';
import { languages, dashboardLanguages } from 'globals/languages';

export function getSelectedLanguage() {
  let currentLanguage = null;

  try {
    currentLanguage = JSON.parse(localStorage.getItem(SELECTED_LANGUAGE));
  } catch (e) {}

  return currentLanguage || languages[0];
}

export function getShortCode(code) {
  if (!code.includes('_')) {
    return code;
  }

  return code.split('_')[0];
}

export function getIcon(languageCode) {
  const language = dashboardLanguages.find(
    (language) => language.code === languageCode
  );

  if (!language) {
    return null;
  }

  return language.icon;
}
