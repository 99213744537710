import urls from 'globals/urls';
import { request } from 'actions/utils';

export default {
  login,
  logout,
  checkSession,
};

export function login({ email, password }, redirect) {
  return request({
    body: { email, password },
    method: 'POST',
    action: 'LOGIN',
    path: '/api/sessions/login',
    redirect: redirect || urls.start,
  });
}

export function logout() {
  return request({
    method: 'POST',
    action: 'LOGOUT',
    path: '/api/sessions/logout',
    redirect: urls.login,
  });
}

export function checkSession(url) {
  return request({
    action: 'SESSION_CHECK',
    path: '/api/sessions/check',
    redirect: url || urls.start,
    failRedirect: urls.login.concat(
      url ? `?redirect=${encodeURIComponent(url)}` : ''
    ),
  });
}
