import loadUser from './loadUser';
import loadUsers from './loadUsers';
import { request } from 'actions/utils';

export default function editUser(user, reloadList) {
  return async (dispatch) => {
    let success = await dispatch(
      request({
        action: 'EDIT_USER',
        path: `/api/admin/users/${user.id}`,
        method: 'PUT',
        body: user,
      })
    );
    if (!success) return;
    if (reloadList) return dispatch(loadUsers());
    dispatch(loadUser(user.id));
  };
}
