import React, { Component } from 'react';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LocalFlux from 'components/general/LocalFlux';
import HotelForm from 'components/general/HotelForm';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import localReducer from './reducer';
import update from './update';

export default class EditModal extends Component {
  update = async (e, state, dispatch) => {
    e.preventDefault();
    const success = await update({ data: state }, dispatch);
    if (success) {
      if (this.props.loadHotels) this.props.loadHotels();
      this.props.closeModal();
    }
  };
  render() {
    return (
      <LocalFlux reducer={localReducer} {...this.props}>
        {({ state, changeInput, dispatch }) => (
          <Dialog open onClose={this.props.closeModal}>
            <DialogTitle>{translate('hotels.edit.header')}</DialogTitle>
            <DialogContent
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <HotelForm
                hotel={state}
                role={this.props.role}
                onChange={(field, value) => changeInput({ field, value })}
              />
              {state.loading && <Loader />}
              {state.error && <Error>{translate(state.error)}</Error>}
            </DialogContent>
            <DialogActions>
              <Button color='primary' onClick={this.props.closeModal}>
                {translate('cancel')}
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={(e) => this.update(e, state, dispatch)}
                disabled={
                  !state.name ||
                  ['inactiveStartDate', 'inactiveEndDate'].some(
                    (dateField) =>
                      state[dateField] && !moment(state[dateField]).isValid()
                  )
                }
              >
                {translate('submit')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </LocalFlux>
    );
  }
}
