import React, { Component } from 'react';
import colors from 'globals/colors';
import {
  ScoreWrapper,
  IconWrapper,
  ScoreButton,
  InspectedIcon,
  SkipIcon,
} from './styled';

const INITIAL_SCORE = {
  score: -1,
};

export default class Score extends Component {
  constructor(props) {
    super(props);
    this.state = {
      score: INITIAL_SCORE,
    };
  }

  render() {
    const { score, setScore, isMandatory } = this.props;

    const onClick = (score) => setScore(score);

    let selectedColor;

    switch (score) {
      case 1:
        selectedColor = colors.rating1;
        break;
      case 2:
        selectedColor = colors.rating2;
        break;
      case 3:
        selectedColor = colors.rating3;
        break;
      default:
        selectedColor = false;
    }
    const hasSetScore = score !== null;
    const scoreUnits = (
      <IconWrapper>
        {!isMandatory && <SkipIcon onClick={() => onClick(-1)} />}
        <InspectedIcon
          selected={hasSetScore ? colors.rating0 : false}
          onClick={() => onClick(0)}
        />
        <ScoreButton
          selected={score >= 1 ? selectedColor : false}
          onClick={() => onClick(1)}
        />
        <ScoreButton
          selected={score >= 2 ? selectedColor : false}
          onClick={() => onClick(2)}
        />
        <ScoreButton
          selected={score >= 3 ? selectedColor : false}
          onClick={() => onClick(3)}
        />
      </IconWrapper>
    );

    return <ScoreWrapper>{scoreUnits}</ScoreWrapper>;
  }
}
