import React, { useRef, useState, useEffect } from 'react';
import translate from 'utils/translate';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import NACheckbox from '../NACheckbox';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function GradeFScore({ score, isMandatory, setScore }) {
  const [internalScore, setInternalScore] = useState(score);

  useEffect(() => {
    setInternalScore(score);
  }, [score]);

  const timeout = useRef(0);

  const onChange = (event) => {
    clearTimeout(timeout.current);
    const score = parseFloat(event.target.value);
    setInternalScore(score);
    timeout.current = setTimeout(() => {
      setScore(score);
    }, 1000);
  };

  return (
    <Wrapper>
      <TextField
        value={internalScore || undefined}
        type='number'
        label={translate('questions.gradeG.number')}
        onChange={onChange}
      />
      <NACheckbox score={score} isMandatory={isMandatory} setScore={setScore} />
    </Wrapper>
  );
}
