import React, { useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import media from 'globals/media';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Button = styled.button`
  all: unset;
  width: fit-content;
  height: ${({ height }) => height || '50px'};
  cursor: pointer;
`;

const ClickableImage = styled.img`
  height: ${({ height }) => height || '50px'};
  width: ${({ width }) => width || 'auto'};
`;

const FullImage = styled.img`
  max-width: 80%;
  height: auto;

  ${media.tablet`
    max-width: 90%;
  `};

  ${media.handheld`
    max-width: 95%;
  `};
`;

export default function ZoomableImage({ src, alt, width, height }) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <Button onClick={() => setOpen(true)} type='button' height={height}>
        <ClickableImage src={src} alt={alt} width={width} height={height} />
      </Button>
      <Backdrop
        className={classes.backdrop}
        open={open}
        onClick={() => setOpen(false)}
      >
        <FullImage src={src} alt={alt} />
      </Backdrop>
    </>
  );
}
