import { fromJS } from 'immutable';

const defaultState = fromJS({
  current: {
    name: '',
  },
  loading: false,
  error: '',
});

export default function (state = defaultState, action = { type: '' }) {
  switch (action.type) {
    case 'LOAD_HOTEL_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'LOAD_HOTEL_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'LOAD_HOTEL_DONE': {
      return state.set('current', fromJS(action.payload)).set('loading', false);
    }
    default: {
      return state;
    }
  }
}
