import styled from 'styled-components';
import media from 'globals/media';

export default styled.div`
  margin: 20px auto 50px auto;
  display: flex;
  flex-direction: column;
  min-width: 280px;
  max-width: 75rem;
  align-items: center;
  justify-content: center;
  ${media.tablet`
    margin: 20px auto ${({ marginBottom }) => marginBottom || 80}px auto;
  `}
`;
