import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import Logo from 'components/general/Logo';
import {
  Wrapper,
  TotalScore,
  Header,
  ReportHeader,
  Footer,
} from 'components/admin/Report/styled';
import translate from 'utils/translate';
import BarChart from 'components/admin/Hotel/SafeQReport/BarChart';
import LineChart from 'components/admin/Hotel/SafeQReport/LineChart';
import PrintWrapper from 'components/general/PrintWrapper';

import ModuleBarCharts from 'components/admin/Hotel/WeeklyReport/ModuleBarCharts';
import WorstQuestionsDetailed from 'components/admin/Hotel/WeeklyReport/WorstQuestionsDetailed';
import WorstQuestions from 'components/admin/Hotel/WeeklyReport/WorstQuestions';
import {
  getAccumulatedScore,
  getLineData,
  getStartWeekOffset,
  filterAnsweredHotels,
} from 'components/admin/Hotel/WeeklyReport/utils';
import useWeeklyData from './useWeeklyData';
import HeaderInformation from './HeaderInformation';
import HotelScores from './HotelScores';
import { moduleAreas } from 'globals/moduleAreas';

const FullfillmentHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  > :nth-child(2) {
    cursor: help;
    margin-left: 4px;
    font-size: 14px;
  }
`;

const SafeQTotalScore = styled(TotalScore)`
  height: initial;
`;

const SafeQFooter = styled(Footer)`
  @media print {
    padding: 0 7px;
  }
`;

const SafeQWrapper = styled(Wrapper)`
  h4 {
    margin: 10px 0;
  }
`;

const SafeQReportHeader = styled(ReportHeader)`
  display: flex;
  justify-content: space-between;

  h1 {
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const SafeQHeader = styled(Header)`
  min-height: 370px;
`;

export default function WeeklyReport({
  error,
  loading,
  organizationId,
  excludeUnansweredHotels,
  hotelIds,
  day,
}) {
  const { name, hotels } = useWeeklyData(organizationId, day);
  if (!hotels) {
    return (
      <SafeQWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Loader />
          <Typography variant='subtitle1' style={{ marginTop: '2rem' }}>
            {translate('safeQReport.pleaseWait')}
          </Typography>
        </div>
      </SafeQWrapper>
    );
  }
  const maxWeeks = moment(day).startOf('isoWeek').isoWeeksInYear() + 1;

  const week = moment(day).isoWeek();
  const lastWeek = moment(day).subtract(1, 'week').isoWeek();
  const initialStartWeek = moment(day)
    .endOf('isoWeek')
    .subtract(1, 'year')
    .add(1, 'week')
    .isoWeek();

  const startWeekOffset = Math.max(
    0,
    getStartWeekOffset({
      startWeek: initialStartWeek,
      hotels,
      maxWeeks,
    })
  );

  let activeModuleAreas = [];
  hotels.forEach((hotel) => {
    hotel.inspections.forEach((inspection) => {
      activeModuleAreas.push(
        ...inspection.moduleTypes.map((moduleType) => moduleType.area)
      );
    });
  });
  activeModuleAreas = moduleAreas.filter(
    (item) => activeModuleAreas.indexOf(item) > -1
  );

  const startDay = moment(day)
    .endOf('isoWeek')
    .subtract(1, 'year')
    .add(1 + startWeekOffset, 'week')
    .startOf('isoWeek')
    .format('YYYY-MM-DD');

  const weeksDiff = moment(day).diff(startDay, 'week');
  const yearWeeks = [];
  for (let i = 0; i <= weeksDiff; i++) {
    yearWeeks.push(moment(startDay).add(i, 'week').isoWeek());
  }

  const selectedHotels = hotelIds.length
    ? hotels.filter((hotel) => !hotelIds.length || hotelIds.includes(hotel.id))
    : hotels;

  const filteredHotels = excludeUnansweredHotels
    ? filterAnsweredHotels({ hotels: selectedHotels, weeks: [week] })
    : selectedHotels;

  if (!filteredHotels.length) {
    return (
      <SafeQWrapper>
        <div>{translate('safeQReport.noReport')}</div>
      </SafeQWrapper>
    );
  }

  const currentWeekFullfillment = getAccumulatedScore({
    fullfillment: true,
    hotels: filteredHotels,
    weeks: [week],
  });

  const lastWeekFullfillment = getAccumulatedScore({
    fullfillment: true,
    hotels: filteredHotels,
    weeks: [lastWeek],
  });

  const fullfillmentMax = Math.max(
    currentWeekFullfillment,
    lastWeekFullfillment
  );

  return (
    <PrintWrapper onRenderFooter={() => <SafeQFooter />} footerHeight='173px'>
      <SafeQWrapper>
        <SafeQReportHeader>
          <div>
            <h1>{translate('safeQReport.headerOrganization')}</h1>
            <p>{translate('site.name')}</p>
          </div>
          <Logo width={'200'} height={'125'} />
        </SafeQReportHeader>
        <div className='flex-centering'>
          {loading && <Loader />}
          {error && <Error>{translate(error)}</Error>}
        </div>
        <SafeQHeader>
          <HeaderInformation
            week={week}
            name={name}
            totalHotels={hotels.length}
            excludedHotelNames={selectedHotels
              .filter(
                (selectedHotel) =>
                  !filteredHotels.some((hotel) => hotel.id === selectedHotel.id)
              )
              .map((hotel) => hotel.name)}
            selectedHotelNames={
              selectedHotels.length === hotels.length
                ? null
                : selectedHotels.map((hotel) => hotel.name)
            }
            day={day}
          />
          <SafeQTotalScore>
            <FullfillmentHeader>
              <h3>{translate('fullfillment').toUpperCase()}</h3>
              <Tooltip title={translate('safeQReport.fullfillmentHelp')}>
                <HelpIcon />
              </Tooltip>
            </FullfillmentHeader>
            <BarChart
              limitMax
              maintainAspectRatio
              max={Math.min(100, 10 * Math.ceil((fullfillmentMax + 1) / 10))}
              labels={['CURRENT', 'PREVIOUS']}
              data={[currentWeekFullfillment, lastWeekFullfillment]}
            />
          </SafeQTotalScore>
        </SafeQHeader>
        <LineChart
          isSorted
          lastColorBlack
          title={{
            display: true,
            text: translate('safeQReport.titleSelectedHotels'),
          }}
          xAxes={[
            {
              type: 'time',
              time: { unit: 'week', displayFormats: { week: 'v.w' } },
            },
          ]}
          data={{
            [filteredHotels.length === hotels.length
              ? translate('fullfillment')
              : translate('safeQReport.fullfillmentSelected')]: getLineData({
              fullfillment: true,
              startDay,
              hotels: filteredHotels,
              weeks: yearWeeks,
            }),
            [filteredHotels.length === hotels.length
              ? translate('safeQReport.score')
              : translate('safeQReport.scoreSelected')]: getLineData({
              startDay,
              hotels: filteredHotels,
              weeks: yearWeeks,
            }),
          }}
        />
        <div style={{ marginTop: '3rem' }}>
          <LineChart
            isSorted
            alternativeColors
            lastColorBlack
            title={{
              display: true,
              text: translate('safeQReport.titleModuleGrades'),
            }}
            xAxes={[
              {
                type: 'time',
                time: { unit: 'week', displayFormats: { week: 'v.w' } },
              },
            ]}
            data={{
              ...activeModuleAreas.reduce(
                (acc, area) => ({
                  ...acc,
                  [translate(`moduleTypeAreas.${area}`)]: getLineData({
                    startDay,
                    hotels: filteredHotels,
                    weeks: yearWeeks,
                    areas: [area],
                  }),
                }),
                {}
              ),
              [translate('safeQReport.average')]: getLineData({
                startDay,
                hotels: filteredHotels,
                weeks: yearWeeks,
              }),
            }}
          />
        </div>
        <div style={{ margin: '4rem 0' }}>
          <Typography variant='h6'>
            {translate('safeQReport.modulesFullfillmentTitle')}
          </Typography>
          <ModuleBarCharts
            areas={activeModuleAreas}
            hotels={filteredHotels}
            week={week}
            lastWeek={lastWeek}
          />
        </div>
        <div className='page-break-before' />
        <Typography variant='h4'>
          {translate('safeQReport.observations')}
        </Typography>
        <Typography variant='h5'>
          {translate('safeQReport.commonObservations')}
        </Typography>
        <LineChart
          isSorted
          alternativeColors
          lastColorBlack
          title={{
            display: true,
            text: translate('safeQReport.titleComfortZones'),
          }}
          xAxes={[
            {
              type: 'time',
              time: { unit: 'week', displayFormats: { week: 'v.w' } },
            },
          ]}
          data={{
            ...['socialDistance', 'hygiene'].reduce(
              (acc, comfortZone) => ({
                ...acc,
                [translate(`classifications.${comfortZone}`)]: getLineData({
                  startDay,
                  hotels: filteredHotels,
                  weeks: yearWeeks,
                  comfortZones: [comfortZone],
                }),
              }),
              {}
            ),
            [translate('safeQReport.average')]: getLineData({
              startDay,
              hotels: filteredHotels,
              weeks: yearWeeks,
              comfortZones: ['socialDistance', 'hygiene'],
            }),
          }}
        />
        {activeModuleAreas.map((area) => (
          <div key={area} style={{ marginTop: '4rem' }}>
            <div className='page-break-before' />
            <div style={{ marginBottom: '1rem' }}>
              <Typography variant='h5'>
                {translate(`moduleTypeAreas.${area}`).toUpperCase()}
              </Typography>
              <Typography variant='subtitle2'>{`${translate('date')}: ${moment(
                day
              )
                .startOf('isoWeek')
                .format('YYYY-MM-DD')} - ${day}`}</Typography>
            </div>
            <LineChart
              isSorted
              alternativeColors
              lastColorBlack
              title={{
                display: true,
                text: translate('safeQReport.titleModuleComfortZones').replace(
                  ':module',
                  translate(`moduleTypeAreas.${area}`)
                ),
              }}
              xAxes={[
                {
                  type: 'time',
                  time: { unit: 'week', displayFormats: { week: 'v.w' } },
                },
              ]}
              data={{
                ...['socialDistance', 'hygiene'].reduce(
                  (acc, comfortZone) => ({
                    ...acc,
                    [translate(`classifications.${comfortZone}`)]: getLineData({
                      startDay,
                      hotels: filteredHotels,
                      weeks: yearWeeks,
                      comfortZones: [comfortZone],
                      areas: [area],
                    }),
                  }),
                  {}
                ),
                [translate('safeQReport.average')]: getLineData({
                  startDay,
                  hotels: filteredHotels,
                  weeks: yearWeeks,
                  areas: [area],
                }),
              }}
            />
            <WorstQuestionsDetailed
              hotels={filteredHotels}
              week={week}
              area={area}
            />
            <WorstQuestions
              hotels={filteredHotels}
              weeks={[
                week,
                lastWeek,
                moment(day).subtract(2, 'week').isoWeek(),
                moment(day).subtract(3, 'week').isoWeek(),
              ]}
              area={area}
            />
          </div>
        ))}
        <HotelScores
          selectedHotelIds={filteredHotels.map((hotel) => hotel.id)}
          hotels={hotels}
          day={day}
          week={week}
        />
      </SafeQWrapper>
    </PrintWrapper>
  );
}
