const auditQATypes = [
  'gradeA',
  'gradeB',
  'gradeC',
  'gradeD',
  'gradeE',
  'gradeF',
  'gradeG',
  'gradeH',
  'gradeJ',
  'gradeK',
  'none',
];

export const isAuditQuestionType = (type) => {
  return auditQATypes.includes(type);
};

export const isScorePositive = (score, type) => {
  switch (type) {
    case 'gradeA': {
      return score === 1;
    }
    case 'gradeB':
    case 'gradeC':
    case 'gradeD':
    case 'gradeE':
    case 'gradeK': {
      return score === 0;
    }
    case 'gradeF': {
      return score === 1;
    }
    case 'gradeG': {
      return score !== null && score !== 0;
    }
    case 'gradeH': {
      return score === 1;
    }
    case 'none': {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const isNA = (score, type) => {
  switch (type) {
    default: {
      return score === -1;
    }
  }
};

export const validateQuestion = (question) => {
  const type = question.get('type');
  const errors = [];

  if (isAuditQuestionType(type)) {
    const answer = question.get('answer');

    if (answer && answer.get('score') !== null) {
      const score = answer.get('score');
      if (!isNA(score, type)) {
        const isPositive = isScorePositive(score, type);
        const hasComment =
          answer.get('comment') && answer.get('comment').trim().length > 0;
        const hasPicture =
          answer.get('attachments') && answer.get('attachments').count() > 0;
        if (!hasComment) {
          if (
            (isPositive && question.get('isCommentMandatoryPos')) ||
            (!isPositive && question.get('isCommentMandatoryNeg'))
          ) {
            errors.push('comment');
          }
        }
        if (!hasPicture) {
          if (
            (isPositive && question.get('isPictureMandatoryPos')) ||
            (!isPositive && question.get('isPictureMandatoryNeg'))
          ) {
            errors.push('picture');
          }
        }
      }
    } else if (answer && type === 'gradeJ') {
      if (question.get('isMandatory') && !answer.get('comment')) {
        return ['noAnswer'];
      }
    } else if (type === 'none') {
      const hasPicture =
        answer &&
        answer.get('attachments') &&
        answer.get('attachments').count() > 0;
      if (question.get('isPictureMandatoryPos') && !hasPicture) {
        errors.push('picture');
      }
    } else {
      if (question.get('isMandatory')) {
        return ['noAnswer'];
      }
    }
  }
  return errors.length > 0 ? errors : undefined;
};

export const validateQuestions = (questions) => {
  let errorMap = {};
  questions.forEach((question) => {
    const errors = validateQuestion(question);
    if (errors) {
      errorMap[`${question.get('id')}`] = errors;
    }
    if (question.get('children')) {
      errorMap = {
        ...errorMap,
        ...validateQuestions(question.get('children')),
      };
    }
  });
  return errorMap;
};
