import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import ReplayIcon from '@material-ui/icons/Replay';
import IconButton from '@material-ui/core/IconButton';
import Card from 'components/inspection/ModulePicker/styled/Card';

export const CardWrapper = Card.extend`
  position: relative;
  ${(props) => (props.isSkipped ? 'background: #e0e0e0;' : '')}
  &:hover {
    transform: scale(1.01);
  }
`;

export const Span = styled.span`
  opacity: 0.5;
  margin-top: 2px;
`;

export function ToggleSkipButton({ isSkipped, onClick }) {
  return (
    <IconButton
      style={{ position: 'absolute', top: '10px', right: '14px' }}
      onClick={onClick}
    >
      {isSkipped && <ReplayIcon />}
      {!isSkipped && <CloseIcon />}
    </IconButton>
  );
}
