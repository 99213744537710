import { request } from 'actions/utils';

export default function setFollowUp({ inspectionId, moduleId, comment }) {
  return async (dispatch) => {
    const action = await request({
      action: 'SET_REPORT_MODULE_FOLLOW_UP',
      path: `/api/inspections/${inspectionId}/modules/${moduleId}/setFollowUp`,
      method: 'POST',
      body: { comment },
      extraPayload: {
        moduleId,
        comment,
      },
    });
    await dispatch(action);
  };
}
