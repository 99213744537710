import { connect } from 'react-redux';
import InspectionModuleAdmin from './InspectionModuleAdmin';
import inspectionModuleActions from 'actions/admin/inspectionModule';
import inspectionTypeActions from 'actions/admin/inspectionType';
import hotelActions from 'actions/admin/hotel';
import { goBack } from 'connected-react-router';

function mapStateToProps(state, ownProps) {
  return {
    error: state.getIn(['admin', 'inspectionModules', 'error']),
    loading: state.getIn(['admin', 'inspectionModules', 'loading']),
    inspectionModules: state.getIn(['admin', 'inspectionModules', 'list']),
    inspectionType: state.getIn([
      'admin',
      'inspectionTypes',
      'current',
      'data',
      'name',
    ]),
    inspectionTypeId: ownProps.match.params.inspectionTypeId,
    hotelId: state.getIn([
      'admin',
      'inspectionTypes',
      'current',
      'data',
      'hotelId',
    ]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    goBack: (hotelId) => {
      if (hotelId) {
        dispatch(hotelActions.openHotel(hotelId));
        return;
      }
      dispatch(goBack());
    },
    load: (inspectionTypeId) => {
      dispatch(inspectionModuleActions.load(inspectionTypeId));
      dispatch(inspectionTypeActions.get(inspectionTypeId));
    },
    updateInspectionModule: ({
      id,
      ordinal,
      isMandatory,
      isDefaultNA,
      useTabs,
      inspectionTypeId,
    }) =>
      dispatch(
        inspectionModuleActions.update({
          id,
          ordinal,
          isMandatory,
          isDefaultNA,
          useTabs,
          inspectionTypeId,
        })
      ),
    openDeleteModal: (inspectionModule) =>
      dispatch(inspectionModuleActions.openDeleteModal(inspectionModule)),
    openCreateModal: () => dispatch(inspectionModuleActions.openCreateModal()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InspectionModuleAdmin);
