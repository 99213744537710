import {request} from 'actions/utils';
import urls from 'globals/urls';

export default function({
  inspectionId,
  moduleId
}) {
  return request({
    action: 'MARK_MODULE_FINISHED',
    path: `/api/inspections/${inspectionId}/modules/${moduleId}/done`,
    method: 'POST',
    redirect: urls.inspectionStart.replace(':id', inspectionId)
  });
}
