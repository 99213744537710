import React, { useState, useEffect } from 'react';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import Logo from 'components/general/Logo';
import translate from 'utils/translate';
import BarChart from './BarChart';
import { rawRequest } from 'actions/utils';
import {
  Wrapper,
  Hotel,
  HotelTitle,
  HotelAddress,
  TotalScore,
  MetaData,
  Header,
  ReportHeader,
  Footer,
} from 'components/admin/Report/styled';
import moment from 'moment';
import styled from 'styled-components';
import {
  getAccumulatedFullfillment,
  getLineData,
  getAverage,
  getActiveModules,
} from './utils';
import LineChart from './LineChart';
import FullfillmentChart from './FullfilmentChart';
import ModuleSummary from './ModuleSummary/index';
import Typography from '@material-ui/core/Typography';
import PrintWrapper from 'components/general/PrintWrapper';
import { getSelectedLanguage } from 'utils/language';
import { moduleAreas } from 'globals/moduleAreas';

const TimeTable = styled.table`
  margin-top: 18px;
  border-collapse: collapse;
  td,
  th {
    text-align: left;
    border: 1px solid ${({ theme }) => theme.palette.grey[300]};
    padding: 3px 5px;
    min-width: 200px;
  }
`;

const SafeQTotalScore = styled(TotalScore)`
  height: initial;
`;

const SafeQFooter = styled(Footer)`
  @media print {
    padding: 0 7px;
  }
`;

const SafeQWrapper = styled(Wrapper)`
  h4 {
    margin: 10px 0;
  }
`;

const SafeQReportHeader = styled(ReportHeader)`
  display: flex;
  justify-content: space-between;

  h1 {
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const SafeQHeader = styled(Header)`
  min-height: 370px;
`;

const BarChartWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const BarChartAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export function useDailyReport(hotelId, day, inspectionType) {
  const [reports, setReports] = useState(null);
  const language = getSelectedLanguage();
  useEffect(() => {
    if (hotelId <= 0) {
      return;
    }

    try {
      rawRequest({
        path: `/api/admin/hotels/${hotelId}/safeq-report/daily?${[
          day ? `day=${day}` : '',
          language.shortCode ? `languageShortCode=${language.shortCode}` : '',
          inspectionType ? `inspectionType=${inspectionType}` : '',
        ]
          .filter((p) => p)
          .join('&')}`,
      }).then((response) => {
        setReports(response);
      });
    } catch (error) {
      console.error(error);
    }
  }, [hotelId, day, language.shortCode, inspectionType]);

  return reports;
}

export default function SafeQReport({
  error,
  loading,
  hotelId,
  day,
  inspectionType,
}) {
  const reports = useDailyReport(hotelId, day, inspectionType);
  if (!reports) {
    return (
      <SafeQWrapper>
        <div className='flex-centering'>
          <Loader />
        </div>
      </SafeQWrapper>
    );
  }
  if (reports.length === 0) {
    return (
      <SafeQWrapper>
        <div>{translate('safeQReport.noReport')}</div>
      </SafeQWrapper>
    );
  }
  const activeModules = getActiveModules(reports);
  const fullfillmentLines = {};

  activeModules.forEach((m) => {
    fullfillmentLines[translate(`moduleTypeAreas.${m}`)] = getLineData(
      reports,
      m
    );
  });

  const average = getAverage(Object.values(fullfillmentLines));
  const commonBarChartProps = {
    width: Math.max(800 / activeModules.length, 190),
    height: 250,
    report: reports,
  };

  const areas = moduleAreas;
  const firstModule = areas.filter((area) => activeModules.includes(area));

  return (
    <PrintWrapper onRenderFooter={() => <SafeQFooter />} footerHeight='173px'>
      <SafeQWrapper>
        <SafeQReportHeader>
          <div>
            <h1>{translate('safeQReport.headerDaily')}</h1>
            <p>{translate('site.name')}</p>
          </div>
          <Logo width={'200'} height={'125'} />
        </SafeQReportHeader>
        <div className='flex-centering'>
          {loading && <Loader />}
          {error && <Error>{translate(error)}</Error>}
        </div>
        <SafeQHeader>
          <MetaData style={{ flex: '1 0 0' }}>
            <Typography>
              {moment(reports[0].date).format('YYYY-MM-DD')}
            </Typography>
            <Hotel>
              <HotelTitle>{reports[0].hotel.name}</HotelTitle>
              <HotelAddress>{reports[0].hotel.address}</HotelAddress>
            </Hotel>
            {inspectionType && (
              <>
                <Typography color='secondary'>
                  {reports[0].inspectionTypeName}
                </Typography>
              </>
            )}
            <TimeTable>
              <thead>
                <tr>
                  <th>{translate('safeQReport.module')}</th>
                  <th>{translate('safeQReport.endTime')}</th>
                </tr>
              </thead>
              <tbody>
                {activeModules.map((m) => {
                  const module = reports[0].modules.find(
                    (mod) => mod.area === m
                  );
                  return (
                    <tr key={m}>
                      <td>{translate(`moduleTypeAreas.${m}`)}</td>
                      <td>
                        {module.finishedAt
                          ? moment(module.finishedAt).format('HH:mm')
                          : '-'}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td>{translate('safeQReport.session')}</td>
                  <td>{moment(reports[0].endTime).format('HH:mm')}</td>
                </tr>
              </tbody>
            </TimeTable>
          </MetaData>
          <SafeQTotalScore>
            <h3>{translate('fullfillment').toUpperCase()}</h3>
            <BarChart
              maintainAspectRatio
              labels={['CURRENT', 'PREVIOUS']}
              data={[
                getAccumulatedFullfillment([reports[0]]),
                getAccumulatedFullfillment(reports[1] ? [reports[1]] : []),
              ]}
            />
          </SafeQTotalScore>
        </SafeQHeader>
        <h4>{translate('safeQReport.answeredQuestionsLastWeek')}</h4>
        <LineChart
          data={{
            ...fullfillmentLines,
            [translate('safeQReport.average')]: average,
          }}
        />

        <h4 className='page-break-before centered'>
          {translate('safeQReport.answeredQuestions')}
        </h4>
        <BarChartWrapper charts={activeModules.length}>
          {areas.map((area) => (
            <BarChartAreaWrapper>
              <FullfillmentChart
                {...commonBarChartProps}
                show={activeModules.includes(area)}
                title={translate(`moduleTypeAreas.${area}`)}
                comfortZone='socialDistance'
                area={area}
              />
              <FullfillmentChart
                {...commonBarChartProps}
                show={activeModules.includes(area)}
                comfortZone='hygiene'
                area={area}
              />
            </BarChartAreaWrapper>
          ))}
        </BarChartWrapper>

        <h4>{translate('generalCommentsCategories.global').toUpperCase()}</h4>
        <Typography color={reports[0].global ? 'textPrimary' : 'textSecondary'}>
          {reports[0].global || translate('noComment')}
        </Typography>
        <h4>
          {translate('comments').toUpperCase()}{' '}
          {translate('classifications.hygiene').toUpperCase()}
        </h4>
        <Typography
          color={reports[0].hygiene ? 'textPrimary' : 'textSecondary'}
        >
          {reports[0].hygiene || translate('noComment')}
        </Typography>
        <h4>
          {translate('comments').toUpperCase()}{' '}
          {translate('classifications.socialDistance').toUpperCase()}
        </h4>
        <Typography
          color={reports[0].socialDistance ? 'textPrimary' : 'textSecondary'}
        >
          {reports[0].socialDistance || translate('noComment')}
        </Typography>
        {areas.map((area, i) => (
          <>
            <div className='page-break-before' />
            <ModuleSummary
              first={firstModule.length > 0 ? firstModule[0] === area : false}
              show={activeModules.includes(area)}
              report={reports}
              area={area}
              title={translate(`moduleTypeAreas.${area}`)}
            />
          </>
        ))}
      </SafeQWrapper>
    </PrintWrapper>
  );
}
