import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { useScreenSize } from 'hooks/useScreenSize';
import { breakpoints } from 'globals/media';
import translate from 'utils/translate';

const Container = styled.div`
  height: 100%;
  min-height: 130px;
  width: 40px;
  background: white;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 3px 3px 0px 0px;
  position: relative;
`;

const InnerBar = styled.div`
  width: 40px;
  height: ${(props) => Math.ceil(props.percent * 100)}%;
  background: ${(props) => props.color};
  border-radius: 3px 3px 0px 0px;
  position: absolute;
  bottom: 0;
`;

const PercentageText = styled.p`
  color: ${(props) => props.color} !important;
  font-size: 2em;
  margin: 5px 0%;
`;

const Label = styled.p`
  margin: 5px 0;
  font-size: 12px;
  text-align: center;
  width: 100%;
`;

export default function Bar({ color, percent, label, language }) {
  const screenSize = useScreenSize();

  let labelWidth = '175px';

  if (
    screenSize.width < breakpoints.smallDesktop &&
    screenSize.width > breakpoints.tablet
  ) {
    labelWidth = '250px';
  }

  if (screenSize.width < breakpoints.smallTablet) {
    labelWidth = '250px';
  }

  if (screenSize.width > breakpoints.desktop) {
    labelWidth = 'auto';
  }

  return (
    <>
      <Grid
        container
        item
        style={{ height: '100%' }}
        spacing={2}
        wrap='nowrap'
        justify='center'
      >
        <Grid item>
          <Container>
            <InnerBar percent={percent} color={color} />
          </Container>
        </Grid>
        <Grid
          item
          container
          direction='column'
          justify='center'
          alignItems='center'
          xs='auto'
          wrap='nowrap'
          style={{
            maxWidth: labelWidth,
            width: 'auto',
          }}
        >
          <Grid
            container
            item
            sm={'auto'}
            direction='column'
            alignItems='center'
            style={{ minHeight: '75px' }}
          >
            <PercentageText color={color}>
              {Math.ceil(percent * 100)}%
            </PercentageText>
            <Label>
              {translate('dashboards.view.average', {
                forceLanguage: language,
              })}
            </Label>
          </Grid>
          <Grid item container sm={'auto'} alignItems='center'>
            <Label>
              <strong>{label}</strong>
            </Label>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
