import { connect } from 'react-redux';
import List from './List';
import userActions from 'actions/admin/user';
import confirm from 'utils/confirm';
import translate from 'utils/translate';
import organizationActions from 'actions/admin/organization';

function mapStateToProps(state) {
  return {
    error: state.getIn(['organization', 'error']),
    loading: state.getIn(['organization', 'loading']),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    load: () => {
      dispatch(
        organizationActions.loadOrganization.users(
          ownProps.organizationId || null
        )
      );
    },
    openUser: (userId) => {
      if (ownProps.organizationId) {
        dispatch(
          userActions.openOrganizationView(userId, ownProps.organizationId)
        );
        return;
      }
      dispatch(userActions.openView(userId));
    },
    deleteUser: async (user) => {
      const shouldDelete = await confirm(
        translate('users.delete.confirmText').replace(
          '{name}',
          user.get('name')
        ),
        {
          title: translate('users.delete.header'),
          confirmText: translate('users.delete'),
        }
      );
      if (shouldDelete) {
        dispatch(
          userActions.deleteUser(user.get('id'), ownProps.onUserDeleted)
        );
      }
    },
    openCreateUserModal: (organizationId) =>
      dispatch(userActions.openCreateModal(organizationId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
