import { request } from 'actions/utils';

export default function saveClusterComments({
  inspectionId,
  moduleId,
  id,
  value
}) {
  return request({
    action: 'SAVE_CLUSTER_COMMENTS',
    method: 'POST',
    path: `/api/inspections/${inspectionId}/clusters/${id}/comments`,
    body: {
      comment: value,
      inspectionModuleId: moduleId
    },
    extraPayload: {
      id,
      moduleId
    }
  });
}
