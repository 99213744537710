import React from 'react';
import logo from './qlogo.svg';
import logoNoText from './qlogo-notext.svg';
import logoInverse from './qlogo-notext.svg';
import logoInverseNoText from './qlogo-inverse-notext.svg';
import styled from 'styled-components';

const Img = styled.img.attrs({
  height: (props) => (props.height ? `${props.height}px` : '150px'),
  width: (props) => (props.width ? `${props.width}px` : '120px'),
})``;

export default function ({ width, height, inverse, text = true, style }) {
  let src = text ? logo : logoNoText;
  if (inverse) {
    src = text ? logoInverse : logoInverseNoText;
  }
  return (
    <Img
      src={src}
      alt='logo'
      width={width}
      height={height}
      style={style || {}}
    />
  );
}
