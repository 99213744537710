import { request } from 'actions/utils';

export default function loadQuestions(moduleTypeId, languageShortCode) {
  return request({
    action: 'LOAD_QUESTIONS',
    path: `/api/admin/questions/${moduleTypeId}?languageShortCode=${
      languageShortCode || ''
    }`,
  });
}
