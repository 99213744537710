import React from 'react';
import {connect} from 'react-redux';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import ModalWrapper from 'components/general/ModalWrapper';
import InnerModal from 'components/general/InnerModal';
import Button from 'components/general/Button';
import inspectionModuleActions from 'actions/admin/inspectionModule';

function DeleteModal({visible, loading, error, remove, closeModal, inspectionModule, inspectionTypeId}) {
  if (!visible) {
    return null;
  }
  return (
    <ModalWrapper onClose={closeModal}>
      <InnerModal
        onSubmit={e => {
          e.preventDefault();
          remove({
            id: inspectionModule.get('id'),
            inspectionTypeId
          });
        }}
      >
        <h4>{translate('inspectionModules.delete.header')}</h4>
        <div>{translate('inspectionModules.delete.confirmText').replace('{name}', inspectionModule.get('name'))}</div>
        <Button>{translate('inspectionModules.delete')}</Button>
        {loading && <Loader />}
        {error && <Error>{translate(error)}</Error>}
      </InnerModal>
    </ModalWrapper>
  );
}

export default connect(
  (state, ownProps) => {
    return {
      visible: state.getIn(['admin', 'inspectionModules', 'deleteModal', 'visible']),
      loading: state.getIn(['admin', 'inspectionModules', 'deleteModal', 'loading']),
      error: state.getIn(['admin', 'inspectionModules', 'deleteModal', 'error']),
      inspectionModule: state.getIn(['admin', 'inspectionModules', 'deleteModal', 'inspectionModule']),
      ...ownProps
    };
  },
  dispatch => ({
    remove: ({id, inspectionTypeId}) => dispatch(inspectionModuleActions.remove({id, inspectionTypeId})),
    closeModal: () => dispatch(inspectionModuleActions.closeDeleteModal())
  })
)(DeleteModal);
