import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'globals/media';

const Logo = styled.img`
  height: 50px;
  max-width: 200px;
  margin-right: 7px;
  ${media.tablet`
    max-width: 170px;
  `}
  ${media.smallTablet`
    max-width: 130px;
  `}
  ${media.largeHandheld`
    max-width: 85px;
  `}
  ${media.handheld`
    max-width: 115px;
  `}
`;

export default function OrganizationLogo() {
  const [logoLoadError, setLogoLoadError] = useState(false);
  const [loadedLogo, setLoadedLogo] = useState(false);

  return logoLoadError ? null : (
    <>
      <Logo
        style={{
          visibility: loadedLogo ? 'visible' : 'hidden',
        }}
        src='/api/admin/organizations/my/logo'
        alt='logo'
        onError={() => setLogoLoadError(true)}
        onLoad={() => setLoadedLogo(true)}
      />
    </>
  );
}
