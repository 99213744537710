import React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Tooltip from '@material-ui/core/Tooltip';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import IconButton from '@material-ui/core/IconButton';
import userActions from 'actions/admin/user';
import hotelActions from 'actions/hotel';
import translate from 'utils/translate';
import { Typography } from '@material-ui/core';

const RowButton = styled(IconButton)`
  padding: 4px !important;
  margin: 4px !important;
`;

const StyledRow = styled(TableRow)`
  cursor: pointer;
`;

function Users({ hotel, openUser, unlinkFromHotel }) {
  const users = hotel.get('users', List());
  if (!users.size)
    return (
      <Typography variant='subtitle1'>{translate('hotel.noUsers')}</Typography>
    );

  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell>{translate('users.edit.name')}</TableCell>
          <TableCell>{translate('users.edit.email')}</TableCell>
          <TableCell>{translate('users.edit.agentCode')}</TableCell>
          <TableCell>{translate('users.edit.role')}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((user) => (
          <StyledRow
            key={user.get('id')}
            hover
            onClick={() => openUser(user.get('id'))}
          >
            <TableCell>{user.get('name')}</TableCell>
            <TableCell>{user.get('email')}</TableCell>
            <TableCell>{user.get('agentCode')}</TableCell>
            <TableCell>
              {translate(`users.edit.role.${user.get('role')}`)}
            </TableCell>
            <TableCell style={{ width: '85px' }}>
              <Tooltip title={translate('users.edit.unlinkFromHotel')}>
                <RowButton
                  size='medium'
                  onClick={(event) => {
                    event.stopPropagation();
                    unlinkFromHotel(user.get('id'), hotel.get('id'));
                  }}
                >
                  <LinkOffIcon />
                </RowButton>
              </Tooltip>
            </TableCell>
          </StyledRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default connect(
  (state) => ({ hotel: state.getIn(['hotel', 'current']) }),
  (dispatch) => ({
    openUser: (userId) => dispatch(userActions.openView(userId)),
    unlinkFromHotel: async (id, hotelId) => {
      await dispatch(userActions.unlinkFromHotel(id, hotelId));
      dispatch(hotelActions.loadHotel(hotelId));
    },
  })
)(Users);
