import React from 'react';
import { List } from 'immutable';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Answer from './Answer';
import Comment from './Comment';
import media from 'globals/media';
import Typography from '@material-ui/core/Typography';
import translate from 'utils/translate';
import isImagePath from 'utils/isImagePath';
import ZoomableImage from 'components/general/ZoomableImage';

const ImageGrid = styled.div`
  display: grid;
  grid-column-gap: 7px;
  grid-row-gap: 7px;
  grid-template-columns: repeat(auto-fit, 300px);
`;

const ModuleWrapper = styled.div`
  margin-bottom: 2rem;

  ${media.handheld`
    h2 {
      margin-top: 2rem;
      font-size: 1.5rem;
    }
  `}
`;

const SubQuestionContent = styled.div`
  padding-left: ${({ indent }) =>
    indent === 1 ? '20px' : indent === 2 ? '40px' : 0} !important;
  font-weight: ${({ indent }) => (indent === 0 ? '500' : '400')};
`;

const ReferenceNumber = styled.div`
  color: ${({ theme, indent }) => theme.palette.secondary.main};
`;

export default function Module({ name, clusters, roomNumbers }) {
  if (
    !clusters ||
    clusters.every((cluster) => !cluster.get('questions', List()).size)
  ) {
    return null;
  }

  const domClusters = clusters.map((cluster, i) => {
    const rows = calculateRows(cluster.get('questions'), 0);

    return (
      <div key={i}>
        <h3>{cluster.get('name')}</h3>
        <Typography variant='subtitle1'>
          <em>{translate('comment')}:</em> {cluster.get('comment')}
        </Typography>
        {roomNumbers && roomNumbers.length > 0 && (
          <Typography variant='subtitle1'>
            <em>{translate('auditQA.controlledRooms')}: </em>{' '}
            <strong>{roomNumbers.join(', ')}</strong>
          </Typography>
        )}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translate('question')}</TableCell>
                <TableCell>{translate('answer')}</TableCell>
                <TableCell>{translate('comment')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  });

  return (
    <ModuleWrapper>
      <h2>{name}</h2>
      {domClusters}
    </ModuleWrapper>
  );

  function calculateRows(questions, indent) {
    if (!questions || questions.size === 0) {
      return [];
    }
    let result = [];

    questions.forEach((q) => {
      result.push(formatQuestion(q));
      result = result.concat(calculateRows(q.get('children'), indent + 1));
    });

    return result;

    function formatQuestion(question) {
      const size = indent > 0 ? 'small' : 'medium';
      const padding = indent > 0 ? 'checkbox' : 'default';
      return (
        <>
          <TableRow key={question.get('id')}>
            <TableCell size={size} padding={padding}>
              <Typography variant='body2' component='span'>
                {question.get('referenceNumber') && indent === 0 && (
                  <ReferenceNumber>
                    {question.get('referenceNumber')}
                  </ReferenceNumber>
                )}
                <SubQuestionContent indent={indent}>
                  {question.get('content') || question.get('defaultContent')}
                </SubQuestionContent>
              </Typography>
            </TableCell>
            <TableCell size={size} style={{ width: '120px' }}>
              <Typography variant='body2'>
                <Answer
                  type={question.get('type')}
                  score={question.getIn(['answers', 0, 'score'])}
                  comment={question.getIn(['answers', 0, 'comment'])}
                />
              </Typography>
            </TableCell>
            <TableCell size={size} style={{ width: '120px' }}>
              <Typography variant='body2'>
                <Comment
                  type={question.get('type')}
                  comment={question.getIn(['answers', 0, 'comment'])}
                />
              </Typography>
            </TableCell>
          </TableRow>
          {question.getIn(['answers', 0, 'attachments'], []).length !== 0 && (
            <TableRow>
              <TableCell colSpan={3}>
                <ImageGrid>
                  {question
                    .getIn(['answers', 0, 'attachments'], [])
                    .filter((file) => !file.get('isFollowUp'))
                    .map((file) =>
                      isImagePath(file.get('path')) ? (
                        <ZoomableImage
                          width='300px'
                          height='auto'
                          src={`/files/${file.get('path')}`}
                          alt=''
                        />
                      ) : null
                    )}
                </ImageGrid>
              </TableCell>
            </TableRow>
          )}
        </>
      );
    }
  }
}
