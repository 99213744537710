const INITIAL_STATE = {
  loading: false,
  error: '',
  serverQuestions: [],
  questions: []
};

export default function reducer(state = INITIAL_STATE, action = {type: ''}) {
  switch (action.type) {
    case 'INITIALIZE_FROM_PROPS': {
      return {
        ...state,
        serverQuestions: action.payload.questions,
        questions: action.payload.questions
      };
    }
    case 'ANSWER_QUESTION_STARTED': {
      return {...state, loading: true, error: ''};
    }
    case 'ANSWER_QUESTION_FAILED': {
      return {...state, loading: false, error: action.payload};
    }
    case 'ANSWER_QUESTION_DONE': {
      const id = parseInt(action.payload.contextQuestionId, 10);
      const index = state.serverQuestions.findIndex(q => q.id === id);
      const newServerQuestions = [].concat(state.serverQuestions);
      newServerQuestions[index].answer = action.payload;
      return {...state, loading: false, serverQuestions: newServerQuestions};
    }
    case 'CHANGE_INPUT': {
      const {value, index} = action.payload;
      const newQuestions = [].concat(state.questions);
      const newAnswer = Object.assign({}, newQuestions[index].answer, {
        content: value
      });
      newQuestions[index] = Object.assign({}, newQuestions[index], {
        answer: newAnswer
      });
      return {...state, questions: newQuestions};
    }
    default:
      return state;
  }
}
