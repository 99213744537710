import React, { useState } from 'react';
import styled from 'styled-components';

import media from 'globals/media';
import { connect } from 'react-redux';
import EditableComment from 'components/inspection/Questions/QuestionRow/Comment';
import translate from 'utils/translate';
import inspectionActions from 'actions/inspection';

const ModuleWrapper = styled.div`
  margin-bottom: 20px;
  margin-top: 10px;

  ${media.handheld`
    h2 {
      margin-top: 2rem;
      font-size: 1.5rem;
    }
  `}
`;

function mapDispatchToProps(dispatch, ownProps) {
  return {
    update: async (comments) => {
      await dispatch(
        inspectionActions.update({
          inspectionId: ownProps.inspectionId,
          ...comments,
          type: 'comfortZones',
        })
      );
    },
    updateState: (comments) => {
      dispatch({
        type: `UPDATE_INSPECTION_DONE`,
        payload: {},
        meta: comments,
      });
    },
  };
}

function ComfortZones({ zones = [], update, updateState }) {
  const [values, setValues] = useState(
    zones.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.name]: curr.value,
      };
    }, {})
  );

  return zones.map((zone) => (
    <ModuleWrapper>
      <h2>{translate(`classifications.${zone.name}`)}</h2>
      <EditableComment
        label={translate('questions.followUp.moduleFollowUp')}
        comment={zone.value}
        multiline
        minmize={false}
        variant='filled'
        fullWidth
        setComment={() => update(values)}
        onChange={(newComment) => {
          const newValues = {
            ...values,
            [zone.name]: newComment,
          };
          setValues(newValues);
          updateState(newValues);
        }}
      />
    </ModuleWrapper>
  ));
}

export default connect(null, mapDispatchToProps)(ComfortZones);
