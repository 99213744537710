import React, { useEffect } from 'react';
import translate from 'utils/translate';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { useScreenSize } from 'hooks/useScreenSize';
import LoadingOverlay from 'components/general/LoadingOverlay';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { breakpoints } from 'globals/media';
import moment from 'moment';
import InspectionList from './components/InspectionList';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import urls from 'globals/urls';
import StatusIcon from './components/StatusIcon';

const RowButton = styled(IconButton)`
  padding: 0px !important;
  margin: 0px !important;
  svg {
    font-size: 20px;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default function AuditQA({
  loading,
  list = [],
  load,
  onRowClick,
  activeTab = 'ongoing',
  onTabChange,
}) {
  const screenSize = useScreenSize();

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  list = list.map((item) =>
    item
      .set('inspectionType', item.getIn(['inspectionType', 'name']))
      .set('user', item.getIn(['user', 'name']))
      .set('hotel', item.getIn(['hotel', 'name']))
  );

  const columns = {
    status: {
      name: '',
      field: 'status',
      onRender: (status) => {
        return <StatusIcon status={status} />;
      },
    },
    hotel: {
      field: 'hotel',
    },
    user: {
      field: 'user',
    },
    startTime: {
      field: 'startTime',
      onRender: (value) => moment(value).format('YYYY-MM-DD'),
    },
    lastEditedBy: {
      field: 'lastEditedByUser',
      onRender: (value) => (value ? value.get('name') : '-'),
    },
    agentEndTimeSetBy: {
      field: 'agentEndTimeSetByUser',
      onRender: (value) => (value ? value.get('name') : '-'),
    },
    adminEndTimeSetBy: {
      field: 'adminEndTimeSetByUser',
      onRender: (value) => (value ? value.get('name') : '-'),
    },
    agentEndTime: {
      field: 'agentEndTime',
      onRender: (value) => moment(value).format('YYYY-MM-DD'),
    },
    adminEndTime: {
      field: 'adminEndTime',
      onRender: (value) => moment(value).format('YYYY-MM-DD'),
    },
    inspectionType: {
      field: 'inspectionType',
    },
    isFollowUp: {
      field: 'followUpToInspectionId',
      onRender: (value) =>
        value ? (
          <Link
            to={urls.reportAuditQA.replace(':inspectionId', value)}
            target='_blank'
            rel='noreferrer noopener'
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <RowButton size='small'>
              <OpenInNewIcon />
            </RowButton>
          </Link>
        ) : (
          '-'
        ),
    },
  };

  const reports = list.filter((inspection) => inspection.get('adminEndTime'));
  const forReview = list.filter(
    (inspection) =>
      !inspection.get('adminEndTime') && inspection.get('agentEndTime')
  );
  const ongoing = list.filter(
    (inspection) =>
      !inspection.get('adminEndTime') && !inspection.get('agentEndTime')
  );

  const handleTabChange = (_, newValue) => {
    onTabChange(newValue);
  };

  return (
    <Wrapper>
      <LoadingOverlay show={loading} />
      <Header>
        <Typography variant='h5' component='h1' gutterBottom>
          {translate('auditQA')}
        </Typography>
      </Header>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant={
          screenSize.width < breakpoints.handheld ? 'fullWidth' : 'standard'
        }
        style={{ marginBottom: '10px' }}
      >
        <Tab
          value='ongoing'
          label={`${translate('auditQA.ongoing')} (${ongoing.size})`}
        />
        <Tab
          value='forReview'
          label={`${translate('auditQA.forReview')} (${forReview.size})`}
        />
        <Tab
          value='reports'
          label={`${translate('auditQA.reports')} (${reports.size})`}
        />
      </Tabs>
      {activeTab === 'ongoing' && (
        <InspectionList
          items={ongoing}
          onRowClick={onRowClick}
          initialSort={columns.startTime}
          columns={[
            columns.status,
            columns.startTime,
            columns.hotel,
            columns.user,
            columns.lastEditedBy,
            columns.inspectionType,
            columns.isFollowUp,
          ]}
        />
      )}
      {activeTab === 'forReview' && (
        <InspectionList
          items={forReview}
          onRowClick={onRowClick}
          columns={[
            columns.startTime,
            columns.hotel,
            columns.user,
            columns.lastEditedBy,
            columns.agentEndTime,
            columns.agentEndTimeSetBy,
            columns.inspectionType,
            columns.isFollowUp,
          ]}
        />
      )}
      {activeTab === 'reports' && (
        <InspectionList
          items={reports}
          onRowClick={onRowClick}
          columns={[
            columns.startTime,
            columns.hotel,
            columns.user,
            columns.adminEndTime,
            columns.adminEndTimeSetBy,
            columns.inspectionType,
            columns.isFollowUp,
          ]}
        />
      )}
    </Wrapper>
  );
}
