import {fromJS} from 'immutable';
import deleteModal from './deleteModal';

const defaultState = fromJS({
  list: [],
  loading: false,
  error: '',
  deleteModal: deleteModal()
});

export default function(state = defaultState, action = {type: ''}) {
  state = state.set('deleteModal', deleteModal(state.get('deleteModal'), action));
  switch (action.type) {
    case 'LOAD_ADMIN_INSPECTIONS_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'LOAD_ADMIN_INSPECTIONS_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'LOAD_ADMIN_INSPECTIONS_DONE': {
      return state.set('list', fromJS(action.payload)).set('loading', false);
    }
    default: {
      return state;
    }
  }
}
