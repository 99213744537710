import React, {Component} from 'react';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import Input from 'components/general/Input';
import translate from 'utils/translate';
import ModalWrapper from 'components/general/ModalWrapper';
import InnerModal from 'components/general/InnerModal';
import Button from 'components/general/Button';
import localReducer from './reducer';
import update from './update';
import styled from 'styled-components';
import LocalFlux from 'components/general/LocalFlux';
import ModalButtonRow from 'components/general/ModalButtonRow';

const Select = styled.select`
  width: 210px;
  outline: none;
  border: none;
  height: 30px;
`;

export default class EditModal extends Component {
  update = async (e, state, dispatch) => {
    e.preventDefault();
    const success = await update({data: state}, dispatch);
    if (success) {
      this.props.closeModal();
    }
  };
  render() {
    const {closeModal, questionClusters} = this.props;
    const clusterOptions = questionClusters.map(cluster => (
      <option key={cluster.get('id')} value={cluster.get('id')}>
        {cluster.get('name')}
      </option>
    ));
    return (
      <LocalFlux reducer={localReducer} {...this.props}>
        {({state, changeInput, dispatch}) => (
          <ModalWrapper onClose={closeModal}>
            <InnerModal onSubmit={e => this.update(e, state, dispatch)}>
              <h4>{translate('questions.editContextQuestion.header')}</h4>
              <Input
                type='text'
                value={state.content}
                onChange={e => changeInput({field: 'content', value: e.target.value})}
                placeholder={translate('questions.create.content')}
              />
              <Input
                type='number'
                value={state.ordinal}
                onChange={e => changeInput({field: 'ordinal', value: e.target.value})}
                placeholder={translate('ordinal')}
              />

              <div>
                <label>{translate('questions.createContextQuestion.showInReportHeader')}</label>
                <input
                  type='checkbox'
                  checked={state.orderInReportHeader !== null}
                  onChange={e => {
                    const value = e.target.checked ? 0 : null;
                    changeInput({field: 'orderInReportHeader', value});
                  }}
                />
              </div>
              {state.orderInReportHeader !== null && (
                <React.Fragment>
                  <label>{translate('questions.createContextQuestion.orderInReportHeader')}</label>
                  <Input
                    type='number'
                    value={state.orderInReportHeader}
                    onChange={e => changeInput({field: 'orderInReportHeader', value: e.target.value})}
                    placeholder={translate('questions.createContextQuestion.orderInReportHeader')}
                  />
                </React.Fragment>
              )}
              <Select
                value={state.questionClusterId}
                onChange={e =>
                  changeInput({
                    field: 'questionClusterId',
                    value: parseInt(e.target.value, 10)
                  })
                }
              >
                <option value={-1}>{translate('choose')}</option>
                {clusterOptions}
              </Select>
              <ModalButtonRow>
                <Button>{translate('questions.editContextQuestion.button')}</Button>
              </ModalButtonRow>
              {state.loading && <Loader />}
              {state.error && <Error>{translate(state.error)}</Error>}
            </InnerModal>
          </ModalWrapper>
        )}
      </LocalFlux>
    );
  }
}
