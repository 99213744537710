import { request } from 'actions/utils';
import { getSelectedLanguage } from 'utils/language';

export default function loadInspectionQuestions(inspectionId, moduleId) {
  const language = getSelectedLanguage();

  return request({
    action: 'LOAD_INSPECTION_QUESTIONS',
    path: `/api/inspections/${inspectionId}/modules/${moduleId}?languageShortCode=${
      language.shortCode || ''
    }`,
    extraPayload: {
      moduleId,
    },
  });
}
