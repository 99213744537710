export const moduleAreas = [
  'room',
  'breakfast',
  'reception',
  'restaurant',
  'conference',
  'entrance',
  'publicAreas',
  'gym',
  'assemblyHall',
  'diningRoom',
  'bar',
  'staffRooms',
  'copySpace',
  'classrooms',
  'workrooms',
  'groupRooms',
  'cafe',
  'store',
  'information',
  'kitchen',
  'toilets',
  'lounge',
  'office',
  'coworking'
];