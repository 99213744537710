import React from 'react';
import translate from 'utils/translate';
import { useTheme } from '@material-ui/core/styles';
import isImagePath from 'utils/isImagePath';
import ZoomableImage from 'components/general/ZoomableImage';
import styled from 'styled-components';

const ImageGrid = styled.div`
  display: grid;
  grid-column-gap: 7px;
  grid-row-gap: 7px;
  grid-template-columns: repeat(3, 90px);
  max-width: 100px;
`;

function isScorePositive(score, type) {
  switch (type) {
    case 'gradeA': {
      return score !== 0;
    }
    case 'gradeB':
    case 'gradeC':
    case 'gradeD':
    case 'gradeE':
    case 'gradeK': {
      return score === 0;
    }
    case 'gradeF': {
      return score === 1;
    }
    case 'gradeG': {
      return score !== null && score !== 0;
    }
    case 'gradeH': {
      return score === 1;
    }
    case 'none': {
      return true;
    }
    default: {
      return false;
    }
  }
}

export default function Answer({
  score,
  type,
  comment,
  needsFollowUp,
  attachments,
}) {
  const theme = useTheme();
  let color = theme.palette.text;
  color = isScorePositive(score, type)
    ? theme.palette.success.main
    : theme.palette.error.main;
  if (score === -1) {
    return <span>{translate('NA')}</span>;
  }

  const domFiles =
    attachments &&
    attachments
      .filter((file) => !file.get('isFollowUp'))
      .map((file) =>
        isImagePath(file.get('path')) ? (
          <ZoomableImage src={`/files/${file.get('path')}`} alt='answerPhoto' />
        ) : null
      );

  const gradeBtoE = {
    0: translate('NoDeviation'),
    1: translate('questions.gradeBtoE.marginal'),
    2: translate('questions.gradeBtoE.low'),
    3: translate('questions.gradeBtoE.medium'),
    4: translate('questions.gradeBtoE.high'),
    5: translate('questions.gradeBtoE.veryHigh'),
  };

  const radioGrades = {
    0: translate('questions.radioOptions.always'),
    1: translate('questions.radioOptions.often'),
    2: translate('questions.radioOptions.rarely'),
    3: translate('questions.radioOptions.never'),
  };

  let text = translate('NA');

  switch (type) {
    case 'circle': {
      text = score;
      break;
    }
    case 'radio': {
      color = theme.palette.text;
      text =
        score === -1
          ? translate('NA')
          : score === null
          ? translate('NoDeviation')
          : radioGrades[score];
      break;
    }
    case 'gradeA': {
      text =
        score === null || score === undefined
          ? translate('NoDeviation')
          : score === 0 || !score
          ? translate('questions.gradeA.nonCompliant')
          : translate('questions.gradeA.compliant');
      break;
    }
    case 'gradeB':
    case 'gradeC':
    case 'gradeD':
    case 'gradeE':
    case 'gradeK': {
      text = gradeBtoE[score];
      break;
    }
    case 'gradeH':
    case 'gradeF': {
      text =
        score === undefined || score === null
          ? ''
          : score === 0
          ? translate('questions.gradeF.no')
          : translate('questions.gradeF.yes');
      break;
    }
    case 'gradeG': {
      text = score;
      break;
    }
    case 'gradeJ':
    case 'gradeI': {
      text = comment;
      color = theme.palette.text;
      break;
    }
    case 'none': {
      text = '';
      break;
    }
    default: {
      text = translate('unanswered');
      break;
    }
  }

  return (
    <span style={{ color }}>
      {text}
      {needsFollowUp && <ImageGrid>{domFiles}</ImageGrid>}
    </span>
  );
}
