import { fromJS } from 'immutable';

const defaultState = fromJS({
  inspectionType: {
    id: -1,
    name: '',
    isSelfServe: false,
    allowUnansweredQuestions: false,
    autoStartTime: null,
    autoCloseTime: null,
    autoCloseWarningTime: null,
    autoCloseDelayDays: '',
    autoStartDelayDays: '',
  },
  visible: false,
  loading: false,
  error: '',
});

export default function editModal(state = defaultState, action = { type: '' }) {
  switch (action.type) {
    case 'EDIT_INSPECTION_TYPE_STARTED':
      return state.set('loading', true).set('error', '');
    case 'EDIT_INSPECTION_TYPE_FAILED':
      return state.set('loading', false).set('error', action.payload);
    case 'EDIT_INSPECTION_TYPE_DONE':
      return state.set('loading', false).set('visible', false);
    case 'OPEN_EDIT_INSPECTION_TYPE_MODAL':
      return state
        .set('error', '')
        .set('visible', true)
        .set('inspectionType', action.payload);
    case 'CLOSE_EDIT_INSPECTION_TYPE_MODAL':
      return state.set('visible', false);
    default:
      return state;
  }
}
