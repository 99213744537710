import React from 'react';
import { connect } from 'react-redux';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import inspectionActions from 'actions/inspectionQuestions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

function ConfirmFinishModal({
  visible,
  loading,
  error,
  closeModal,
  reportType,
  confirm,
  moduleId,
  inspectionId,
  allowUnansweredQuestions,
  unansweredQuestions,
}) {
  if (!visible) {
    return null;
  }
  const unansweredQuestionsText =
    unansweredQuestions.size > 0
      ? (allowUnansweredQuestions || reportType === 'auditQA'
          ? translate(
              'inspectionQuestions.finishModule.confirmTextAllowUnanswered'
            )
          : translate('inspectionQuestions.finishModule.confirmText2')
        ).replace('{number}', unansweredQuestions.size)
      : '';
  return (
    <Dialog open onClose={closeModal}>
      <DialogTitle>
        {unansweredQuestions.size > 0
          ? translate('inspectionQuestions.finishModule.unansweredHeader')
          : translate('inspectionQuestions.finishModule.header')}
      </DialogTitle>
      <DialogContent>
        {loading && <Loader />}
        {error && <Error>{translate(error)}</Error>}
        <div>{`${translate(
          'inspectionQuestions.finishModule.confirmText'
        )} ${unansweredQuestionsText}`}</div>
      </DialogContent>
      <DialogActions>
        <Button color='primary' type='button' onClick={closeModal}>
          {translate('cancel')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() =>
            confirm({
              inspectionId,
              moduleId,
            })
          }
        >
          {translate('inspectionQuestions.finishModule')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default connect(
  (state, ownProps) => {
    return {
      visible: state.getIn([
        'inspections',
        'questions',
        'confirmFinishModal',
        'visible',
      ]),
      loading: state.getIn([
        'inspections',
        'questions',
        'confirmFinishModal',
        'loading',
      ]),
      error: state.getIn([
        'inspections',
        'questions',
        'confirmFinishModal',
        'error',
      ]),
      allowUnansweredQuestions: state.getIn([
        'inspections',
        'current',
        'inspectionType',
        'allowUnansweredQuestions',
      ]),
      unansweredQuestions: state.getIn([
        'inspections',
        'questions',
        'confirmFinishModal',
        'unansweredQuestions',
      ]),
      ...ownProps,
    };
  },
  (dispatch) => ({
    confirm: ({ inspectionId, moduleId }) =>
      dispatch(inspectionActions.finishModule({ inspectionId, moduleId })),
    closeModal: () => dispatch(inspectionActions.closeConfirmFinishModal()),
  })
)(ConfirmFinishModal);
