import {request} from 'actions/utils';

export default function answer({moduleId, inspectionId, questionId, content}, dispatch) {
  return request({
    action: 'ANSWER_QUESTION',
    path: `/api/inspections/${inspectionId}/modules/${moduleId}/context-questions/${questionId}`,
    method: 'POST',
    body: {
      content
    }
  })(dispatch);
}
