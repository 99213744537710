import { useState, useEffect, useRef } from 'react';
import { List } from 'immutable';

const INITIAL_QUESTIONS = 5;
const QUESTION_LOAD = 5;
const SCROLL_THRESHOLD = 800;

export function useScrolledQuestions(cluster, useScroll) {
  const lastLoadedClusterId = useRef(null);
  const clusterId = cluster && cluster.get('id');
  const questions = cluster ? cluster.get('questions') : List();
  const [scrolledIndex, setScrolledIndex] = useState(INITIAL_QUESTIONS);
  const totalQuestions = questions.size;

  function increaseScrollIndex() {
    const scrollLeft =
      document.body.scrollHeight - window.scrollY - window.innerHeight;

    if (scrollLeft < SCROLL_THRESHOLD && totalQuestions > scrolledIndex) {
      setScrolledIndex((scrolledIndex) =>
        Math.min(scrolledIndex + QUESTION_LOAD, totalQuestions)
      );
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', increaseScrollIndex);

    return () => {
      window.removeEventListener('scroll', increaseScrollIndex);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrolledIndex, totalQuestions]);

  useEffect(() => {
    lastLoadedClusterId.current = clusterId;
    setScrolledIndex(INITIAL_QUESTIONS);
  }, [clusterId]);

  if (!useScroll) {
    return questions;
  }

  if (clusterId !== lastLoadedClusterId.current) {
    return questions.slice(0, INITIAL_QUESTIONS);
  }

  return questions.slice(0, scrolledIndex);
}
