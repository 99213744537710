import { connect } from 'react-redux';
import ActionPlan from './ActionPlan';
import reportActions from 'actions/admin/report';

function mapStateToProps(state, ownProps) {
  return {
    error: state.getIn(['admin', 'reports', 'error']),
    loading: state.getIn(['admin', 'reports', 'loading']),
    report: state.getIn(['admin', 'reports', 'content']),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    load: () => dispatch(reportActions.getActionPlan(ownProps.match.params.id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionPlan);
