import React, { useEffect, useState } from 'react';
import { List } from 'immutable';
import Button from '@material-ui/core/Button';
import PeopleIcon from '@material-ui/icons/People';
import Typography from '@material-ui/core/Typography';
import Loader from 'components/general/Loader';
import translate from 'utils/translate';
import SafeQInfo from './SafeQInfo';
import { Wrapper } from './styled';
import UsersDialog from './UsersDialog';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default function ManageHotel({
  role,
  load,
  hotel,
  loading,
  hotelId,
  back,
}) {
  const [showDialog, setShowDialog] = useState(false);
  useEffect(() => {
    load();
    // We only want to do one initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !hotel.get('id')) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  const numLinkedUsers = hotel.get('users', List()).size;

  return (
    <>
      <Button
        variant='outlined'
        color='secondary'
        onClick={back}
        startIcon={<ArrowBackIcon />}
      >
        {translate('back')}
      </Button>
      <Wrapper>
        <Typography
          variant='h6'
          component='h1'
          gutterBottom
          align='center'
          color='secondary'
        >
          {hotel.get('name')}
        </Typography>
        {hotel.get('organizationId', null) && (
          <Button
            style={{ margin: '1rem 0', padding: '1rem', alignSelf: 'center' }}
            startIcon={<PeopleIcon />}
            onClick={() => setShowDialog(true)}
          >
            {`${numLinkedUsers} ${translate('users')}`}
          </Button>
        )}
        {showDialog && (
          <UsersDialog
            visible
            role={role}
            onClose={() => setShowDialog(false)}
          />
        )}
        <SafeQInfo
          show={hotel.getIn(['safeQInfo', 'safeQAvailable'])}
          info={hotel.get('safeQInfo')}
          hotelId={hotelId}
        />
      </Wrapper>
    </>
  );
}
