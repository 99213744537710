import React from 'react';
import translate from 'utils/translate';
import { useTheme } from '@material-ui/core/styles';

export default function Comment({ type, comment }) {
  const theme = useTheme();
  let color = theme.palette.text;

  let text = translate('NA');

  switch (type) {
    case 'gradeA':
    case 'gradeB':
    case 'gradeC':
    case 'gradeD':
    case 'gradeE':
    case 'gradeH':
    case 'gradeF':
    case 'gradeG':
    case 'gradeK': {
      text = comment;
      break;
    }
    case 'gradeJ':
    case 'gradeI': {
      text = '';
      break;
    }
    case 'none': {
      text = '';
      break;
    }
    default: {
      text = comment;
      break;
    }
  }

  return <span style={{ color }}>{text}</span>;
}
