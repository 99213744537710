import { useState, useEffect } from 'react';
import { rawRequest } from 'actions/utils';

export function useOrganizationHotels(organizationId, useMyOrganization) {
  const [hotels, setHotels] = useState(null);

  if (useMyOrganization || organizationId < 0 || organizationId == null) {
    organizationId = 'my';
  }

  useEffect(() => {
    try {
      rawRequest({
        path: `/api/admin/organizations/${organizationId}/hotels`,
      }).then((organization) => {
        setHotels(organization.hotels);
      });
    } catch (error) {
      console.error(error);
    }
  }, [organizationId]);

  return hotels;
}
