import { connect } from 'react-redux';
import InspectionTypeAdmin from './InspectionTypeAdmin';
import inspectionTypeActions from 'actions/admin/inspectionType';
import inspectionModuleActions from 'actions/admin/inspectionModule';

function mapStateToProps(state, ownProps) {
  return {
    error: state.getIn(['admin', 'inspectionTypes', 'error']),
    loading: state.getIn(['admin', 'inspectionTypes', 'loading']),
    role: state.getIn(['currentUser', 'role']),
    inspectionTypes: state.getIn(['admin', 'inspectionTypes', 'list']),
    hotelId: ownProps.match.params.hotelId,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const hotelId = ownProps.match.params.hotelId;
  return {
    load: () => dispatch(inspectionTypeActions.load(hotelId)),
    openCreateModal: () => dispatch(inspectionTypeActions.openCreateModal()),
    openEditModal: (inspectionType) =>
      dispatch(inspectionTypeActions.openEditModal(inspectionType)),
    openDeleteModal: (inspectionType) =>
      dispatch(inspectionTypeActions.openDeleteModal(inspectionType)),
    openModuleAdministration: (inspectionTypeId) =>
      dispatch(inspectionModuleActions.openView(inspectionTypeId)),
    onUpdated: () => dispatch(inspectionTypeActions.load(hotelId)),
    onCreated: () => dispatch(inspectionTypeActions.load(hotelId)),
    onDeleted: () => dispatch(inspectionTypeActions.load(hotelId)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InspectionTypeAdmin);
