import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import hotelActions from 'actions/admin/hotel';
import HotelForm from 'components/general/HotelForm';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const initialState = {
  name: '',
  address: '',
  emailContact: '',
  reminderEmail: '',
  reportEmail: '',
  autoCloseWarningEmail: '',
  inactiveStartDate: null,
  inactiveEndDate: null,
};

class CreateModal extends Component {
  constructor() {
    super();
    this.state = initialState;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible && !this.props.visible) this.setState(initialState);
  }

  render() {
    const {
      visible,
      loading,
      error,
      createHotel,
      closeModal,
      organizationId,
    } = this.props;
    if (!visible) {
      return null;
    }
    return (
      <Dialog open onClose={closeModal}>
        <DialogTitle>{translate('hotels.create.header')}</DialogTitle>
        <DialogContent>
          <HotelForm
            hotel={this.state}
            onChange={(field, value) => this.setState({ [field]: value })}
          />
          {loading && <Loader />}
          {error && <Error>{translate(error)}</Error>}
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={closeModal}>
            {translate('cancel')}
          </Button>
          <Button
            color='primary'
            variant='contained'
            disabled={
              !this.state.name ||
              ['inactiveStartDate', 'inactiveEndDate'].some(
                (dateField) =>
                  this.state[dateField] &&
                  !moment(this.state[dateField]).isValid()
              )
            }
            onClick={() => createHotel(this.state, organizationId)}
          >
            {translate('hotels.create')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connect(
  (state) => ({
    visible: state.getIn(['admin', 'hotels', 'createModal', 'visible']),
    loading: state.getIn(['admin', 'hotels', 'createModal', 'loading']),
    error: state.getIn(['admin', 'hotels', 'createModal', 'error']),
    organizationId: state.getIn([
      'admin',
      'hotels',
      'createModal',
      'organizationId',
    ]),
  }),
  (dispatch, ownProps) => ({
    createHotel: (params, organizationId) =>
      dispatch(
        hotelActions.createHotel(
          params,
          organizationId,
          ownProps.onHotelCreated
        )
      ),
    closeModal: () => dispatch(hotelActions.closeCreateModal()),
  })
)(CreateModal);
