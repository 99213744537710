import React from 'react';
import { Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import {
  ConnectedRouter,
  routerMiddleware,
} from 'connected-react-router/immutable';
import { createStore, applyMiddleware, compose } from 'redux';
import reducers from 'reducers';
import { createBrowserHistory } from 'history';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import './App.css';
import routes from '../routes';
import mainTheme from 'globals/theme';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider as MuiThemeProvider } from 'styled-components';
import FileUploadProgress from 'components/general/FileUploadProgress';

import moment from 'moment';
import 'moment/locale/sv';

moment.locale('sv');

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const history = createBrowserHistory();
const store = createStore(
  //Order is important.
  reducers(history),
  composeEnhancers(applyMiddleware(thunk, routerMiddleware(history)))
);
history.listen(() => {
  window.scrollTo(0, 0);
});

export default function App() {
  const theme = createMuiTheme(mainTheme);

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={'se'}>
          <Provider store={store}>
            <div>
              <FileUploadProgress />
              <ConnectedRouter history={history}>
                <Switch>{routes()}</Switch>
              </ConnectedRouter>
            </div>
          </Provider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  );
}
