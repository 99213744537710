import styled from 'styled-components';

const Print = styled.div`
  display: ${({ show }) => (show ? 'none' : 'block')};

  @media print {
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;

export default Print;
