import { fromJS } from 'immutable';
import createModal from './createModal';

const defaultState = fromJS({
  organizations: [],
  loading: false,
  error: '',
  createModal: createModal(),
});

export default function (state = defaultState, action = { type: '' }) {
  state = state.set(
    'createModal',
    createModal(state.get('createModal'), action)
  );
  switch (action.type) {
    case 'LOAD_ORGANIZATIONS_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'LOAD_ORGANIZATIONS_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'LOAD_ORGANIZATIONS_DONE': {
      return state
        .set('organizations', fromJS(action.payload))
        .set('loading', false);
    }
    default: {
      return state;
    }
  }
}
