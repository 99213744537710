import React from 'react';
import translate from 'utils/translate';
import { List } from 'immutable';
import { Wrapper } from './styled';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import { breakpoints } from 'globals/media';
import { useScreenSize } from 'hooks/useScreenSize';
import ListUsers from 'components/user/List/Container';
import ListHotels from 'components/hotel/List/Container';
import ListInspectionTypes from 'components/admin/Organization/InspectionTypes/Container';
import Statistics from 'components/admin/Organization/Statistics';
import Settings from 'components/admin/Organization/Settings';

export default function ManageOrganization({
  variant = 'both',
  queryStringTab,
  organization,
  loading,
  organizationId,
  onTabChange,
  error,
}) {
  const screenSize = useScreenSize();
  const activeTab = variant === 'both' ? queryStringTab || 'user' : variant;

  const handleTabChange = (_, newValue) => {
    onTabChange(newValue);
  };

  const commonProps = {
    loading,
    error,
    organizationId,
  };

  return (
    <Wrapper>
      {variant === 'both' && (
        <>
          <Typography
            variant='h6'
            component='h1'
            gutterBottom
            align='center'
            color='secondary'
          >
            {organization.get('name')}
          </Typography>
          <Divider
            variant={
              screenSize.width > breakpoints.tablet ? 'fullWidth' : 'middle'
            }
          />
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered={screenSize.width > breakpoints.handheld}
            variant={
              screenSize.width < breakpoints.handheld ? 'fullWidth' : 'standard'
            }
            style={{ marginBottom: '10px' }}
          >
            <Tab value='user' label={translate('users')} />
            <Tab value='hotel' label={translate('hotels')} />
            <Tab value='inspectionTypes' label={translate('inspectionTypes')} />
            <Tab value='statistics' label={translate('statistics')} />
            <Tab value='settings' label={translate('settings')} />
          </Tabs>
        </>
      )}
      {activeTab === 'user' && (
        <ListUsers
          organizationId={organizationId}
          users={organization.get('users')}
          {...commonProps}
        />
      )}
      {activeTab === 'hotel' && (
        <ListHotels
          organizationId={organizationId}
          hotels={organization.get('hotels')}
          {...commonProps}
        />
      )}
      {activeTab === 'inspectionTypes' && (
        <ListInspectionTypes
          organizationId={organizationId}
          inspectionTypes={organization.get('inspectionTypes') || List()}
          {...commonProps}
        />
      )}
      {activeTab === 'statistics' && (
        <Statistics organizationId={organizationId} {...commonProps} />
      )}
      {activeTab === 'settings' && (
        <Settings organizationId={organizationId} {...commonProps} />
      )}
    </Wrapper>
  );
}
