import {fromJS} from 'immutable';

const defaultState = fromJS({
  visible: false,
  loading: false,
  user: {
    id: 0,
    name: '',
    email: '',
    password: '',
    role: '',
    profile: ''
  },
  error: ''
});

export default function(state = defaultState, action = {type: ''}) {
  switch (action.type) {
    case 'EDIT_USER_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'EDIT_USER_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'EDIT_USER_DONE': {
      return state.set('loading', false).set('visible', false);
    }
    case 'OPEN_EDIT_USER_MODAL': {
      return state.set('visible', true).set('user', fromJS(action.payload));
    }
    case 'CLOSE_EDIT_USER_MODAL': {
      return state.set('visible', false);
    }
    default: {
      return state;
    }
  }
}
