import React, { useEffect } from 'react';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import Logo from 'components/general/Logo';
import translate from 'utils/translate';
import { Wrapper, ReportHeader } from './styled';
import PrintedReport from '../../admin/ReportAuditQA/ActionPlan/PrintedReport';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { getFollowUp } from 'utils/inspection';
import { Map } from 'immutable';

export default function ActionPlan({ load, error, loading, report }) {
  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <ReportHeader>
        <Typography variant='h3' component='h1'>
          {translate('actionPlan')}
        </Typography>
        <Logo width={'140'} height={'75'} />
      </ReportHeader>
      <div className='flex-centering'>
        {loading && <Loader />}
        {error && <Error>{translate(error)}</Error>}
      </div>
      {report && report.get('modules') && (
        <>
          <Typography>
            <strong>{translate('hotel')}:</strong>{' '}
            {report.getIn(['inspectionType', 'hotel', 'name'])}
          </Typography>
          <Typography>
            <strong>{translate('auditQA.columns.startTime')}:</strong>{' '}
            {moment(report.getIn(['startTime'])).format('YYYY-MM-DD')}
          </Typography>
          <Typography>
            <strong>
              {translate('auditQA.columns.agentEndTimeSetByUser')}
            </strong>
            : {report.getIn(['agentEndTimeSetByUser', 'name'])} (
            {moment(report.getIn(['agentEndTime'])).format('YYYY-MM-DD')})
          </Typography>
          <Typography gutterBottom>
            <strong>
              {translate('auditQA.columns.adminEndTimeSetByUser')}
            </strong>
            : {report.getIn(['adminEndTimeSetByUser', 'name'])} (
            {moment(report.getIn(['adminEndTime'])).format('YYYY-MM-DD')})
          </Typography>
          <PrintedReport
            summary={(report.getIn(['followUpSummary']) || Map()).toJS()}
            modules={getFollowUp(report.get('modules'))}
            comfortZones={[
              { name: 'bi', value: report.get('bi') },
              { name: 'bs', value: report.get('bs') },
            ]}
            originalModules={report.get('modules')}
          />
        </>
      )}
    </Wrapper>
  );
}
