import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteButton from 'components/general/DeleteButton';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import CreateQuestionModal from './CreateQuestionModal';
import DeleteQuestionModal from './DeleteQuestionModal';
import CreateClusterModal from './CreateClusterModal';
import DeleteClusterModal from './DeleteClusterModal';
import CreateModuleTypeModal from './CreateModuleTypeModal';
import CopyModuleTypeModal from './CopyModuleTypeModal';
import DeleteModuleTypeModal from './DeleteModuleTypeModal';
import CreateContextQuestionModal from './CreateContextQuestionModal';
import DeleteContextQuestionModal from './DeleteContextQuestionModal';
import EditContextQuestionModal from './EditContextQuestionModal';
import EditClusterModal from './EditClusterModal';
import EditQuestionModal from './EditQuestionModal';
import EditModuleTypeModal from './EditModuleTypeModal';
import Ordinal from 'components/admin/general/Ordinal';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LanguagePicker from 'components/general/LanguagePicker';
import Button from 'components/general/Button';
import { List } from 'immutable';
import {
  Cluster,
  ClusterHeader,
  Row,
  Wrapper,
  Centered,
  ContextQuestion,
  ContextQuestions,
} from './styled';
const MS_TO_WAIT_UNTIL_SERVER_REQUEST = 1200;
export default class QuestionAdmin extends Component {
  constructor() {
    super();
    this.state = {
      questionClusters: List(),
      createContextQuestionModalVisible: false,
      deleteContextQuestionModalVisible: false,
      contextQuestionToDelete: {},
      clusterToEdit: {},
      editingCluster: false,
      editingQuestion: false,
      editingModuleType: false,
      questionToEdit: {},
      editingContextQuestion: false,
      contextQuestionToEdit: {},
      language: null,
    };
  }
  openEditClusterModal = (cluster) => {
    this.setState({
      clusterToEdit: cluster,
      editingCluster: true,
    });
  };
  closeEditClusterModal = () => {
    this.setState({ clusterToEdit: {}, editingCluster: false });
    this.props.loadQuestions(
      this.props.moduleTypeId,
      this.state.language ? this.state.language.shortCode : ''
    );
  };
  openEditQuestionModal = (question) => {
    this.setState({
      questionToEdit: question,
      editingQuestion: true,
    });
  };
  closeEditQuestionModal = () => {
    this.setState({ questionToEdit: {}, editingQuestion: false });
    this.props.loadQuestions(
      this.props.moduleTypeId,
      this.state.language ? this.state.language.shortCode : ''
    );
  };
  openEditContextQuestionModal = (question) => {
    this.setState({
      contextQuestionToEdit: question,
      editingContextQuestion: true,
    });
  };
  closeEditContextQuestionModal = () => {
    this.setState({ contextQuestionToEdit: {}, editingContextQuestion: false });
    this.props.loadQuestions(
      this.props.moduleTypeId,
      this.state.language ? this.state.language.shortCode : ''
    );
  };
  openCreateContextQuestionModal = () =>
    this.setState({
      createContextQuestionModalVisible: true,
    });
  closeCreateContextQuestionModal = () => {
    this.setState({
      createContextQuestionModalVisible: false,
    });
    this.props.loadQuestions(
      this.props.moduleTypeId,
      this.state.language ? this.state.language.shortCode : ''
    );
  };
  openEditModuleTypeModal = () => {
    this.setState({
      editingModuleType: true,
    });
  };
  closeEditModuleTypeModal = () => {
    this.setState({
      editingModuleType: false,
    });
    this.props.loadModuleTypes();
  };
  openDeleteContextQuestionModal = (id, content) =>
    this.setState({
      contextQuestionToDelete: {
        id,
        content,
      },
      deleteContextQuestionModalVisible: true,
    });
  closeDeleteContextQuestionModal = () => {
    this.setState({
      deleteContextQuestionModalVisible: false,
      contextQuestionToDelete: {},
    });
    this.props.loadQuestions(
      this.props.moduleTypeId,
      this.state.language ? this.state.language.shortCode : ''
    );
  };
  componentDidMount() {
    const {
      loadQuestions,
      loadQuestionTypes,
      moduleTypeId,
      loadModuleTypes,
    } = this.props;
    if (moduleTypeId !== 'default') {
      loadQuestions(
        moduleTypeId,
        this.state.language ? this.state.language.shortCode : ''
      );
    }
    loadQuestionTypes();
    loadModuleTypes();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.moduleTypeId === 'default' &&
      prevProps.moduleTypes !== this.props.moduleTypes
    ) {
      this.props.changeModuleType(this.props.moduleTypes.getIn([0, 'id']));
    }
  }
  componentWillReceiveProps({ questionClusters }) {
    if (questionClusters !== this.props.questionClusters) {
      this.setState({ questionClusters });
    }
  }
  timeoutUntilServerUpdate = {};

  updateOrdinal = ({ event, clusterIndex, questionIndex }) => {
    const ordinal = event.target.value && parseInt(event.target.value, 10);
    if (questionIndex === undefined) {
      const questionClusters = this.state.questionClusters
        .setIn([clusterIndex, 'ordinal'], ordinal)
        .setIn([clusterIndex, 'status'], 'updating');
      this.setState(() => ({ questionClusters }));
      const id = questionClusters.getIn([clusterIndex, 'id']);
      clearTimeout(this.timeoutUntilServerUpdate[`cluster${id}`]);
      this.timeoutUntilServerUpdate[`cluster${id}`] = setTimeout(() => {
        this.props.updateQuestionCluster({
          id,
          ordinal,
          moduleTypeId: this.props.moduleTypeId,
        });
      }, MS_TO_WAIT_UNTIL_SERVER_REQUEST);
    } else {
      const questionClusters = this.state.questionClusters
        .setIn([clusterIndex, 'questions', questionIndex, 'ordinal'], ordinal)
        .setIn(
          [clusterIndex, 'questions', questionIndex, 'status'],
          'updating'
        );
      this.setState(() => ({ questionClusters }));
      const id = questionClusters.getIn([
        clusterIndex,
        'questions',
        questionIndex,
        'id',
      ]);
      clearTimeout(this.timeoutUntilServerUpdate[`question${id}`]);
      this.timeoutUntilServerUpdate[`question${id}`] = setTimeout(() => {
        this.props.updateQuestion({
          id,
          ordinal,
          moduleTypeId: this.props.moduleTypeId,
        });
      }, MS_TO_WAIT_UNTIL_SERVER_REQUEST);
    }
  };

  render() {
    const { questionClusters, language } = this.state;
    const {
      error,
      loading,
      openCreateQuestionModal,
      openDeleteQuestionModal,
      openCreateModuleTypeModal,
      openCopyModuleTypeModal,
      openDeleteModuleTypeModal,
      questionTypes,
      moduleTypeId,
      moduleTypes,
      moduleType,
      openCreateClusterModal,
      openDeleteClusterModal,
      changeModuleType,
      moduleTypeClassifications,
      moduleTypeAreas,
    } = this.props;

    const domClusters = questionClusters.map((cluster, clusterIndex) => {
      const domQuestions = cluster
        .get('questions')
        .map((question, questionIndex) => (
          <Row key={question.get('id')}>
            <div>
              {question
                .get('content')
                .split('\n')
                .map((c, i) => (
                  <div key={c}>{c}</div>
                ))}
            </div>
            <div>
              {translate(`classifications.${question.get('classification')}`)}
            </div>
            <Ordinal
              status={question.get('status')}
              type='number'
              value={question.get('ordinal')}
              onChange={(event) =>
                this.updateOrdinal({ clusterIndex, questionIndex, event })
              }
            />
            <FormControl>
              <InputLabel style={{ whiteSpace: 'nowrap' }}>
                {translate('questions.create.requiredCommentMinScore')}
              </InputLabel>
              <Select
                value={
                  question.get('requiredCommentMinScore') === null
                    ? -1
                    : question.get('requiredCommentMinScore')
                }
                onChange={(event) => {
                  this.props.updateQuestion({
                    moduleTypeId,
                    id: question.get('id'),
                    requiredCommentMinScore:
                      event.target.value === -1 ? null : event.target.value,
                  });
                }}
              >
                <MenuItem value={-1}>{translate('none')}</MenuItem>
                <MenuItem value={3}>
                  3 ({translate('questions.radioOptions.never')})
                </MenuItem>
                <MenuItem value={2}>
                  2 ({translate('questions.radioOptions.rarely')})
                </MenuItem>
                <MenuItem value={1}>
                  1 ({translate('questions.radioOptions.often')})
                </MenuItem>
                <MenuItem value={0}>
                  0 ({translate('questions.radioOptions.always')})
                </MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              label={translate('questions.create.isMandatory')}
              labelPlacement='top'
              style={{ marginLeft: 0, marginRight: 0 }}
              control={
                <Checkbox
                  name='isMandatory'
                  checked={question.get('isMandatory')}
                  onChange={() =>
                    this.props.updateQuestion({
                      moduleTypeId,
                      id: question.get('id'),
                      isMandatory: !question.get('isMandatory'),
                    })
                  }
                />
              }
            />
            <FormControlLabel
              label={translate('questions.create.notify')}
              labelPlacement='top'
              style={{ marginLeft: 0, marginRight: 0 }}
              control={
                <Checkbox
                  name='notifyHotelResponsible'
                  checked={question.get('notifyHotelResponsible')}
                  onChange={() =>
                    this.props.updateQuestion({
                      moduleTypeId,
                      id: question.get('id'),
                      notifyHotelResponsible: !question.get(
                        'notifyHotelResponsible'
                      ),
                    })
                  }
                />
              }
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Button
                onClick={() => this.openEditQuestionModal(question.toJS())}
              >
                {translate('edit')}
              </Button>
              <DeleteButton
                style={{ maxWidth: 'unset' }}
                onClick={() => openDeleteQuestionModal(question.toJS())}
              >
                {translate('questions.delete')}
              </DeleteButton>
            </div>
          </Row>
        ));
      const domContextQuestions = cluster.get('contextQuestions').map((imq) => (
        <ContextQuestion key={imq.get('id')}>
          <span className='ordinal'>{imq.get('ordinal')}</span>
          <span>{imq.get('content')}</span>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100px' }}
          >
            <Button
              onClick={() => this.openEditContextQuestionModal(imq.toJS())}
            >
              {translate('edit')}
            </Button>
            <Button
              color='errorRed'
              onClick={() =>
                this.openDeleteContextQuestionModal(
                  imq.get('id'),
                  imq.get('content')
                )
              }
            >
              {translate('questions.removeContextQuestion.button')}
            </Button>
          </div>
        </ContextQuestion>
      ));
      return (
        <Cluster key={cluster.get('id')}>
          <ClusterHeader>
            <h3>{cluster.get('name')}</h3>
            <div>
              <Ordinal
                status={cluster.get('status')}
                key={cluster.get('name') + 'ordinal'}
                type='number'
                value={cluster.get('ordinal')}
                onChange={(event) =>
                  this.updateOrdinal({ clusterIndex, event })
                }
              />
              <FormControl
                variant='outlined'
                fullWidth
                style={{ margin: '7px auto' }}
                margin='dense'
              >
                <InputLabel
                  id={`question-form-cluster-comment-position-label-${cluster.get(
                    'id'
                  )}`}
                >
                  {translate('questions.clusterCommentPosition')}
                </InputLabel>
                <Select
                  labelId={`question-form-cluster-comment-position-label-${cluster.get(
                    'id'
                  )}`}
                  value={cluster.get('commentPosition')}
                  label={translate('questions.clusterCommentPosition')}
                  onChange={(event) =>
                    this.props.updateQuestionCluster({
                      id: cluster.get('id'),
                      commentPosition: event.target.value,
                      moduleTypeId: this.props.moduleTypeId,
                    })
                  }
                >
                  <MenuItem key={'top'} value={'top'}>
                    {translate('questions.clusterCommentPosition.top')}
                  </MenuItem>
                  <MenuItem key={'bottom'} value={'bottom'}>
                    {translate('questions.clusterCommentPosition.bottom')}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Button onClick={() => this.openEditClusterModal(cluster.toJS())}>
                {translate('questions.editCluster')}
              </Button>
              <DeleteButton
                style={{ maxWidth: 'unset' }}
                onClick={() => openDeleteClusterModal(cluster.toJS())}
              >
                {translate('questions.deleteCluster')}
              </DeleteButton>
            </div>
          </ClusterHeader>
          {domContextQuestions.size > 0 && (
            <Centered>
              <h4>{translate('questions.contextQuestions')}</h4>
            </Centered>
          )}
          <ContextQuestions>{domContextQuestions}</ContextQuestions>
          {domQuestions}
        </Cluster>
      );
    });
    return (
      <Wrapper>
        <Centered>
          <h1>{translate('questions.administration')}</h1>
        </Centered>
        <Centered>
          <FormControl variant='outlined' fullWidth>
            <Select
              fullWidth
              required
              value={moduleTypeId}
              onChange={(e) => changeModuleType(e.target.value)}
            >
              {(moduleTypes || []).map((moduleType) => (
                <MenuItem
                  key={moduleType.get('id')}
                  value={moduleType.get('id')}
                >
                  {moduleType.get('name')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LanguagePicker
            value={language}
            onLanguageSelected={(language) => {
              this.setState({ language }, () => {
                this.props.loadQuestions(
                  this.props.moduleTypeId,
                  this.state.language ? this.state.language.shortCode : ''
                );
              });
            }}
          />
          <Button type='button' onClick={openCopyModuleTypeModal}>
            {translate('questions.copyModuleType')}
          </Button>
        </Centered>
        <Centered>
          <Button type='button' onClick={openCreateModuleTypeModal}>
            {translate('questions.createModuleType')}
          </Button>
          <Button
            color='alternate'
            type='button'
            onClick={this.openEditModuleTypeModal}
          >
            {translate('questions.editModuleType')}
          </Button>
          <Button
            color='errorRed'
            type='button'
            onClick={openDeleteModuleTypeModal}
          >
            {translate('questions.deleteModuleType')}
          </Button>
        </Centered>
        <Centered>
          <Button type='button' onClick={openCreateQuestionModal}>
            {translate('questions.create')}
          </Button>
          <Button type='button' onClick={openCreateClusterModal}>
            {translate('questions.createCluster')}
          </Button>
          <Button type='button' onClick={this.openCreateContextQuestionModal}>
            {translate('questions.createContextQuestion')}
          </Button>
        </Centered>
        <Centered>
          {loading && <Loader />}
          {error && <Error>{translate(error)}</Error>}
        </Centered>
        <Centered>
          <h2>{moduleType.get('name')}</h2>
        </Centered>
        <Centered>
          <h2>{translate('questions.clusters')}</h2>
        </Centered>
        {domClusters}
        <CreateQuestionModal
          moduleTypeId={moduleTypeId}
          language={this.state.language}
        />
        <DeleteQuestionModal moduleTypeId={moduleTypeId} />
        <CreateClusterModal moduleTypeId={moduleTypeId} />
        <DeleteClusterModal moduleTypeId={moduleTypeId} />
        <DeleteModuleTypeModal moduleTypeId={moduleTypeId} />
        <CopyModuleTypeModal moduleTypeId={moduleTypeId} />
        <CreateModuleTypeModal
          moduleTypeClassifications={moduleTypeClassifications}
          moduleTypeAreas={moduleTypeAreas}
        />
        {this.state.createContextQuestionModalVisible && (
          <CreateContextQuestionModal
            moduleTypeId={moduleTypeId}
            closeModal={this.closeCreateContextQuestionModal}
            questionClusters={questionClusters}
          />
        )}
        {this.state.deleteContextQuestionModalVisible && (
          <DeleteContextQuestionModal
            question={this.state.contextQuestionToDelete}
            closeModal={this.closeDeleteContextQuestionModal}
          />
        )}
        {this.state.editingCluster && (
          <EditClusterModal
            cluster={this.state.clusterToEdit}
            closeModal={this.closeEditClusterModal}
          />
        )}
        {this.state.editingQuestion && (
          <EditQuestionModal
            language={this.state.language}
            question={this.state.questionToEdit}
            closeModal={this.closeEditQuestionModal}
            questionTypes={questionTypes || []}
            questionClusters={questionClusters}
          />
        )}
        <EditModuleTypeModal
          visible={this.state.editingModuleType}
          moduleType={moduleType.toJS()}
          closeModal={this.closeEditModuleTypeModal}
          moduleTypeClassifications={moduleTypeClassifications}
          moduleTypeAreas={moduleTypeAreas}
        />
        {this.state.editingContextQuestion && (
          <EditContextQuestionModal
            question={this.state.contextQuestionToEdit}
            closeModal={this.closeEditContextQuestionModal}
            questionTypes={questionTypes || []}
            questionClusters={questionClusters}
          />
        )}
      </Wrapper>
    );
  }
}
