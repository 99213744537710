import React from 'react';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';
import adminRoutes from './admin';
import organizationRoutes from './organization';
import InspectionRoutes from './inspection';
import actionPlanRoutes from './actionPlan';
import Toolbar from 'components/general/Toolbar';
import media from 'globals/media';

const Body = styled.div`
  margin: 0 auto;
  max-width: 75rem;
  margin-top: 84px;
  padding-left: 210px;

  ${media.handheld`
    max-width: 95vw;
  `}

  ${media.desktop`
    padding-left: 0;
  `}

  @media print {
    max-width: inherit;
    margin-top: 0;
    padding-left: 0;
  }
`;

export default function SessionRoutes() {
  return (
    <>
      <Toolbar />
      <Body>
        <Switch>
          {adminRoutes}
          {organizationRoutes}
          {actionPlanRoutes}
          <Route path='/' component={InspectionRoutes} />
        </Switch>
      </Body>
    </>
  );
}
