import { dashboardLanguages } from 'globals/languages';
import { formatHotelData } from './utils';

export const INITIAL_STATE = {
  data: {
    inspections: [],
    inspectionModuleTypes: [],
    hotelName: '',
  },
  languageIndex: 0,
  lastUpdate: new Date(),
  loading: false,
};

export default function reducer(state, action = { type: '' }) {
  switch (action.type) {
    case 'GET_DASHBOARD_DATA_START': {
      return { ...state, loading: true };
    }
    case 'GET_DASHBOARD_DATA_DONE': {
      return {
        ...state,
        data: {
          ...action.payload,
          inspections: formatHotelData({
            inspectionTypes: action.payload.inspectionTypes,
            inspectionModuleTypes: action.payload.inspectionModuleTypes,
          }),
          inspectionModuleTypes: action.payload.inspectionModuleTypes,
          dashboard: {
            ...action.payload.dashboard,
            language: action.payload.dashboard.language
              ? action.payload.dashboard.language.split(',')
              : [dashboardLanguages[0].code],
          },
        },
        loading: false,
        lastUpdate: new Date(),
      };
    }
    case 'UPDATE_LANGUAGE': {
      return {
        ...state,
        languageIndex: action.payload,
      };
    }
    default:
      return state;
  }
}
