import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import translate from 'utils/translate';
import Error from 'components/general/Error';
import Loader from 'components/general/Loader';
import inspectionTypeActions from 'actions/admin/inspectionType';
import InspectionTypeForm from 'components/general/InspectionTypeForm';
import InspectionTypeLinkForm from 'components/general/InspectionTypeLinkForm';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const initialState = {
  name: '',
  isSelfServe: false,
  allowUnansweredQuestions: false,
  autoStartTime: null,
  autoCloseTime: null,
  autoCloseWarningTime: null,
  autoCloseDelayDays: '',
  autoStartDelayDays: '',
  reportType: 'agentQ',
};

function CreateModal({
  visible,
  loading,
  error,
  role,
  link,
  createOnly,
  create,
  closeModal,
  hotelId,
  organizationId,
}) {
  const showTabs = !createOnly && role === 'superUser' && organizationId;
  const [state, setState] = useState(initialState);
  const [inspectionType, setInspectionType] = useState('');
  const [tab, setTab] = useState(role === 'superUser' ? 'create' : 'link');
  useEffect(() => {
    if (!visible) {
      setState(initialState);
      setInspectionType(null);
    }
  }, [visible]);

  if (!visible) return null;

  return (
    <Dialog open={visible} onClose={closeModal}>
      <DialogTitle>
        {showTabs
          ? translate('inspectionTypes.create.header')
          : translate('inspectionTypes.link.header')}
      </DialogTitle>
      <DialogContent dividers>
        {showTabs && (
          <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
            <Tab value='create' label={translate('create')} />
            <Tab value='link' label={translate('link')} />
          </Tabs>
        )}
        {tab === 'create' && (
          <InspectionTypeForm
            {...state}
            role={role}
            onChange={(field, value) => setState({ ...state, [field]: value })}
          />
        )}
        {tab === 'link' && (
          <InspectionTypeLinkForm
            organizationId={role === 'superUser' ? organizationId : null}
            inspectionType={inspectionType}
            onChange={(id) => setInspectionType(id)}
          />
        )}
        {loading && <Loader />}
        {error && (
          <Error>{translate(error).replace('{name}', state.name)}</Error>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color='primary'>
          {translate('back')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            if (tab === 'create') {
              create({ organizationId, hotelId, ...state });
            } else {
              link({ organizationId, hotelId, id: inspectionType });
            }
          }}
        >
          {translate('inspectionTypes.create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default connect(
  (state, ownProps) => ({
    role: state.getIn(['currentUser', 'role']),
    organizationId: state.getIn(['hotel', 'current', 'organizationId']),
    visible: state.getIn([
      'admin',
      'inspectionTypes',
      'createModal',
      'visible',
    ]),
    loading: state.getIn([
      'admin',
      'inspectionTypes',
      'createModal',
      'loading',
    ]),
    error: state.getIn(['admin', 'inspectionTypes', 'createModal', 'error']),
    ...ownProps,
  }),
  (dispatch, ownProps) => ({
    create: (inspectionData) =>
      dispatch(
        inspectionTypeActions.create(inspectionData, ownProps.onCreated)
      ),
    link: (inspectionData) =>
      dispatch(inspectionTypeActions.link(inspectionData, ownProps.onCreated)),
    closeModal: () => dispatch(inspectionTypeActions.closeCreateModal()),
  })
)(CreateModal);
