import { connect } from 'react-redux';
import InspectionPicker from './InspectionPicker';
import inspectionActions from 'actions/inspection';
import inspectionTypeActions from 'actions/inspectionType';
import { push } from 'connected-react-router';
import urls from 'globals/urls';

function mapStateToProps(state, ownProps) {
  return {
    error: state.getIn(['inspections', 'list', 'error']),
    loading: state.getIn(['inspections', 'list', 'loading']),
    inspections: state.getIn(['inspections', 'list', 'list']),
    userId: state.getIn(['currentUser', 'id']),
    canCreateSessions:
      state.getIn(['inspections', 'list', 'inspectionTypes']).size > 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    create: () => dispatch(push(urls.inspectionsCreate)),
    load: () => {
      dispatch(inspectionActions.list());
      dispatch(inspectionTypeActions.listSelfServe());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionPicker);
