import translate from 'utils/translate';
import moment from 'moment';

export function getTitle(chartData) {
  const groupTranslation = translate(
    `classifications.${chartData.get('comfortZone')}`,
    true
  );
  const group = groupTranslation.includes('classifications.')
    ? chartData.get('comfortZone')
    : groupTranslation;
  return `${group}`;
}

export function getDepartmentTitle(chartData, inspectionModules) {
  let title;

  inspectionModules
    .filter(mod => mod.get('id') === chartData.get('inspectionModuleId'))
    .map(obj => (title = obj.get('name')));

  return title;
}

export function getModuleTypeClassification(chartData, inspectionModules) {
  let classification;

  inspectionModules
    .filter(mod => mod.get('id') === chartData.get('inspectionModuleId'))
    .map(
      obj =>
        (classification = obj.getIn(['inspectionModuleType', 'classification']))
    );

  return classification;
}

export function findPreviousAverage(currentHotelAverage, currentDate) {
  let previousAverage;

  if (currentHotelAverage.count() <= 1) {
    return;
  }

  previousAverage = currentHotelAverage
    .entrySeq()
    .filter(([date, percent]) => moment(date) - moment(currentDate) < 0)
    .sort(
      ([dateA, percentA], [dateB, percentB]) =>
        moment(currentDate) -
        moment(dateA) -
        (moment(currentDate) - moment(dateB))
    )
    .first()[1];

  return previousAverage;
}

export function getName(chartData) {
  return getTitle(chartData)
    .replace(/ /g, '')
    .replace(/[äå]/g, 'a')
    .replace(/[ÄÅ]/g, 'A')
    .replace(/ö/g, 'o')
    .replace(/Ö/g, 'O')
    .replace(/-/g, '');
}
