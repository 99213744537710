import { request } from 'actions/utils';

export default function save(
  {
    id,
    name,
    isSelfServe,
    allowUnansweredQuestions,
    autoStartTime,
    autoCloseTime,
    autoCloseWarningTime,
    autoCloseDelayDays,
    autoStartDelayDays,
    autoWeekdays,
    reportType,
    reminderEmail,
    autoCloseWarningEmail,
    feedbackEmail,
    reportEmail
  },
  onSuccess
) {
  return async (dispatch) => {
    const success = await dispatch(
      request({
        action: 'EDIT_INSPECTION_TYPE',
        path: `/api/admin/inspection-types/${id}`,
        method: 'PUT',
        body: {
          name,
          isSelfServe,
          allowUnansweredQuestions,
          autoStartTime,
          autoCloseTime,
          autoCloseWarningTime,
          autoCloseDelayDays,
          autoStartDelayDays,
          autoWeekdays,
          reportType,
          reminderEmail: trimArray(reminderEmail),
          autoCloseWarningEmail: trimArray(autoCloseWarningEmail),
          feedbackEmail: trimArray(feedbackEmail),
          reportEmail: trimArray(reportEmail)
        },
      })
    );
    if (success && typeof onSuccess === 'function') onSuccess();
  };
}

const trimArray = (array) => array ? array.filter(value => value && value !== '') : null;
