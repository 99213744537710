import React from 'react';
import translate from 'utils/translate';
import Answer from '../Answer';
import Comment from '../Comment';
import EditableComment from 'components/inspection/Questions/QuestionRow/Comment';
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';
import FollowUpDeadline from './FollowUpDeadline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteButton from 'components/general/DeleteButton';
import FileLink from 'components/general/FileLink';
import isImagePath from 'utils/isImagePath';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import {
  FileUpload,
  FileRow,
} from 'components/inspection/Questions/QuestionRow/styled';
import { UploadButton } from './styled';
import { fromJS } from 'immutable';

const Content = styled.div`
  display: flex;
  margin-bottom: 20px;
  > div:nth-child(1) {
    flex: 2 0 0;
  }
  > div:nth-child(2) {
    margin-left: 7px;
    flex: 1.7 0 0;
  }
`;

export default function ActionPlanQuestions({
  questions,
  indent = 0,
  setFollowUp,
  uploadFile,
  removeFile,
}) {
  if (!questions || !questions.length) return null;

  return (
    <ul>
      {questions.map((question, i) => (
        <li key={i}>
          <Content>
            <div>
              <div className={'referenceNumber'}>
                {question.referenceNumber}
              </div>
              <div className={'content'}>
                {question.content || question.defaultContent}
              </div>
              {question.type !== 'none' && (
                <>
                  <div>
                    {translate('answer')}:{' '}
                    <Answer
                      type={question.type}
                      score={question.score}
                      comment={question.comment}
                      attachments={
                        question.answer && fromJS(question.answer.attachments)
                      }
                      needsFollowUp={question.needsFollowUp}
                    />
                  </div>
                  <div>
                    {translate('comment')}:{' '}
                    <Comment type={question.type} comment={question.comment} />
                  </div>
                </>
              )}
            </div>
            {question.needsFollowUp && (
              <div>
                <EditableComment
                  label={translate('questions.followUp.followUpComment')}
                  comment={question.followUpComment}
                  multiline
                  minmize={false}
                  fullWidth
                  setComment={(newComment) =>
                    setFollowUp({
                      inspectionId: question.answer.inspectionId,
                      questionId: question.answer.questionId,
                      moduleId: question.answer.inspectionModuleId,
                      needsFollowUp: true,
                      followUpDeadline: question.answer.followUpDeadline,
                      followUpComment: newComment,
                      followUpTip: question.answer.followUpTip,
                      followUpFeedback: question.answer.followUpFeedback,
                    })
                  }
                />
                <FollowUpDeadline
                  errorIfMissing={
                    !question.answer.followUpTip &&
                    !question.answer.followUpFeedback
                  }
                  label={translate('questions.followUp.followUpDeadline')}
                  value={question.answer.followUpDeadline}
                  onChange={(date) =>
                    setFollowUp({
                      inspectionId: question.answer.inspectionId,
                      questionId: question.answer.questionId,
                      moduleId: question.answer.inspectionModuleId,
                      needsFollowUp: true,
                      followUpComment: question.answer.followUpComment,
                      followUpDeadline: date,
                      followUpTip: question.answer.followUpTip,
                      followUpFeedback: question.answer.followUpFeedback,
                    })
                  }
                />
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <FormControl component='fieldset'>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={
                            !question.answer.followUpTip &&
                            !question.answer.followUpFeedback
                          }
                          onChange={() =>
                            setFollowUp({
                              inspectionId: question.answer.inspectionId,
                              questionId: question.answer.questionId,
                              moduleId: question.answer.inspectionModuleId,
                              needsFollowUp: true,
                              followUpComment: question.answer.followUpComment,
                              followUpDeadline:
                                question.answer.followUpDeadline,
                              followUpTip: false,
                              followUpFeedback: false,
                            })
                          }
                        />
                      }
                      label={translate('auditQA.action')}
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={question.answer.followUpTip}
                          onChange={() =>
                            setFollowUp({
                              inspectionId: question.answer.inspectionId,
                              questionId: question.answer.questionId,
                              moduleId: question.answer.inspectionModuleId,
                              needsFollowUp: true,
                              followUpComment: question.answer.followUpComment,
                              followUpDeadline:
                                question.answer.followUpDeadline,
                              followUpTip: true,
                              followUpFeedback: false,
                            })
                          }
                        />
                      }
                      label={translate('auditQA.tip')}
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={question.answer.followUpFeedback}
                          onChange={() =>
                            setFollowUp({
                              inspectionId: question.answer.inspectionId,
                              questionId: question.answer.questionId,
                              moduleId: question.answer.inspectionModuleId,
                              needsFollowUp: true,
                              followUpComment: question.answer.followUpComment,
                              followUpDeadline:
                                question.answer.followUpDeadline,
                              followUpTip: false,
                              followUpFeedback: true,
                            })
                          }
                        />
                      }
                      label={translate('auditQA.feedback')}
                    />
                  </FormControl>
                  <div>
                    <FormControlLabel
                      label={translate('inspectionQuestions.uploadFile')}
                      control={
                        <UploadButton component='label' label='Test'>
                          <FileUpload
                            type='file'
                            name='pic'
                            onChange={(event) => {
                              const file = event.target.files[0];
                              uploadFile({
                                moduleId: question.answer.inspectionModuleId,
                                questionId: question.answer.questionId,
                                file,
                              });
                            }}
                          />
                          <AttachFileIcon />
                        </UploadButton>
                      }
                    />
                  </div>
                </div>
                <div>
                  {(question.answer.attachments || [])
                    .filter((file) => file.isFollowUp)
                    .map((file) => (
                      <FileRow key={file.id}>
                        {isImagePath(file.path) ? (
                          <img
                            src={`/files/${file.path}`}
                            alt=''
                            width='300px'
                          />
                        ) : (
                          <FileLink
                            path={file.path}
                            name={file.originalName || file.path}
                          />
                        )}
                        <DeleteButton
                          onClick={() => {
                            removeFile({
                              fileId: file.id,
                              questionId: question.answer.questionId,
                              moduleId: question.answer.inspectionModuleId,
                            });
                          }}
                        >
                          {translate('inspectionQuestions.deleteFile')}
                        </DeleteButton>
                      </FileRow>
                    ))}
                </div>
              </div>
            )}
          </Content>
          <ActionPlanQuestions
            uploadFile={uploadFile}
            removeFile={removeFile}
            questions={question.children}
            setFollowUp={setFollowUp}
            indent={indent + 1}
          />
          {i !== questions.length - 1 && <Divider />}
        </li>
      ))}
    </ul>
  );
}
