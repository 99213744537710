import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import styled from 'styled-components';
import Button from 'components/general/Button';
import colors from 'globals/colors';
import translate from 'utils/translate';
import ModalWrapper from 'components/general/ModalWrapper';
import InnerModal from 'components/general/InnerModal';
import inspectionActions from 'actions/inspection';
import ButtonRow from 'components/general/ModalButtonRow';

const Wrapper = styled.div`
  overflow: scroll;
  max-height: 90%;
`;

const Input = styled.input`
  padding: 20px;
  border-radius: 3px;
  font-size: 14px;
  width: 200px;
  border: 1px solid ${colors.border};
  margin: 5px 0 25px 0;
  outline: none;
`;

export const providers = {
  facebook: 5,
  tripAdvisor: 5,
  booking: 10,
  hotels: 10,
  custom: Infinity
};

class ExternalScoresModal extends Component {
  constructor() {
    super();
    const state = {};
    Object.keys(providers).forEach(provider => {
      state[provider] = '';
    });
    this.state = state;
  }

  onInputChange = e => {
    const newState = {};
    newState[e.target.id] = e.target.value;
    this.setState(newState);
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.props.savedScores !== nextProps.savedScores &&
      nextProps.savedScores
    ) {
      const newState = {};
      Object.keys(providers).forEach(provider => {
        newState[provider] =
          this.state[provider] || nextProps.savedScores.get(provider);
        if (!newState[provider] && newState[provider] !== 0) {
          delete newState[provider];
        }
      });
      this.setState(newState);
    }
  }

  render() {
    const {
      visible,
      loading,
      error,
      closeModal,
      setExternalScores,
      inspectionId,
      focusField,
      reloadOnSave
    } = this.props;

    if (!visible) {
      return null;
    }
    const saveParams = {
      inspectionId,
      scores: this.state,
      reload: !!reloadOnSave
    };

    const inputs = Object.entries(providers).map(([provider, maxValue]) => (
      <Fragment key={provider}>
        <label>{translate(`inspection.externalScores.${provider}`)}</label>
        <Input
          type='number'
          step='0.01'
          min={0}
          max={maxValue}
          autoFocus={focusField === provider}
          value={this.state[provider]}
          id={provider}
          onChange={this.onInputChange}
        />
      </Fragment>
    ));

    return (
      <ModalWrapper onClose={closeModal}>
        <Wrapper>
          <InnerModal
            onSubmit={e => {
              e.preventDefault();
              setExternalScores(saveParams);
            }}
          >
            <h4>{translate('inspection.externalScores')}</h4>
            {inputs}
            {loading && <Loader />}
            {error && <Error>{translate(error)}</Error>}
            <ButtonRow>
              <Button
                color='white'
                textColor='textGray'
                type='button'
                onClick={closeModal}
              >
                {translate('back')}
              </Button>
              <Button>{translate('save')}</Button>
            </ButtonRow>
          </InnerModal>
        </Wrapper>
      </ModalWrapper>
    );
  }
}

export default connect(
  state => ({
    visible: state.getIn(['inspections', 'externalScoresModal', 'visible']),
    loading: state.getIn(['inspections', 'externalScoresModal', 'loading']),
    focusField: state.getIn([
      'inspections',
      'externalScoresModal',
      'focusField'
    ]),
    error: state.getIn(['inspections', 'externalScoresModal', 'error']),
    inspectionId: state.getIn(['inspections', 'current', 'id']),
    savedScores: state.getIn(['inspections', 'current', 'externalScores'])
  }),
  dispatch => ({
    setExternalScores: async ({inspectionId, scores, reload}) => {
      await dispatch(inspectionActions.setExternalScores(inspectionId, scores));
      if (reload) {
        dispatch(inspectionActions.load(inspectionId));
      }
    },
    closeModal: () => dispatch(inspectionActions.closeExternalScoresModal())
  })
)(ExternalScoresModal);
