import React, { useEffect } from 'react';
import CreateModal from './CreateModal';
import EditModal from './EditModal';
import DeleteModal from './DeleteModal';
import Paper from '@material-ui/core/Paper';
import InspectionTypes from 'components/general/InspectionTypes';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import translate from 'utils/translate';

export default function InspectionTypeAdmin({
  inspectionTypes,
  error,
  load,
  loading,
  role,
  openCreateModal,
  openEditModal,
  openDeleteModal,
  openModuleAdministration,
  hotelId,
  onUpdated,
  onCreated,
  onDeleted,
}) {
  useEffect(() => {
    load();
  }, [role, load]);

  return (
    <>
      <Paper elevation={2} style={{ padding: '5px 10px' }}>
        <InspectionTypes
          error={error}
          loading={loading}
          inspectionTypes={inspectionTypes}
          onClick={role === 'superUser' ? openModuleAdministration : null}
          openCreateModal={openCreateModal}
          openEditModal={openEditModal}
          openDeleteModal={openDeleteModal}
          onRenderRowActions={
            role === 'superUser'
              ? null
              : (inspectionType) => (
                  <Tooltip title={translate('edit')}>
                    <IconButton
                      size='small'
                      onClick={(event) => {
                        event.stopPropagation();
                        openEditModal(inspectionType);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )
          }
        />
      </Paper>
      <CreateModal hotelId={hotelId} onCreated={onCreated} />
      <EditModal onUpdated={onUpdated} limited={role !== 'superUser'} />
      <DeleteModal hotelId={hotelId} onDeleted={onDeleted} />
    </>
  );
}
