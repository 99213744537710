import React from 'react';
import urls from 'globals/urls';
import PrivateRoute from './PrivateRoute';
import UserAdmin from 'components/admin/User/Container';
import OrganizationAdmin from 'components/admin/Organization/Container';
import HotelAdmin from 'components/admin/Hotel/Container';
import InspectionAdmin from 'components/admin/Inspection/Container';
import InspectionModuleAdmin from 'components/admin/InspectionModule/Container';
import InspectionTypeAdmin from 'components/admin/InspectionType/Container';
import InspectionCreation from 'components/admin/InspectionCreation/Container';
import QuestionAdmin from 'components/admin/Question/Container';
import QuestionsUpload from 'components/admin/QuestionsUpload/QuestionsUpload';
import Report from 'components/admin/Report/Container';
import ReportAuditQA from 'components/admin/ReportAuditQA/Container';
import SafeQReport from 'components/admin/Hotel/SafeQReport/Container';
import WeeklyReport from 'components/admin/Hotel/WeeklyReport/Container';
import OrganizationReport from 'components/admin/Organization/Report/Container';
import HotelInspectionLists from 'components/admin/HotelInspectionList/Container';
import ManageOrganization from 'components/organization/Manage/Container';
import ManageHotel from 'components/hotel/Manage/Container';
import ManageDashboard from 'components/dashboards/Edit/Container';
import AuditQA from 'components/admin/auditQA';

export default [
  <PrivateRoute
    exact
    path={urls.adminInspections}
    component={InspectionAdmin}
    key={urls.adminInspections}
    admin
  />,
  <PrivateRoute
    exact
    path={urls.adminInspectionCreation}
    component={InspectionCreation}
    admin
    key={urls.adminInspectionCreation}
  />,
  <PrivateRoute
    exact
    path={urls.inspectionModules}
    component={InspectionModuleAdmin}
    key={urls.inspectionModules}
    admin
  />,
  <PrivateRoute
    exact
    path={[urls.hotelDetails, urls.organizationEntity]}
    component={(props) => (
      <React.Fragment>
        <ManageHotel {...props}></ManageHotel>
        <InspectionTypeAdmin {...props} />
        <HotelInspectionLists {...props} />
      </React.Fragment>
    )}
    key={urls.hotelDetails}
  />,
  <PrivateRoute
    exact
    path={urls.hotels}
    component={HotelAdmin}
    key={urls.hotels}
    admin
  />,
  <PrivateRoute
    exact
    path={urls.questionsUpload}
    component={QuestionsUpload}
    key={urls.questionsUpload}
    admin
  />,
  <PrivateRoute
    exact
    path={urls.questionAdmin}
    component={QuestionAdmin}
    key={urls.questionAdmin}
    admin
  />,
  <PrivateRoute
    exact
    path={urls.users}
    component={UserAdmin}
    key={urls.users}
    admin
  />,
  <PrivateRoute
    exact
    path={urls.organizations}
    component={OrganizationAdmin}
    key={urls.organizations}
    admin
  />,
  <PrivateRoute
    exact
    key={urls.organizationStart}
    path={urls.organizationStart}
    component={(routeProps) => (
      <ManageOrganization variant='both' {...routeProps} />
    )}
  />,
  <PrivateRoute
    exact
    path={urls.report}
    component={Report}
    key={urls.report}
    admin
  />,
  <PrivateRoute
    exact
    path={urls.reportAuditQA}
    component={ReportAuditQA}
    key={urls.reportAuditQA}
  />,
  <PrivateRoute
    exact
    path={urls.safeQDailyReport}
    component={SafeQReport}
    key={urls.safeQDailyReport}
  />,
  <PrivateRoute
    exact
    path={urls.weeklyReport}
    component={WeeklyReport}
    key={urls.weeklyReport}
  />,
  <PrivateRoute
    exact
    path={urls.organizationReport}
    component={OrganizationReport}
    key={urls.organizationReport}
  />,
  <PrivateRoute
    exact
    path={urls.dashboardsCreate}
    component={ManageDashboard}
    key={`${urls.dashboardCreate}x`}
  />,
  <PrivateRoute
    path={urls.dashboards}
    component={ManageDashboard}
    key={urls.dashboards}
  />,
  <PrivateRoute
    exact
    path={urls.listAuditQA}
    component={AuditQA}
    key={urls.listAuditQA}
  />,
];
