import { fromJS } from 'immutable';

const defaultState = fromJS({
  visible: false,
  loading: false,
  comments: {
    global: '',
  },
  error: '',
});

export default function (state = defaultState, action = { type: '' }) {
  switch (action.type) {
    case 'OPEN_GENERAL_COMMENTS_MODAL': {
      return state.set('visible', true).set('loading', false).set('error', '');
    }
    case 'CLOSE_GENERAL_COMMENTS_MODAL': {
      return state.set('visible', false);
    }
    case 'SET_GENERAL_COMMENTS_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'SET_GENERAL_COMMENTS_DONE': {
      return state
        .set('loading', false)
        .setIn(['comments', 'global'], action.meta.comments);
    }
    case 'SET_GENERAL_COMMENTS_FAILED': {
      return state.set('error', action.payload).set('loading', false);
    }
    case 'LOAD_INSPECTION_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'LOAD_INSPECTION_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'LOAD_INSPECTION_DONE': {
      return state.set('loading', false).set(
        'comments',
        fromJS({
          global: action.payload.generalComments,
        })
      );
    }
    default: {
      return state;
    }
  }
}
