import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 200px;
  max-width: 600px;
  margin: 20px auto;
  padding: 40px;
  box-shadow: -2px 3px 9px rgba(0,0,0,0.2);
transition: all .1s ease-in-out;
`;
