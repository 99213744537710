import { connect } from 'react-redux';
import organizationActions from 'actions/admin/organization';
import OrganizationFormModal from './OrganizationFormModal';

export default connect(
  (state) => ({
    visible: state.getIn(['admin', 'organizations', 'createModal', 'visible']),
    loading: state.getIn(['admin', 'organizations', 'createModal', 'loading']),
    error: state.getIn(['admin', 'organizations', 'createModal', 'error']),
    creating: true,
  }),
  (dispatch) => ({
    submit: (organization) =>
      dispatch(organizationActions.createOrganization(organization)),
    closeModal: () => dispatch(organizationActions.closeCreateModal()),
  })
)(OrganizationFormModal);
