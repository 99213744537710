import React from 'react';
import translate from 'utils/translate';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox } from '@material-ui/core';

export default function NACheckbox({ isMandatory, score, setScore }) {
  if (isMandatory) {
    return null;
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={score === -1}
          onChange={(event) => (event.target.checked ? setScore(-1) : null)}
        />
      }
      label={translate('NA')}
    />
  );
}
