import { request } from 'actions/utils';

export default function deleteFile({ inspectionId, fileId }) {
  return request({
    action: 'FOLLOW_UP_SUMMARY_DELETE_FILE',
    path: `/api/admin/inspections/${inspectionId}/follow-up-summary/file/${fileId}`,
    method: 'DELETE',
    extraPayload: {
      inspectionId,
      fileId,
    },
  });
}
