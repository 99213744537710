import React from 'react';
import styled from 'styled-components';
import media from 'globals/media';

const Logo = styled.img`
  height: 80px;
  margin-right: 15px;

  ${media.tablet`
    height: 60px;
  `};

  ${media.smallTablet`
    height: 40px;
  `};
`;

export default function OrganizationLogo({ data, mimeType }) {
  const src = `data:${mimeType};base64, ${data}`;
  return <Logo src={src} />;
}
