import { request } from 'actions/utils';

export default function ({ inspectionId, ...fields }) {
  return request({
    action: 'UPDATE_INSPECTION',
    method: 'PUT',
    path: `/api/inspections/${inspectionId}`,
    body: fields,
    extraPayload: fields,
  });
}
