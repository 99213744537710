import React from 'react';
import { Route } from 'react-router-dom';
import urls from 'globals/urls';
import ManageOrganization from 'components/organization/Manage/Container';
import { Container as ManageUsers } from 'components/user/Manage';

export default [
  <Route
    key={urls.organizationHotels}
    path={urls.organizationHotels}
    component={(routeProps) => (
      <ManageOrganization variant='hotel' {...routeProps} />
    )}
  />,
  <Route
    key={urls.organizationInspectionTypes}
    path={urls.organizationInspectionTypes}
    component={(routeProps) => (
      <ManageOrganization variant='inspectionTypes' {...routeProps} />
    )}
  />,
  <Route
    key={urls.organizationStatistics}
    path={urls.organizationStatistics}
    component={(routeProps) => (
      <ManageOrganization variant='statistics' {...routeProps} />
    )}
  />,
  <Route
    key={urls.organizationSettings}
    path={urls.organizationSettings}
    component={(routeProps) => (
      <ManageOrganization variant='settings' {...routeProps} />
    )}
  />,
  <Route
    key={urls.organizationUsers}
    path={urls.organizationUsers}
    component={(routeProps) => (
      <ManageOrganization variant='user' {...routeProps} />
    )}
  />,
  <Route
    key={urls.usersStart}
    path={[urls.usersStart, urls.organizationUser]}
    component={ManageUsers}
  />,
];
