import { fromJS } from 'immutable';

const defaultState = fromJS({
  list: [],
  inspectionTypes: [],
  loading: false,
  error: '',
});

export default function (state = defaultState, action = { type: '' }) {
  switch (action.type) {
    case 'LOAD_INSPECTIONS_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'LOAD_INSPECTIONS_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'LOAD_INSPECTIONS_DONE': {
      return state.set('list', fromJS(action.payload)).set('loading', false);
    }
    case 'LOAD_SELF_SERVE_INSPECTION_TYPES_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'LOAD_SELF_SERVE_INSPECTION_TYPES_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'LOAD_SELF_SERVE_INSPECTION_TYPES_DONE': {
      return state
        .set('inspectionTypes', fromJS(action.payload))
        .set('loading', false);
    }
    default: {
      return state;
    }
  }
}
