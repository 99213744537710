import React from 'react';
import { connect } from 'react-redux';
import translate from 'utils/translate';
import inspectionActions from 'actions/inspectionQuestions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

function ErrorModal({
  visible,
  closeModal
}) {
  if (!visible) {
    return null;
  }
  
  return (
    <Dialog open onClose={closeModal}>
      <DialogTitle>
        {translate('inspectionQuestions.errorModal.header')}
      </DialogTitle>
      <DialogContent>
      {translate('inspectionQuestions.errorModal.message')}
      </DialogContent>
      <DialogActions>
        <Button color='primary' type='button' onClick={closeModal}>
          {translate('inspectionQuestions.errorModal.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default connect(
  (state, ownProps) => {
    return {
      visible: state.getIn([
        'inspections',
        'questions',
        'errorModal',
        'visible',
      ]),
      ...ownProps
    };
  },
  (dispatch) => ({
    closeModal: () => dispatch(inspectionActions.closeErrorModal()),
  })
)(ErrorModal);
