import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js';
import colors from 'globals/colors';
import { isToday } from 'utils/date';
import { lighten, getLuminance, darken } from '@material-ui/core/styles';

const lineColors = [
  'hsl(180, 60%, 60%)',
  'hsl(90, 60%, 30%)',
  'hsl(255, 60%, 60%)',
  'hsl(180, 60%, 30%)',
  'hsl(255, 60%, 30%)',
  'hsl(255, 60%, 15%)',
];

const altLineColors = [
  'hsl(197, 90%, 52%)',
  'hsl(287, 72%, 37%)',
  'hsl(24, 73%, 55%)',
  'hsl(332, 90%, 59%)',
  'hsl(255, 60%, 30%)',
  'hsl(255, 60%, 15%)',
];

function getRandomColor(i, alternativeColors) {
  const colors = alternativeColors ? altLineColors : lineColors;
  return colors[i % colors.length];
}

function roundDown(nr) {
  return nr - (nr % 10);
}

export default function LineChart({
  labels,
  data,
  isSorted,
  alternativeColors,
  lastColorBlack,
  backgroundColor,
  stepSize = 10,
  forceSize = false,
  xAxes,
  title,
}) {
  const canvas = useRef(null);

  useEffect(() => {
    if (!canvas.current) {
      return;
    }
    const ctx = canvas.current.getContext('2d');

    let minScore = 100;
    let maxScore = 0;

    const datasets = Object.entries(data).map(([key, lineData], i, self) => {
      if (!isSorted) {
        lineData = lineData.sort((a, b) => new Date(a.x) - new Date(b.x));
      }
      const minY = lineData.reduce((min, p) => (p.y < min ? p.y : min), 100);
      const maxY = lineData.reduce((max, p) => (p.y < max ? max : p.y), 0);

      minScore = minScore > minY ? minY : minScore;
      maxScore = maxScore <= maxY ? Math.ceil((maxY + 1) / 10) * 10 : maxScore;

      const forceBlack = i === self.length - 1 && lastColorBlack && 'black';
      return {
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor:
          forceBlack || colors[i] || getRandomColor(i, alternativeColors),
        pointBackgroundColor:
          forceBlack || colors[i] || getRandomColor(i, alternativeColors),
        label: key,
        pointRadius: lineData.length > 1 ? 3 : 6,
        data: lineData,
        lineTension: false,
        borderWidth: 3,
      };
    });

    maxScore = Math.min(110, maxScore);
    minScore = Math.min(90, minScore);

    const gridLineColor =
      backgroundColor &&
      (getLuminance(backgroundColor) < 0.5
        ? lighten(backgroundColor, 0.2)
        : darken(backgroundColor, 0.2));

    const axisTextColor =
      backgroundColor &&
      (getLuminance(backgroundColor) < 0.5
        ? lighten(backgroundColor, 0.8)
        : darken(backgroundColor, 0.8));

    new Chart(ctx, {
      type: 'line',
      data: {
        datasets,
        labels: labels || Object.values(data)[0].map((d) => d.x),
      },
      options: {
        maintainAspectRatio: !forceSize,
        legend: {
          labels: {
            fontColor: axisTextColor,
          },
        },
        title,
        scales: {
          xAxes: xAxes || [
            {
              type: 'time',
              ticks: {
                source: 'labels',
                callback: (value, index, values) => {
                  if (isToday(values[index].value)) {
                    return 'Today';
                  }

                  return value;
                },
              },
              time: {
                unit: 'month',
                displayFormats: {
                  month: 'DD-MMM',
                },
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                color: gridLineColor,
              },
              ticks: {
                autoSkip: true,
                fontColor: axisTextColor,
                stepSize,
                suggestedMax: 110,
                min:
                  roundDown(minScore) >= 10
                    ? minScore - roundDown(minScore) === 0
                      ? roundDown(minScore - 10)
                      : roundDown(minScore)
                    : 0,
                callback: (value) => {
                  if (value > 100) return '';
                  return value + '%';
                },
              },
            },
          ],
        },
        chartArea: {},
      },
    });
  });

  return <canvas ref={canvas} />;
}
