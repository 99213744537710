export const languages = [
  {
    icon: 'se',
    name: 'Svenska',
    code: 'sv_SE',
    shortCode: 'sv',
  },
  {
    icon: 'us',
    name: 'English (US)',
    code: 'en_US',
    shortCode: 'en',
  },
  {
    icon: 'gb',
    name: 'English (GB)',
    code: 'en_GB',
    shortCode: 'en',
  },
  {
    icon: 'de',
    name: 'Deutsche',
    code: 'de_DE',
    shortCode: 'de',
  },
];

export const dashboardLanguages = [
  {
    icon: 'gb',
    name: 'English (GB)',
    code: 'en_GB',
    shortCode: 'en',
  },
  {
    icon: 'us',
    name: 'English (US)',
    code: 'en_US',
    shortCode: 'en',
  },
  {
    icon: 'se',
    name: 'Svenska',
    code: 'sv_SE',
    shortCode: 'sv',
  },
  {
    icon: 'de',
    name: 'Deutsche',
    code: 'de_DE',
    shortCode: 'de',
  },
  {
    icon: 'dk',
    name: 'Dansk',
    code: 'da_DK',
    shortCode: 'da',
  },
  {
    icon: 'es',
    name: 'Español',
    code: 'es_ES',
    shortCode: 'es',
  },
  {
    icon: 'fi',
    name: 'Suomalainen',
    code: 'fi_FI',
    shortCode: 'fi',
  },
  {
    icon: 'fr',
    name: 'Français',
    code: 'fr_FR',
    shortCode: 'fr',
  },
  {
    icon: 'no',
    name: 'Norsk',
    code: 'nb_NO',
    shortCode: 'nb',
  },
  {
    icon: 'it',
    name: 'Italiano',
    code: 'it_IT',
    shortCode: 'it',
  },
];
