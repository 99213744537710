import { connect } from 'react-redux';
import List from './List';
import hotelActions from 'actions/admin/hotel';
import confirm from 'utils/confirm';
import translate from 'utils/translate';
import organizationActions from 'actions/admin/organization';

function mapStateToProps(state, ownProps) {
  return {
    role: state.getIn(['currentUser', 'role']),
    error: state.getIn(['organization', 'error']),
    loading: state.getIn(['organization', 'loading']),
    organization: state.getIn(['organization', 'current']),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    load: () => {
      dispatch(
        organizationActions.loadOrganization.hotels(
          ownProps.organizationId || null
        )
      );
    },
    openHotelAdministration: (id) => {
      if (ownProps.organizationId) {
        dispatch(
          hotelActions.openOrganizationHotel(id, ownProps.organizationId)
        );
        return;
      }
      dispatch(hotelActions.openHotel(id));
    },
    openCreateHotelModal: (organizationId) =>
      dispatch(hotelActions.openCreateModal(organizationId)),
    openDeleteModal: (hotel) => dispatch(hotelActions.openDeleteModal(hotel)),
    deleteHotel: async (hotel) => {
      const shouldDelete = await confirm(
        translate('hotels.delete.confirmText').replace(
          '{name}',
          hotel.get('name')
        ),
        {
          title: translate('hotels.delete.header'),
          confirmText: translate('hotels.delete'),
        }
      );
      if (shouldDelete) {
        dispatch(
          hotelActions.deleteHotel(hotel.get('id'), ownProps.onHotelDeleted)
        );
      }
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
