import { rawRequest } from 'actions/utils';

export function requestPasswordReset(email) {
  return rawRequest({
    method: 'POST',
    path: `/api/sessions/request-password-reset`,
    body: {
      email,
    },
  });
}
