import React from 'react';
import styled from 'styled-components';
import Fade from '@material-ui/core/Fade';
import { useTheme } from '@material-ui/core/styles';
import colors from 'globals/colors';

const Container = styled.div`
  width: ${({ autoWidth }) => (autoWidth ? 'auto' : '300px')};
  background-color: ${({ background }) => background};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 3px;
  margin: 10px 0;
  overflow: hidden;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
  pointer-events: ${(props) => (props.show ? 'all' : 'none')};
  opacity: ${(props) => (props.show ? '1' : '0')};
  min-height: 19px;
`;

export default function Message({
  type,
  show,
  children,
  style,
  className,
  autoWidth = true,
}) {
  const theme = useTheme();
  let background = theme.palette.background.paper;

  switch (type) {
    case 'warning': {
      background = theme.palette.warning.main;
      break;
    }
    case 'error': {
      background = colors.errorRed;
      break;
    }
    case 'success': {
      background = theme.palette.success.main;
      break;
    }
    default: {
      background = theme.palette.background.paper;
      break;
    }
  }

  show = show === undefined || show;
  return (
    <Fade in={show}>
      <Container
        style={style}
        className={className}
        show={show}
        autoWidth={autoWidth}
        background={background}
      >
        {children}
      </Container>
    </Fade>
  );
}
