const INITIAL_STATE = {
  loading: false,
  error: ''
};

export default function reducer(state = INITIAL_STATE, action = {type: ''}) {
  switch (action.type) {
    case 'REMOVE_QUESTION_STARTED': {
      return {...state, loading: true, error: ''};
    }
    case 'REMOVE_QUESTION_FAILED': {
      return {...state, loading: false, error: action.payload};
    }
    case 'REMOVE_QUESTION_DONE': {
      return {...state, loading: false};
    }
    default:
      return state;
  }
}