import loadUsers from './loadUsers';
import createUser from './createUser';
import deleteUser from './deleteUser';
import editUser from './editUser';
import loadUser from './loadUser';
import linkToHotel from './linkToHotel';
import unlinkFromHotel from './unlinkFromHotel';
import linkToInspectionType from './linkToInspectionType';
import unlinkFromInspectionType from './unlinkFromInspectionType';
import { push } from 'connected-react-router';
import urls from 'globals/urls';

export default {
  loadUsers,
  loadUser,
  createUser,
  deleteUser,
  linkToHotel,
  unlinkFromHotel,
  linkToInspectionType,
  unlinkFromInspectionType,
  editUser,
  openView,
  openOrganizationView,
  openCreateModal,
  closeCreateModal,
  openEditModal,
  closeEditModal,
  openDeleteModal,
  closeDeleteModal,
};

export function openView(userId) {
  return push(urls.usersStart.replace(':id', userId));
}

export function openOrganizationView(userId, organizationId) {
  return push(
    urls.organizationUser
      .replace(':organizationId', organizationId)
      .replace(':id', userId)
  );
}

export function openCreateModal(organizationId) {
  return {
    type: 'OPEN_CREATE_USER_MODAL',
    payload: {
      organizationId,
    },
  };
}
export function closeCreateModal() {
  return {
    type: 'CLOSE_CREATE_USER_MODAL',
  };
}

export function openEditModal(user) {
  return {
    type: 'OPEN_EDIT_USER_MODAL',
    payload: user,
  };
}

export function closeEditModal() {
  return {
    type: 'CLOSE_EDIT_USER_MODAL',
  };
}

export function openDeleteModal(user) {
  return {
    type: 'OPEN_DELETE_USER_MODAL',
    payload: user,
  };
}

export function closeDeleteModal() {
  return {
    type: 'CLOSE_DELETE_USER_MODAL',
  };
}
