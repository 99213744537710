import { uploadAttachment } from 'actions/utils';

export default function uploadFile({
  inspectionId,
  questionId,
  moduleId,
  file,
  isFollowUp,
}) {
  return async (dispatch) => {
    const extraPayload = {
      questionId,
      moduleId,
      name: file && file.name,
    };

    dispatch({
      type: `UPLOAD_FILE_STARTED`,
      payload: extraPayload,
      meta: extraPayload,
    });

    try {
      const responseBody = await uploadAttachment({
        path: `/api/inspections/${inspectionId}/modules/${moduleId}/questions/${questionId}/file`,
        file,
        isFollowUp,
        dispatch,
      });

      dispatch({
        type: `UPLOAD_FILE_DONE`,
        payload: responseBody.data,
        meta: extraPayload,
      });
    } catch (error) {
      if (error.message === 'canceled') {
        dispatch({
          type: `UPLOAD_FILE_CANCELED`,
          payload: 'upload.canceled',
          meta: extraPayload,
          error: '',
        });
        return;
      }
      dispatch({
        type: `UPLOAD_FILE_FAILED`,
        payload:
          error.response && error.response.data && error.response.data.msg,
        meta: extraPayload,
        error:
          error.response && error.response.data && error.response.data.errors,
      });
      return;
    }
  };
}
