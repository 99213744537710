import { fromJS } from 'immutable';
import createModal from './createModal';
import editModal from './editModal';
import deleteModal from './deleteModal';
import current from './current';

const defaultState = fromJS({
  list: [],
  current: current(),
  loading: false,
  error: '',
  createModal: createModal(),
  editModal: editModal(),
  deleteModal: deleteModal(),
});

export default function (state = defaultState, action = { type: '' }) {
  state = state.set(
    'createModal',
    createModal(state.get('createModal'), action)
  );
  state = state.set('editModal', editModal(state.get('editModal'), action));
  state = state.set(
    'deleteModal',
    deleteModal(state.get('deleteModal'), action)
  );
  state = state.set('current', current(state.get('current'), action));
  switch (action.type) {
    case 'LOAD_INSPECTION_TYPES_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'LOAD_INSPECTION_TYPES_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'LOAD_INSPECTION_TYPES_DONE': {
      return state.set('list', fromJS(action.payload)).set('loading', false);
    }
    default: {
      return state;
    }
  }
}
