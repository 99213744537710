import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 15px;
  > button {
    margin-right: 15px;
  }

  h1 {
    margin: 0;
  }
`;
