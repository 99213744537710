import moment from 'moment';

const INITIAL_STATE = {
  startTime: '',
  startTimeHour: '',
  id: 0,
  loading: false,
  error: ''
};

export default function reducer(state = INITIAL_STATE, action = {type: ''}) {
  switch (action.type) {
    case 'INITIALIZE_FROM_PROPS': {
      const {data} = action.payload;
      return {
        ...state,
        id: data.id,
        startTime: moment(data.startTime).format('YYYY-MM-DD') || '',
        startTimeHour: moment(data.startTime).format('kk:mm') || ''
      };
    }
    case 'UPDATE_INSPECTION_STARTED': {
      return {loading: true, error: ''};
    }
    case 'UPDATE_INSPECTION_FAILED': {
      return {loading: false, error: action.payload};
    }
    case 'UPDATE_INSPECTION_DONE': {
      return {loading: false};
    }
    case 'CHANGE_INPUT': {
      const {field, value} = action.payload;
      return {[field]: value};
    }
    default:
      return state;
  }
}
