import {connect} from 'react-redux';
import inspectionTypeActions from 'actions/admin/inspectionType';
import hotelActions from 'actions/admin/hotel';
import userActions from 'actions/admin/user';
import {openInspectionAdmin} from 'actions/navigation';
import InspectionCreation from './InspectionCreation';

export default connect(
  (state, ownProps) => ({
    users: state.getIn(['admin', 'users', 'users']),
    hotels: state.getIn(['admin', 'hotels', 'hotels']),
    inspectionTypes: state.getIn(['admin', 'inspectionTypes', 'list'])
  }),
  dispatch => ({
    loadHotels: () => dispatch(hotelActions.loadHotels()),
    loadInspectionTypes: hotelId => dispatch(inspectionTypeActions.load(hotelId)),
    loadUsers: () => dispatch(userActions.loadUsers()),
    back: () => dispatch(openInspectionAdmin())
  })
)(InspectionCreation);