import React from 'react';
import LineChart from '../SafeQReport/LineChart';
import translate from 'utils/translate';
import { getLineData } from './utils';

export default function HotelLineCharts({
  currentHotel,
  hotels,
  startDay,
  currentWeek,
  yearWeeks,
}) {
  return (
    <LineChart
      isSorted
      lastColorBlack
      xAxes={[
        {
          type: 'time',
          time: { unit: 'week', displayFormats: { week: 'v.w' } },
        },
      ]}
      data={{
        [translate('safeQReport.fullfillmentHotel')]: getLineData({
          fullfillment: true,
          startDay,
          hotels: [currentHotel],
          weeks: yearWeeks,
        }),
        [translate('safeQReport.fullfillmentBest')]: getLineData({
          bestPerWeek: true,
          fullfillment: true,
          startDay,
          hotels,
          weeks: yearWeeks,
        }),
        [translate('safeQReport.fullfillmentWorst')]: getLineData({
          worstPerWeek: true,
          fullfillment: true,
          startDay,
          hotels,
          weeks: yearWeeks,
        }),
        [translate('safeQReport.fullfillmentAverage')]: getLineData({
          fullfillment: true,
          startDay,
          hotels,
          weeks: yearWeeks,
        }),
      }}
    />
  );
}
