import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import NACheckbox from '../NACheckbox';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function GradeHScore({
  score,
  content,
  isMandatory,
  question,
  setScore,
}) {
  const onChange = (event) => {
    const score = event.target.checked ? 1 : 0;

    setScore(score);
  };

  return (
    <Wrapper>
      <FormControlLabel
        control={
          <Checkbox
            indeterminate={score === null}
            checked={score === 1}
            onChange={onChange}
            name='checkedA'
          />
        }
        label={content}
      />
      <NACheckbox score={score} isMandatory={isMandatory} setScore={setScore} />
    </Wrapper>
  );
}
