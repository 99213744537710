import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { Hotel, HotelTitle, MetaData } from 'components/admin/Report/styled';
import translate from 'utils/translate';

const InfoWrapper = styled.div`
  margin-top: 2rem;
  margin-right: 2rem;
  display: grid;
  grid-row-gap: 0.5rem;
  grid-column-gap: 1rem;
  grid-template-columns: min-content auto;
  p {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default function HeaderInformation({
  week,
  name,
  totalHotels,
  excludedHotelNames,
  selectedHotelNames,
  day,
}) {
  const startDay = moment(day).startOf('isoWeek').format('YYYY-MM-DD');
  const selectedHotels = !selectedHotelNames
    ? `${translate('safeQReport.allHotels')} (${totalHotels} ${translate(
        'hotels'
      )})`
    : selectedHotelNames.length > 5
    ? `${selectedHotelNames.length} ${translate('hotels')}`
    : selectedHotelNames.map((name) => (
        <>
          {name}
          <br />
        </>
      ));

  const excludedHotels =
    !excludedHotelNames || !excludedHotelNames.length
      ? []
      : excludedHotelNames.length > 5
      ? [`${excludedHotelNames.length} ${translate('hotels')}`]
      : excludedHotelNames.map((name) => (
          <>
            {name}
            <br />
          </>
        ));

  return (
    <MetaData>
      <Hotel>
        <HotelTitle>{name}</HotelTitle>
      </Hotel>
      <div>
        <InfoWrapper>
          <Typography style={{ whiteSpace: 'nowrap' }}>
            {translate('safeQReport.startDate').concat(':')}
          </Typography>
          <Typography>{startDay}</Typography>
          <Typography style={{ whiteSpace: 'nowrap' }}>
            {translate('safeQReport.endDate').concat(':')}
          </Typography>
          <Typography>{day}</Typography>
          <Typography style={{ whiteSpace: 'nowrap' }}>
            {translate('safeQReport.selectedHotels').concat(':')}
          </Typography>
          <Typography>{selectedHotels}</Typography>
          {!!excludedHotels.length && (
            <>
              <Typography style={{ whiteSpace: 'nowrap' }}>
                {translate('safeQReport.excludedHotels').concat(':')}
              </Typography>
              <Typography>{excludedHotels}</Typography>
            </>
          )}
        </InfoWrapper>
      </div>
    </MetaData>
  );
}
