import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import QuestionAdmin from './QuestionAdmin';
import inspectionModuleActions from 'actions/admin/inspectionModule';
import questionActions from 'actions/admin/question';
import urls from 'globals/urls';
import { Map, List } from 'immutable';

function mapStateToProps(state, ownProps) {
  let moduleTypeId =
    ownProps.match.params.moduleTypeId ||
    state.getIn([
      'admin',
      'inspectionModules',
      'moduleTypes',
      'types',
      'inspectionModuleTypes',
      0,
      'id',
    ]);
  moduleTypeId = isNaN(parseInt(moduleTypeId, 10))
    ? moduleTypeId
    : parseInt(moduleTypeId, 10);
  const types =
    state.getIn([
      'admin',
      'inspectionModules',
      'moduleTypes',
      'types',
      'inspectionModuleTypes',
    ]) || List();
  const moduleType = types.find((t) => t.get('id') === moduleTypeId) || Map();
  const moduleTypeClassifications =
    state.getIn([
      'admin',
      'inspectionModules',
      'moduleTypes',
      'types',
      'moduleTypeClassifications',
    ]) || List();

  const moduleTypeAreas =
    state.getIn([
      'admin',
      'inspectionModules',
      'moduleTypes',
      'types',
      'moduleTypeAreas',
    ]) || List();

  return {
    error: state.getIn(['admin', 'questions', 'error']),
    loading: state.getIn(['admin', 'questions', 'loading']),
    questionClusters: state.getIn(['admin', 'questions', 'list']),
    questionTypes: state.getIn(['admin', 'questions', 'types']),
    moduleTypes: state.getIn([
      'admin',
      'inspectionModules',
      'moduleTypes',
      'types',
      'inspectionModuleTypes',
    ]),
    moduleTypeClassifications,
    moduleTypeAreas,
    moduleTypeId,
    moduleType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadQuestions: (moduleType, languageShortCode) =>
      dispatch(questionActions.load(moduleType, languageShortCode)),
    changeModuleType: (moduleTypeId) => {
      dispatch(questionActions.load(moduleTypeId));
      dispatch(
        replace(urls.questionAdmin.replace(':moduleTypeId', moduleTypeId))
      );
    },
    loadQuestionTypes: () => dispatch(questionActions.loadTypes()),
    loadModuleTypes: () => dispatch(inspectionModuleActions.loadTypes()),
    openDeleteQuestionModal: (question) =>
      dispatch(questionActions.openDeleteQuestionModal(question)),
    updateQuestion: (question) => dispatch(questionActions.update(question)),
    updateQuestionCluster: (cluster) =>
      dispatch(questionActions.updateCluster(cluster)),
    openCreateQuestionModal: () =>
      dispatch(questionActions.openCreateQuestionModal()),
    openCreateClusterModal: () =>
      dispatch(questionActions.openCreateClusterModal()),
    openDeleteClusterModal: (cluster) =>
      dispatch(questionActions.openDeleteClusterModal(cluster)),
    openCopyModuleTypeModal: () =>
      dispatch(inspectionModuleActions.openCopyTypeModal()),
    openCreateModuleTypeModal: () =>
      dispatch(inspectionModuleActions.openCreateTypeModal()),
    openEditModuleTypeModal: () =>
      dispatch(inspectionModuleActions.openEditTypeModal()),
    openDeleteModuleTypeModal: () =>
      dispatch(inspectionModuleActions.openDeleteTypeModal()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionAdmin);
