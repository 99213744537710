import styled from 'styled-components';
import colors from 'globals/colors';

export default styled.button`
  padding: ${(props) => (props.small ? '5px 8px' : '10px 15px')};
  margin-left: ${(props) => props.marginLeft || '0'};
  border-radius: 3px;
  font-size: 14px;
  width: auto;
  border: none;
  color: ${(props) => colors[props.textColor] || 'white'};
  outline: none;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  background-color: ${(props) =>
    colors[props.color] || props.theme.palette.primary.main};
  &:disabled {
    cursor: not-allowed;
  }
  &:hover {
    filter: brightness(1.04);
  }
`;
