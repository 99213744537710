import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import translate from 'utils/translate';
import styled from 'styled-components';
import FollowUpDialog from './FollowUpDialog';
import moment from 'moment';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { getInspectionLink } from '../utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import confirm from 'utils/confirm';
import Alert from '@material-ui/lab/Alert';
import { DeadlineWarnings } from '../styled';

const CheckCircle = styled(CheckCircleIcon)`
  vertical-align: middle;
  margin-right: 5px;
  color: ${({ theme }) => theme.palette.success.main};
`;

const Container = styled(Paper)`
  padding: 10px 10px;
  margin: 20px 0;

  h2 {
    text-align: left;
    margin-bottom: 0.35em;
  }
`;

export default function FollowUp({
  isCreating,
  inspectionId,
  followUpInspection,
  onCreateFollowUp,
  enableActionPlan,
  deadlineQuestions,
}) {
  const questionsMissingDeadline = deadlineQuestions.filter(
    (q) => !q.getIn(['answers', 0, 'followUpDeadline'])
  );
  debugger;
  const needsFollowUp =
    deadlineQuestions.filter((q) => q.getIn(['answers', 0, 'followUpDeadline']))
      .size > 0;
  const [showDialog, setShowDialog] = useState(false);
  const [sendCCToCreator, setSendCCToCreator] = useState(true);
  return (
    <Container>
      <Typography variant='h2' gutterBottom align='left'>
        {translate('followUp')}
      </Typography>
      {isCreating ? (
        <CircularProgress />
      ) : (
        <>
          {followUpInspection && followUpInspection.get('id') ? (
            <>
              <Typography>
                <strong>{translate('auditQA.columns.startTime')}:</strong>{' '}
                {moment(followUpInspection.get('startTime')).format(
                  'YYYY-MM-DD'
                )}
              </Typography>
              <Typography>
                <strong>{translate('auditQA.columns.agentEndTime')}:</strong>{' '}
                {followUpInspection.get('agentEndTime')
                  ? moment(followUpInspection.get('agentEndTime')).format(
                      'YYYY-MM-DD'
                    )
                  : '-'}
              </Typography>
              <Typography>
                <strong>{translate('auditQA.columns.adminEndTime')}</strong>:{' '}
                {followUpInspection.get('adminEndTime')
                  ? moment(followUpInspection.get('adminEndTime')).format(
                      'YYYY-MM-DD'
                    )
                  : '-'}
              </Typography>
              <Link
                to={getInspectionLink(followUpInspection)}
                target='_blank'
                rel='noreferrer noopener'
              >
                <Button
                  variant='contained'
                  type='button'
                  endIcon={<OpenInNewIcon />}
                >
                  {translate('followUp.open')}
                </Button>
              </Link>
            </>
          ) : enableActionPlan ? (
            <p>
              <CheckCircle />
              {translate('followUp.sent')}
            </p>
          ) : (
            <>
              <Button
                onClick={async () => {
                  if (needsFollowUp) {
                    return setShowDialog(true);
                  }

                  const shouldSendActionPlan = await confirm(
                    translate('followUp.sendConfirmation'),
                    {
                      title: translate('followUp.send'),
                      confirmText: translate('send'),
                      onRenderContent: (message) => (
                        <>
                          <Typography gutterBottom>{message}</Typography>
                          {questionsMissingDeadline.size > 0 && (
                            <Alert severity='info'>
                              {translate(
                                'followUp.missingDeadlineWarningFollowUp'
                              )}
                              <DeadlineWarnings>
                                {questionsMissingDeadline.map((q) => (
                                  <li key={q.get('id')}>
                                    {q.get('content') ||
                                      q.get('defaultContent')}
                                  </li>
                                ))}
                              </DeadlineWarnings>
                            </Alert>
                          )}
                        </>
                      ),
                    }
                  );

                  if (!shouldSendActionPlan) {
                    return;
                  }

                  onCreateFollowUp({
                    startTime: new Date(),
                    startTimeHour: new Date(),
                    user: null,
                    sendCCToCreator,
                  });
                }}
                variant='contained'
              >
                {needsFollowUp
                  ? translate('followUp.create')
                  : translate('followUp.send')}
              </Button>
              {!needsFollowUp && (
                <Box>
                  <FormControlLabel
                    label={translate('followUp.sendCCToCreator')}
                    labelPlacement='right'
                    control={
                      <Checkbox
                        name='sendCCToCreator'
                        label={translate('followUp.sendCCToCreator')}
                        checked={sendCCToCreator}
                        onChange={() => setSendCCToCreator(!sendCCToCreator)}
                      />
                    }
                  />
                </Box>
              )}
            </>
          )}
        </>
      )}
      <FollowUpDialog
        questionsMissingDeadline={questionsMissingDeadline}
        followUpToInspectionId={inspectionId}
        show={showDialog}
        onClose={() => setShowDialog(false)}
        onSubmit={(inspection) => {
          setShowDialog(false);
          onCreateFollowUp(inspection);
        }}
      />
    </Container>
  );
}
