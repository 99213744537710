import load from './load';
import setScore from './setScore';
import setFollowUp from './setFollowUp';
import setComment from './setComment';
import updateExtraQuestion from './updateExtraQuestion';
import setModuleData from './setModuleData';
import finishModule from './finishModule';
import loadModuleData from './loadModuleData';
import uploadFile from './uploadFile';
import deleteFile from './deleteFile';
import saveClusterComments from './saveClusterComments';
export default {
  load,
  loadModuleData,
  setScore,
  setFollowUp,
  setComment,
  setModuleData,
  finishModule,
  openComfortZoneModal,
  closeComfortZoneModal,
  openConfirmFinishModal,
  closeConfirmFinishModal,
  openErrorModal,
  closeErrorModal,
  uploadFile,
  deleteFile,
  updateExtraQuestion,
  saveClusterComments,
};

export function openComfortZoneModal(focusField) {
  return {
    type: 'OPEN_COMFORT_ZONE_MODAL',
    payload: { focusField },
  };
}

export function closeComfortZoneModal() {
  return {
    type: 'CLOSE_COMFORT_ZONE_MODAL',
  };
}

export function openConfirmFinishModal(unansweredQuestions) {
  return {
    type: 'OPEN_CONFIRM_FINISH_MODULE_MODAL',
    payload: unansweredQuestions,
  };
}

export function closeConfirmFinishModal() {
  return {
    type: 'CLOSE_CONFIRM_FINISH_MODULE_MODAL',
  };
}

export function openErrorModal() {
  return {
    type: 'OPEN_ERROR_MODAL',
  };
}

export function closeErrorModal() {
  return {
    type: 'CLOSE_ERROR_MODAL',
  };
}
