import {request} from 'actions/utils';

export default function setEndTime(inspectionId) {
  return request({
    action: 'SET_END_TIME',
    method: 'POST',
    path: `/api/inspections/${inspectionId}/endTime`,
    redirect: '/'
  });
}
