import React from 'react';
import styled from 'styled-components';
import { getAccumulatedFullfillment } from './utils';
import BarChart from './BarChart';
import translate from 'utils/translate';

const Container = styled.div`
  display: inline-block;
  width: ${(props) => props.width}px;

  h3 {
    margin: 0;
  }

  h4 {
    margin: 5px 0;
  }
`;

export default function FullfillmentChart({
  title,
  report,
  show,
  area,
  comfortZone,
  height = 200,
  width = 200,
}) {
  if (!show) {
    return null;
  }
  return (
    <Container width={width}>
      {title && <h3>{title}</h3>}
      <h4>{translate(`classifications.${comfortZone}`)}</h4>
      <div style={{ height: `${height}px` }}>
        <BarChart
          labels={['CURRENT', 'PREVIOUS']}
          data={[
            getAccumulatedFullfillment([report[0]], area, comfortZone),
            getAccumulatedFullfillment(
              report[1] ? [report[1]] : [],
              area,
              comfortZone
            ),
          ]}
        />
      </div>
    </Container>
  );
}
