import { useState, useEffect } from 'react';

export default function useStickyPosition(
  element,
  { spacingTop = 0, offsetTop = 0 } = {}
) {
  const [originalY, setOriginalY] = useState(null);
  const [stickyY, setStickyY] = useState(null);
  const [isStuck, setIsStuck] = useState(null);

  function updateY() {
    const updated = (originalY || 0) - window.scrollY;
    const newValue = offsetTop + Math.max(updated, spacingTop);

    setStickyY(newValue);
    setIsStuck(newValue === offsetTop + spacingTop);
  }

  useEffect(() => {
    window.addEventListener('scroll', updateY);

    return () => {
      window.removeEventListener('scroll', updateY);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalY, stickyY]);

  useEffect(() => {
    setOriginalY(null);
    setStickyY(null);
  }, [element]);

  if (!element) {
    return { y: null, isStuck };
  }

  const clientRect = element.getBoundingClientRect();
  if (originalY === null) {
    setOriginalY(clientRect.y);
  }

  if (stickyY === null) {
    setStickyY(offsetTop + clientRect.y);
  }

  return { y: stickyY, isStuck };
}
