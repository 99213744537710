import { useState, useEffect } from 'react';
import { rawRequest } from 'actions/utils';

export function useUserHotels() {
  const [hotels, setHotels] = useState(null);

  useEffect(() => {
    try {
      rawRequest({
        path: `/api/hotels`,
      }).then((response) => {
        setHotels(response);
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  return hotels;
}
