import styled from 'styled-components';
import media from 'globals/media';

export const Wrapper = styled.div`
  -webkit-print-color-adjust: exact;
  color-adjust: exact;

  h1,
  h2,
  h3,
  h4 {
    font-weight: bold;
  }

  h2 {
    text-align: left;
    margin: 0;
    font-size: 27px;
  }
  h1 {
    text-align: left;
  }
  h3 {
    margin-bottom: 0;
  }
  h3.external-scores {
    margin-top: 3rem;
  }

  .flex-centering {
    display: flex;
    justify-content: center;
  }
  .page-break-before {
    @media print {
      page-break-before: always;
    }
  }
  .page-break-after {
    @media print {
      page-break-after: always;
    }
  }
  .page-break-inside {
    page-break-inside: avoid;
  }

  @media print {
    padding: 20px;
  }
`;

export const ReportHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    height: auto;
    margin: 0.8rem 0.8rem 0 0;
    ${media.handheld`
      width: 100px;
      margin: 0;
    `}
  }
`;
