import { fromJS } from 'immutable';
import createModal from './createModal';
import deleteModal from './deleteModal';
import editModal from './editModal';
import copyModal from './copyModal';

const defaultState = fromJS({
  loading: false,
  types: [],
  error: '',
  createModal: createModal(),
  editModal: editModal(),
  deleteModal: deleteModal(),
  copyModal: copyModal(),
});

export default function (state = defaultState, action = { type: '' }) {
  state = state.set(
    'createModal',
    createModal(state.get('createModal'), action)
  );
  state = state.set('editModal', editModal(state.get('editModal'), action));
  state = state.set(
    'deleteModal',
    deleteModal(state.get('deleteModal'), action)
  );
  state = state.set('copyModal', copyModal(state.get('copyModal'), action));

  switch (action.type) {
    case 'LOAD_INSPECTION_MODULE_TYPES_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'LOAD_INSPECTION_MODULE_TYPES_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'LOAD_INSPECTION_MODULE_TYPES_DONE': {
      return state.set('loading', false).set('types', fromJS(action.payload));
    }
    default: {
      return state;
    }
  }
}
