import React, { useEffect, useReducer } from 'react';
import translate from 'utils/translate';
import LoadingOverlay from 'components/general/LoadingOverlay';
import Error from 'components/general/Error';
import GridRow from 'components/admin/general/GridRow';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import reducer, { INITIAL_STATE } from './reducer';
import {
  createDashboard,
  getDashboard,
  saveDashboard,
  regenerateUuid,
} from './actions';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CopyIcon from '@material-ui/icons/FileCopy';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ColorPicker from 'components/general/ColorPicker';
import Button from '@material-ui/core/Button';
import urls from 'globals/urls';
import Tooltip from '@material-ui/core/Tooltip';
import { copyValue } from 'utils/clipboard';
import confirm from 'utils/confirm';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Help from '@material-ui/icons/Help';
import { dashboardLanguages } from 'globals/languages';
import { withinBounds } from './utils';
import { useTheme } from '@material-ui/core/styles';
import { Prism } from 'components/general/Prism';

const Inner = styled.div`
  padding: 5px 10px;

  h3 {
    text-align: left;
  }
`;

const Wrapper = styled.div`
  position: relative;
  min-height: 200px;
`;

export default function Edit({ hotelId, id, onCreated }) {
  const [{ dashboard, loading, error, newId }, dispatch] = useReducer(
    reducer,
    INITIAL_STATE
  );
  const theme = useTheme();
  useEffect(() => {
    if (!id) {
      createDashboard(hotelId, dispatch);
    } else {
      getDashboard(id, dispatch);
    }
  }, [hotelId, id]);

  useEffect(() => {
    if (newId) {
      onCreated(newId);
    }
  }, [newId, onCreated]);

  const dashboardLink = `${window.location.origin}${urls.dashboardsView.replace(
    ':uuid',
    dashboard.uuid
  )}`;

  if (!dashboard.uuid) {
    return (
      <Wrapper>
        <LoadingOverlay show={loading} minDisplayTime={500} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <LoadingOverlay show={loading} minDisplayTime={500} />
      <Typography variant='h5' component='h1' gutterBottom>
        {translate('dashboards.header')}
      </Typography>
      <GridRow>{error && <Error>{translate(error)}</Error>}</GridRow>
      {!error && (
        <Paper elevation={2}>
          <Inner>
            <Grid
              container
              direction='column'
              spacing={2}
              style={{ marginTop: '10px' }}
            >
              <Grid container item>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems='flex-end'>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        value={dashboard.name || ''}
                        label={translate('dashboards.name')}
                        onChange={(event) =>
                          dispatch({
                            type: 'DASHBOARD_CHANGE',
                            payload: {
                              field: 'name',
                              value: event.target.value,
                            },
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        disabled
                        label={translate('dashboards.link')}
                        fullWidth
                        value={dashboardLink || ''}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Tooltip title={translate('copyToClipboard')}>
                        <IconButton
                          size='small'
                          onClick={() => copyValue(dashboardLink)}
                        >
                          <CopyIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={translate('dashboards.regenerate')}>
                        <IconButton
                          size='small'
                          onClick={async () => {
                            const shouldRegenerate = await confirm(
                              translate('dashboards.regenerateConfirm'),
                              {
                                title: translate('dashboards.regenerate'),
                                confirmText: translate('dashboards.generate'),
                              }
                            );
                            if (shouldRegenerate) {
                              regenerateUuid(id, dispatch);
                            }
                          }}
                        >
                          <RefreshIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={1} item>
                <Grid item container xs={6} direction='column' spacing={1}>
                  <Grid item>
                    <Typography variant='h6' component='h4'>
                      {translate('dashboards.settings')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      value={dashboard.friendlyName || ''}
                      label={translate('dashboards.friendlyName')}
                      onChange={(event) =>
                        dispatch({
                          type: 'DASHBOARD_CHANGE',
                          payload: {
                            field: 'friendlyName',
                            value: event.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      value={dashboard.headerLink || ''}
                      label={translate('dashboards.headerLink')}
                      onChange={(event) =>
                        dispatch({
                          type: 'DASHBOARD_CHANGE',
                          payload: {
                            field: 'headerLink',
                            value: event.target.value,
                          },
                        })
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'>
                            <Tooltip
                              title={translate('dashboards.headerLinkTooltip')}
                            >
                              <Help
                                style={{
                                  cursor: 'help',
                                  color: theme.palette.grey[600],
                                }}
                              />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      label={translate('dashboards.showOrganizationData')}
                      control={
                        <Checkbox
                          checked={!!dashboard.showOrganizationData}
                          disabled
                          onChange={() =>
                            dispatch({
                              type: 'DASHBOARD_CHANGE',
                              payload: {
                                field: 'showOrganizationData',
                                value: !dashboard.showOrganizationData,
                              },
                            })
                          }
                        />
                      }
                    />
                    <FormControlLabel
                      label={translate('dashboards.showOrganizationLogo')}
                      control={
                        <Checkbox
                          checked={!!dashboard.showOrganizationLogo}
                          onChange={() =>
                            dispatch({
                              type: 'DASHBOARD_CHANGE',
                              payload: {
                                field: 'showOrganizationLogo',
                                value: !dashboard.showOrganizationLogo,
                              },
                            })
                          }
                        />
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={6} direction='column' spacing={1}>
                  <Grid item>
                    <Typography variant='h6' component='h4'>
                      {translate('dashboards.styling')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ColorPicker
                      label={translate('dashboards.backgroundColor')}
                      value={dashboard.backgroundColor}
                      onChange={(value) =>
                        dispatch({
                          type: 'DASHBOARD_CHANGE',
                          payload: {
                            field: 'backgroundColor',
                            value,
                          },
                        })
                      }
                    />
                  </Grid>
                  <Grid item>
                    <ColorPicker
                      label={translate('dashboards.textColor')}
                      value={dashboard.textColor}
                      onChange={(value) =>
                        dispatch({
                          type: 'DASHBOARD_CHANGE',
                          payload: {
                            field: 'textColor',
                            value,
                          },
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={1} item>
                <Grid item container xs={6} direction={'column'} spacing={1}>
                  <Grid item>
                    <Typography variant='h6' component='h4'>
                      {translate('dashboards.languageSettings')}
                    </Typography>
                  </Grid>
                  <Grid item style={{ maxWidth: '100%' }}>
                    <FormControl fullWidth>
                      <InputLabel id='language-selection-label'>
                        {dashboard.rotateLanguages
                          ? translate('dashboards.languages')
                          : translate('dashboards.language')}
                      </InputLabel>
                      <Select
                        fullWidth
                        multiple={dashboard.rotateLanguages}
                        labelId='language-selection-label'
                        value={dashboard.language.split(',')}
                        onChange={(event) => {
                          return dispatch({
                            type: 'DASHBOARD_CHANGE',
                            payload: {
                              field: 'language',
                              value: (dashboard.rotateLanguages
                                ? event.target.value.filter(
                                    (language) => !!language
                                  )
                                : [event.target.value]
                              ).join(','),
                            },
                          });
                        }}
                        input={<Input fullWidth />}
                        renderValue={(selected) => {
                          if (
                            !selected ||
                            selected.length === 0 ||
                            selected[0] === ''
                          ) {
                            return '';
                          }
                          if (dashboard.rotateLanguages) {
                            return selected
                              .filter(
                                (language) => !!language && language !== ''
                              )
                              .map((l) => translate(`languages.${l}`))
                              .join(', ');
                          }

                          return translate(`languages.${selected}`);
                        }}
                      >
                        {dashboardLanguages.map((language) => (
                          <MenuItem key={language.code} value={language.code}>
                            {dashboard.rotateLanguages ? (
                              <Checkbox
                                checked={dashboard.language.includes(
                                  language.code
                                )}
                              />
                            ) : null}
                            <ListItemText
                              primary={translate(`languages.${language.code}`)}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      label={translate('dashboards.rotateLanguages')}
                      control={
                        <Checkbox
                          checked={!!dashboard.rotateLanguages}
                          onChange={() => {
                            dispatch({
                              type: 'DASHBOARD_CHANGE',
                              payload: {
                                field: 'rotateLanguages',
                                value: !dashboard.rotateLanguages,
                              },
                            });

                            if (dashboard.rotateLanguages) {
                              dispatch({
                                type: 'DASHBOARD_CHANGE',
                                payload: {
                                  field: 'language',
                                  value: dashboardLanguages[0].code,
                                },
                              });
                            }
                          }}
                        />
                      }
                    />
                  </Grid>
                  {dashboard.rotateLanguages && (
                    <Grid item>
                      <TextField
                        fullWidth
                        type='number'
                        value={dashboard.languageRotationInterval || ''}
                        label={translate('dashboards.languageRotationInterval')}
                        InputProps={{
                          inputProps: {
                            min: 3,
                            max: 300,
                            step: 1,
                          },
                        }}
                        onBlur={(event) =>
                          dispatch({
                            type: 'DASHBOARD_CHANGE',
                            payload: {
                              field: 'languageRotationInterval',
                              value: withinBounds({
                                value: event.target.value,
                                min: 3,
                                max: 300,
                              }),
                            },
                          })
                        }
                        onChange={(event) =>
                          dispatch({
                            type: 'DASHBOARD_CHANGE',
                            payload: {
                              field: 'languageRotationInterval',
                              value: event.target.value,
                            },
                          })
                        }
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  xs={6}
                  direction={'column'}
                  spacing={1}
                ></Grid>
              </Grid>
              <Grid item>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => saveDashboard(id, dashboard, dispatch)}
                >
                  {translate('save')}
                </Button>
              </Grid>
              <Grid item container xs={12} direction={'column'} spacing={1}>
                <Grid item>
                  <Typography variant='h6' component='h4'>
                    {translate('dashboards.embedCode')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Prism>
                    {`<script id="${dashboard.uuid}">
  const frame = document.createElement('iframe');
  const scriptTag = document.getElementById('${dashboard.uuid}');
  frame.src = "${dashboardLink}";
  frame.style.width = "100%";
  frame.style.border = "0";
  frame.style.minHeight = "744px";
  frame.style.overflow = 'hidden';
  window.addEventListener('message', event => {
    if (event.origin !== '${window.location.origin}') {
      return;
    }
    const size = JSON.parse(event.data);
    const height = parseFloat(size.height);
    if (isNaN(height)) {
      return;
    }

    frame.style.height = height + 'px';
  });
  scriptTag.parentElement.insertBefore(frame, scriptTag);
</script>`}
                  </Prism>
                </Grid>
              </Grid>
            </Grid>
          </Inner>
        </Paper>
      )}
    </Wrapper>
  );
}
