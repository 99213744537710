import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import UserForm from 'components/general/UserForm';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import LinkToHotelDialog from 'components/user/Manage/LinkToHotelDialog';
import { useOrganizationHotels } from 'hooks/useOrganizationHotels';
import { breakpoints } from 'globals/media';
import translate from 'utils/translate';

const RowButton = styled(IconButton)`
  padding: 4px !important;
  margin: 4px !important;
`;

const HotelHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const INITIAL_USER = {
  name: '',
  email: '',
  agentCode: '',
  password: '',
  passwordRepeat: '',
  role: '',
  profile: '',
  id: -1,
  hotelIds: [],
};

export default function UserFormModal({
  visible,
  loading,
  error,
  role,
  submit,
  closeModal,
  organizationId,
  useMyOrganization,
  creating,
  user,
}) {
  const [userState, setUserState] = useState(INITIAL_USER);
  const [showLinkHotelDialog, setShowLinkHotelDialog] = useState(false);
  const hotelIds = userState.hotelIds || [];
  useEffect(() => {
    if (!visible) {
      setShowLinkHotelDialog(false);
      setUserState(INITIAL_USER);
      return;
    }

    if (user) setUserState(user.toJS());
  }, [visible, user]);

  if (showLinkHotelDialog)
    return (
      <LinkToHotelDialog
        open
        organizationId={organizationId}
        onClose={() => setShowLinkHotelDialog(false)}
        onSubmit={(hotelId) => {
          setShowLinkHotelDialog(false);
          if (hotelIds.includes(hotelId)) return;
          setUserState({ ...userState, hotelIds: hotelIds.concat(hotelId) });
        }}
      />
    );

  return (
    <Dialog
      open={visible}
      onClose={closeModal}
      maxWidth='md'
      fullWidth
      fullScreen={window.innerWidth < breakpoints.handheld}
    >
      <DialogTitle>
        {translate(creating ? 'users.create' : 'users.edit.header')}
      </DialogTitle>
      <DialogContent>
        {loading && <Loader />}
        {error && <Error>{translate(error)}</Error>}
        <UserForm
          minRole={role === 'superUser' ? 'deactivated' : null}
          maxRole={organizationId > 0 || role !== 'superUser' ? role : null}
          user={userState}
          includePasswordRepeat={creating}
          onChange={(user) => setUserState(user)}
        />
        {!useMyOrganization &&
        (organizationId < 0 || organizationId == null) ? null : (
          <HotelsInfo
            organizationId={organizationId}
            useMyOrganization={useMyOrganization}
            userState={userState}
            setUserState={setUserState}
            setShowLinkHotelDialog={setShowLinkHotelDialog}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color='primary'>
          {translate('back')}
        </Button>
        <Button
          color='primary'
          onClick={() => submit({ ...userState, organizationId })}
        >
          {translate(creating ? 'create' : 'users.edit.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function HotelsInfo({
  organizationId,
  useMyOrganization,
  userState,
  setUserState,
  setShowLinkHotelDialog,
}) {
  const organizationHotels =
    useOrganizationHotels(organizationId, useMyOrganization) || [];
  const hotelIds = userState.hotelIds || [];
  const userHotels = organizationHotels.filter((hotel) =>
    hotelIds.includes(hotel.id)
  );
  return (
    <div style={{ marginTop: '1rem' }}>
      <HotelHeader>
        <Typography variant='h6' component='h2'>
          {translate('hotels')}
        </Typography>
        <Button
          variant='outlined'
          color='primary'
          startIcon={<LinkIcon />}
          onClick={() => setShowLinkHotelDialog(true)}
        >
          {translate('users.edit.linkToHotel')}
        </Button>
      </HotelHeader>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>{translate('hotels.create.name')}</TableCell>
            <TableCell>{translate('hotels.create.address')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {userHotels.map((hotel) => (
            <TableRow key={hotel.id} hover>
              <TableCell>{hotel.name}</TableCell>
              <TableCell>{hotel.address}</TableCell>
              <TableCell style={{ width: '41px' }}>
                <Tooltip title={translate('users.edit.unlinkFromHotel')}>
                  <RowButton
                    size='medium'
                    onClick={(event) => {
                      event.stopPropagation();
                      setUserState({
                        ...userState,
                        hotelIds: hotelIds.filter((id) => id !== hotel.id),
                      });
                    }}
                  >
                    <LinkOffIcon />
                  </RowButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!userHotels.length && (
        <Typography
          variant='body2'
          style={{ fontStyle: 'italic', marginTop: '5px' }}
        >
          {translate('users.edit.noHotels')}
        </Typography>
      )}
    </div>
  );
}
