import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { KeyboardTimePicker } from '@material-ui/pickers';
import translate from 'utils/translate';
import WeekDayPicker from './WeekDayPicker';
import { Button, ButtonGroup } from '@material-ui/core';

const AutomationForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: -5px 0;

  > * {
    margin: 5px 0 !important;
  }
`;

const timeRegex = /^\d\d:\d\d$/;

export default function InspectionTypeAutomationForm({
  onChange,
  role,
  autoStartTime,
  autoCloseTime,
  autoCloseWarningTime,
  autoCloseDelayDays,
  autoStartDelayDays,
  autoWeekdays
}) {
  const [automationType, setAutomationType] = React.useState(autoStartDelayDays ? 0 : 1);

  if (timeRegex.test(autoStartTime))
    autoStartTime = moment().format(`YYYY-MM-DD ${autoStartTime}:ss`);

  if (timeRegex.test(autoCloseTime))
    autoCloseTime = moment().format(`YYYY-MM-DD ${autoCloseTime}:ss`);

  if (timeRegex.test(autoCloseWarningTime))
    autoCloseWarningTime = moment().format(`YYYY-MM-DD ${autoCloseWarningTime}:ss`);

  const disabled = !['superUser', 'companyAdmin'].includes(role);

  return (
    <AutomationForm>

      <Typography variant={'subtitle2'}>{translate('inspectionTypes.create.autoDistribution')}</Typography>

      <ButtonGroup variant="outlined" color="primary" fullWidth={true}>
        <Button
          variant={ automationType === 1 ? 'contained' : null }
          onClick={ () => {
            if (!disabled) {
              setAutomationType(1);
              onChange('autoStartDelayDays', null);
            }
          }}
        >
          Veckodagar
        </Button>
        <Button
          variant={ automationType === 0 ? 'contained' : null }
          onClick={ () => {
            if (!disabled) {
              setAutomationType(0);
              onChange('autoWeekdays', []);
            }
          }}
        >
          Intervall
        </Button>
      </ButtonGroup>

      {automationType === 0 && (
        <TextField
          type='number'
          variant='outlined'
          size='small'
          disabled={disabled}
          label={translate('inspectionTypes.create.autoStartDelayDays')}
          value={autoStartDelayDays || ''}
          onChange={(event) => onChange('autoStartDelayDays', event.target.value)}
        />
      )}

      {automationType === 1 && (
        <WeekDayPicker
          disabled={disabled}
          label={translate('inspectionTypes.create.autoWeekDays')}
          value={autoWeekdays}
          onChange={weekDays => onChange('autoWeekdays', weekDays)}
        />
      )}

      <KeyboardTimePicker
        format='HH:mm'
        inputVariant='outlined'
        size='small'
        clearable={!!autoStartTime}
        disabled={disabled}
        label={translate('inspectionTypes.create.autoStartTime')}
        value={autoStartTime || null}
        onChange={(date) =>
          onChange('autoStartTime', date ? moment(date).format('HH:mm') : null)
        }
      />

      <Typography variant={'subtitle2'}>{translate('inspectionTypes.create.autoShutdown')}</Typography>

      <KeyboardTimePicker
        format='HH:mm'
        inputVariant='outlined'
        size='small'
        clearable={!!autoCloseTime}
        disabled={disabled}
        label={translate('inspectionTypes.create.autoCloseTime')}
        value={autoCloseTime || null}
        onChange={(date) =>
          onChange('autoCloseTime', date ? moment(date).format('HH:mm') : null)
        }
      />
      <KeyboardTimePicker
        inputVariant='outlined'
        size='small'
        clearable={!!autoCloseWarningTime}
        views={['hours']}
        ampm={false}
        disabled={disabled}
        label={translate('inspectionTypes.create.autoCloseWarningTime')}
        value={autoCloseWarningTime || null}
        format='HH:00'
        onChange={(date) =>
          onChange(
            'autoCloseWarningTime',
            date ? moment(date).format('HH:00') : null
          )
        }
      />
      <TextField
        type='number'
        variant='outlined'
        size='small'
        disabled={disabled}
        label={translate('inspectionTypes.create.autoCloseDelayDays')}
        value={autoCloseDelayDays || ''}
        onChange={(event) => onChange('autoCloseDelayDays', event.target.value)}
      />
    </AutomationForm>
  );
}
