import React from 'react';
import translate from 'utils/translate';
import { List } from 'immutable';
import {
  Img as SmallImg,
  SpecialRequestFileWrapper,
  QuestionWrapper,
  QuestionHeader,
  QuestionAnswer,
  ExtraQuestionsWrapper as Wrapper,
  SpecialRequestFile,
  SpecialRequestFileRow,
} from './styled';
const Img = SmallImg.extend`
  display: block;
`;

export default function SpecialRequestAnswers({ data = List() }) {
  if (data.size === 0) {
    return null;
  }

  const questionIdOrdinal = {};

  const sortedQuestions = data.sort((a, b) => {
    return questionIdOrdinal[a.get('id')] - questionIdOrdinal[b.get('id')];
  });

  const domQuestions = sortedQuestions.map((question) => (
    <QuestionWrapper key={question.get('id')}>
      <QuestionHeader score={question.get('score')}>
        {question.get('content')}
      </QuestionHeader>
      <QuestionAnswer>
        {question.get('comment') ? `${question.get('comment')}` : ''}
      </QuestionAnswer>
    </QuestionWrapper>
  ));

  const pictureRows = sortedQuestions.map((question) => (
    <div key={question.get('id')}>
      {question.get('file') && (
        <SpecialRequestFileRow>
          <div>
            {(question.get('comment') || '').split('\n').map((c, i) => (
              <div key={i}>{c}</div>
            ))}
          </div>
          <SpecialRequestFile>
            <Img src={`/files/${question.get('file')}`} alt='answer' />
          </SpecialRequestFile>
        </SpecialRequestFileRow>
      )}
    </div>
  ));

  return (
    <Wrapper>
      <h3>{translate('extraQuestions')}</h3>
      {domQuestions}
      {data.filter((question) => question.get('file')).size > 0 && (
        <SpecialRequestFileWrapper>
          <h4>PICTURES</h4>
          {pictureRows}
        </SpecialRequestFileWrapper>
      )}
    </Wrapper>
  );
}
