import React, { Component } from 'react';
import User from './styled/User';
import DeleteButton from 'components/general/DeleteButton';
import Wrapper from './styled/Wrapper';
import Button from 'components/general/Button';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import CreateModal from './CreateModal';
import DeleteModal from './DeleteModal';
import EditModal from './EditModal';
import GridRow from 'components/admin/general/GridRow';

export default class UserAdmin extends Component {
  componentDidMount() {
    this.props.loadUsers();
  }
  render() {
    const {
      users,
      error,
      loading,
      openCreateModal,
      openDeleteModal,
      openEditModal,
    } = this.props;
    const domUsers = users.map((user, i) => (
      <React.Fragment key={user.get('id')}>
        <User>{user.get('name')}</User>
        <Button
          color='white'
          textColor='textGray'
          small
          onClick={() => openEditModal(user.toJS())}
        >
          {translate('users.edit')}
        </Button>
        <DeleteButton small onClick={() => openDeleteModal(user.toJS())}>
          {translate('users.delete')}
        </DeleteButton>
      </React.Fragment>
    ));
    return (
      <Wrapper>
        <GridRow>
          <h1>{translate('users.administration')}</h1>
        </GridRow>
        <GridRow>
          <Button type='button' onClick={openCreateModal}>
            {translate('users.create')}
          </Button>
        </GridRow>
        <GridRow>
          {loading && <Loader />}
          {error && <Error>{translate(error)}</Error>}
        </GridRow>
        {domUsers}
        <CreateModal />
        <EditModal />
        <DeleteModal />
      </Wrapper>
    );
  }
}
