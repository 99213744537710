import React from 'react';
import Card from '../styled/Card';
import translate from 'utils/translate';
import moment from 'moment';
import styled from 'styled-components';
import RateReviewIcon from '@material-ui/icons/RateReview';

const FollowUpWrapper = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  align-items: center;
`;

const CardWrapper = Card.extend`
  &:hover {
    transform: scale(1.01);
  }
`;

const Span = styled.span`
  opacity: 0.5;
  margin-top: 2px;
`;

export default function InspectionCard({ inspection, hotel }) {
  return (
    <CardWrapper>
      <h4>{inspection.getIn(['inspectionType', 'hotel', 'name'])}</h4>
      {inspection.get('followUpToInspection') ? (
        <FollowUpWrapper>
          <span>
            {translate('followUp')} {translate('for')}{' '}
            {moment(
              inspection.getIn(['followUpToInspection', 'startTime'])
            ).format('YYYY-MM-DD')}
          </span>
          <RateReviewIcon />
        </FollowUpWrapper>
      ) : (
        ''
      )}
      <h5>{inspection.getIn(['inspectionType', 'name'])}</h5>
      <Span>
        {translate('inspections.choose.inspectionCreated')}{' '}
        {moment(inspection.get('startTime')).format('YYYY-MM-DD')}
      </Span>
    </CardWrapper>
  );
}
