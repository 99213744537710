import React, { useEffect, useReducer, useCallback } from 'react';
import { getDashboardData, changeDashboardLanguage } from './actions';
import styled from 'styled-components';
import reducer, { INITIAL_STATE } from './reducer';
import ByArea from './ByArea';
import Grid from '@material-ui/core/Grid';
import Bar from './Bar';
import Typography from '@material-ui/core/Typography';
import PerformanceChart from './PerformanceChart';
import moment from 'moment';
import { useScreenSize } from 'hooks/useScreenSize';
import { getStats, borderStyles, weeklyAverages } from './utils';
import Total from './Total';
import CircularProgress from '@material-ui/core/CircularProgress';
import Logo from 'components/general/Logo';
import {
  lighten,
  getLuminance,
  darken,
  useTheme,
} from '@material-ui/core/styles';
import useAutoRefresh from './hooks/useAutoRefresh';
import translate from 'utils/translate';
import { getShortCode, getIcon } from 'utils/language';
import media, { breakpoints } from 'globals/media';
import OrganizationLogo from './OrganizationLogo';

const Header = styled.div`
  position: relative;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.desktop`
    h1 {
      font-size: 28px;
    }
  `};

  ${media.smallDesktop`
    h1 {
      font-size: 24px;
    }
    h2 {
      font-size: 19px;
    }
  `};

  ${media.tablet`
    h1 {
      font-size: 21px;
    }
    h2 {
      font-size: 18px;
    }
  `};

  ${media.smallTablet`
    h1 {
      font-size: 18px;
    }
    h2 {
      font-size: 16px;
    }
  `};
`;

const Wrapper = styled.div`
  padding: 10px 20px;
  box-sizing: border-box;
  ${media.smallTablet`
    padding: 5px;
  `};
  * {
    color: ${(props) => props.color};
  }
  background: ${(props) => props.background};
`;

const FlagWrapper = styled.div`
  display: inline-block;
  margin-left: 7px;
`;

export default function View(props) {
  const screenSize = useScreenSize();
  const uuid = props.match.params.uuid;
  const [{ data, lastUpdate, languageIndex }, dispatch] = useReducer(
    reducer,
    INITIAL_STATE
  );
  const inspections = data.inspections;
  const hotelName = data.hotelName;
  const inspectionModuleTypes = data.inspectionModuleTypes;
  const getData = useCallback(() => getDashboardData(uuid, dispatch), [uuid]);
  const theme = useTheme();
  const changeLanguage = useCallback(
    () => changeDashboardLanguage(languageIndex, data.dashboard, dispatch),
    [languageIndex, data.dashboard]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  useAutoRefresh(getData, 60 * 1000);
  useAutoRefresh(
    changeLanguage,
    data.dashboard && data.dashboard.languageRotationInterval * 1000
  );

  if (!data.dashboard) {
    return (
      <Grid
        container
        justify='center'
        alignItems='center'
        style={{ height: '200px' }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  const language = data.dashboard.language[languageIndex];
  const localTranslate = (key) => translate(key, { forceLanguage: language });
  const backgroundColor = data.dashboard.backgroundColor;

  const borderColor =
    getLuminance(backgroundColor) < 0.5
      ? lighten(backgroundColor, 0.7)
      : darken(backgroundColor, 0.7);

  const sevenDaysAgo = moment().subtract(7, 'days').startOf('day');
  const thirtyDaysAgo = moment().subtract(30, 'days').startOf('day');
  const weekIndex = inspections.findIndex((i) =>
    moment(i.date).isBefore(sevenDaysAgo)
  );
  const monthIndex = inspections.findIndex((i) =>
    moment(i.date).isBefore(thirtyDaysAgo)
  );
  const thisWeek =
    weekIndex === -1 ? inspections : inspections.slice(0, weekIndex);

  const thisMonth =
    monthIndex === -1 ? inspections : inspections.slice(0, monthIndex);

  let zeroed = {};
  let totalPoints = 0;

  if (inspections.length > 0 && inspectionModuleTypes.length > 0) {
    inspections.forEach(
      (inspection) => (totalPoints += inspection.totalAnsweredQuestions)
    );
    inspectionModuleTypes.forEach((m) => {
      zeroed[m.area] = {
        fullfillment: [0],
        total: m.questionClusters.map((cluster) => cluster.questions).flat()
          .length,
      };
    });
  }

  const weekStats = getStats(thisWeek, zeroed);
  const monthStats = getStats(thisMonth, zeroed);

  const gridBreakpoints = {
    sm: 12,
    md: 6,
  };

  return (
    <Wrapper color={data.dashboard.textColor} background={backgroundColor}>
      <Header>
        <div>
          <Typography
            component={data.dashboard.headerLink ? 'a' : 'h1'}
            href={data.dashboard.headerLink}
            variant='h4'
            align='left'
            style={{ fontWeight: 500 }}
          >
            {localTranslate('dashboards.view.title')}
            <FlagWrapper>
              <span className={`flag-icon flag-icon-${getIcon(language)}`} />
            </FlagWrapper>
          </Typography>
          <Typography component='h2' variant='h5' align='left'>
            {hotelName}
          </Typography>
          <Typography color='primary' variant='subtitle2'>
            {localTranslate('dashboards.view.lastUpdated')}:{' '}
            {moment(lastUpdate).format('YYYY-MM-DD HH:mm')}
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {data.dashboard.logo && (
            <OrganizationLogo
              data={data.dashboard.logo.data}
              mimeType={data.dashboard.logo.mimeType}
            />
          )}
          <Logo
            width={
              screenSize.width < breakpoints.tablet
                ? data.dashboard.logo
                  ? 70
                  : 120
                : data.dashboard.logo
                ? 100
                : 170
            }
            height={data.dashboard.logo ? 40 : 75}
          />
        </div>
      </Header>
      <Grid
        container
        spacing={4}
        xs={12}
        direction='column'
        alignItems='center'
        style={{ margin: 0 }}
      >
        <Grid container item wrap='wrap' spacing={4}>
          <Grid
            container
            item
            {...gridBreakpoints}
            {...borderStyles(screenSize.width, borderColor, {
              lg: { right: 1, bottom: 1 },
              md: { bottom: 1 },
            })}
          >
            <Grid container item justify='center'>
              <Typography variant='h6' gutterBottom>
                {localTranslate('dashboards.view.lastSevenDays')}
              </Typography>
            </Grid>
            <Grid container item spacing={4} wrap='nowrap' justify='center'>
              <Grid item xs={12}>
                <Bar
                  language={language}
                  percent={weekStats.average}
                  color='#00af00'
                  label={hotelName}
                />
              </Grid>
              {screenSize.width > breakpoints.smallTablet ? (
                <Grid item>
                  <ByArea
                    language={language}
                    stats={weekStats}
                    zeroed={zeroed}
                    color={data.dashboard.textColor}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid
            container
            item
            {...gridBreakpoints}
            {...borderStyles(screenSize.width, borderColor, {
              lg: { left: 1, bottom: 1 },
              md: { bottom: 1, top: 1 },
            })}
            direction='column'
          >
            <Grid container item justify='center'>
              <Typography
                variant='h6'
                gutterBottom
                style={{ marginBottom: '1em' }}
              >
                {localTranslate('dashboards.view.checkpointsTotal')}
              </Typography>
            </Grid>
            <Grid container item wrap='nowrap' justify='center'>
              <Total label={hotelName} color='#a746d6'>
                {totalPoints.toLocaleString(getShortCode(language))}
              </Total>
            </Grid>
            <Grid
              container
              item
              wrap='nowrap'
              justify='center'
              style={{ marginTop: '25px' }}
            >
              <Total
                label={data.organizationName}
                color={theme.palette.primary.main}
              >
                {data.organizationTotal.toLocaleString(getShortCode(language))}
              </Total>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item wrap='wrap' spacing={4}>
          <Grid
            container
            item
            {...gridBreakpoints}
            {...borderStyles(screenSize.width, borderColor, {
              lg: { right: 1, top: 1 },
              md: { bottom: 1, top: 1 },
            })}
          >
            <Grid container item justify='center'>
              <Typography variant='h6' gutterBottom>
                {localTranslate('dashboards.view.lastThirtyDays')}
              </Typography>
            </Grid>
            <Grid container item spacing={4} wrap='nowrap' justify='center'>
              <Grid item xs={12}>
                <Bar
                  language={language}
                  percent={monthStats.average}
                  color={'#43d3ff'}
                  label={hotelName}
                />
              </Grid>
              {screenSize.width > breakpoints.smallTablet ? (
                <Grid item>
                  <ByArea
                    language={language}
                    stats={monthStats}
                    secondary
                    zeroed={zeroed}
                    color={data.dashboard.textColor}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          {screenSize.width > breakpoints.smallTablet ? (
            <Grid
              container
              item
              {...gridBreakpoints}
              {...borderStyles(screenSize.width, borderColor, {
                lg: { left: 1, top: 1 },
                md: { top: 1 },
              })}
            >
              <Grid container item justify='center'>
                <Typography variant='h6' gutterBottom>
                  {localTranslate('dashboards.view.performanceOverTime')}
                </Typography>
              </Grid>
              <Grid container item spacing={4} justify='center'>
                <div style={{ width: '90%', height: '240px' }}>
                  <PerformanceChart
                    language={language}
                    backgroundColor={backgroundColor}
                    data={{
                      [hotelName]: weeklyAverages(inspections),
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export function getPercentage(fraction, total) {
  return Math.ceil((fraction / total) * 100);
}
