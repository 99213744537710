import React from 'react';
import styled from 'styled-components';
import translate from 'utils/translate';

export const DoubleColumn = styled.div`
  grid-column: span 2;
`;

export const Wrapper = styled.div`
  position: relative;
  > button {
    margin-right: 15px;
  }

  h1 {
    margin: 0;
  }
`;

export const HeaderRow = styled.div`
  grid-column: span 5;
`;

export const ReportListItem = styled.div`
  margin-right: 15px;
  align-self: center;

  > span {
    font-weight: bold;
  }
`;

export const ResetButton = styled.button`
  padding: ${(props) => (props.small ? '5px 8px' : '10px 15px')};
  margin-left: ${(props) => props.marginLeft || '0'};
  border-radius: 3px;
  font-size: 14px;
  width: auto;
  border: none;
  outline: none;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  &:hover {
    filter: brightness(1.04);
  }
`;

function UndoSvg() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='8'
      height='8'
      viewBox='0 0 8 8'
    >
      <path
        d='M4.5 0c-1.93 0-3.5 1.57-3.5 3.5v.5h-1l2 2 2-2h-1v-.5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5c0-1.93-1.57-3.5-3.5-3.5z'
        transform='translate(0 1)'
      />
    </svg>
  );
}

export const UndoButton = styled.button.attrs({
  children: <UndoSvg />,
  'data-hint': translate('inspections.undoToControl'),
})`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 20px;
  svg {
    width: 20px;
    height: 100%;
  }
  position: relative;

  @media (hover: hover) {
    :hover {
      :after,
      :before {
        transform: translateY(2px);
        opacity: 1;
      }
    }
  }
  :before,
  :after {
    transition: 0.12s ease;
    opacity: 0;
    top: 100%;
    z-index: 2;
    position: absolute;
    transform: translateY(-8px);
    pointer-events: none;
  }
  :before {
    content: '';
    background: transparent;
    border: 5px solid transparent;
    border-bottom-color: hsla(0, 0%, 7%, 1);
    left: 6px;
    margin-top: -9px;
  }
  :after {
    border-radius: 3px;
    left: 0px;
    content: attr(data-hint);
    background: hsla(0, 0%, 7%, 1);
    color: white;
    padding: 6px 8px;
    font-size: 14px;
    line-height: 14px;
    white-space: nowrap;
  }
`;
