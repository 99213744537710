import { useState, useEffect } from 'react';
import { rawRequest } from 'actions/utils';

export function useUserLinkableInspectionTypes(userId) {
  const [inspectionTypes, setInspectionTypes] = useState(null);

  useEffect(() => {
    if (userId) {
      try {
        rawRequest({
          path: `/api/admin/users/${userId}/inspection-types`,
        }).then((response) => {
          setInspectionTypes(response);
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [userId]);

  return inspectionTypes;
}
