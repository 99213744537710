import load from './load';
import { request } from 'actions/utils';

export default function ({
  id,
  ordinal,
  notifyHotelResponsible,
  isMandatory,
  requiredCommentMinScore,
  moduleTypeId,
}) {
  return async (dispatch) => {
    let success = await dispatch(
      request({
        action: 'UPDATE_QUESTION',
        path: `/api/admin/questions/${id}`,
        body: {
          ordinal,
          notifyHotelResponsible,
          isMandatory,
          requiredCommentMinScore,
        },
        method: 'PUT',
      })
    );
    if (success) {
      dispatch(load(moduleTypeId));
    }
  };
}
