import moment from 'moment';

export function isToday(date) {
  if (!date) {
    return false;
  }
  const now = moment();
  const dateToCheck = moment(date);

  if (now.year() !== dateToCheck.year()) return false;
  if (now.month() !== dateToCheck.month()) return false;
  if (now.date() !== dateToCheck.date()) return false;

  return true;
}
