import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import translate from 'utils/translate';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useUserLinkableInspectionTypes } from 'hooks/useUserLinkableInspectionTypes';

export default function LinkToInspectionTypeDialog({
  open,
  onClose,
  onSubmit,
  userId
}) {
  const inspectionTypes = useUserLinkableInspectionTypes(userId);
  const [selectedInspectionType, setSelectedInspectionType] = useState('');

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{translate('users.edit.linkToInspectionType')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translate('users.edit.linkToInspectionTypeText')}
        </DialogContentText>
        <FormControl variant='outlined' fullWidth>
          <InputLabel id='user-form-role-input-label'>
            {translate('inspectionType')}
          </InputLabel>
          <Select
            fullWidth
            required
            disabled={!inspectionTypes || inspectionTypes.length === 0}
            labelId='user-form-role-input-label'
            value={selectedInspectionType}
            onChange={(event) => setSelectedInspectionType(event.target.value)}
            label={translate('inspectionType')}
          >
            {(inspectionTypes || []).map(it => (
              <MenuItem key={it.id} value={it.id}>
                {`${it.name} (${it.hotel.name})`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          {translate('cancel')}
        </Button>
        <Button
          onClick={() => onSubmit(selectedInspectionType)}
          color='primary'
          disabled={!inspectionTypes || inspectionTypes.length === 0 || !selectedInspectionType}
        >
          {translate('link')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
