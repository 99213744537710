import React, {Component} from 'react';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import Input from 'components/general/Input';
import translate from 'utils/translate';
import ModalWrapper from 'components/general/ModalWrapper';
import InnerModal from 'components/general/InnerModal';
import Button from 'components/general/Button';
import localReducer from './reducer';
import update from './update';
import LocalFlux from 'components/general/LocalFlux';
import ModalButtonRow from 'components/general/ModalButtonRow';
import {EditInspectionRow} from '../styled';

export default class EditModal extends Component {
  update = async (e, state, dispatch) => {
    e.preventDefault();
    const success = await update({data: state}, dispatch);
    if (success) {
      this.props.closeModal();
    }
  };
  render() {
    return (
      <LocalFlux reducer={localReducer} {...this.props}>
        {({state, changeInput, dispatch}) => (
          <ModalWrapper onClose={this.props.closeModal}>
            <InnerModal onSubmit={e => this.update(e, state, dispatch)}>
              <h4>{translate('inspections.edit.header')}</h4>
              <EditInspectionRow>
                <span>{translate('inspections.edit.date')}</span>
                <Input
                  type='date'
                  value={state.startTime}
                  onChange={e =>
                    changeInput({field: 'startTime', value: e.target.value})
                  }
                />
              </EditInspectionRow>
              <EditInspectionRow>
                <span>{translate('inspections.edit.time')}</span>
                <Input
                  type='time'
                  value={state.startTimeHour}
                  onChange={e =>
                    changeInput({field: 'startTimeHour', value: e.target.value})
                  }
                />
              </EditInspectionRow>
              <ModalButtonRow>
                <Button>{translate('submit')}</Button>
              </ModalButtonRow>
              {state.loading && <Loader />}
              {state.error && <Error>{translate(state.error)}</Error>}
            </InnerModal>
          </ModalWrapper>
        )}
      </LocalFlux>
    );
  }
}
