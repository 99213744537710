import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import translate from 'utils/translate';
import { FileUpload } from './styled';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { rawRequest } from 'actions/utils';
import styled from 'styled-components';
import media from 'globals/media';
import organizationActions from 'actions/admin/organization';

const LogoActions = styled.div`
  display: flex;
  margin-top: 15px;

  > button:first-child {
    margin-right: 10px;
  }
`;

const Logo = styled.img`
  visibility: ${({ loadedLogo }) => (loadedLogo ? 'visible' : 'hidden')};
  height: ${({ loadedLogo }) => (loadedLogo ? '100px' : '0')};
  max-width: 400px;
  ${media.handheld`
    max-width: 100%;
  `}
`;

export default connect(
  (state, ownProps) => ({
    organization: state.getIn(['organization', 'current']),
    user: state.get('currentUser'),
  }),
  (dispatch, ownProps) => ({
    load: () => {
      dispatch(
        organizationActions.loadOrganization.settings(
          ownProps.organizationId || null
        )
      );
    },
  })
)(Settings);

function Settings({ organization, user, load }) {
  const fileInput = useRef(null);
  const [logoLoadError, setLogoLoadError] = useState(false);
  const [loadedLogo, setLoadedLogo] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [imageTimestamp, setImageTimestamp] = useState(new Date().getTime());
  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let logoPath =
    user.get('role') === 'superUser'
      ? `/api/admin/organizations/${organization.get('id')}/logo`
      : '/api/admin/organizations/my/logo';
  logoPath = `${logoPath}?timestamp=${imageTimestamp}`;

  return (
    <div>
      <Paper>
        <div style={{ padding: '1rem 2rem' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='h5' gutterBottom>
              {translate('settings')}
            </Typography>
          </div>
          {organization.get('id') ? (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Typography variant='h6' gutterBottom>
                  {translate('organizations.logo')}
                </Typography>
                {uploading ? (
                  <CircularProgress />
                ) : logoLoadError ? (
                  <p>No logo</p>
                ) : (
                  <>
                    <Logo
                      loadedLogo={loadedLogo}
                      src={logoPath}
                      alt='logo'
                      onError={() => setLogoLoadError(true)}
                      onLoad={() => setLoadedLogo(true)}
                    />
                    {!loadedLogo && <CircularProgress />}
                  </>
                )}
                <LogoActions>
                  <Button
                    variant='contained'
                    onClick={() => fileInput.current.click()}
                  >
                    {translate('organizations.uploadLogo')}
                  </Button>
                  <Button
                    variant='contained'
                    onClick={async () => {
                      await rawRequest({
                        action: 'DELETE_ORGANIZATION_LOGO',
                        path: `/api/admin/organizations/${organization.get(
                          'id'
                        )}/logo`,
                        method: 'DELETE',
                      });
                      setLogoLoadError(false);
                      setLoadedLogo(false);
                      setImageTimestamp(new Date().getTime());
                    }}
                  >
                    {translate('organizations.deleteLogo')}
                  </Button>
                  <FileUpload
                    innerRef={fileInput}
                    id={`uploadorganizationlogo-${organization.get('id')}`}
                    type='file'
                    name='pic'
                    accept='image/*'
                    onChange={async (event) => {
                      if (
                        !event.target.files ||
                        event.target.files.length === 0
                      ) {
                        return;
                      }
                      const image = event.target.files[0];
                      const body = new FormData();
                      body.append('image', image);
                      event.target.value = '';
                      setUploading(true);
                      await rawRequest({
                        action: 'UPLOAD_ORGANIZATION_LOGO',
                        path: `/api/admin/organizations/${organization.get(
                          'id'
                        )}/logo`,
                        method: 'POST',
                        body,
                      });
                      setUploading(false);

                      setLoadedLogo(false);
                      setLogoLoadError(false);
                      setImageTimestamp(new Date().getTime());
                    }}
                  />
                </LogoActions>
              </div>
            </>
          ) : (
            <CircularProgress />
          )}
        </div>
      </Paper>
    </div>
  );
}
