import React, { Component } from 'react';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import Logo from 'components/general/Logo';
import GeneralComments from './GeneralComments';
import ComfortZoneComments from './ComfortZoneComments';
import Department from './Department';
import OverviewChart from './OverviewChart';
import SpecialRequestAnswers from './SpecialRequestAnswers';
import translate from 'utils/translate';
import { classifications } from 'globals/classifications';
import {
  getTitle,
  getDepartmentTitle,
  getModuleTypeClassification,
  findPreviousAverage,
} from './parseChartData';
import moment from 'moment';
import Immutable from 'immutable';
import {
  Wrapper,
  Hotel,
  HotelTitle,
  HotelAddress,
  TotalScore,
  MetaData,
  Header,
  ReportHeader,
  ContextAnswers,
  HeaderInfo,
  ExternalChartWrapper,
  ChartWrapper,
  PerformedBy,
  Footer,
  Canvas,
  InspectionDescriptionRow,
  InspectionDescriptionWrapper,
} from './styled';

import {
  renderTotalScore,
  renderLineChart,
  renderExternalScoresChart,
} from './getChart';

export default class Report extends Component {
  constructor() {
    super();
    this.state = {
      lineCanvases: [],
    };
  }

  componentDidMount() {
    this.props.load();
  }

  renderLineCharts = (departmentNameToId) => {
    const report = this.props.report;
    const inspectionModules = report.getIn(
      ['inspection', 'inspectionType', 'inspectionModules'],
      []
    );
    const comfortZones = report.getIn(['comfortZones'], Immutable.List());
    const charts = report.get('charts');
    const externalScores = report.get('externalScores');
    const externalScoresChartData = externalScores
      .entrySeq()
      .filter(([date, externalScores]) => !!externalScores)
      .map(([date, externalScores]) => ({
        x: date,
        y: externalScores.toJS(),
      }))
      .toJS();
    const chartsTimeFrame = report
      .get('chartsTimeFrame', Immutable.List())
      .toJS();
    renderExternalScoresChart({
      canvas: this.externalScoresCanvas,
      data: externalScoresChartData,
      labelList: chartsTimeFrame,
    });
    const totalComfortZoneData = {};
    const totalDepartmentData = {};
    const comfortZoneData = {};
    const departmentData = {};
    charts.entrySeq().forEach(([date, chartDatas]) => {
      chartDatas
        .filter(
          (chartData) =>
            comfortZones.find((z) => (z = chartData.get('comfortZone'))) &&
            !chartData.get('inspectionModuleId')
        )
        .forEach((chartData) => {
          const name = getTitle(chartData);
          totalComfortZoneData[name] = totalComfortZoneData[name] || [];
          totalComfortZoneData[name].push({
            x: date,
            y: chartData.get('percent'),
          });
        });
      chartDatas
        .filter(
          (chartData) =>
            chartData.get('inspectionModuleId') && !chartData.get('comfortZone')
        )
        .forEach((chartData) => {
          const name = getDepartmentTitle(chartData, inspectionModules);
          const moduleTypeClassification = getModuleTypeClassification(
            chartData,
            inspectionModules
          );
          totalDepartmentData[moduleTypeClassification] =
            totalDepartmentData[moduleTypeClassification] || [];
          totalDepartmentData[moduleTypeClassification][name] =
            totalDepartmentData[moduleTypeClassification][name] || [];
          totalDepartmentData[moduleTypeClassification][name].push({
            x: date,
            y: chartData.get('percent'),
          });
        });

      chartDatas
        .filter(
          (chartData) =>
            chartData.get('inspectionModuleId') && chartData.get('comfortZone')
        )
        .forEach((chartData) => {
          const name = getDepartmentTitle(chartData, inspectionModules);
          const moduleTypeClassification = getModuleTypeClassification(
            chartData,
            inspectionModules
          );
          const classification = chartData.get('comfortZone');
          const translatedClassification = translate(
            `classifications.${chartData.get('comfortZone')}`
          );
          const dataPoint = {
            x: date,
            y: chartData.get('percent'),
          };
          comfortZoneData[moduleTypeClassification] =
            comfortZoneData[moduleTypeClassification] || [];
          comfortZoneData[moduleTypeClassification][classification] =
            comfortZoneData[moduleTypeClassification][classification] || [];
          comfortZoneData[moduleTypeClassification][classification][name] =
            comfortZoneData[moduleTypeClassification][classification][name] ||
            [];
          comfortZoneData[moduleTypeClassification][classification][name].push(
            dataPoint
          );
          departmentData[name] = departmentData[name] || {};
          departmentData[name][translatedClassification] =
            departmentData[name][translatedClassification] || [];
          departmentData[name][translatedClassification].push(dataPoint);
        });
    });
    const labelList = report.get('chartsTimeFrame', Immutable.List()).toJS();
    renderLineChart({
      canvas: this.comfortZoneCanvas,
      data: totalComfortZoneData,
      labelList,
      forceSize: true,
    });

    Object.entries(totalDepartmentData).forEach(
      ([moduleTypeClassification, values]) => {
        renderLineChart({
          canvas: this[`department-${moduleTypeClassification}`],
          data: values,
          labelList,
          forceSize: true,
        });
      }
    );

    charts.entrySeq().forEach(([date, chartDatas]) => {});
    Object.entries(departmentData)
      .filter(
        ([departmentName, values]) =>
          this[`department${departmentNameToId[departmentName]}`]
      ) // If current report has incomplete data
      .forEach(([departmentName, values]) => {
        renderLineChart({
          canvas: this[`department${departmentNameToId[departmentName]}`],
          data: values,
          labelList,
          forceSize: true,
          fixedLabels: {
            Gästförväntan: true,
            Bemötande: true,
            Estetik: true,
            Kvalitet: true,
            'Funktion & trygghet': true,
          },
        });
      });

    Object.entries(comfortZoneData).forEach(
      ([moduleTypeClassification, values]) => {
        Object.entries(values).forEach(([classification, values]) => {
          renderLineChart({
            canvas: this[`${moduleTypeClassification}${classification}Canvas`],
            data: values,
            labelList,
            forceSize: true,
          });
        });
      }
    );
  };

  renderBarCharts = () => {
    const report = this.props.report;
    const charts = report.get('charts');
    if (!charts) {
      return;
    }
    const currentName = report.get('current');
    const current = charts.get(currentName);
    const currentHotelAverage = report.getIn(
      ['totalOverTime', 'currentHotelAverage'],
      Immutable.List()
    );
    let totalScoreData = current.find((r) => !r.get('group'));
    totalScoreData = totalScoreData ? totalScoreData.toJS() : {};

    const currentTotalScore = totalScoreData.percent.toFixed(1);
    const previousAverage = findPreviousAverage(
      currentHotelAverage,
      currentName
    );

    renderTotalScore({
      canvas: this.totalScoreCanvas,
      labels: ['CURRENT', 'PREVIOUS'],
      data: [currentTotalScore, previousAverage],
    });
  };

  getDepartmentNameToId(report) {
    const departmentNameToId = {};
    report
      .getIn(['inspection', 'inspectionType', 'inspectionModules'], [])
      .forEach((department) => {
        departmentNameToId[department.get('name')] = department.get('id');
      });
    return departmentNameToId;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.report !== prevProps.report) {
      const departmentNameToId = this.getDepartmentNameToId(this.props.report);
      this.renderBarCharts();
      this.renderLineCharts(departmentNameToId);
    }
  }
  render() {
    const { error, loading, report } = this.props;

    let startedTime = moment();
    const chartsTimeFrame = report
      .get('chartsTimeFrame', Immutable.List())
      .toJS();
    report.get('answers').forEach((a) => {
      const createdAt = moment(a.get('createdAt'));
      startedTime = createdAt.isBefore(startedTime) ? createdAt : startedTime;
    });
    const comfortZones = classifications; // hardcoded because they want the graphs in this order..

    const inspectionModuleTypeClassifications = report.getIn(
      ['inspectionModuleTypeClassifications'],
      Immutable.List()
    );

    const classificationsCanvases = comfortZones.map((comfortZone) =>
      inspectionModuleTypeClassifications.map((classification) => (
        <ChartWrapper height={275} key={classification}>
          <h3 className="centered">
            {`${translate(
              `classifications.${comfortZone}`
            ).toUpperCase()} - ${classification.toUpperCase()}`}
          </h3>
          <Canvas>
            <canvas
              ref={(c) => (this[`${classification}${comfortZone}Canvas`] = c)}
            />
          </Canvas>
        </ChartWrapper>
      ))
    );

    const reportHeaderContextAnswers = report
      .getIn(['reportHeaderContextAnswers'], Immutable.List())
      .map((contextAnswer) => (
        <HeaderInfo key={contextAnswer.get('id')}>
          <span>{contextAnswer.getIn(['contextQuestion', 'content'])}</span>
          <span>{contextAnswer.get('content')}</span>
        </HeaderInfo>
      ));

    const inspectionDescriptions = report
      .getIn(['inspection', 'inspectionDescriptions'], Immutable.List())
      .map((inspectionDescriptions) => (
        <InspectionDescriptionRow key={inspectionDescriptions.get('id')}>
          <span>{inspectionDescriptions.get('header').toUpperCase()}:</span>
          <span>{inspectionDescriptions.get('body')}</span>
        </InspectionDescriptionRow>
      ));
    const departmentCanvases = report
      .getIn(['inspectionModuleTypeClassifications'], Immutable.List())
      .map((moduleTypeClassification) => (
        <ChartWrapper
          key={`department-${moduleTypeClassification}`}
          height={400}
        >
          <h3 className="centered">{`DEPARTMENT - 
            ${moduleTypeClassification.toUpperCase()}`}</h3>
          <Canvas>
            <canvas
              ref={(c) => (this[`department-${moduleTypeClassification}`] = c)}
            />
          </Canvas>
        </ChartWrapper>
      ));

    return (
      <Wrapper>
        <ReportHeader>
          <h1>AGENT-Q REPORT</h1>
          <PerformedBy>
            AGENT-Q performed by:{' '}
            <b>
              {report.getIn(['user', 'agentCode']) ||
                translate('report.unknownAgent')}
              {report.getIn(['user', 'profile'])
                ? `, ${report.getIn(['user', 'profile'])}`
                : ''}
            </b>
          </PerformedBy>
          <Logo width={'200'} height={'125'} />
        </ReportHeader>
        <div className="flex-centering">
          {loading && <Loader />}
          {error && <Error>{translate(error)}</Error>}
        </div>
        <Header>
          <MetaData>
            <Hotel>
              <HotelTitle>{report.getIn(['hotel', 'name'])}</HotelTitle>
              <HotelAddress>{report.getIn(['hotel', 'address'])}</HotelAddress>
            </Hotel>
            {reportHeaderContextAnswers.size > 0 && (
              <ContextAnswers>{reportHeaderContextAnswers}</ContextAnswers>
            )}
          </MetaData>
          <TotalScore>
            <h3>AGENT-Q RESULT</h3>
            <canvas ref={(c) => (this.totalScoreCanvas = c)} />
          </TotalScore>
        </Header>
        {inspectionDescriptions.size > 0 && (
          <React.Fragment>
            <InspectionDescriptionWrapper>
              {inspectionDescriptions}
            </InspectionDescriptionWrapper>
          </React.Fragment>
        )}
        <OverviewChart
          data={report.get('totalOverTime')}
          currentHotel={report.getIn(['hotel', 'name'])}
          chartsTimeFrame={chartsTimeFrame}
        />
        <ExternalChartWrapper>
          <h3 className="centered">GUEST SATISFACTIONS</h3>
          <div>
            <canvas ref={(c) => (this.externalScoresCanvas = c)} />
          </div>
        </ExternalChartWrapper>
        {departmentCanvases}
        <GeneralComments
          data={report.getIn(['inspection', 'generalComments'])}
        />
        <ChartWrapper key={`totalComfortZone`} height={400}>
          <h3 className="centered">COMFORT</h3>
          <Canvas>
            <canvas ref={(c) => (this.comfortZoneCanvas = c)} />
          </Canvas>
        </ChartWrapper>
        <ComfortZoneComments data={report.get('inspection')} />
        {classificationsCanvases}
        <h2 className="page-break-before centered">OBSERVATIONS</h2>
        {report
          .getIn(['inspection', 'inspectionType', 'inspectionModules'], [])
          .map((department, i) => (
            <Department
              key={department.get('id')}
              data={department}
              index={i}
              canvasRef={(c) => (this[`department${department.get('id')}`] = c)}
            />
          ))}
        <SpecialRequestAnswers
          data={report.getIn(
            ['inspection', 'extraQuestions'],
            Immutable.List()
          )}
        />
        <Footer />
      </Wrapper>
    );
  }
}
