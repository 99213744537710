import React, { useEffect } from 'react';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import Logo from 'components/general/Logo';
import translate from 'utils/translate';
import { Wrapper, ReportHeader, Tabs, Tab } from './styled';
import Module from './Module';
import ActionPlan from './ActionPlan/ActionPlan';
import FollowUp from './FollowUp';
import {
  getInspectionLink,
  getControlledRooms,
  buildSortedModules,
  MODULE_NAME_PREFIXES,
  moduleMatches,
} from './utils';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Button from '@material-ui/core/Button';
import Print from 'components/general/Print';
import { getDeadlineQuestions } from 'utils/inspection';

export default function Report({
  load,
  error,
  loading,
  report,
  onCreateFollowUp,
  creatingFollowUp,
}) {
  const [tab, setTab] = React.useState(0);
  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const controlledRooms = getControlledRooms(report);

  return (
    <Wrapper>
      <ReportHeader>
        <Print show={false}>
          <Tabs
            value={tab}
            onChange={(event, tabIndex) => setTab(tabIndex)}
            indicatorColor='primary'
            textColor='primary'
          >
            <Tab label={'AUDIT QA REPORT'} />
            <Tab label={translate('actionPlan')} />
          </Tabs>
        </Print>
        <Print show>
          <Typography variant='h3' component='h1'>
            {translate('actionPlan')}
          </Typography>
        </Print>
        <Logo width={'140'} height={'75'} />
      </ReportHeader>
      <div className='flex-centering'>
        {loading && <Loader />}
        {error && <Error>{translate(error)}</Error>}
      </div>
      {report && report.get('modules') && (
        <>
          <Typography>
            <strong>{translate('hotel')}:</strong>{' '}
            {report.getIn(['inspectionType', 'hotel', 'name'])}
          </Typography>
          <Typography>
            <strong>{translate('auditQA.columns.startTime')}:</strong>{' '}
            {moment(report.getIn(['startTime'])).format('YYYY-MM-DD')}
          </Typography>
          <Typography>
            <strong>
              {translate('auditQA.columns.agentEndTimeSetByUser')}
            </strong>
            : {report.getIn(['agentEndTimeSetByUser', 'name'])} (
            {moment(report.getIn(['agentEndTime'])).format('YYYY-MM-DD')})
          </Typography>
          <Typography gutterBottom>
            <strong>
              {translate('auditQA.columns.adminEndTimeSetByUser')}
            </strong>
            : {report.getIn(['adminEndTimeSetByUser', 'name'])} (
            {moment(report.getIn(['adminEndTime'])).format('YYYY-MM-DD')})
          </Typography>
        </>
      )}
      {tab === 0 && report.get('followedUpInspection') && (
        <Print show={false}>
          <Typography gutterBottom align='left'>
            {translate('followUp.followedUp')}
          </Typography>
          <Link
            to={getInspectionLink(report.get('followedUpInspection'))}
            target='_blank'
            rel='noreferrer noopener'
          >
            <Button
              variant='contained'
              type='button'
              endIcon={<OpenInNewIcon />}
            >
              {translate('followUp.followedUp.open')}
            </Button>
          </Link>
        </Print>
      )}
      {tab === 0 &&
        report &&
        report.get('modules') &&
        buildSortedModules(report).map((m, i) => (
          <Module
            name={m.get('name')}
            clusters={m.get('clusters')}
            key={i}
            roomNumbers={
              moduleMatches(m.get('name'), MODULE_NAME_PREFIXES.KEY_INFORMATION)
                ? controlledRooms
                : null
            }
          />
        ))}
      {tab === 1 && (
        <Print>
          <FollowUp
            isCreating={creatingFollowUp}
            followUpInspection={report.get('followUp')}
            inspectionId={report.get('id')}
            enableActionPlan={report.get('enableActionPlan')}
            onCreateFollowUp={(inspection) =>
              onCreateFollowUp({ ...inspection, id: report.get('id') })
            }
            deadlineQuestions={getDeadlineQuestions(report.get('modules'))}
          />
        </Print>
      )}
      {tab === 1 && <ActionPlan report={report} />}
    </Wrapper>
  );
}
