import { List } from 'immutable';

export default {
  getFollowUp,
  getDeadlineQuestions,
};

export function getDeadlineQuestions(modules = List()) {
  debugger;
  let result = List();
  modules.forEach((module) => {
    module.get('clusters').forEach((cluster) => {
      const clusterDeadlineQuestions = deadlineQuestions(
        cluster.get('questions')
      );

      result = result.concat(clusterDeadlineQuestions);
    });
  });

  return result;

  function deadlineQuestions(questions) {
    if (questions.size === 0) {
      return List();
    }
    let dq = List();
    questions.forEach((q) => {
      if (
        q.getIn(['answers', 0, 'needsFollowUp']) &&
        !q.getIn(['answers', 0, 'followUpTip']) &&
        !q.getIn(['answers', 0, 'followUpFeedback'])
      ) {
        dq = dq.push(q);
      }
      dq = dq.concat(deadlineQuestions(q.get('children')));
    });
    return dq;
  }
}

export function getFollowUp(modules = List()) {
  const followUpArray = [];
  modules.forEach((module) => {
    const moduleResult = {
      id: module.get('id'),
      followUp: module.getIn(['followUp', 0, 'comment']),
      name: module.get('name'),
      clusters: [],
    };
    module.get('clusters').forEach((cluster) => {
      const clusterResult = {
        name: cluster.get('name'),
        questions: getFollowUpQuestions(cluster.get('questions')),
      };
      if (clusterResult.questions && clusterResult.questions.length > 0) {
        moduleResult.clusters.push(clusterResult);
      }
    });
    followUpArray.push(moduleResult);
  });
  return followUpArray;
}

function getFollowUpQuestions(questions) {
  const result = [];
  questions.forEach((question) => {
    const questionResult = getFollowUpQuestion(question);
    if (questionResult) {
      result.push(questionResult);
    }
  });
  return result.length > 0 ? result : undefined;
}

function getFollowUpQuestion(question) {
  const answer = question.get('answers')
    ? question.get('answers').first()
    : undefined;
  const result = {
    referenceNumber: question.get('referenceNumber'),
    content: question.get('defaultContent')
      ? question.get('defaultContent')
      : question.get('content'),
    needsFollowUp: answer ? answer.get('needsFollowUp') : undefined,
    followUpComment: answer ? answer.get('followUpComment') : undefined,
    followUpDeadline: answer ? answer.get('followUpDeadline') : undefined,
    followUpTip: answer ? answer.get('followUpTip') : undefined,
    followUpFeedback: answer ? answer.get('followUpFeedback') : undefined,
    score: answer ? answer.get('score') : undefined,
    comment: answer ? answer.get('comment') : undefined,
    type: question.get('type'),
    children: getFollowUpQuestions(question.get('children')),
    answer: answer ? answer.toJS() : undefined,
  };
  if (result.needsFollowUp || (result.children && result.children.length > 0)) {
    return result;
  }
  return undefined;
}
