import styled from 'styled-components';

export const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -5px;
  &:not(:first-child) {
    margin-top: 10px;
  }
  > * {
    flex: 1 0 300px;
    margin: 5px !important;
  }
`;
