import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import translate from 'utils/translate';
import FormControl from '@material-ui/core/FormControl';
import styled from 'styled-components';
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useAvailableUsers } from './hooks/useAvailableUsers';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import { DeadlineWarnings } from '../styled';

const Form = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: dense;
  grid-column-gap: 10px;
`;

export default function FollowUpDialog({
  followUpToInspectionId,
  show,
  onClose,
  onSubmit,
  questionsMissingDeadline,
}) {
  const users = useAvailableUsers(followUpToInspectionId);
  const [inspection, setInspection] = useState({
    startTime: new Date(),
    startTimeHour: new Date(),
    user: null,
    sendCCToCreator: true,
  });
  const inputMargin = 'dense';

  return (
    <Dialog open={show} onClose={onClose} maxWidth='sm' minWidth={400}>
      <DialogTitle>{translate('followUp.create')}</DialogTitle>
      <DialogContent dividers>
        <Form>
          <KeyboardDatePicker
            required
            variant='inline'
            margin={inputMargin}
            inputVariant='outlined'
            label={translate('inspections.create.date')}
            format='yyyy-MM-DD'
            value={inspection.startTime}
            onChange={(date) =>
              setInspection({ ...inspection, startTime: date })
            }
          />
          <KeyboardTimePicker
            required
            margin={inputMargin}
            variant='inline'
            inputVariant='outlined'
            label={translate('inspections.create.time')}
            value={inspection.startTimeHour}
            onChange={(date) =>
              setInspection({ ...inspection, startTimeHour: date })
            }
          />
          <FormControl variant='outlined' fullWidth margin={inputMargin}>
            <Autocomplete
              options={users}
              getOptionLabel={(option) => {
                if (typeof option !== 'object') {
                  const selectedUser = users.find((u) => u.id === option);
                  return selectedUser ? selectedUser.name : '';
                }
                return option.name;
              }}
              value={inspection.user ? inspection.user : null}
              onChange={(event, newValue) => {
                setInspection({
                  ...inspection,
                  user: newValue ? newValue.id : null,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  margin='dense'
                  label={translate('inspections.choose.user')}
                  variant='outlined'
                />
              )}
            />
            <FormControlLabel
              label={translate('followUp.sendCCToCreator')}
              labelPlacement='right'
              control={
                <Checkbox
                  name='sendCCToCreator'
                  label={translate('followUp.sendCCToCreator')}
                  checked={inspection.sendCCToCreator}
                  onChange={() =>
                    setInspection({
                      ...inspection,
                      sendCCToCreator: !inspection.sendCCToCreator,
                    })
                  }
                />
              }
            />
          </FormControl>
          {questionsMissingDeadline.size > 0 && (
            <Alert severity='warning'>
              {translate('followUp.missingDeadlineWarning')}
              <DeadlineWarnings>
                {questionsMissingDeadline.map((q) => (
                  <li key={q.get('id')}>{q.get('content')}</li>
                ))}
              </DeadlineWarnings>
            </Alert>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          {translate('back')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => onSubmit(inspection)}
        >
          {translate('create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
