import { push } from 'connected-react-router';
import urls from 'globals/urls';
import loadHotels from './loadHotels';
import createHotel from './createHotel';
import deleteHotel from './deleteHotel';
import editHotel from './editHotel';

export default {
  loadHotels,
  createHotel,
  deleteHotel,
  editHotel,
  openHotel,
  openOrganizationHotel,
  openCreateModal,
  closeCreateModal,
  openDeleteModal,
  closeDeleteModal,
};

export function openHotel(hotelId) {
  return push(urls.hotelDetails.replace(':hotelId', hotelId));
}

export function openOrganizationHotel(hotelId, organizationId) {
  return push(
    urls.organizationEntity
      .replace(':organizationId', organizationId)
      .replace(':hotelId', hotelId)
  );
}

export function openCreateModal(organizationId) {
  return {
    type: 'OPEN_CREATE_HOTEL_MODAL',
    payload: { organizationId },
  };
}
export function closeCreateModal() {
  return {
    type: 'CLOSE_CREATE_HOTEL_MODAL',
  };
}
export function openDeleteModal({ name, id }) {
  return {
    type: 'OPEN_DELETE_HOTEL_MODAL',
    payload: { name, id },
  };
}
export function closeDeleteModal() {
  return {
    type: 'CLOSE_DELETE_HOTEL_MODAL',
  };
}
