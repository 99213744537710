import React, { useState, useEffect } from 'react';
import moment from 'moment';
import translate from 'utils/translate';
import Error from 'components/general/Error';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import CreateHotelModal from 'components/admin/Hotel/CreateModal';
import { breakpoints } from 'globals/media';
import { useScreenSize } from 'hooks/useScreenSize';
import LoadingOverlay from 'components/general/LoadingOverlay';
import EditModal from 'components/admin/Hotel/EditModal';
import Zoom from '@material-ui/core/Zoom';
import { List } from 'immutable';

const Wrapper = styled.div`
  position: relative;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RowButton = styled(IconButton)`
  padding: 4px !important;
  margin: 4px !important;
`;

const StyledRow = styled(TableRow)`
  cursor: pointer;
`;

export default function ListHotels({
  hotels = List(),
  title,
  openCreateHotelModal,
  onHotelCreated,
  openHotelAdministration,
  loading,
  organizationId,
  deleteHotel,
  error,
  role,
  load,
}) {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [hotelToEdit, setHotelToEdit] = useState(null);
  const screenSize = useScreenSize();
  const theme = useTheme();
  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <LoadingOverlay show={loading} />
      <Header>
        <Typography variant='h5' component='h1' gutterBottom>
          {title || translate('hotels')}
        </Typography>
        {['superUser', 'companyAdmin'].includes(role) && (
          <Zoom
            in={true}
            timeout={{
              enter: theme.transitions.duration.enteringScreen,
              exit: theme.transitions.duration.leavingScreen,
            }}
            unmountOnExit
          >
            <Fab
              variant={
                screenSize.width < breakpoints.tablet ? 'round' : 'extended'
              }
              style={{
                position:
                  screenSize.width < breakpoints.tablet ? 'fixed' : 'relative',
                bottom:
                  screenSize.width < breakpoints.tablet ? theme.spacing(2) : 0,
                right:
                  screenSize.width < breakpoints.tablet ? theme.spacing(2) : 0,
              }}
              color='primary'
              onClick={() => openCreateHotelModal(organizationId)}
              label={translate('hotels.create')}
            >
              <AddIcon />
              {screenSize.width > breakpoints.tablet &&
                translate('hotels.create')}
            </Fab>
          </Zoom>
        )}
      </Header>
      {error && <Error>{translate(error)}</Error>}
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>{translate('status')}</TableCell>
            <TableCell>{translate('hotels.create.name')}</TableCell>
            <TableCell>{translate('hotels.create.address')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {hotels
            .map((hotel) =>
              hotel.set(
                'inactive',
                hotel.get('inactiveStartDate') &&
                  moment().isAfter(hotel.get('inactiveStartDate')) &&
                  (!hotel.get('inactiveEndDate') ||
                    moment().isBefore(hotel.get('inactiveEndDate')))
              )
            )
            .sort((a, b) =>
              a.get('inactive') ? 1 : b.get('inactive') ? -1 : 0
            )
            .map((hotel) => (
              <StyledRow
                key={hotel.get('name')}
                hover
                onClick={() => openHotelAdministration(hotel.get('id'))}
              >
                <TableCell>
                  <Tooltip
                    placement='top'
                    title={
                      hotel.get('inactive')
                        ? translate('inactive')
                        : translate('active')
                    }
                  >
                    <FiberManualRecordIcon
                      style={{
                        color: hotel.get('inactive') ? 'grey' : 'green',
                      }}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell>{hotel.get('name')}</TableCell>
                <TableCell>{hotel.get('address')}</TableCell>
                <TableCell style={{ width: '85px' }}>
                  <Tooltip title={translate('organizations.edit')}>
                    <RowButton
                      size='medium'
                      onClick={(event) => {
                        event.stopPropagation();
                        setEditModalOpen(true);
                        setHotelToEdit(hotel.toJS());
                      }}
                    >
                      <EditIcon />
                    </RowButton>
                  </Tooltip>
                  {role === 'superUser' && (
                    <Tooltip title={translate('organizations.delete')}>
                      <RowButton
                        size='medium'
                        onClick={(event) => {
                          event.stopPropagation();
                          deleteHotel(hotel);
                        }}
                      >
                        <DeleteOutlineIcon />
                      </RowButton>
                    </Tooltip>
                  )}
                </TableCell>
              </StyledRow>
            ))}
        </TableBody>
      </Table>
      <CreateHotelModal onHotelCreated={onHotelCreated} />
      {editModalOpen && (
        <EditModal
          role={role}
          loadHotels={onHotelCreated}
          closeModal={() => setEditModalOpen(false)}
          data={hotelToEdit}
        />
      )}
    </Wrapper>
  );
}
