import React, {Component} from 'react';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import ModalWrapper from 'components/general/ModalWrapper';
import InnerModal from 'components/general/InnerModal';
import Button from 'components/general/Button';
import localReducer from './reducer';
import remove from './remove';

export default class DeleteModal extends Component {
  constructor() {
    super();
    this.state = localReducer();
  }
  dispatch = action => {
    this.setState(prevState => localReducer(prevState, action));
  };
  remove = async e => {
    e.preventDefault();
    const {question} = this.props;
    let success;
    success = await remove({questionId: question.id}, this.dispatch);
    if (success) {
      this.props.closeModal();
    }
  };
  changeInput = payload =>
    this.dispatch({
      type: 'CHANGE_INPUT',
      payload
    });

  render() {
    const {closeModal, question} = this.props;
    const {loading, error} = this.state;
    return (
      <ModalWrapper onClose={closeModal}>
        <InnerModal onSubmit={this.remove}>
          <h4>{translate('questions.removeContextQuestion')}</h4>
          <p>
            [{question.id}]: {question.content}
          </p>
          <Button color='errorRed'>{translate('questions.removeContextQuestion.button')}</Button>
          {loading && <Loader />}
          {error && <Error>{translate(error)}</Error>}
        </InnerModal>
      </ModalWrapper>
    );
  }
}
