import React, { Component } from 'react';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import Button from 'components/general/Button';
import { Wrapper, FieldError } from './styled';
import { createInspection } from './actions';
import InspectionDescription from './InspectionDescription';
import ExtraQuestion from './ExtraQuestion';
import localReducer from './reducer';
import InspectionForm from 'components/general/InspectionForm';
import QuestionRow from 'components/inspection/Questions/QuestionRow';
import { List } from 'immutable';

export default class CreateInspection extends Component {
  constructor() {
    super();
    this.state = localReducer();
  }
  dispatch = (action) => {
    this.setState((prevState) => localReducer(prevState, action));
  };
  create = async () => {
    const success = await createInspection(this.state, this.dispatch);
    if (success) {
      this.props.back();
    }
  };
  changeDescriptionInput = (payload) =>
    this.dispatch({
      type: 'CHANGE_DESCRIPTION_INPUT',
      payload,
    });
  changeExtraQuestionInput = (payload) =>
    this.dispatch({
      type: 'CHANGE_EXTRA_QUESTION_INPUT',
      payload,
    });
  changeInput = (payload) =>
    this.dispatch({
      type: 'CHANGE_INPUT',
      payload,
    });
  componentDidMount() {
    this.props.loadHotels();
    this.props.loadUsers();
  }
  componentDidUpdate(prevProps, prevState) {
    const { hotel } = this.state;
    if (hotel !== prevState.hotel) {
      this.props.loadInspectionTypes(hotel);
    }
  }
  render() {
    const { users, hotels, inspectionTypes, back } = this.props;
    const {
      error,
      startTime,
      startTimeHour,
      user,
      hotel,
      inspectionType,
      inspectionDescriptions,
      prefilledQuestions,
      extraQuestions,
      loading,
      fieldErrors,
      shouldSendNotification,
    } = this.state;

    const domInspectionDescriptions = inspectionDescriptions.map(
      (desc, index) => (
        <InspectionDescription
          {...desc}
          index={index}
          changeDescriptionInput={this.changeDescriptionInput}
          key={index}
        />
      )
    );
    domInspectionDescriptions.push(
      <InspectionDescription
        index={inspectionDescriptions.length}
        changeDescriptionInput={this.changeDescriptionInput}
        key={inspectionDescriptions.length}
      />
    );

    const domExtraQuestions = extraQuestions.map((desc, index) => (
      <ExtraQuestion
        changeExtraQuestionInput={this.changeExtraQuestionInput}
        {...desc}
        index={index}
        key={index}
      />
    ));
    domExtraQuestions.push(
      <ExtraQuestion
        changeExtraQuestionInput={this.changeExtraQuestionInput}
        index={extraQuestions.length}
        key={extraQuestions.length}
      />
    );

    return (
      <Wrapper>
        <h2>{translate('inspections.create.header')}</h2>
        <InspectionForm
          disabled={loading}
          inspection={{
            startTime,
            startTimeHour,
            user,
            hotel,
            inspectionType,
            inspectionDescriptions,
            extraQuestions,
            shouldSendNotification,
          }}
          hotels={hotels.toJS()}
          users={users.toJS()}
          inspectionTypes={inspectionTypes.toJS()}
          onChange={({ field, value }) => {
            if (field === 'inspectionType') {
              const selectedInspectionType = inspectionTypes.find(
                (type) => type.get('id') === value
              );

              this.dispatch({
                type: 'SET_PREFILLED_QUESTIONS',
                payload: selectedInspectionType.get('prefilledQuestions'),
              });
            }
            if (field === 'hotel') {
              this.changeInput({
                value: -1,
                field: 'inspectionType',
              });
              this.dispatch({
                type: 'SET_PREFILLED_QUESTIONS',
                payload: List(),
              });
            }
            this.changeInput({ value, field });
          }}
        />
        {prefilledQuestions &&
          prefilledQuestions.size > 0 &&
          prefilledQuestions.map((question, i) => (
            <QuestionRow
              setScore={() => null}
              setComment={({ questionId, comment }) => {
                this.dispatch({
                  type: 'UPDATE_PREFILLED_QUESTIONS',
                  payload: {
                    index: i,
                    comment,
                  },
                });
              }}
              showFollowUp={false}
              uploadFile={() => null}
              deleteFile={() => null}
              unhideQuestion={() => null}
              key={question.get('id')}
              question={question}
              prefillMode
              isControl={false}
            />
          ))}
        <h4>{translate('extraQuestions')}</h4>
        {domExtraQuestions}
        <h4>{translate('inspection.description')}</h4>
        {domInspectionDescriptions}
        <Button onClick={this.create}>{translate('inspections.create')}</Button>
        <Button color='white' textColor='textGray' onClick={back}>
          {translate('back')}
        </Button>
        {loading && <Loader />}
        {error && <Error>{translate(error)}</Error>}
        {fieldErrors &&
          Object.entries(fieldErrors).map(([key, errors]) => (
            <Error autoWidth key={key}>
              [{key}]
              {errors.map((e, i) => (
                <FieldError key={i}>{e}</FieldError>
              ))}
            </Error>
          ))}
      </Wrapper>
    );
  }
}
