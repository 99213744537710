import {request} from 'actions/utils';

export default function({data}, dispatch) {
  return request({
    action: 'UPDATE_MODULE',
    path: `/api/admin/inspection-types/${data.inspectionTypeId}/inspection-modules/${data.id}`,
    method: 'PUT',
    body: data
  })(dispatch);
}
