import React from 'react';
import styled from 'styled-components';
import colors from 'globals/colors';
import translate from 'utils/translate';
import companyInfo from 'globals/companyInfo';

export const QuestionWrapper = styled.div`
  border-radius: 4px;
  page-break-inside: avoid;
`;
export const QuestionAnswer = styled.div`
  margin: 0.5rem 0 0.3rem 0;
  font-size: 0.9rem;
`;
export const Img = styled.img`
  width: 100%;
  max-height: 166px;
`;
export const Line = styled.hr`
  margin: 20px 0;
`;

export const Hotel = styled.div`
  margin: 0.3rem 0;
`;

export const HotelTitle = styled.div`
  font-size: 1.3rem;
  font-weight: bold;
`;

export const HotelAddress = styled.div`
  margin-top: 0.2rem;
  font-size: 0.9rem;
`;

export const TotalScore = styled.div`
  width: 300px;
  height: 250px;
  h3 {
    margin-top: 0;
    text-align: center;
  }
`;

export const ContextAnswers = styled.div`
  margin-top: 1.5rem;
`;

export const PerformedBy = styled.div`
  grid-area: d;
  margin: 0.8rem 0;
`;

export const Header = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  min-height: 300px;
`;

export const InspectionDescriptionRow = styled.div`
  display: grid;
  grid-template-columns: 12% 85%;
  grid-template-rows: auto;
  margin-bottom: 5px;

  span:first-child {
    margin-right: 5px;
    font-weight: bold;
  }
`;

export const InspectionDescriptionWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  margin: 1rem 0 0.5rem 0;
  font-size: 0.9rem;
`;

export const ExtraQuestionsWrapper = styled.div`
  margin-top: 2rem;
  page-break-before: always;
`;

export const MetaData = styled.div`
  font-size: 0.9rem;
`;

export const HeaderInfo = styled.div`
  display: flex;
  margin: 6px 0;
  font-weight: bold;

  *:first-child {
    width: 15rem;
    letter-spacing: 0.045rem;
  }
  *:last-child {
    flex: 1;
  }
`;

export const ClusterName = styled.h4`
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: auto;
  display: inline-block;
  padding: 0.5rem;
  color: ${colors.white};
  font-weight: 400;
  border-radius: 0 6px 6px 0;
  margin: 3rem 0 0 0;

  @media print {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
`;

export const ChartWrapper = styled.div`
  padding: 1rem 0 1rem 0;
  page-break-inside: avoid;
  height: ${(props) => `${props.height}px`};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const ObservationsChartWrapper = styled.div`
  padding: 1rem 0 0 0;
  page-break-inside: avoid;
  height: 350px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const Canvas = styled.div`
  position: relative;
  flex: 1;
`;

export const Department = styled.div`
  @media print {
    page-break-before: ${(props) => (props.index > 0 ? 'always' : 'auto')};
  }
`;

export const ExternalChartWrapper = styled.div`
  > div {
    max-height: 300px;
    max-width: 100%;
    position: relative;
  }
  @media print {
    margin-top: 20px;
    page-break-after: always;
    page-break-inside: avoid;
  }
`;

export const Wrapper = styled.div`
  -webkit-print-color-adjust: exact;
  color-adjust: exact;

  h1,
  h2,
  h3,
  h4 {
    font-weight: bold;
  }

  h2 {
    text-align: center;
    margin-top: 5rem;
    font-size: 27px;
  }
  h1 {
    text-align: center;
  }
  h3.external-scores {
    margin-top: 3rem;
  }
  h3.centered {
    text-align: center;
  }
  .flex-centering {
    display: flex;
    justify-content: center;
  }
  .page-break-before {
    @media print {
      page-break-before: always;
    }
  }
  .page-break-after {
    @media print {
      page-break-after: always;
    }
  }
  .page-break-inside {
    page-break-inside: avoid;
  }
`;
export const FilesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  page-break-inside: avoid;

  h4 {
    grid-column: 1 / -1;
  }
`;

export const SpecialRequestFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FileTexts = styled.div`
  > div {
    display: flex;
    margin-bottom: 5px;
    font-size: 0.9rem;
    div:first-child {
      margin-right: 10px;
    }
  }
`;
export const Files = styled.div`
  > div {
    float: left;
    max-width: 125px;
    margin-right: 8px;
  }
`;

export const SpecialRequestFile = styled.div`
  float: left;
  max-width: 125px;
  margin-left: 10px;
`;

export const SpecialRequestFileRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 0.9rem;
`;

export const Footer = styled.footer.attrs({
  children: (
    <React.Fragment>
      <div>
        <h3>{companyInfo.name}</h3>
        <div>
          <span>
            Box: {companyInfo.box}, {companyInfo.postalAddress}{' '}
            {companyInfo.city}, Sweden
          </span>
          <span>Mail: {companyInfo.mail}</span>
          <span>{companyInfo.siteUrl}</span>
          <span>{companyInfo.phone}</span>
        </div>
      </div>
    </React.Fragment>
  ),
})`
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;

  > div {
    width: 100%;
    flex-direction: column;
    > h3 {
      color: ${({ theme }) => theme.palette.primary.main};
    }
    div:nth-child(2) {
      display: grid;
      grid-template-columns: 65% 35%;
      grid-template-rows: auto;
      > span {
        font-family: 'Geometria-regular';
        font-weight: normal;
        color: ${colors.textGray};
      }
      span:nth-child(even) {
        text-align: right;
      }
    }
  }
`;

export const ReportHeader = styled.div`
  display: grid;
  grid-template-areas:
    'h i'
    'd i';
  grid-template-columns: 1fr auto;
  align-items: center;

  h1 {
    grid-area: h;
    justify-self: flex-start;
    font-weight: bold;
  }
  img {
    grid-area: i;
    justify-self: flex-end;
    height: auto;
    margin: 0.8rem 0.8rem 0 0;
  }
`;

export const QuestionHeader = styled.div.attrs({
  title: (props) =>
    props.score !== undefined
      ? `${translate('score')} - ${props.score}`
      : undefined,
})`
  margin-top: 10px;
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
  align-items: baseline;
  div > div:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  :before {
    display: ${(props) => (props.score === undefined ? 'none' : 'block')};
    content: '⬤';
    margin-right: 10px;
    color: ${(props) => {
      switch (props.score) {
        case 0:
          return colors.rating0;
        case 1:
          return colors.rating1;
        case 2:
          return colors.rating2;
        case 3:
          return colors.rating3;
        default:
          return colors.transparent;
      }
    }};
  }
`;
