import React from 'react';
import translate from 'utils/translate';
import styled from 'styled-components';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: -5px 0;

  > * {
    margin: 5px 0 !important;
  }
`;

const EmailRow = styled.div`
  display: flex;
  align-items: center;
  > *:first-child {
    flex-grow: 1;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: flex-start;
  > :nth-child(2) {
    cursor: help;
    margin-left: 4px;
    font-size: 14px;
  }
`;


export default function InspectionTypeNotificationForm({
  onChange,
  reminderEmail=[],
  autoCloseWarningEmail=[],
  feedbackEmail=[],
  reportEmail=[]
}) {
  const addEmail = (fieldName, values, value) => {
    onChange(fieldName, [...values, value]);
  };

  const removeEmail = (fieldName, values, index) => {
    onChange(fieldName, values.filter((value,i) => i !== index));
  };

  const changeEmail = (fieldName, values, value, index) => {
    onChange(fieldName, values.map((email,i) => i !== index ? email : value));
  };

  return (
    <Wrapper>
      <Email
        title={translate('inspectionTypes.notifications.reminder')}
        help={translate('inspectionTypes.notifications.reminderHelp')}
        values={reminderEmail}
        onAdd={(value) => addEmail('reminderEmail', reminderEmail, value)}
        onChange={(value, index) => changeEmail('reminderEmail', reminderEmail, value, index)}
        onDelete={(index) => removeEmail('reminderEmail', reminderEmail, index)}
      />
      <Divider style={{ margin: '1rem 0' }} />
      <Email
        title={translate('inspectionTypes.notifications.autoCloseWarning')}
        help={translate('inspectionTypes.notifications.autoCloseWarningHelp')}
        values={autoCloseWarningEmail}
        onAdd={(value) => addEmail('autoCloseWarningEmail', autoCloseWarningEmail, value)}
        onChange={(value, index) => changeEmail('autoCloseWarningEmail', autoCloseWarningEmail, value, index)}
        onDelete={(index) => removeEmail('autoCloseWarningEmail', autoCloseWarningEmail, index)}
      />
      <Divider style={{ margin: '1rem 0' }} />
      <Email
        title={translate('inspectionTypes.notifications.feedback')}
        help={translate('inspectionTypes.notifications.feedbackHelp')}
        values={feedbackEmail}
        onAdd={(value) => addEmail('feedbackEmail', feedbackEmail, value)}
        onChange={(value, index) => changeEmail('feedbackEmail', feedbackEmail, value, index)}
        onDelete={(index) => removeEmail('feedbackEmail', feedbackEmail, index)}
      />
      <Divider style={{ margin: '1rem 0' }} />
      <Email
        title={translate('inspectionTypes.notifications.report')}
        help={translate('inspectionTypes.notifications.reportHelp')}
        values={reportEmail}
        onAdd={(value) => addEmail('reportEmail', reportEmail, value)}
        onChange={(value, index) => changeEmail('reportEmail', reportEmail, value, index)}
        onDelete={(index) => removeEmail('reportEmail', reportEmail, index)}
      />
    </Wrapper>
  );
}

const Email = ({
  title,
  help,
  values,
  onAdd,
  onChange,
  onDelete
}) => {
  return (
    <>
      <HeaderRow>
        <Typography variant='subtitle1' gutterBottom>
          {title}
        </Typography>
        <Tooltip title={help}>
          <HelpIcon />
        </Tooltip>
      </HeaderRow>

      {values.map((value, index) => (
        <EmailRow key={index}>
          <TextField
            variant={'outlined'}
            size={'small'}
            label={'Email'}
            value={value || ''}
            onChange={(event) => onChange(event.target.value, index)}
          />
          <IconButton
            size='small'
            onClick={() => onDelete(index)}
          >
            <DeleteIcon />
          </IconButton>
        </EmailRow>
      ))}

      <Button
      variant={'outlined'}
        startIcon={<AddIcon />}
        onClick={() => onAdd('')}
      >
        {translate('add')}
      </Button>
    </>
  );
}
