import React from 'react';
import {connect} from 'react-redux';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import ModalWrapper from 'components/general/ModalWrapper';
import InnerModal from 'components/general/InnerModal';
import Button from 'components/general/Button';
import questionActions from 'actions/admin/question';

function DeleteModal({visible, loading, error, remove, closeModal, cluster, moduleTypeId}) {
  if (!visible) {
    return null;
  }
  return (
    <ModalWrapper onClose={closeModal}>
      <InnerModal
        onSubmit={e => {
          e.preventDefault();
          remove({
            id: cluster.get('id'),
            moduleTypeId
          });
        }}
      >
        <h4>{translate('questions.deleteCluster.header')}</h4>
        <div>{translate('questions.deleteCluster.confirmText').replace('{name}', cluster.get('name'))}</div>
        <Button>{translate('questions.deleteCluster')}</Button>
        {loading && <Loader />}
        {error && <Error>{translate(error)}</Error>}
      </InnerModal>
    </ModalWrapper>
  );
}

export default connect(
  (state, ownProps) => ({
    visible: state.getIn(['admin', 'questions', 'deleteClusterModal', 'visible']),
    loading: state.getIn(['admin', 'questions', 'deleteClusterModal', 'loading']),
    error: state.getIn(['admin', 'questions', 'deleteClusterModal', 'error']),
    cluster: state.getIn(['admin', 'questions', 'deleteClusterModal', 'cluster']),
    ...ownProps
  }),
  dispatch => ({
    remove: ({id, moduleTypeId}) => dispatch(questionActions.removeCluster({id, moduleTypeId})),
    closeModal: () => dispatch(questionActions.closeDeleteClusterModal())
  })
)(DeleteModal);
