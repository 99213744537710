import React, { useRef, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import 'flag-icon-css/css/flag-icon.min.css';
import { languages } from 'globals/languages';

const Wrapper = styled.div`
  height: ${(props) => props.size || '22px'};
  width: ${(props) => props.size || '22px'};
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid white;
  line-height: ${(props) => props.size || '22px'};

  .flag-icon {
    font-size: ${(props) => props.size || '22px'};
  }
`;

export default function LanguagePicker({ value, size, onLanguageSelected }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorEl = useRef(null);

  const currentLanguage = value || languages[0];

  moment.locale(currentLanguage.code);

  return (
    <>
      <IconButton
        size='small'
        ref={anchorEl}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <Wrapper size={size}>
          <span
            className={`flag-icon flag-icon-${currentLanguage.icon} flag-icon-squared`}
          />
        </Wrapper>
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl.current}
        keepMounted
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        {languages.map((language) => (
          <MenuItem
            key={language.code}
            onClick={() => {
              setMenuOpen(false);
              if (typeof onLanguageSelected === 'function') {
                onLanguageSelected(language);
              }
            }}
          >
            <span
              className={`flag-icon flag-icon-${language.icon}`}
              style={{ marginRight: '3px' }}
            />{' '}
            {language.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
