import React from 'react';
import translate from 'utils/translate';
import { CardWrapper, ToggleSkipButton, Span } from './styled';

export default function ModuleCard({
  answered,
  started,
  name,
  isSkipped,
  onToggleSkip,
}) {
  let text = 'inspection.module.unanswered';
  if (isSkipped) {
    text = 'inspection.module.skipped';
  } else if (answered) {
    text = 'inspection.module.answered';
  } else if (started) {
    text = 'inspection.module.started';
  }
  return (
    <CardWrapper isSkipped={isSkipped}>
      {onToggleSkip && (
        <ToggleSkipButton isSkipped={isSkipped} onClick={onToggleSkip} />
      )}
      <h4>{name}</h4>
      <Span>{translate(text)}</Span>
    </CardWrapper>
  );
}
