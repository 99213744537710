import { fromJS } from 'immutable';
import { classifications } from 'globals/classifications';

const defaultState = fromJS({
  visible: false,
  loading: false,
  comments: {
    ...classifications.reduce((prev, curr) => ({ ...prev, [curr]: '' }), {}),
  },
  focusField: undefined,
  error: '',
});

export default function (state = defaultState, action = { type: '' }) {
  switch (action.type) {
    case 'OPEN_COMFORT_ZONE_MODAL': {
      return state
        .set('visible', true)
        .set('loading', false)
        .set('focusField', action.payload.focusField)
        .set('error', '');
    }
    case 'CLOSE_COMFORT_ZONE_MODAL': {
      return state.set('visible', false);
    }
    case 'UPDATE_INSPECTION_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'UPDATE_INSPECTION_DONE': {
      return state.set('loading', false);
    }
    case 'UPDATE_INSPECTION_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'LOAD_INSPECTION_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'LOAD_INSPECTION_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'LOAD_INSPECTION_DONE': {
      return state.set('loading', false).set(
        'comments',
        fromJS({
          ...classifications.reduce(
            (prev, curr) => ({ ...prev, [curr]: action.payload[curr] }),
            {}
          ),
        })
      );
    }
    default: {
      return state;
    }
  }
}
