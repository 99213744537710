import { request } from 'actions/utils';

export default function ({ data }, onSuccess) {
  return async (dispatch) => {
    let success = await dispatch(
      request({
        action: 'UPDATE_HOTEL',
        path: `/api/admin/hotels/${data.id}`,
        method: 'PUT',
        body: data,
      })
    );
    if (success && typeof onSuccess === 'function') {
      onSuccess();
    }
  };
}
