import React from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import dashboardActions from 'actions/dashboard';
import translate from 'utils/translate';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import { Typography } from '@material-ui/core';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RowButton = styled(IconButton)`
  padding: 4px !important;
  margin: 4px !important;
`;

const StyledRow = styled(TableRow)`
  cursor: pointer;
`;

function Dashboards({ hotel, openDashboard, createDashboard }) {
  if (!hotel.get('dashboardEnabled')) {
    return null;
  }
  const dashboards = hotel.get('dashboards', List());
  if (!dashboards.size)
    return (
      <>
        <Typography variant='h6' component='h4'>
          {translate('dashboards')}
        </Typography>
        <Button
          variant='outlined'
          color='secondary'
          onClick={() => createDashboard(hotel.get('id'))}
        >
          {translate('dashboards.create')}
        </Button>
      </>
    );

  return (
    <>
      <Header>
        <Typography variant='h6' component='h4'>
          {translate('dashboards')}
        </Typography>

        <Button
          variant='outlined'
          color='primary'
          startIcon={<AddIcon />}
          onClick={() => createDashboard(hotel.get('id'))}
        >
          {translate('dashboards.create')}
        </Button>
      </Header>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{translate('dashboards.name')}</TableCell>
            <TableCell>{translate('created')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dashboards.map((dashboard) => (
            <StyledRow
              key={dashboard.get('id')}
              hover
              onClick={() => openDashboard(dashboard.get('id'))}
            >
              <TableCell>{dashboard.get('name')}</TableCell>
              <TableCell>
                {moment(dashboard.get('createdAt')).format('YYYY-MM-DD')}
              </TableCell>
              <TableCell style={{ width: '85px' }}>
                <Tooltip title={translate('delete')}>
                  <RowButton
                    size='medium'
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  ></RowButton>
                </Tooltip>
              </TableCell>
            </StyledRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

export default connect(
  (state) => ({ hotel: state.getIn(['hotel', 'current']) }),
  (dispatch) => ({
    openDashboard: (userId) => dispatch(dashboardActions.openView(userId)),
    createDashboard: (hotelId) => dispatch(dashboardActions.create(hotelId)),
  })
)(Dashboards);
