import colors from 'globals/colors';
import styled from 'styled-components';
import media from 'globals/media';

export const CommentButtonWrapper = styled.div`
  position: fixed;
  left: 210px;
  width: 100px;
  top: 40%;
  display: flex;
  flex-direction: column;

  ${media.desktop`
    left: 0;
  `};

  > * {
    height: 65px;
    background-color: ${colors.modalBackground};
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  ${media.tablet`
    bottom: ${({ useTabs }) => (useTabs ? '48px' : '0')};
    top: initial;
    width: 100%;
    flex-direction: row;
    margin: 0;
    z-index: 1;

    > * {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  `}
`;

export const CommentIcon = styled.button`
  border: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  img,
  svg {
    width: 30px;
  }
`;
