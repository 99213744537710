import styled from 'styled-components';
import media from 'globals/media';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;

  button {
    margin-top: 15px;
    width: 200px;
  }
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;

  ${media.handheld`
    display: block;
  `};

  h1 {
    margin-left: 8px;
  }
`;

export const LogoContainer = styled.div`
  margin-bottom: 7px;
`;

export const InnerWrapper = styled.div`
  position: relative;
  width: 400px;

  ${media.handheld`
    width: 320px;
  `};
`;
