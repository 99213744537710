import {
  moduleMatches,
  MODULE_NAME_PREFIXES,
  isRoomNumberQuestion,
  isSupplementalTypeQuestion,
} from '../utils';

export function findClusters(modules, moduleId) {
  const moduleIndex = modules.findIndex((m) => m.get('id') === moduleId);
  if (moduleIndex === -1) {
    return null;
  }

  return modules.getIn([moduleIndex, 'clusters']).toJS();
}

export function getClusterTitle(cluster, originalClusters, followUpModule) {
  const originalCluster = originalClusters.find((c) => c.name === cluster.name);

  if (!originalCluster) {
    return cluster.name;
  }

  if (moduleMatches(followUpModule.name, MODULE_NAME_PREFIXES.GUEST_ROOM)) {
    const roomNumberQuestions = originalCluster.questions.filter((q) =>
      isRoomNumberQuestion(q.referenceNumber)
    );

    if (roomNumberQuestions.length === 0) {
      return originalCluster.name;
    }

    return `${roomNumberQuestions[0].content}: ${roomNumberQuestions
      .map((q) => q.answers && q.answers[0] && q.answers[0].score)
      .join(', ')}`;
  }

  if (moduleMatches(followUpModule.name, MODULE_NAME_PREFIXES.SUPPLEMENTAL)) {
    const supplementalTypeQuestions = originalCluster.questions.filter((q) =>
      isSupplementalTypeQuestion(q.referenceNumber)
    );

    if (supplementalTypeQuestions.length === 0) {
      return originalCluster.name;
    }

    return `${supplementalTypeQuestions
      .map((q) => q.answers && q.answers[0] && q.answers[0].comment)
      .join(', ')}`;
  }

  return cluster.name;
}
