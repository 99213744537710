import {request} from 'actions/utils';

export default function({data}, dispatch) {
  return request({
    action: 'UPDATE_HOTEL',
    path: `/api/admin/hotels/${data.id}`,
    method: 'PUT',
    body: data
  })(dispatch);
}
