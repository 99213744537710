import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import translate from 'utils/translate';

export default function ComfortZoneComments({ hotels }) {
  const { hygiene, socialDistance } = getComfortZoneData(
    hotels.reduce((acc, hotel) => acc.concat(hotel.inspections), [])
  );

  if (!hygiene.length && !socialDistance.length) return null;
  return (
    <div style={{ marginTop: '2rem' }}>
      <Typography variant='h5'>
        {translate('safeQReport.observationsComments')}
      </Typography>
      {!!socialDistance.length && (
        <div style={{ marginTop: '1rem' }}>
          <Typography variant='h6'>
            {translate('classifications.socialDistance')}
          </Typography>
          <List>
            {socialDistance
              .sort((a, b) =>
                moment(a.date).isBefore(b.date)
                  ? -1
                  : moment(b.date).isBefore(a.date)
                  ? 1
                  : 0
              )
              .map(({ date, text }) => (
                <ListItemText
                  primary={text}
                  secondary={moment(date).format('YYYY-MM-DD')}
                />
              ))}
          </List>
        </div>
      )}
      {!!hygiene.length && (
        <div style={{ marginTop: '1rem' }}>
          <Typography variant='h6'>
            {translate('classifications.hygiene')}
          </Typography>
          <List dense>
            {hygiene
              .sort((a, b) =>
                moment(a.date).isBefore(b.date)
                  ? -1
                  : moment(b.date).isBefore(a.date)
                  ? 1
                  : 0
              )
              .map(({ date, text }) => (
                <ListItemText
                  primary={text}
                  secondary={moment(date).format('YYYY-MM-DD')}
                />
              ))}
          </List>
        </div>
      )}
    </div>
  );
}

function getComfortZoneData(inspections) {
  return inspections.reduce(
    (acc, inspection) => ({
      hygiene: inspection.hygiene
        ? acc.hygiene.concat({
            text: inspection.hygiene,
            date: inspection.startTime,
          })
        : acc.hygiene,
      socialDistance: inspection.socialDistance
        ? acc.socialDistance.concat({
            text: inspection.socialDistance,
            date: inspection.startTime,
          })
        : acc.socialDistance,
    }),
    { hygiene: [], socialDistance: [] }
  );
}
