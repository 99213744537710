import { fromJS } from 'immutable';
import createQuestionModal from './createQuestionModal';
import deleteQuestionModal from './deleteQuestionModal';
import createClusterModal from './createClusterModal';
import deleteClusterModal from './deleteClusterModal';

const defaultState = fromJS({
  list: [],
  types: [],
  loading: false,
  error: '',
  createQuestionModal: createQuestionModal(),
  deleteQuestionModal: deleteQuestionModal(),
  createClusterModal: createClusterModal(),
  deleteClusterModal: deleteClusterModal(),
});

export default function (state = defaultState, action = { type: '' }) {
  state = state.set(
    'createQuestionModal',
    createQuestionModal(state.get('createQuestionModal'), action)
  );
  state = state.set(
    'deleteQuestionModal',
    deleteQuestionModal(state.get('deleteQuestionModal'), action)
  );
  state = state.set(
    'createClusterModal',
    createClusterModal(state.get('createClusterModal'), action)
  );
  state = state.set(
    'deleteClusterModal',
    deleteClusterModal(state.get('deleteClusterModal'), action)
  );
  switch (action.type) {
    case 'LOAD_QUESTIONS_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'LOAD_QUESTIONS_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'LOAD_QUESTIONS_DONE': {
      return state.set('list', fromJS(action.payload)).set('loading', false);
    }
    case 'LOAD_QUESTION_TYPES_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'LOAD_QUESTION_TYPES_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'LOAD_QUESTION_TYPES_DONE': {
      return state.set('types', fromJS(action.payload)).set('loading', false);
    }
    default: {
      return state;
    }
  }
}
