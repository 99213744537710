import { uploadAttachment } from 'actions/utils';

export default function uploadFile({ inspectionId, file, isFollowUp }) {
  return async (dispatch) => {
    const extraPayload = {
      inspectionId,
    };

    dispatch({
      type: `FOLLOW_UP_SUMMARY_UPLOAD_FILE_STARTED`,
      payload: extraPayload,
      meta: extraPayload,
    });

    try {
      const responseBody = await uploadAttachment({
        path: `/api/admin/inspections/${inspectionId}/follow-up-summary/file`,
        file,
        isFollowUp,
        dispatch,
      });
      dispatch({
        type: `FOLLOW_UP_SUMMARY_UPLOAD_FILE_DONE`,
        payload: responseBody.data,
        meta: extraPayload,
      });
    } catch (error) {
      if (error.message === 'canceled') {
        dispatch({
          type: `FOLLOW_UP_SUMMARY_UPLOAD_FILE_CANCELED`,
          payload: 'upload.canceled',
          meta: extraPayload,
          error: '',
        });
        return;
      }
      dispatch({
        type: `FOLLOW_UP_SUMMARY_UPLOAD_FILE_FAILED`,
        payload:
          error.response && error.response.data && error.response.data.msg,
        meta: extraPayload,
        error:
          error.response && error.response.data && error.response.data.errors,
      });
      return;
    }
  };
}
