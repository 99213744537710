import * as React from 'react';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';

export default class VirtualizedList extends React.PureComponent {

  cache = new CellMeasurerCache({
    defaultHeight: 24,
    fixedWidth: true
  });

  rowRenderer = ({ index, key, style, parent }) => {
    return (
      <CellMeasurer
        cache={this.cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <div key={key} style={style}>
          {this.props.renderRow(index)}
        </div>
      </CellMeasurer>
    );
  };

  render() {
    return (
      <AutoSizer onResize={() => this.cache.clearAll()}>
        {({ width, height }) => (
          <List
            rowCount={this.props.rowCount}
            width={width}
            height={height}
            rowHeight={this.cache.rowHeight}
            rowRenderer={this.rowRenderer}
            deferredMeasurementCache={this.cache}
            overscanRowCount={3}
          />
        )}
      </AutoSizer>
    );
  }
}
