import React, { useEffect, useState } from 'react';
import translate from 'utils/translate';
import LoadingOverlay from 'components/general/LoadingOverlay';
import Error from 'components/general/Error';
import GridRow from 'components/admin/general/GridRow';
import UserForm from 'components/general/UserForm';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import LinkToHotelDialog from './LinkToHotelDialog';
import InspectionTypes from './InspectionTypes';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Wrapper = styled.div`
  position: relative;
  min-height: 200px;
`;

const RowButton = styled(IconButton)`
  padding: 4px !important;
  margin: 4px !important;
`;

const StyledRow = styled(TableRow)`
  cursor: pointer;
`;

const HotelHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubmitButton = styled(Button)`
  margin-top: 10px !important;
  margin-bottom: 10px !important;
`;

export default function ManageUser({
  load,
  userId,
  organizationId,
  user,
  role,
  linkToHotel,
  unlinkFromHotel,
  linkToInspectionType,
  unlinkFromInspectionType,
  back,
  openHotel,
  submit,
  loading,
  error,
}) {
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  const [updatedUser, setUpdatedUser] = useState(user.toJS());

  useEffect(() => {
    if (userId > 0) {
      load(userId);
    }
  }, [load, userId]);

  useEffect(() => {
    setUpdatedUser(user.toJS());
  }, [user]);

  let form = null;
  let hotelTable = null;
  if (user.get('id') > 0) {
    form = (
      <>
        <UserForm
          minRole={role === 'superUser' ? 'deactivated' : null}
          maxRole={organizationId > 0 || role !== 'superUser' ? role : null}
          disabled={loading}
          user={updatedUser}
          onChange={(change) => setUpdatedUser(change)}
        />
        <SubmitButton
          variant='contained'
          color='primary'
          onClick={() => submit(updatedUser)}
        >
          {translate('users.edit.save')}
        </SubmitButton>
      </>
    );

    hotelTable = (
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>{translate('hotels.create.name')}</TableCell>
            <TableCell>{translate('hotels.create.address')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {user.get('hotels').map((hotel) => (
            <StyledRow
              key={hotel.get('name')}
              hover
              onClick={() => openHotel(hotel.get('id'))}
            >
              <TableCell>{hotel.get('name')}</TableCell>
              <TableCell>{hotel.get('address')}</TableCell>
              <TableCell style={{ width: '41px' }}>
                <Tooltip title={translate('users.edit.unlinkFromHotel')}>
                  <RowButton
                    size='medium'
                    onClick={(event) => {
                      event.stopPropagation();
                      unlinkFromHotel(userId, hotel.get('id'));
                    }}
                  >
                    <LinkOffIcon />
                  </RowButton>
                </Tooltip>
              </TableCell>
            </StyledRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return (
    <Wrapper>
      <LoadingOverlay show={loading} />
      {linkDialogOpen && (
        <LinkToHotelDialog
          open
          organizationId={organizationId}
          onClose={() => setLinkDialogOpen(false)}
          onSubmit={(hotelId) => {
            setLinkDialogOpen(false);
            linkToHotel(userId, hotelId);
          }}
        />
      )}
      <Button
        variant='outlined'
        color='secondary'
        onClick={back}
        startIcon={<ArrowBackIcon />}
      >
        {translate('back')}
      </Button>
      <Typography
        variant='h6'
        component='h1'
        gutterBottom
        align='center'
        color='secondary'
      >
        {translate('users.edit.header')}
      </Typography>
      {form}
      <GridRow>{error && <Error>{translate(error)}</Error>}</GridRow>
      {user.get('organizationId') && (
        <HotelHeader>
          <Typography variant='h6' component='h2'>
            {translate('hotels')}
          </Typography>
          <Button
            variant='outlined'
            color='primary'
            startIcon={<LinkIcon />}
            onClick={() => setLinkDialogOpen(true)}
            disabled={user.get('role') === 'deactivated'}
          >
            {translate('users.edit.linkToHotel')}
          </Button>
        </HotelHeader>
      )}
      {user.get('organizationId') && user.get('hotels').size > 0 && hotelTable}
      {user.get('organizationId') && user.get('hotels').size === 0 && (
        <Typography
          variant='body2'
          style={{ fontStyle: 'italic', marginTop: '5px' }}
        >
          {translate('users.edit.noHotels')}
        </Typography>
      )}
      <InspectionTypes
        user={user.toJS()}
        linkToInspectionType={linkToInspectionType}
        unlinkFromInspectionType={unlinkFromInspectionType}
      />
    </Wrapper>
  );
}
