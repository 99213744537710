import styled from 'styled-components';
import media from 'globals/media';
import colors from 'globals/colors';

export default styled.div`
  display: flex;
  align-items: center;
  button {
    flex: 1;
  }
  img {
    margin: 0 auto;
    flex-basis: 80%;
    flex-grow: 1;
    flex-shrink: 0;
    height: auto;
    max-width: 80%;
    display: block;
  }
  ${media.tablet`
    padding-bottom: 15px;
    border-bottom: 1px solid ${colors.borderGray}
  `};
  margin-bottom: 15px;
`;

export const SmallFileRow = styled.div`
  display: flex;
  align-items: center;
  img {
    height: auto;
    display: block;
  }
  ${media.tablet`
    padding-bottom: 15px;
    border-bottom: 1px solid ${colors.borderGray}
  `};
  margin-bottom: 15px;
`;
