export function copyValue(value, onCopied) {
  if (!value) {
    return;
  }
  const tempContainer = document.createElement('div');
  document.body.appendChild(tempContainer);
  tempContainer.innerText = value;

  window.getSelection().empty();
  const range = document.createRange();
  range.selectNode(tempContainer);
  window.getSelection().addRange(range);
  let success = false;

  try {
    success = document.execCommand('copy');
  } catch (error) {
    console.error(error);
  }
  document.body.removeChild(tempContainer);
  if (typeof onCopied === 'function') {
    onCopied(success);
  }
  window.getSelection().empty();
}
