import React, { useReducer } from 'react';
import translate from 'utils/translate';
import LoadingOverlay from 'components/general/LoadingOverlay';
import Error from 'components/general/Error';
import GridRow from 'components/admin/general/GridRow';
import InspectionForm from 'components/general/InspectionForm';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import reducer, { INITIAL_STATE } from './reducer';
import { createInspection } from './actions';

const Wrapper = styled.div`
  position: relative;
  min-height: 200px;
`;

const SubmitButton = styled(Button)`
  margin-top: 10px !important;
  margin-bottom: 10px !important;
`;

export default function InspectionCreate({ userId, role, onCreated }) {
  const [{ inspection, loading, error }, dispatch] = useReducer(
    reducer,
    INITIAL_STATE
  );

  return (
    <Wrapper>
      <LoadingOverlay show={loading} />
      <Typography variant='h5' component='h1' gutterBottom>
        {translate('inspections.create.header')}
      </Typography>
      <InspectionForm
        disabled={loading}
        inspection={inspection}
        onChange={({ inspection }) =>
          dispatch({
            type: 'CHANGE_INSPECTION',
            payload: inspection,
          })
        }
      />
      <SubmitButton
        variant='contained'
        color='primary'
        onClick={async () => {
          await createInspection({ ...inspection, user: userId }, dispatch);
          if (typeof onCreated === 'function') {
            onCreated();
          }
        }}
      >
        {translate('inspections.create')}
      </SubmitButton>
      <GridRow>{error && <Error>{translate(error)}</Error>}</GridRow>
    </Wrapper>
  );
}
