import {fromJS} from 'immutable';

const defaultState = fromJS({
  visible: false,
  loading: false,
  error: ''
});

export default function(state = defaultState, action = {type: ''}) {
  switch (action.type) {
    case 'DELETE_MODULE_TYPE_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'DELETE_MODULE_TYPE_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'DELETE_MODULE_TYPE_DONE': {
      return state.set('loading', false).set('visible', false);
    }
    case 'OPEN_DELETE_MODULE_TYPE_MODAL': {
      return state.set('visible', true);
    }
    case 'CLOSE_DELETE_MODULE_TYPE_MODAL': {
      return state.set('visible', false);
    }
    default: {
      return state;
    }
  }
}
