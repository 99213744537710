import { request } from 'actions/utils';

export default function ({ data }, languageCode, dispatch) {
  return request({
    action: 'UPDATE_QUESTION',
    path: `/api/admin/questions/${data.id}?languageShortCode=${
      languageCode || ''
    }`,
    method: 'PUT',
    body: data,
  })(dispatch);
}
