import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import translate from 'utils/translate';
import { getDaysData } from './utils';

export default function UnansweredDays({ week, hotel }) {
  const { unansweredDays } = getDaysData({ hotel, weeks: [week] });
  if (!unansweredDays.length) return null;

  return (
    <Typography gutterBottom>
      <strong>{translate('safeQReport.unansweredDays')}</strong>
      {': '}
      {unansweredDays
        .sort((a, b) => (a === 0 ? 1 : b === 0 ? -1 : a - b)) // Put Sunday last
        .map((dayNumber) => capitalize(moment().day(dayNumber).format('dddd')))
        .join(', ')}
    </Typography>
  );
}

function capitalize(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
