import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import Button from 'components/general/Button';
import inspectionModuleActions from 'actions/admin/inspectionModule';
import ModuleTypeForm from 'components/general/ModuleTypeForm';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

class CreateModal extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
    };
  }

  changeInput = (moduleType) => {
    this.setState(moduleType);
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      this.setState({
        name: '',
      });
    }
  }
  render() {
    const {
      visible,
      loading,
      error,
      create,
      closeModal,
      moduleTypeClassifications,
      moduleTypeAreas,
    } = this.props;
    if (!visible) {
      return null;
    }
    // return (
    //   <ModalWrapper onClose={closeModal}>
    //     <InnerModal
    //       onSubmit={(e) => {
    //         e.preventDefault();
    //         const { name } = this.state;
    //         create(name);
    //       }}
    //     >
    //       <h4>{translate('questions.createModuleType.header')}</h4>
    //       <Input
    //         type='text'
    //         value={this.state.name}
    //         onChange={this.changeName}
    //         placeholder={translate('questions.createModuleType.name')}
    //       />
    //       <Button>{translate('questions.createModuleType')}</Button>
    //       {loading && <Loader />}
    //       {error && <Error>{translate(error)}</Error>}
    //     </InnerModal>
    //   </ModalWrapper>
    // );
    return (
      <Dialog open={visible} onClose={closeModal}>
        <DialogTitle>
          {translate('questions.createModuleType.header')}
        </DialogTitle>
        <DialogContent>
          <ModuleTypeForm
            moduleType={this.state}
            classifications={moduleTypeClassifications}
            onChange={this.changeInput}
            areas={moduleTypeAreas}
          />
          {loading && <Loader />}
          {error && <Error>{translate(error)}</Error>}
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={closeModal}>
            {translate('cancel')}
          </Button>
          <Button color='primary' onClick={() => create(this.state)}>
            {translate('questions.createModuleType')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    visible: state.getIn([
      'admin',
      'inspectionModules',
      'moduleTypes',
      'createModal',
      'visible',
    ]),
    loading: state.getIn([
      'admin',
      'inspectionModules',
      'moduleTypes',
      'createModal',
      'loading',
    ]),
    error: state.getIn([
      'admin',
      'inspectionModules',
      'moduleTypes',
      'createModal',
      'error',
    ]),
    ...ownProps,
  }),
  (dispatch) => ({
    create: (moduleType) =>
      dispatch(inspectionModuleActions.createType(moduleType)),
    closeModal: () => dispatch(inspectionModuleActions.closeCreateTypeModal()),
  })
)(CreateModal);
