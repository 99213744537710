import { request } from 'actions/utils';

export function createDashboard(hotelId, dispatch) {
  return request({
    action: 'CREATE_DASHBOARD',
    path: `/api/dashboards?hotelId=${hotelId}`,
    method: 'POST',
  })(dispatch);
}

export function getDashboard(id, dispatch) {
  return request({
    action: 'GET_DASHBOARD',
    path: `/api/dashboards/${id}`,
    method: 'GET',
  })(dispatch);
}

export function saveDashboard(id, data, dispatch) {
  return request({
    action: 'SAVE_DASHBOARD',
    path: `/api/dashboards/${id}`,
    method: 'PUT',
    body: data,
  })(dispatch);
}

export function regenerateUuid(id, dispatch) {
  return request({
    action: 'REGENERATE_DASHBOARD_UUID',
    path: `/api/dashboards/${id}/regenerate`,
    method: 'PUT',
  })(dispatch);
}
