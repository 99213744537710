import React from 'react';
import styled from 'styled-components';
import { SELECTED_LANGUAGE } from 'globals/localStorage';
import { Button, ButtonGroup, Typography } from '@material-ui/core';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 5px;
`;

const userLocale = JSON.parse(localStorage.getItem(SELECTED_LANGUAGE));
const locale = userLocale && userLocale.code ? userLocale.code.replace('_', '-') : 'sv-SE';
const weekDays = getWeekDays();

export default function WeekDayPicker({ label, value = [], onChange, disabled }) {

  const toggleChange = (weekDay) => {
    const i = value.indexOf(weekDay);
    if (i > -1) {
      onChange(value.filter(day => day !== weekDay));
    } else {
      onChange(value.concat([weekDay]));
    }
  };

  return (
    <Wrapper>
      <Typography variant="body2" component="label">
        {label}
      </Typography>
      <ButtonGroup color="primary">
        {weekDays.map((nameOfDay, index) => {
          const isSelected = value.indexOf(index+1) > -1;
          return (
            <Button
              key={index}
              variant={isSelected ? 'contained' : undefined}
              onClick={() => !disabled && toggleChange(index+1)}
            >
              {nameOfDay}
            </Button>
          )
        })}
      </ButtonGroup>
    </Wrapper>
  );
}

function getWeekDays() {
    const baseDate = new Date(Date.UTC(2017, 0, 2)); // just a Monday
    let days = [];
    for (let i = 0; i < 7; i++) {     
        const nameOfDay = baseDate.toLocaleDateString(locale, { weekday: 'short' });
        days.push(nameOfDay.charAt(0).toUpperCase() + nameOfDay.slice(1));
        baseDate.setDate(baseDate.getDate() + 1);       
    }
    return days;
}