import { request } from 'actions/utils';

export default function setEndTime(inspectionId, revert = false) {
  return request({
    action: 'SET_ADMIN_END_TIME',
    method: 'POST',
    path: `/api/admin/inspections/${inspectionId}/endTime`,
    body: {
      revert,
    },
  });
}
