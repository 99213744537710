import React, { useEffect } from 'react';
import moment from 'moment';
import Wrapper from './styled/Wrapper';
import Header from './styled/Header';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import CreateModal from './CreateModal';
import GridRow from 'components/admin/general/GridRow';
import { breakpoints } from 'globals/media';
import { useScreenSize } from 'hooks/useScreenSize';
import { useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styled from 'styled-components';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';

const StyledRow = styled(TableRow)`
  cursor: pointer;
`;

export default function OrganizationAdmin({
  loadOrganizations,
  organizations,
  error,
  loading,
  open,
  openCreateModal,
}) {
  const theme = useTheme();
  const screenSize = useScreenSize();

  useEffect(() => {
    loadOrganizations();
  }, [loadOrganizations]);

  return (
    <>
      <Header>
        <div />
        <h1>{translate('organizations.administration')}</h1>
        <div className='actions'>
          <Zoom
            in={true}
            timeout={{
              enter: theme.transitions.duration.enteringScreen,
              exit: theme.transitions.duration.leavingScreen,
            }}
            unmountOnExit
          >
            <Fab
              variant={
                screenSize.width < breakpoints.tablet ? 'round' : 'extended'
              }
              style={{
                position:
                  screenSize.width < breakpoints.tablet ? 'fixed' : 'relative',
                bottom:
                  screenSize.width < breakpoints.tablet ? theme.spacing(2) : 0,
                right:
                  screenSize.width < breakpoints.tablet ? theme.spacing(2) : 0,
              }}
              color='primary'
              onClick={openCreateModal}
              label={translate('organizations.create')}
            >
              <AddIcon />
              {screenSize.width > breakpoints.tablet &&
                translate('organizations.create')}
            </Fab>
          </Zoom>
        </div>
      </Header>
      <Wrapper>
        <GridRow>
          {loading && <Loader />}
          {error && <Error>{translate(error)}</Error>}
        </GridRow>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>{translate('organizations.name')}</TableCell>
              <TableCell>{translate('created')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizations.map((organization) => (
              <StyledRow
                onClick={() => open(organization.get('id'))}
                key={organization.get('name')}
                hover
              >
                <TableCell>{organization.get('name')}</TableCell>
                <TableCell>
                  {moment(organization.get('createdAt')).format('YYYY-MM-DD')}
                </TableCell>
              </StyledRow>
            ))}
          </TableBody>
        </Table>
        <CreateModal />
      </Wrapper>
    </>
  );
}
