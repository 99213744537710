import React, { useState, useRef } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import translate from 'utils/translate';
import { useScreenSize } from 'hooks/useScreenSize';
import { Link } from 'react-router-dom';
import media, { breakpoints } from 'globals/media';
import urls from 'globals/urls';
import { useTheme } from '@material-ui/core/styles';
import sessionActions from 'actions/session';
import styled from 'styled-components';
import { adminMenuMap } from 'globals/adminMenuMap';
import Logo from 'components/general/Logo';
import LanguagePicker from 'components/general/LanguagePicker';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { SELECTED_LANGUAGE } from 'globals/localStorage';
import OrganizationLogo from 'components/general/OrganizationLogo';

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 40px !important;
`;

const AccountArea = styled.div`
  display: flex;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  cursor: pointer;
  margin-left: 7px;

  p {
    line-height: 1;
  }
  ${media.largeHandheld`
    p, h6 {
      font-size: 12px;
    }
  `}
  ${media.handheld`
    display: none;
  `}
`;

const StyledAppBar = styled(AppBar)`
  @media print {
    display: none !important;
  }
`;

const StyledDrawer = styled(Drawer)`
  @media print {
    display: none !important;
  }
`;

export function ResponsiveToolbar({ name, logout, role }) {
  const theme = useTheme();
  const userMenuAnchorEl = useRef(null);
  const mainMenuAnchorEl = useRef(null);
  const screenSize = useScreenSize();
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [adminMenuOpen, setAdminMenuOpen] = useState(false);

  const menuItems = adminMenuMap[role] || [];
  const isDesktop = screenSize.width >= breakpoints.desktop;
  const showAdminMenu = menuItems && menuItems.length !== 0;
  let currentLanguage = null;

  try {
    currentLanguage = JSON.parse(localStorage.getItem(SELECTED_LANGUAGE));
  } catch (e) {}

  return (
    <>
      <StyledAppBar
        position='fixed'
        style={{ zIndex: theme.zIndex.drawer + 1 }}
      >
        <Toolbar style={{ paddingLeft: '24px' }}>
          {!isDesktop && showAdminMenu && (
            <>
              <IconButton
                edge='start'
                color='inherit'
                aria-label='menu'
                ref={mainMenuAnchorEl}
                onClick={() => setAdminMenuOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            </>
          )}
          <Link
            to={urls.start}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <Logo width={'35'} height={'35'} inverse text={false} />
          </Link>
          <Typography variant='h6' style={{ flexGrow: 1, marginLeft: '7px' }}>
            <Link
              to={urls.start}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {screenSize.width < breakpoints.largeHandheld
                ? translate('site.shortName')
                : translate('site.name')}
            </Link>
          </Typography>
          <OrganizationLogo />
          <LanguagePicker
            value={currentLanguage}
            size='18px'
            onLanguageSelected={(language) => {
              localStorage.setItem(SELECTED_LANGUAGE, JSON.stringify(language));
              window.location.reload();
            }}
          />
          <AccountArea
            onClick={() => setUserMenuOpen(true)}
            innerRef={userMenuAnchorEl}
          >
            <IconButton color='inherit' size='small'>
              <AccountCircle />
            </IconButton>
            <UserInfo>
              <Typography>{name}</Typography>
              <Typography variant='subtitle2'>
                {translate(`users.edit.role.${role}`) || ''}
              </Typography>
            </UserInfo>
          </AccountArea>
          <Menu
            anchorEl={userMenuAnchorEl.current}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={userMenuOpen}
            onClose={() => setUserMenuOpen(false)}
          >
            <MenuItem onClick={logout}>{translate('logout')}</MenuItem>
          </Menu>
        </Toolbar>
      </StyledAppBar>
      {showAdminMenu && (
        <StyledDrawer
          variant={isDesktop ? 'permanent' : 'temporary'}
          anchor={'left'}
          open={isDesktop ? true : adminMenuOpen}
          onClose={() => setAdminMenuOpen(false)}
        >
          <div style={{ width: '210px' }}>
            {isDesktop && <Toolbar />}
            <Divider />
            <List>
              {menuItems.map((item) => (
                <Link key={item.url} to={item.url}>
                  <ListItem button onClick={() => setAdminMenuOpen(false)}>
                    {item.icon && (
                      <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                    )}
                    <ListItemText>{item.label}</ListItemText>
                  </ListItem>
                </Link>
              ))}
            </List>
          </div>
        </StyledDrawer>
      )}
    </>
  );
}

export default connect(
  (state) => ({
    role: state.getIn(['currentUser', 'role']),
    name: state.getIn(['currentUser', 'name']),
  }),
  (dispatch) => ({
    logout: () => dispatch(sessionActions.logout()),
  })
)(ResponsiveToolbar);
