export function withinBounds({ value, min, max }) {
  const number = parseInt(value, 10);
  if (isNaN(number)) {
    return min;
  }

  if (number < min) {
    return min;
  }

  if (number > max) {
    return max;
  }

  return Math.floor(number);
}
