import styled from 'styled-components';
import colors from 'globals/colors';
import media from 'globals/media';

export default styled.div`
  margin-top: 5px;
  margin-bottom: ${({ slim }) => (slim ? '0' : '2rem')};
  position: relative;
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
  justify-content: ${({ flex }) => (flex ? 'space-between' : 'initial')};
  align-items: ${({ flex, column }) =>
    column ? 'flex-start' : flex ? 'center' : 'initial'};
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};

  ${media.tablet`
    flex-direction: column;
    padding-bottom: 10px;
    border-bottom: ${({ hideBorder }) =>
      hideBorder ? 'none' : `1px solid ${colors.borderGray}`}
  `};
`;
