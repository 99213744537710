import React from 'react';
import styled from 'styled-components';
import colors from 'globals/colors';
import Fade from '@material-ui/core/Fade';

const Container = styled.div`
  width: ${({ autoWidth }) => (autoWidth ? 'auto' : '300px')};
  background-color: ${colors.errorRed};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 3px;
  margin: 10px 0;
  overflow: hidden;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
  pointer-events: ${(props) => (props.show ? 'all' : 'none')};
  opacity: ${(props) => (props.show ? '1' : '0')};
  min-height: 19px;
`;

export default function Error({
  show,
  children,
  style,
  className,
  autoWidth = true,
}) {
  show = show === undefined || show;
  return (
    <Fade in={show}>
      <Container
        style={style}
        className={className}
        show={show}
        autoWidth={autoWidth}
      >
        {children}
      </Container>
    </Fade>
  );
}
