import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 20px auto 0 auto;
  display: grid;
  grid-template-columns: 1fr repeat(3, 70px);
  min-width: 280px;
  max-width: 60rem;
  width: 70%;
  white-space: nowrap;
  grid-gap: 20px;
`;

export const Hotel = styled.div`
  padding: 5px;
  font-size: 14px;
  grid-column: 1;
`;
