import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import translate from 'utils/translate';
import { DialogContent, TextField, Tooltip } from '@material-ui/core';
import { DialogActions, IconButton } from './styles';

export default function FollowUpCommentDialog({
  className,
  question,
  setFollowUp,
}) {
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState(
    question.getIn(['answer', 'followUpComment'])
  );

  const questionId = question.get('id');
  const needsFollowUp = question.getIn(['answer', 'needsFollowUp']) || '';
  const followUpComment = question.getIn(['answer', 'followUpComment']) || '';
  const hasComment = followUpComment.length > 0;
  const tooltip = hasComment
    ? followUpComment
    : translate('questions.followUp.followUpComment');

  const save = () => {
    setFollowUp({
      questionId: questionId,
      needsFollowUp: true,
      followUpComment: comment,
    });
    setOpen(false);
  };

  const cancel = () => {
    setOpen(false);
    setComment(question.getIn(['answer', 'followUpComment']));
  };

  if (needsFollowUp) {
    return (
      <div className={className}>
        <Tooltip title={tooltip} placement={'right'}>
          <IconButton
            aria-label='edit'
            color='primary'
            onClick={() => setOpen(true)}
          >
            {hasComment ? <EditIcon /> : <EditOutlinedIcon />}
          </IconButton>
        </Tooltip>

        <Dialog maxWidth={'lg'} fullWidth={true} open={open}>
          <DialogTitle>
            {translate('questions.followUp.followUpComment')}
          </DialogTitle>
          <DialogContent dividers>
            <TextField
              multiline
              rows={8}
              fullWidth
              variant={'outlined'}
              value={comment}
              onChange={(event) => setComment(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => cancel()}>
              {translate('cancel')}
            </Button>
            <Button autoFocus onClick={() => save()} color='primary'>
              {translate('save')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  return <div className={className}></div>;
}
