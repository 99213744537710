import React, {Component} from 'react';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import Input from 'components/general/Input';
import translate from 'utils/translate';
import Button from 'components/general/Button';
import localReducer from './reducer';
import initializeState from './initializeState';
import answer from './answer';
import {Question, Wrapper} from './styled';
import _get from 'lodash/get';

export default class ContextQuestions extends Component {
  constructor() {
    super();
    this.state = localReducer();
  }
  dispatch = action => {
    this.setState(prevState => localReducer(prevState, action));
  };
  answer = async ({questionId, content}) => {
    const {moduleId, inspectionId} = this.props;
    await answer({content, moduleId, inspectionId, questionId}, this.dispatch);
  };
  changeInput = payload =>
    this.dispatch({
      type: 'CHANGE_INPUT',
      payload
    });

  componentDidMount() {
    initializeState(this.props, this.dispatch);
  }
  render() {
    const {loading, error, questions, serverQuestions} = this.state;
    if (questions.length === 0) {
      return null;
    }
    const domQuestions = questions.map(({id, answer = {}, content}, index) => {
      const serverValue = _get(
        serverQuestions,
        [index, 'answer', 'content'],
        ''
      );
      answer.content = answer.content || '';
      const dirty = serverValue !== answer.content;
      return (
        <form
          key={id}
          onSubmit={e => {
            e.preventDefault();
            this.answer({questionId: id, content: answer.content});
          }}
        >
          <Question>{content}</Question>
          <Input
            type='text'
            value={answer.content}
            onChange={e => this.changeInput({index, value: e.target.value})}
          />
          {dirty && (
            <Button small marginLeft='10px'>
              {translate('save')}
            </Button>
          )}
        </form>
      );
    });
    return (
      <Wrapper>
        {domQuestions}
        {loading && <Loader />}
        {error && <Error>{translate(error)}</Error>}
      </Wrapper>
    );
  }
}
