import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js';
import { lighten, getLuminance, darken } from '@material-ui/core/styles';
import translate from 'utils/translate';

export default function PerformanceChart({
  labels,
  data,
  backgroundColor,
  language,
}) {
  const canvas = useRef(null);

  useEffect(() => {
    if (!canvas.current) {
      return;
    }
    const axisTextColor =
      backgroundColor &&
      (getLuminance(backgroundColor) < 0.5
        ? lighten(backgroundColor, 0.8)
        : darken(backgroundColor, 0.8));

    const ctx = canvas.current.getContext('2d');

    const datasets = Object.entries(data).map(([key, lineData], i, self) => {
      return {
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: '#a746d6',
        pointBackgroundColor: '#a746d6',
        label: key,
        pointRadius: 0,
        data: lineData,
        lineTension: false,
        borderWidth: 3,
      };
    });

    new Chart(ctx, {
      type: 'line',
      data: {
        datasets,
        labels: labels || Object.values(data)[0].map((d) => d.x),
      },
      options: {
        maintainAspectRatio: false,
        title: {
          display: false,
        },
        events: [],
        legend: false,
        scales: {
          xAxes: [
            {
              type: 'time',
              ticks: {
                fontColor: axisTextColor,
              },
              time: {
                unit: 'week',
                displayFormats: {
                  week: `[${translate(
                    'dashboards.view.weekPrefix',
                    language
                  )}].w`,
                },
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                stepSize: 20,
                max: 110,
                min: 0,
                callback: (value) => {
                  if (value > 100) return '';
                  return value + '%';
                },
              },
            },
          ],
        },
        chartArea: {},
      },
    });
  });

  return <canvas ref={canvas} />;
}
