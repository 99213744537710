import {request} from 'actions/utils';

export default function setModuleData({inspectionId, moduleId, comments}) {
  return request({
    action: 'SET_MODULE_DATA',
    path: `/api/inspections/${inspectionId}/modules/${moduleId}/general-comments`,
    method: 'POST',
    body: comments,
    extraPayload: {
      moduleId
    }
  });
}
