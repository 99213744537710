import { fromJS } from 'immutable';
import currentUser from './currentUser';
import admin from './admin';
import inspections from './inspections';
import organization from './organization';
import hotel from './hotel';
import user from './user';
import fileUpload from './fileUpload';
import { connectRouter } from 'connected-react-router/immutable';

export default function (history) {
  const routerReducer = connectRouter(history);

  const defaultState = fromJS({
    currentUser: currentUser(),
    admin: admin(),
    inspections: inspections(),
    organization: organization(),
    hotel: hotel(),
    router: routerReducer(),
    fileUpload: fileUpload(),
  });

  return (state = defaultState, action = { type: '' }) => {
    if (action.type === 'LOGOUT_DONE') {
      return defaultState;
    }
    state = state.set(
      'currentUser',
      currentUser(state.get('currentUser'), action)
    );
    state = state.set(
      'inspections',
      inspections(state.get('inspections'), action)
    );
    state = state.set('admin', admin(state.get('admin'), action));
    state = state.set(
      'organization',
      organization(state.get('organization'), action)
    );
    state = state.set('hotel', hotel(state.get('hotel'), action));
    state = state.set('user', user(state.get('user'), action));
    state = state.set('router', routerReducer(state.get('router'), action));
    state = state.set(
      'fileUpload',
      fileUpload(state.get('fileUpload'), action)
    );
    return state;
  };
}
