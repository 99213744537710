import { connect } from 'react-redux';
import Manage from './Manage';
import hotelActions from 'actions/hotel';
import { push } from 'connected-react-router';
import urls from 'globals/urls';

function mapStateToProps(state, ownProps) {
  return {
    role: state.getIn(['currentUser', 'role']),
    error: state.getIn(['hotel', 'error']),
    loading: state.getIn(['hotel', 'loading']),
    hotel: state.getIn(['hotel', 'current']),
    hotelId: parseInt(ownProps.match.params.hotelId),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    back: () => {
      if (ownProps.match.params.organizationId) {
        dispatch(
          push({
            pathname: urls.organizationStart.replace(
              ':id',
              ownProps.match.params.organizationId
            ),
            search: `?tab=hotel`,
          })
        );
        return;
      }
      dispatch(
        push({
          pathname: urls.organizationHotels,
        })
      );
    },
    load: () => {
      dispatch(hotelActions.loadHotel(ownProps.match.params.hotelId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Manage);
