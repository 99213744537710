export default function initializeState(props, dispatch) {
  // Did not want to rewrite entire component to be immutable-based. This is a potential performance hit.
  const jsonQuestions = props.questions.toJS();
  dispatch({
    type: 'INITIALIZE_FROM_PROPS',
    payload: {
      ...props,
      questions: jsonQuestions
    }
  });
}
