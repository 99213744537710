import { connect } from 'react-redux';
import InspectionModulePicker from './InspectionModulePicker';
import inspectionActions from 'actions/inspection';
import { openComfortZoneModal } from 'actions/inspectionQuestions';
import { List } from 'immutable';
import { push } from 'connected-react-router';
import urls from 'globals/urls';

function mapStateToProps(state, ownProps) {
  const id = parseInt(ownProps.match.params.id, 10);
  return {
    inspectionModules:
      state.getIn(['inspections', 'current', 'modules']) || List(),
    id,
    inspectionType: state.getIn([
      'inspections',
      'current',
      'inspectionType',
      'name',
    ]),
    hotelName: state.getIn([
      'inspections',
      'current',
      'inspectionType',
      'hotel',
      'name',
    ]),
    lastEditedByUser: state.getIn([
      'inspections',
      'current',
      'lastEditedByUser',
      'name',
    ]),
    allowUnansweredQuestions: state.getIn([
      'inspections',
      'current',
      'inspectionType',
      'allowUnansweredQuestions',
    ]),
    extraQuestions:
      state.getIn(['inspections', 'current', 'extraQuestions']) || List(),
    inspectionDescriptions: state.getIn([
      'inspections',
      'current',
      'inspectionDescriptions',
    ]),
    reportType: state.getIn([
      'inspections',
      'current',
      'inspectionType',
      'reportType',
    ]),
    loading: state.getIn(['inspections', 'loading']),
    error: state.getIn(['inspections', 'error']),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const id = parseInt(ownProps.match.params.id, 10);

  return {
    loadInspection: () => dispatch(inspectionActions.load(id)),
    openSubmitModal: () => dispatch(inspectionActions.openSubmitModal()),
    openExternalScoresModal: () =>
      dispatch(inspectionActions.openExternalScoresModal()),
    openGeneralCommentsModal: () =>
      dispatch(inspectionActions.openGeneralCommentsModal()),
    openComfortZoneModal: () => dispatch(openComfortZoneModal(id)),
    goBack: () => dispatch(push(urls.start)),
    onSaveComments: () => dispatch(inspectionActions.load(id)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InspectionModulePicker);
