import React from 'react';
import { Route, Switch } from 'react-router-dom';
import urls from 'globals/urls';
import InspectionModulePicker from 'components/inspection/ModulePicker/Container';
import InspectionPicker from 'components/inspection/Picker/Container';
import InspectionCreate from 'components/inspection/Create/Container';
import InspectionQuestions from 'components/inspection/Questions/RegularInspectionQuestions';
import ExtraInspectionQuestions from 'components/inspection/Questions/ExtraInspectionQuestions';
import InspectionControl from 'components/inspection/Control/Container';
import StepsIndicator from 'components/general/StepsIndicator';

export default function InspectionRoutes({ isAdmin }) {
  return (
    <div>
      <Route component={StepsIndicator} path='/' />
      <Route exact path='/' component={InspectionPicker} key='/' />
      <Switch>
        <Route
          exact
          path={urls.inspectionsCreate}
          component={InspectionCreate}
          key={urls.inspectionsCreate}
        />
        <Route
          exact
          path={urls.inspectionStart}
          component={InspectionModulePicker}
          key={urls.inspectionStart}
        />
        <Route
          path={urls.start}
          component={InspectionPicker}
          key={urls.start}
        />
        <Route
          path={urls.extraInspectionQuestions}
          component={ExtraInspectionQuestions}
          key={urls.extraInspectionQuestions}
        />
        <Route
          path={urls.inspectionQuestions}
          component={InspectionQuestions}
          key={urls.inspectionQuestions}
        />
        <Route
          path={urls.inspectionControl}
          component={InspectionControl}
          key={urls.inspectionControl}
        />
      </Switch>
    </div>
  );
}
