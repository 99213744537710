import {request} from 'actions/utils';

export default function setComment({inspectionId, questionId, moduleId, comment}) {
  return async dispatch => {
    await dispatch(
      request({
        action: 'SET_COMMENT',
        path: `/api/inspections/${inspectionId}/modules/${moduleId}/questions/${questionId}/comment`,
        method: 'POST',
        body: {comment},
        extraPayload: {
          questionId,
          moduleId
        }
      })
    );
  };
}
