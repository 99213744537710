import React, { Component } from 'react';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import Button from '@material-ui/core/Button';
import localReducer from './reducer';
import update from './update';
import ModuleTypeForm from 'components/general/ModuleTypeForm';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

export default class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = localReducer(undefined, {
      type: 'INITIALIZE_FROM_PROPS',
      payload: {},
    });
  }
  dispatch = (action) => {
    this.setState((prevState) => localReducer(prevState, action));
  };
  update = async () => {
    const success = await update(
      { data: this.state.moduleType },
      this.dispatch
    );
    if (success) {
      this.props.closeModal();
    }
  };
  changeInput = (payload) =>
    this.dispatch({
      type: 'CHANGE_INPUT',
      payload,
    });

  render() {
    const {
      closeModal,
      moduleTypeClassifications,
      moduleTypeAreas,
      moduleType,
      visible,
    } = this.props;
    const { loading, error } = this.state;

    return (
      <Dialog open={visible} onClose={closeModal}>
        <DialogTitle>
          {translate('questions.editModuleType.header')}
        </DialogTitle>
        <DialogContent>
          <ModuleTypeForm
            moduleType={{
              ...moduleType,
              ...this.state.moduleType,
            }}
            classifications={moduleTypeClassifications}
            onChange={this.changeInput}
            areas={moduleTypeAreas}
          />
          {loading && <Loader />}
          {error && <Error>{translate(error)}</Error>}
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={closeModal}>
            {translate('cancel')}
          </Button>
          <Button color='primary' onClick={this.update}>
            {translate('questions.editModuleType.button')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
