import { useEffect } from 'react';

export default function useAutoRefresh(callback, interval) {
  useEffect(() => {
    const t = setInterval(callback, interval);

    return () => {
      clearInterval(t);
    };
  }, [callback, interval]);
}
