import load from './load';
import {request} from 'actions/utils';

export default function createQuestion({
  name,
  moduleTypeId
}) {
  return async dispatch => {
    let success = await dispatch(request({
      action: 'CREATE_QUESTION_CLUSTER',
      path: `/api/admin/questions/${moduleTypeId}/clusters`,
      method: 'POST',
      body: {
        name,
        moduleTypeId
      }
    }));
    if (success) {
      dispatch(load(moduleTypeId));
    }
  };
}
