import React from 'react';
import ActionPlanModule from './ActionPlanModule';
import Print from 'components/general/Print';
import PrintedReport from './PrintedReport';
import ComfortZones from './ComfortZones';
import { moduleMatches, MODULE_NAME_PREFIXES } from '../utils';
import { findClusters } from './utils.js';
import { getFollowUp } from 'utils/inspection';
import EditableComment from 'components/inspection/Questions/QuestionRow/Comment';
import translate from 'utils/translate';
import { connect } from 'react-redux';
import { UploadButton } from './styled';
import {
  FileUpload,
  SmallFileRow,
} from 'components/inspection/Questions/QuestionRow/styled';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import followUpSummaryActions from 'actions/inspection/followUpSummary';
import DeleteButton from 'components/general/DeleteButton';
import FileLink from 'components/general/FileLink';
import isImagePath from 'utils/isImagePath';
import { List, Map } from 'immutable';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styled from 'styled-components';
const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 0 !important;
  margin-bottom: 10px;
  margin-top: 10px;

  > span {
    margin-left: 7px;
  }
`;

function mapDispatchToProps(dispatch, ownProps) {
  return {
    setFollowUpSummary: (followUp) => {
      dispatch(followUpSummaryActions.set(followUp));
    },
    uploadFile: ({ file }) => {
      dispatch(
        followUpSummaryActions.uploadFile({
          inspectionId: ownProps.report.get('id'),
          file,
          isFollowUp: true,
        })
      );
    },
    removeFile: ({ fileId }) => {
      dispatch(
        followUpSummaryActions.deleteFile({
          inspectionId: ownProps.report.get('id'),
          fileId,
        })
      );
    },
  };
}

function ActionPlan({ report, setFollowUpSummary, uploadFile, removeFile }) {
  if (report) {
    const modules = report.get('modules');
    const followUpArray = getFollowUp(modules);
    const inspectionId = report.get('id');
    const comfortZones = [
      { name: 'bi', value: report.get('bi') },
      { name: 'bs', value: report.get('bs') },
    ];

    return (
      <>
        <Print show={false}>
          <div>
            <EditableComment
              label={translate('questions.followUp.summary')}
              comment={report.getIn(['followUpSummary', 'content'])}
              multiline
              minmize={false}
              variant='filled'
              fullWidth
              setComment={(newComment) =>
                setFollowUpSummary({
                  inspectionId,
                  summary: newComment,
                })
              }
            />
            <StyledFormControlLabel
              label={translate('inspectionQuestions.uploadFile')}
              control={
                <UploadButton component='label' label='Test'>
                  <FileUpload
                    type='file'
                    name='pic'
                    onChange={(event) => {
                      const file = event.target.files[0];
                      uploadFile({
                        file,
                      });
                    }}
                  />
                  <AttachFileIcon />
                </UploadButton>
              }
            />
            {(
              report.getIn(['followUpSummary', 'attachments'], List()).toJS() ||
              []
            )
              .filter((file) => file.isFollowUp)
              .map((file) => (
                <SmallFileRow>
                  {isImagePath(file.path) ? (
                    <img src={`/files/${file.path}`} alt='' width='300px' />
                  ) : (
                    <FileLink
                      path={file.path}
                      name={file.originalName || file.path}
                    />
                  )}
                  <DeleteButton
                    onClick={() => {
                      removeFile({
                        fileId: file.id,
                      });
                    }}
                  >
                    {translate('inspectionQuestions.deleteFile')}
                  </DeleteButton>
                </SmallFileRow>
              ))}
            {followUpArray.map((module, i) => (
              <>
                <ActionPlanModule
                  key={i}
                  inspectionId={inspectionId}
                  followUpModule={module}
                  originalClusters={findClusters(modules, module.id)}
                />
                {moduleMatches(
                  module.name,
                  MODULE_NAME_PREFIXES.KEY_INFORMATION
                ) && (
                  <ComfortZones
                    inspectionId={inspectionId}
                    zones={comfortZones}
                  />
                )}
              </>
            ))}
          </div>
        </Print>
        <Print show>
          <PrintedReport
            summary={(report.getIn(['followUpSummary']) || Map()).toJS()}
            modules={followUpArray}
            comfortZones={comfortZones}
            originalModules={modules}
          />
        </Print>
      </>
    );
  }
  return null;
}

export default connect(null, mapDispatchToProps)(ActionPlan);
