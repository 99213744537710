import { fromJS } from 'immutable';

const defaultState = fromJS({
  visible: false,
});

export default function (state = defaultState, action = { type: '' }) {
  switch (action.type) {
    case 'OPEN_ERROR_MODAL': {
      return state.set('visible', true);
    }
    case 'CLOSE_ERROR_MODAL': {
      return state.set('visible', false);
    }
    default: {
      return state;
    }
  }
}
