import { connect } from 'react-redux';
import Manage from './Manage';
import userActions from 'actions/admin/user';
import hotelActions from 'actions/admin/hotel';
import { push } from 'connected-react-router';
import urls from 'globals/urls';
import qs from 'qs';

function mapStateToProps(state, ownProps) {
  const queryStringTab = qs.parse(ownProps.location.search.substring(1)).tab;

  return {
    error: state.getIn(['organization', 'error']),
    loading: state.getIn(['organization', 'loading']),
    organization: state.getIn(['organization', 'current']),
    organizationId:
      ownProps.variant === 'both' ? parseInt(ownProps.match.params.id) : null,
    queryStringTab,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onTabChange: (newTab) => {
      dispatch(
        push({
          pathname: urls.organizationStart.replace(
            ':id',
            ownProps.match.params.id
          ),
          search: `?tab=${newTab}`,
        })
      );
    },
    openUser: (userId) => dispatch(userActions.openView(userId)),
    openCreateUserModal: (organizationId) =>
      dispatch(userActions.openCreateModal(organizationId)),
    openCreateHotelModal: (organizationId) =>
      dispatch(hotelActions.openCreateModal(organizationId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Manage);
