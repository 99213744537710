import { connect } from 'react-redux';
import userActions from 'actions/admin/user';
import UserFormModal from './UserFormModal';

export default connect(
  (state) => ({
    visible: state.getIn(['admin', 'users', 'editModal', 'visible']),
    loading: state.getIn(['admin', 'users', 'editModal', 'loading']),
    error: state.getIn(['admin', 'users', 'editModal', 'error']),
    role: state.getIn(['currentUser', 'role']),
    user: state.getIn(['admin', 'users', 'editModal', 'user']),
    creating: false,
  }),
  (dispatch) => ({
    submit: (user) => dispatch(userActions.editUser(user, true)),
    closeModal: () => dispatch(userActions.closeEditModal()),
  })
)(UserFormModal);
