const INITIAL_STATE = {
  name: '',
  ordinal: 0,
  isMandatory: false,
  isDefaultNA: false,
  inspectionTypeId: 0,
  id: 0,
  loading: false,
  error: ''
};

export default function reducer(state = INITIAL_STATE, action = {type: ''}) {
  switch (action.type) {
    case 'INITIALIZE_FROM_PROPS': {
      const {module} = action.payload;
      return {...state, ...module};
    }
    case 'UPDATE_MODULE_STARTED': {
      return {...state, loading: true, error: ''};
    }
    case 'UPDATE_MODULE_FAILED': {
      return {...state, loading: false, error: action.payload};
    }
    case 'UPDATE_MODULE_DONE': {
      return {...state, loading: false};
    }
    case 'CHANGE_INPUT': {
      const {field, value} = action.payload;
      return {...state, [field]: value};
    }
    default:
      return state;
  }
}
