import React from 'react';
import styled from 'styled-components';
import colors from 'globals/colors';

export const ScoreWrapper = styled.div`
  width: 200px;
`;

export const IconWrapper = styled.div`
  margin: 10px;
  display: flex;
  justify-content: space-around;
`;
const buttonSize = '25px';

export const ScoreButton = styled.button`
  outline: none;
  border-radius: 50%;
  border: 1px solid ${colors.borderGray};
  width: ${buttonSize};
  height: ${buttonSize};
  background: ${props => props.selected || ''};
  cursor: pointer;
`;

const SvgWrapper = ScoreButton.extend`
  border: none;
  fill: ${props => props.selected || colors.borderGray};
  margin-right: 15px;
  padding: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 100%;
    height: 100%;
  }
`;

function InspectedSvg() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
      <path d='M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z' />
    </svg>
  );
}

export const InspectedIcon = SvgWrapper.extend.attrs({
  children: <InspectedSvg />
})`
  border-color: ${props => props.selected || colors.borderGray};
  color: ${props => props.selected || colors.borderGray};
  margin-right: 15px;
  background: none;
`;

function SkipSvg() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
      <path d='M4.93 19.07A10 10 0 1 1 19.07 4.93 10 10 0 0 1 4.93 19.07zm1.41-1.41A8 8 0 1 0 17.66 6.34 8 8 0 0 0 6.34 17.66zM13.41 12l1.42 1.41a1 1 0 1 1-1.42 1.42L12 13.4l-1.41 1.42a1 1 0 1 1-1.42-1.42L10.6 12l-1.42-1.41a1 1 0 1 1 1.42-1.42L12 10.6l1.41-1.42a1 1 0 1 1 1.42 1.42L13.4 12z' />
    </svg>
  );
}

export const SkipIcon = SvgWrapper.extend.attrs({
  children: <SkipSvg />
})`
  margin-right: 5px;
`;
