import { fromJS } from 'immutable';

const defaultState = fromJS({
  id: '',
  name: '',
  email: '',
  role: '',
  login: {
    loading: false,
    error: '',
    triedLogin: false,
  },
});

export default function (state = defaultState, action = { type: '' }) {
  switch (action.type) {
    case 'LOGIN_STARTED': {
      return state
        .setIn(['login', 'loading'], true)
        .setIn(['login', 'error'], '');
    }
    case 'LOGIN_FAILED': {
      return state
        .setIn(['login', 'loading'], false)
        .setIn(['login', 'error'], action.payload);
    }
    case 'LOGIN_DONE': {
      return state
        .set('id', action.payload.user.id)
        .set('name', action.payload.user.name)
        .set('email', action.payload.user.email)
        .set('role', action.payload.user.role)
        .setIn(['login', 'loading'], false);
    }
    case 'SESSION_CHECK_DONE': {
      return state
        .set('id', action.payload.user.id)
        .set('name', action.payload.user.name)
        .set('email', action.payload.user.email)
        .set('role', action.payload.user.role)
        .setIn(['login', 'triedLogin'], false);
    }
    case 'SESSION_CHECK_FAILED': {
      return state.setIn(['login', 'triedLogin'], true);
    }
    default: {
      return state;
    }
  }
}
