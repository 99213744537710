import React from 'react';
import { connect } from 'react-redux';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import ModalWrapper from 'components/general/ModalWrapper';
import InnerModal from 'components/general/InnerModal';
import Button from 'components/general/Button';
import inspectionTypeActions from 'actions/admin/inspectionType';

function DeleteModal({
  visible,
  loading,
  error,
  remove,
  closeModal,
  inspectionType,
  hotelId,
}) {
  if (!visible) {
    return null;
  }
  return (
    <ModalWrapper onClose={closeModal}>
      <InnerModal
        onSubmit={(e) => {
          e.preventDefault();
          remove({
            id: inspectionType.get('id'),
            hotelId,
          });
        }}
      >
        <h4>{translate('inspectionTypes.delete.header')}</h4>
        <div>
          {translate('inspectionTypes.delete.confirmText').replace(
            '{name}',
            inspectionType.get('name')
          )}
        </div>
        <Button>{translate('inspectionTypes.delete')}</Button>
        {loading && <Loader />}
        {error && <Error>{translate(error)}</Error>}
      </InnerModal>
    </ModalWrapper>
  );
}

export default connect(
  (state, ownProps) => ({
    visible: state.getIn([
      'admin',
      'inspectionTypes',
      'deleteModal',
      'visible',
    ]),
    loading: state.getIn([
      'admin',
      'inspectionTypes',
      'deleteModal',
      'loading',
    ]),
    error: state.getIn(['admin', 'inspectionTypes', 'deleteModal', 'error']),
    inspectionType: state.getIn([
      'admin',
      'inspectionTypes',
      'deleteModal',
      'inspectionType',
    ]),
    ...ownProps,
  }),
  (dispatch, ownProps) => ({
    remove: ({ id }) =>
      dispatch(inspectionTypeActions.remove(id, ownProps.onDeleted)),
    closeModal: () => dispatch(inspectionTypeActions.closeDeleteModal()),
  })
)(DeleteModal);
