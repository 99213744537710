export async function resetPassword({ token, password }) {
  let response = null;
  let body = null;

  try {
    response = await fetch(`/api/sessions/reset-password`, {
      method: 'POST',
      body: JSON.stringify({
        token,
        password,
      }),
      headers: {
        'content-type': 'application/json',
      },
      credentials: 'same-origin',
    });
    body = await response.json();
  } catch (error) {
    console.error(error);
    throw new Error('errors.server');
  }

  if (response.status !== 200) {
    throw new Error((body && body.msg) || '');
  }

  return body;
}
