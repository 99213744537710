import { connect } from 'react-redux';
import InspectionQuestions from './InspectionQuestions';
import inspectionQuestionActions from 'actions/inspectionQuestions';
import inspectionQuestionsActions from 'actions/inspectionQuestions';
import inspectionActions from 'actions/inspection';
import { List, Map } from 'immutable';
const {
  load,
  openComfortZoneModal,
  openConfirmFinishModal,
  openErrorModal,
  finishModule,
} = inspectionQuestionActions;

function mapStateToProps(state, ownProps) {
  const inspectionId = parseInt(ownProps.match.params.id, 10);
  const moduleId = ownProps.match.params.moduleId;

  const useTabs = (
    (state.getIn(['inspections', 'current', 'modules']) || List()).find(
      (m) => String(m.get('id')) === moduleId
    ) || Map()
  ).get('useTabs');

  return {
    userRole: state.getIn(['currentUser', 'role']),
    inspectionId,
    moduleId,
    isFollowUpInspection: !!state.getIn([
      'inspections',
      'current',
      'followUpToInspectionId',
    ]),
    reportType: state.getIn([
      'inspections',
      'current',
      'inspectionType',
      'reportType',
    ]),
    moduleName: state.getIn([
      'inspections',
      'questions',
      'modules',
      moduleId,
      'list',
      0,
      'moduleName',
    ]),
    useTabs,
    clusters: state.getIn(
      ['inspections', 'questions', 'modules', moduleId, 'list'],
      List()
    ),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const inspectionId = parseInt(ownProps.match.params.id, 10);
  const moduleId = ownProps.match.params.moduleId;

  return {
    loadInspectionQuestions: (inspectionId, moduleId) =>
      dispatch(load(inspectionId, moduleId)),
    openComfortZoneModal: (id) => dispatch(openComfortZoneModal(id)),
    openConfirmFinishModal: (unansweredQuestions) =>
      dispatch(openConfirmFinishModal(unansweredQuestions)),
    openErrorModal: () => dispatch(openErrorModal()),
    finishModule: ({ inspectionId, moduleId }) =>
      dispatch(finishModule({ inspectionId, moduleId })),
    goBack: (id) => dispatch(inspectionActions.openInspection(id)),
    saveClusterComments: (data) =>
      dispatch(inspectionQuestionActions.saveClusterComments(data)),
    setScore: ({ questionId, score, comment }) => {
      dispatch(
        inspectionQuestionsActions.setScore({
          inspectionId,
          questionId,
          moduleId,
          score,
          comment,
        })
      );
    },
    setComment: ({ questionId, comment }) =>
      dispatch(
        inspectionQuestionsActions.setComment({
          inspectionId,
          questionId,
          moduleId,
          comment,
        })
      ),
    uploadFile: ({ questionId, file }) => {
      dispatch(
        inspectionQuestionsActions.uploadFile({
          inspectionId,
          moduleId,
          questionId,
          file,
        })
      );
    },
    deleteFile: ({ questionId, fileId }) => {
      dispatch(
        inspectionQuestionsActions.deleteFile({
          inspectionId,
          moduleId,
          questionId,
          fileId,
        })
      );
    },
    unhideQuestion: ({ questionId }) => {
      dispatch(
        inspectionQuestionsActions.setScore({
          inspectionId,
          questionId,
          moduleId,
          score: null,
        })
      );
    },
    validateModule: ({ moduleId, errors }) => {
      dispatch({
        type: 'SET_VALIDATION_ERRORS',
        meta: {
          moduleId,
          errors,
        },
      });
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InspectionQuestions);
