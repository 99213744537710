import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import Button from '@material-ui/core/Button';
import questionActions from 'actions/admin/question';
import { classifications } from 'globals/classifications';
import QuestionForm from 'components/general/QuestionForm';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

class CreateModal extends Component {
  constructor(props) {
    super();
    this.state = {
      content: '',
      referenceNumber: '',
      classification: classifications[0],
      questionClusterId: -1,
      notifyHotelResponsible: false,
      isMandatory: false,
      requiredCommentMinScore: null,
      type: 'circle',
    };
    this.changeInput = this.changeInput.bind(this);
  }

  changeInput({ field, value }) {
    const stateDiff = {};
    stateDiff[field] = value;
    this.setState(stateDiff);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      this.setState({
        content: '',
        classification: classifications[0],
        cluster: this.props.questionClusters.getIn([0, 'id']) || -1,
      });
    }
  }
  render() {
    const {
      visible,
      loading,
      error,
      create,
      closeModal,
      questionTypes,
      moduleTypeId,
      questionClusters,
    } = this.props;

    return (
      <Dialog open={visible} onClose={closeModal}>
        <DialogTitle>{translate('questions.create.header')}</DialogTitle>
        <DialogContent>
          <QuestionForm
            onChange={(question) => this.setState(question)}
            questionTypes={questionTypes}
            questionClusters={questionClusters.toJS()}
            question={this.state}
          />
          {loading && <Loader />}
          {error && <Error>{translate(error)}</Error>}
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={closeModal}>
            {translate('cancel')}
          </Button>
          <Button
            color='primary'
            onClick={() =>
              create({
                ...this.state,
                moduleTypeId,
              })
            }
          >
            {translate('questions.create')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    visible: state.getIn([
      'admin',
      'questions',
      'createQuestionModal',
      'visible',
    ]),
    loading: state.getIn([
      'admin',
      'questions',
      'createQuestionModal',
      'loading',
    ]),
    questionTypes: state.getIn(['admin', 'questions', 'types']),
    error: state.getIn(['admin', 'questions', 'createQuestionModal', 'error']),
    questionClusters: state.getIn(['admin', 'questions', 'list']),
    ...ownProps,
  }),
  (dispatch, ownProps) => ({
    create: (question) =>
      dispatch(
        questionActions.create(
          question,
          ownProps.language ? ownProps.language.shortCode : ''
        )
      ),
    closeModal: () => dispatch(questionActions.closeCreateQuestionModal()),
  })
)(CreateModal);
