import { request } from 'actions/utils';
import { getSelectedLanguage } from 'utils/language';

export default function getActionPlan(inspectionId) {
  const language = getSelectedLanguage();

  return request({
    action: 'LOAD_REPORT',
    path: `/api/inspections/${inspectionId}/action-plan?languageShortCode=${
      language.shortCode || ''
    }`,
  });
}
