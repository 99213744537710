import { useEffect, useState } from 'react';
import { rawRequest } from 'actions/utils';
import { formatHotelData } from 'components/admin/Hotel/WeeklyReport/utils';
import { getSelectedLanguage } from 'utils/language';

export default function useWeeklyData(organizationId, day) {
  const [name, setName] = useState(null);
  const [hotels, setHotels] = useState(null);
  const language = getSelectedLanguage();

  useEffect(() => {
    if (!organizationId || organizationId < 0) return;
    rawRequest({
      path: `/api/admin/organizations/${organizationId}/report?${[
        day ? `day=${day}` : '',
        language.shortCode ? `languageShortCode=${language.shortCode}` : '',
      ]
        .filter((p) => p)
        .join('&')}`,
    })
      .then((response) => {
        if (response.msg) {
          setHotels([]);
          throw new Error(response.msg);
        }
        const { name, hotels, inspectionModuleTypes } = response;
        setName(name);
        setHotels(formatHotelData({ hotels, inspectionModuleTypes }));
      })
      .catch(console.error);
  }, [organizationId, day, language.shortCode]);

  return { name, hotels };
}
