const imageExtensions = [
  'png',
  'jpg',
  'jpeg',
  'gif',
  'svg',
  'iff',
  'bmp',
  'exif',
  'ico',
  'pbm',
  'pcf',
  'pcx',
  'pdn',
  'pgm',
  'pict',
  'pct',
  'pnm',
  'pns',
  'ppm',
  'psb',
  'psd',
  'pdd',
  'psp',
  'px',
  'pxm',
  'pxr',
  'raw',
  'rgb',
  'tiff',
  'tif',
  'xbm',
  'xcf',
  'xpm',
  'wmf',
  'emf',
  'art',
  'rgb',
  'rgba',
];

export default function isImagePath(filePath) {
  return imageExtensions.some((extension) =>
    filePath.endsWith(`.${extension}`)
  );
}
