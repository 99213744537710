import React from 'react';
import { Route } from 'react-router-dom';
import urls from 'globals/urls';
import ActionPlan from 'components/ActionPlanAuditQA/ActionPlan/Container';
import ActionPlanList from 'components/ActionPlanAuditQA/ActionPlanList/Container';

export default [
  <Route
    exact
    path={urls.inspectionActionPlanList}
    component={ActionPlanList}
    key={urls.inspectionActionPlanList}
  />,
  <Route
    exact
    path={urls.inspectionActionPlan}
    component={ActionPlan}
    key={urls.inspectionActionPlan}
  />,
];
