const INITIAL_STATE = {
  content: '',
  ordinal: 0,
  questionClusterId: 0,
  orderInReportHeader: null,
  id: 0,
  loading: false,
  error: ''
};

export default function reducer(state = INITIAL_STATE, action = {type: ''}) {
  switch (action.type) {
    case 'INITIALIZE_FROM_PROPS': {
      const {question} = action.payload;
      return {...state, ...question};
    }
    case 'UPDATE_QUESTION_STARTED': {
      return {loading: true, error: ''};
    }
    case 'UPDATE_QUESTION_FAILED': {
      return {loading: false, error: action.payload};
    }
    case 'UPDATE_QUESTION_DONE': {
      return {loading: false};
    }
    case 'CHANGE_INPUT': {
      const {field, value} = action.payload;
      return {[field]: value};
    }
    default:
      return state;
  }
}
