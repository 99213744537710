import React from 'react';
import PropTypes from 'prop-types';

export default class LocalFlux extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.reducer(undefined, {
      type: 'INITIALIZE_FROM_PROPS',
      payload: props
    });
  }

  static propTypes = {
    reducer: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired
  };

  dispatch = action => this.mountCheck && this.setState(prevState => this.props.reducer(prevState, action));

  changeInput = payload =>
    this.dispatch({
      type: 'CHANGE_INPUT',
      payload
    });

  componentDidMount() {
    this.mountCheck = true;
  }
  componentWillUnmount() {
    this.mountCheck = false;
  }

  render() {
    return this.props.children({state: this.state, changeInput: this.changeInput, dispatch: this.dispatch});
  }
}
