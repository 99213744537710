import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import ModalWrapper from 'components/general/ModalWrapper';
import InnerModal from 'components/general/InnerModal';
import Input from 'components/general/Input';
import Button from 'components/general/Button';
import inspectionModuleActions from 'actions/admin/inspectionModule';

const Select = styled.select`
  width: 210px;
  outline: none;
  border: none;
  height: 30px;
`;

class CreateModal extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      type: '',
      isMandatory: false,
      isDefaultNA: false,
      useTabs: false,
    };
    this.changeName = this.changeName.bind(this);
    this.changeType = this.changeType.bind(this);
    this.changeIsMandatory = this.changeIsMandatory.bind(this);
    this.changeIsDefaultNA = this.changeIsDefaultNA.bind(this);
    this.changeUseTabs = this.changeUseTabs.bind(this);
  }
  changeName(e) {
    this.setState({
      name: e.target.value,
    });
  }
  changeType(e) {
    this.setState({
      type: e.target.value,
    });
  }

  changeIsMandatory() {
    this.setState((state) => ({ isMandatory: !state.isMandatory }));
  }

  changeIsDefaultNA() {
    this.setState((state) => ({ isDefaultNA: !state.isDefaultNA }));
  }

  changeUseTabs() {
    this.setState((state) => ({ useTabs: !state.useTabs }));
  }

  componentDidMount() {
    this.props.loadModuleTypes();
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      this.setState({
        name: '',
        type: this.props.moduleTypes.getIn([0, 'name']) || '',
        isMandatory: false,
        isDefaultNA: false,
        useTabs: false,
      });
    }
  }
  render() {
    const {
      visible,
      loading,
      error,
      create,
      closeModal,
      inspectionTypeId,
      moduleTypes,
    } = this.props;
    if (!visible) {
      return null;
    }

    const domModuleTypes = moduleTypes
      .get('inspectionModuleTypes')
      .map((type) => (
        <option key={type.get('id')} value={type.get('id')}>
          {type.get('name')}
        </option>
      ));
    return (
      <ModalWrapper onClose={closeModal}>
        <InnerModal
          onSubmit={(e) => {
            e.preventDefault();
            const { name, type, isMandatory, isDefaultNA, useTabs } =
              this.state;
            create({
              name,
              type,
              isMandatory,
              isDefaultNA,
              useTabs,
              inspectionTypeId,
            });
          }}
        >
          <h4>{translate('inspectionModules.create.header')}</h4>
          <Input
            type='text'
            value={this.state.name}
            onChange={this.changeName}
            placeholder={translate('inspectionModules.create.name')}
          />
          <Select value={this.state.type} onChange={this.changeType}>
            <option key={''} value={''}>
              {translate('choose')}
            </option>
            {domModuleTypes}
          </Select>
          <FormControlLabel
            label={translate('inspectionModules.edit.isMandatory')}
            control={
              <Checkbox
                name='isMandatory'
                checked={this.state.isMandatory}
                onChange={this.changeIsMandatory}
              />
            }
          />
          <FormControlLabel
            label={translate('inspectionModules.edit.isDefaultNA')}
            control={
              <Checkbox
                name='isDefaultNA'
                checked={this.state.isDefaultNA}
                onChange={this.changeIsDefaultNA}
              />
            }
          />
          <FormControlLabel
            label={translate('inspectionModules.edit.useTabs')}
            control={
              <Checkbox
                name='useTabs'
                checked={this.state.useTabs}
                onChange={this.changeUseTabs}
              />
            }
          />
          <Button>{translate('inspectionModules.create')}</Button>
          {loading && <Loader />}
          {error && <Error>{translate(error)}</Error>}
        </InnerModal>
      </ModalWrapper>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    visible: state.getIn([
      'admin',
      'inspectionModules',
      'createModal',
      'visible',
    ]),
    loading: state.getIn([
      'admin',
      'inspectionModules',
      'createModal',
      'loading',
    ]),
    error: state.getIn(['admin', 'inspectionModules', 'createModal', 'error']),
    moduleTypes: state.getIn([
      'admin',
      'inspectionModules',
      'moduleTypes',
      'types',
    ]),
    ...ownProps,
  }),
  (dispatch) => ({
    create: ({
      name,
      type,
      isMandatory,
      isDefaultNA,
      useTabs,
      inspectionTypeId,
    }) =>
      dispatch(
        inspectionModuleActions.create({
          name,
          type,
          isMandatory,
          isDefaultNA,
          useTabs,
          inspectionTypeId,
        })
      ),
    closeModal: () => dispatch(inspectionModuleActions.closeCreateModal()),
    loadModuleTypes: () => dispatch(inspectionModuleActions.loadTypes()),
  })
)(CreateModal);
