import styled from 'styled-components';
import media from 'globals/media';

export default styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 7px 0;
  position: relative;
  align-items: center;
  ${media.tablet`
    flex-direction: column;
  `};
  ${media.handheld`
    align-items: flex-start;
  `};
`;
