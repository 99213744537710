import urls from 'globals/urls';
import { push } from 'connected-react-router';

export default {
  openView,
  create,
};

export function openView(dashboardId) {
  return push(urls.dashboards.replace(':id', dashboardId));
}

export function create(hotelId) {
  return push(`${urls.dashboardsCreate}?hotelId=${hotelId}`);
}
