import React, { useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { v4 as uuid } from 'uuid';

export default function SimpleSelect({ label, value, onChange, items }) {
  const [id] = useState(uuid());
  const labelId = `question-form-select-${id}`;

  return (
    <FormControl variant='outlined' fullWidth margin='dense'>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        value={value || ''}
        label={label}
        onChange={onChange}
      >
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
