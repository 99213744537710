import qs from 'querystring';
import { connect } from 'react-redux';
import { login, checkSession } from 'actions/session';
import Form from './Form';

function mapStateToProps(state, ownProps) {
  return {
    error: state.getIn(['currentUser', 'login', 'error']),
    loading: state.getIn(['currentUser', 'login', 'loading']),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const queryRedirect = decodeURIComponent(
    qs.parse(ownProps.location.search.substring(1)).redirect
  );
  const redirect = queryRedirect.startsWith('/') ? queryRedirect : null;

  return {
    login: (credentials) => dispatch(login(credentials, redirect)),
    checkSession: () => dispatch(checkSession(redirect)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
