import styled from 'styled-components';
import colors from 'globals/colors';

export default styled.input`
  padding: 5px;
  border-radius: 3px;
  font-size: 14px;
  width: 200px;
  border: 1px solid ${colors.border};
  margin-bottom: 5px;
  outline: none;
`;