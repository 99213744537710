import React from 'react';
import {connect} from 'react-redux';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import ModalWrapper from 'components/general/ModalWrapper';
import InnerModal from 'components/general/InnerModal';
import Button from 'components/general/Button';
import inspectionModuleActions from 'actions/admin/inspectionModule';

function DeleteModal({visible, loading, error, remove, closeModal, moduleTypeId}) {
  if (!visible) {
    return null;
  }
  return (
    <ModalWrapper onClose={closeModal}>
      <InnerModal
        onSubmit={e => {
          e.preventDefault();
          remove(moduleTypeId);
        }}
      >
        <h4>{translate('questions.deleteModuleType.header')}</h4>
        <div>{translate('questions.deleteModuleType.confirmText')}</div>
        <Button>{translate('questions.deleteModuleType')}</Button>
        {loading && <Loader />}
        {error && <Error>{translate(error)}</Error>}
      </InnerModal>
    </ModalWrapper>
  );
}

export default connect(
  (state, ownProps) => ({
    visible: state.getIn(['admin', 'inspectionModules', 'moduleTypes', 'deleteModal', 'visible']),
    loading: state.getIn(['admin', 'inspectionModules', 'moduleTypes', 'deleteModal', 'loading']),
    error: state.getIn(['admin', 'inspectionModules', 'moduleTypes', 'deleteModal', 'error']),
    moduleTypeId: ownProps.moduleTypeId
  }),
  dispatch => ({
    remove: id => dispatch(inspectionModuleActions.removeType(id)),
    closeModal: () => dispatch(inspectionModuleActions.closeDeleteTypeModal())
  })
)(DeleteModal);
