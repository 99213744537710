import React from 'react';
import styled from 'styled-components';
import colors from 'globals/colors';
import PropTypes from 'prop-types';

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${colors.modalWrapperBackground};
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

export default class ModalWrapper extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired
  };
  componentDidMount() {
    document.body.className = 'modal-open';
  }
  componentWillUnmount() {
    document.body.className = '';
  }
  render() {
    return (
      <StyledWrapper
        onClick={e => {
          if (e.target === e.currentTarget) {
            this.props.onClose();
          }
        }}
      >
        {this.props.children}
      </StyledWrapper>
    );
  }
}
