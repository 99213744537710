import React from 'react';
import { List } from 'immutable';
import styled from 'styled-components';

const Question = styled.tr`
  font-size: 0.9rem;

  .bold {
    font-weight: bold;
    padding-right: 10px;
  }
`;

const ContextQuestionTableWrapper = styled.div`
  margin-top: 1.6rem;
`;

export default function ContextQuestions({
  data = List(),
  inspectionModuleId = ''
}) {
  const domQuestions = data
    .filter(question => question.getIn(['contextAnswers', 0, 'content']))
    .map(question => (
      <Question key={question.get('id')}>
        <td className={'bold'}>{question.get('content')}</td>
        {question.get('contextAnswers').size > 1 ? (
          question
            .get('contextAnswers')
            .filter(
              answer => answer.get('inspectionModuleId') === inspectionModuleId
            )
            .map(answer => (
              <td key={answer.get('id')}>{answer.get('content')}</td>
            ))
        ) : (
          <td>{question.getIn(['contextAnswers', 0, 'content'])}</td>
        )}
      </Question>
    ));
  if (domQuestions.size === 0) {
    return null;
  }
  return (
    <div key={inspectionModuleId}>
      <ContextQuestionTableWrapper>
        <table>
          <tbody>{domQuestions}</tbody>
        </table>
      </ContextQuestionTableWrapper>
    </div>
  );
}
