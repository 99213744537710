import React, { useState } from 'react';
import translate from 'utils/translate';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import LinkToInspectionTypeDialog from './LinkToInspectionTypeDialog';

const Wrapper = styled.div`
  position: relative;
  margin: 1rem 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledRow = styled(TableRow)`
  cursor: pointer;
`;

const RowButton = styled(IconButton)`
  padding: 4px !important;
  margin: 4px !important;
`;

export default function InspectionTypes({
  user,
  linkToInspectionType,
  unlinkFromInspectionType,
}) {
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);

  return user && user.inspectionTypes ? (
    <Wrapper>
      <Header>
        <Typography variant='h6' component='h2'>
          {translate('inspectionTypes')}
        </Typography>
        <Button
          variant='outlined'
          color='primary'
          disabled={user.role === 'deactivated'}
          startIcon={<LinkIcon />}
          onClick={() => setLinkDialogOpen(true)}
        >
          {translate('users.edit.linkToInspectionType')}
        </Button>
      </Header>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>{translate('inspectionTypes.create.name')}</TableCell>
            <TableCell>{translate('hotels.create.name')}</TableCell>
            <TableCell>
              {translate('inspectionTypes.reportType.label')}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {user.inspectionTypes.map((it, index) => (
            <StyledRow key={index} hover>
              <TableCell>{it.name}</TableCell>
              <TableCell>{it.hotel.name}</TableCell>
              <TableCell>
                {translate(`inspectionTypes.reportType.${it.reportType}`)}
              </TableCell>
              <TableCell style={{ width: '41px' }}>
                <Tooltip
                  title={translate('users.edit.unlinkFromInspectionType')}
                >
                  <RowButton
                    size='medium'
                    onClick={(event) => {
                      event.stopPropagation();
                      unlinkFromInspectionType(user.id, it.id);
                    }}
                  >
                    <LinkOffIcon />
                  </RowButton>
                </Tooltip>
              </TableCell>
            </StyledRow>
          ))}
        </TableBody>
      </Table>
      {linkDialogOpen && (
        <LinkToInspectionTypeDialog
          open
          userId={user.id}
          onClose={() => setLinkDialogOpen(false)}
          onSubmit={(inspectionTypeId) => {
            setLinkDialogOpen(false);
            linkToInspectionType(user.id, inspectionTypeId);
          }}
        />
      )}
    </Wrapper>
  ) : null;
}
