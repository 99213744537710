import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GridElement from './styled/GridElement';
import DeleteButton from 'components/general/DeleteButton';
import Button from 'components/general/Button';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import CreateModal from './CreateModal';
import EditModal from './EditModal';
import DeleteModal from './DeleteModal';
import Ordinal from 'components/admin/general/Ordinal';
import GridRow from 'components/admin/general/GridRow';
import { List } from 'immutable';

const MS_TO_WAIT_UNTIL_SERVER_REQUEST = 1200;

export default class InspectionModuleAdmin extends Component {
  constructor() {
    super();
    this.state = {
      inspectionModules: List(),
      editingModule: false,
      moduleToEdit: {},
    };
  }
  componentDidMount() {
    const { load, inspectionTypeId } = this.props;
    load(inspectionTypeId);
  }
  componentWillReceiveProps({ inspectionModules }) {
    if (inspectionModules !== this.props.inspectionModules) {
      this.setState({ inspectionModules });
    }
  }

  openEditModal = (module) =>
    this.setState({ moduleToEdit: module, editingModule: true });
  closeEditModal = () => {
    this.setState({ moduleToEdit: {}, editingModule: false });
    this.props.load(this.props.inspectionTypeId);
  };

  timeoutUntilServerUpdate = {};

  updateOrdinal = (event, index) => {
    const ordinal = event.target.value && parseInt(event.target.value, 10);
    const inspectionModules = this.state.inspectionModules
      .setIn([index, 'ordinal'], ordinal)
      .setIn([index, 'status'], 'updating');
    this.setState(() => ({ inspectionModules }));
    const id = inspectionModules.getIn([index, 'id']);
    clearTimeout(this.timeoutUntilServerUpdate[id]);
    this.timeoutUntilServerUpdate[id] = setTimeout(() => {
      this.props.updateInspectionModule({
        id,
        ordinal,
        inspectionTypeId: this.props.inspectionTypeId,
      });
    }, MS_TO_WAIT_UNTIL_SERVER_REQUEST);
  };

  render() {
    const { inspectionModules, editingModule, moduleToEdit } = this.state;
    const {
      error,
      loading,
      hotelId,
      inspectionType,
      inspectionTypeId,
      goBack,
      openCreateModal,
      openDeleteModal,
      updateInspectionModule,
    } = this.props;
    const domModules = inspectionModules.map((inspectionModule, i) => (
      <React.Fragment key={inspectionModule.get('id')}>
        <GridElement>{inspectionModule.get('name')}</GridElement>
        <GridElement>
          {inspectionModule.getIn(['inspectionModuleType', 'name'])}
        </GridElement>
        <Ordinal
          status={inspectionModule.get('status')}
          type='number'
          value={inspectionModule.get('ordinal')}
          onChange={(e) => this.updateOrdinal(e, i)}
        />
        <FormControlLabel
          label={translate('inspectionModules.edit.isMandatory')}
          labelPlacement='top'
          control={
            <Checkbox
              name='isMandatory'
              checked={!!inspectionModule.get('isMandatory')}
              onChange={() =>
                updateInspectionModule({
                  id: inspectionModule.get('id'),
                  inspectionTypeId,
                  isMandatory: !inspectionModule.get('isMandatory'),
                  isDefaultNA: !!inspectionModule.get('isDefaultNA'),
                })
              }
            />
          }
        />
        <FormControlLabel
          label={translate('inspectionModules.edit.isDefaultNA')}
          labelPlacement='top'
          control={
            <Checkbox
              name='isDefaultNA'
              checked={!!inspectionModule.get('isDefaultNA')}
              onChange={() =>
                updateInspectionModule({
                  id: inspectionModule.get('id'),
                  inspectionTypeId,
                  isMandatory: !!inspectionModule.get('isMandatory'),
                  isDefaultNA: !inspectionModule.get('isDefaultNA'),
                })
              }
            />
          }
        />
        <FormControlLabel
          label={translate('inspectionModules.edit.useTabs')}
          labelPlacement='top'
          control={
            <Checkbox
              name='useTabs'
              checked={!!inspectionModule.get('useTabs')}
              onChange={() =>
                updateInspectionModule({
                  id: inspectionModule.get('id'),
                  inspectionTypeId,
                  isMandatory: inspectionModule.get('isMandatory'),
                  isDefaultNA: inspectionModule.get('isDefaultNA'),
                  useTabs: !inspectionModule.get('useTabs'),
                })
              }
            />
          }
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button onClick={() => this.openEditModal(inspectionModule.toJS())}>
            {translate('edit')}
          </Button>
          <DeleteButton
            style={{ maxWidth: 'unset' }}
            onClick={() => openDeleteModal(inspectionModule.toJS())}
          >
            {translate('inspectionModules.delete')}
          </DeleteButton>
        </div>
      </React.Fragment>
    ));
    return (
      <>
        <GridRow>
          <h1>{translate('inspectionModules.administration')}</h1>
        </GridRow>
        <GridRow>
          <h2>{inspectionType}</h2>
        </GridRow>
        <GridRow>
          <Button type='button' onClick={openCreateModal}>
            {translate('inspectionModules.create')}
          </Button>
        </GridRow>
        <GridRow>
          {loading && <Loader />}
          {error && <Error>{translate(error)}</Error>}
        </GridRow>
        <div
          style={{
            display: 'grid',
            margin: '2rem auto 0',
            alignItems: 'center',
            gridRowGap: '2rem',
            gridColumnGap: '1rem',
            gridTemplateColumns: '1fr 1fr 4rem 1fr 2fr 8rem 8rem',
          }}
        >
          {domModules}
        </div>
        <Button
          color='white'
          textColor='textGray'
          onClick={() => goBack(hotelId)}
        >
          {translate('back')}
        </Button>
        <CreateModal inspectionTypeId={inspectionTypeId} />
        <DeleteModal inspectionTypeId={inspectionTypeId} />
        {editingModule && (
          <EditModal module={moduleToEdit} closeModal={this.closeEditModal} />
        )}
      </>
    );
  }
}
