import styled from 'styled-components';
import colors from 'globals/colors';

export const Wrapper = styled.div`
  margin: 20px auto 0 auto;
  min-width: 280px;
  max-width: 60rem;
  white-space: nowrap;
`;

export const GridElement = styled.div`
  padding: 10px;
  font-size: 14px;
  white-space: normal;
  overflow: hidden;
  word-break: break-word;
  text-align: center;
  a {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const EditInspectionRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-self: center;

  > span {
    margin-right: 10px;
    padding-top: 5px;
  }
`;

export const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-word;
  white-space: normal;
`;

export const EditButton = styled.button`
  padding: 10px 8px;
  border-radius: 3px;
  font-size: 14px;
  width: auto;
  border: none;
  color: ${(props) => colors[props.textColor] || 'white'};
  outline: none;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  background-color: ${(props) =>
    colors[props.color] || props.theme.palette.primary.main};
  &:hover {
    filter: brightness(1.04);
  }
`;

export const Row = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  min-height: 40px;
  padding: 10px 0;
  background-color: hsla(0, 0%, 98%, 1);
  &:nth-child(odd) {
    background-color: hsla(0, 0%, 92%, 1);
  }
`;
