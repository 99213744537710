import qs from 'qs';
import moment from 'moment';
import { connect } from 'react-redux';
import Report from './Report';

function mapStateToProps(state, ownProps) {
  const queryDay = qs.parse(ownProps.location.search.substring(1)).day;
  const day =
    queryDay && moment(queryDay).isValid()
      ? moment(queryDay).format('YYYY-MM-DD')
      : moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD');

  const { excludeUnansweredHotels, hotelIds = [] } =
    ownProps.location.state || {};

  return {
    error: state.getIn(['admin', 'reports', 'error']),
    loading: state.getIn(['admin', 'reports', 'loading']),
    organizationId: ownProps.match.params.id,
    excludeUnansweredHotels,
    hotelIds,
    day,
  };
}

export default connect(mapStateToProps, {})(Report);
