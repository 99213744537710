import { connect } from 'react-redux';
import userActions from 'actions/admin/user';
import UserFormModal from './UserFormModal';

export default connect(
  (state) => ({
    visible: state.getIn(['admin', 'users', 'createModal', 'visible']),
    loading: state.getIn(['admin', 'users', 'createModal', 'loading']),
    error: state.getIn(['admin', 'users', 'createModal', 'error']),
    role: state.getIn(['currentUser', 'role']),
    organizationId: state.getIn([
      'admin',
      'users',
      'createModal',
      'organizationId',
    ]),
    creating: true,
  }),
  (dispatch, ownProps) => ({
    submit: (user) =>
      dispatch(
        userActions.createUser(user, () => {
          if (typeof ownProps.onSuccess === 'function') {
            ownProps.onSuccess();
          } else {
            dispatch(userActions.loadUsers());
          }
        })
      ),
    closeModal: () => dispatch(userActions.closeCreateModal()),
  })
)(UserFormModal);
