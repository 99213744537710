import load from './loadTypes';
import { request } from 'actions/utils';

export default function (name, copiedModuleId) {
  return async (dispatch) => {
    let success = await dispatch(
      request({
        action: 'COPY_MODULE_TYPE',
        path: `/api/admin/inspection-module-types/${copiedModuleId}/copy`,
        method: 'POST',
        body: {
          name,
        },
      })
    );
    if (success) {
      dispatch(load());
    }
  };
}
