import { connect } from 'react-redux';
import Manage from './Manage';
import userActions from 'actions/admin/user';
import hotelActions from 'actions/admin/hotel';
import { push } from 'connected-react-router';
import urls from 'globals/urls';

function mapStateToProps(state, ownProps) {
  let organizationId = state.getIn(['user', 'current', 'organizationId']) || -1;
  if (state.getIn(['currentUser', 'role']) !== 'superUser') {
    organizationId = null;
  }
  return {
    loading: state.getIn(['user', 'loading']),
    role: state.getIn(['currentUser', 'role']),
    error: state.getIn(['user', 'error']),
    user: state.getIn(['user', 'current']),
    userId: parseInt(ownProps.match.params.id),
    organizationId,
    creating: false,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    load: (id) => dispatch(userActions.loadUser(id)),
    submit: (user) => dispatch(userActions.editUser(user)),
    back: () => {
      if (ownProps.match.params.organizationId) {
        dispatch(
          push({
            pathname: urls.organizationStart.replace(
              ':id',
              ownProps.match.params.organizationId
            ),
            search: `?tab=user`,
          })
        );
        return;
      }
      dispatch(
        push({
          pathname: urls.organizationUsers,
        })
      );
    },
    linkToHotel: async (id, hotelId) => {
      await dispatch(userActions.linkToHotel(id, hotelId));
      dispatch(userActions.loadUser(id));
    },
    unlinkFromHotel: async (id, hotelId) => {
      await dispatch(userActions.unlinkFromHotel(id, hotelId));
      dispatch(userActions.loadUser(id));
    },
    linkToInspectionType: async (id, inspectionTypeId) => {
      await dispatch(userActions.linkToInspectionType(id, inspectionTypeId));
      dispatch(userActions.loadUser(id));
    },
    unlinkFromInspectionType: async (id, inspectionTypeId) => {
      await dispatch(
        userActions.unlinkFromInspectionType(id, inspectionTypeId)
      );
      dispatch(userActions.loadUser(id));
    },
    openHotel: (hotelId) => dispatch(hotelActions.openHotel(hotelId)),
  };
}

export const Container = connect(mapStateToProps, mapDispatchToProps)(Manage);
