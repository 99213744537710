import {fromJS} from 'immutable';

const defaultState = fromJS({
  visible: false,
  loading: false,
  error: ''
});

export default function(state = defaultState, action = {type: ''}) {
  switch (action.type) {
    case 'CREATE_QUESTION_CLUSTER_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'CREATE_QUESTION_CLUSTER_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'CREATE_QUESTION_CLUSTER_DONE': {
      return state
        .set('loading', false)
        .set('visible', false);
    }
    case 'OPEN_CREATE_QUESTION_CLUSTER_MODAL': {
      return state.set('visible', true).set('error', '');
    }
    case 'CLOSE_CREATE_QUESTION_CLUSTER_MODAL': {
      return state.set('visible', false);
    }
    default: {
      return state;
    }
  }
}
