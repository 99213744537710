import load from './load';
import { request } from 'actions/utils';

export default function createQuestion(
  {
    content,
    classification,
    referenceNumber,
    ordinal,
    questionClusterId,
    moduleTypeId,
    notifyHotelResponsible,
    isMandatory,
    requiredCommentMinScore,
    type,
  },
  languageCode
) {
  return async (dispatch) => {
    let success = await dispatch(
      request({
        action: 'CREATE_QUESTION',
        path: `/api/admin/questions/${moduleTypeId}?languageShortCode=${
          languageCode || ''
        }`,
        method: 'POST',
        body: {
          content,
          classification,
          referenceNumber,
          questionClusterId,
          ordinal,
          type,
          notifyHotelResponsible,
          isMandatory,
          requiredCommentMinScore,
        },
      })
    );
    if (success) {
      dispatch(load(moduleTypeId));
    }
  };
}
