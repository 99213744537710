import React from 'react';
import translate from 'utils/translate';
import TextArea from 'components/general/TextArea';
import Input from 'components/general/Input';
import {Label, SubFormWrapper} from './styled';

export default function InspectionDescription({header = '', body = '', ordinal = 0, index, changeDescriptionInput}) {
  return (
    <SubFormWrapper>
      <div>
        <Label>{translate('inspection.description.header')}</Label>
        <Input
          type='text'
          onChange={e => changeDescriptionInput({value: e.target.value, field: 'header', index})}
          value={header}
        />
      </div>
      <div>
        <Label>{translate('inspection.description.body')}</Label>
        <TextArea
          onChange={e => changeDescriptionInput({value: e.target.value, field: 'body', index})}
          value={body}
        />
      </div>
      <div>
        <Label>{translate('ordinal')}</Label>
        <Input
          type='number'
          onChange={e => changeDescriptionInput({value: e.target.value, field: 'ordinal', index})}
          value={ordinal}
        />
      </div>
    </SubFormWrapper>
  );
}
