import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

const ColorDisplay = styled.div`
  background: ${({ color }) => color};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  height: 20px;
  width: 20px;
  border-radius: 3px;
`;

export default function ColorPicker({ label, value = '', onChange }) {
  let displayValue = value;
  if (value && value.startsWith('#')) {
    displayValue = value.substring(1);
  }

  return (
    <Grid container alignItems='center' spacing={1}>
      <Grid item>
        <ColorDisplay color={value} />
      </Grid>
      <Grid item>
        <TextField
          value={displayValue}
          label={label}
          onChange={(event) => {
            let newValue = event.target.value;
            if (newValue.startsWith('#')) {
              newValue = newValue.substring(1);
            }
            onChange(`#${newValue.substring(0, 6).toUpperCase()}`);
          }}
          InputProps={{
            startAdornment: <InputAdornment position='start'>#</InputAdornment>,
          }}
        />
      </Grid>
    </Grid>
  );
}
