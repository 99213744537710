export function rescaleImage(file, options = {}) {
  return new Promise((resolve, reject) => {
    const { maxSize, maxWidth, maxHeight } = options;
    const supportedFileTypes = ['image/jpeg', 'image/png'];

    if (!supportedFileTypes.includes(file.type)) {
      return resolve(file);
    }

    if (maxSize && file.size < maxSize) {
      return resolve(file);
    }

    if (!maxSize && !maxWidth && !maxHeight) {
      return resolve(file);
    }

    let canvas = document.createElement('canvas');
    if (!canvas.toBlob && !canvas.msToBlob) {
      return resolve(file);
    }

    if (canvas.msToBlob && file.type !== 'image/png') {
      // IE 11 will always treat canvas images and png which might result in unexpected behavior for jpeg
      return resolve(file);
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const image = new Image();
      image.src = event.target.result;
      image.onload = () => {
        let newWidth = image.width;
        let newHeight = image.height;

        if (maxSize) {
          // This is just an estimate assuming that the resolution is linearly proportional
          // to the file size
          const estimatedScale = maxSize / file.size;
          newWidth = image.width * Math.sqrt(estimatedScale);
          newHeight = image.height * Math.sqrt(estimatedScale);
        } else {
          if (maxWidth && newWidth > maxWidth) {
            newWidth = maxWidth;
            newHeight = newHeight * (newWidth / image.width);
          }
          if (maxHeight && newHeight > maxHeight) {
            newWidth = newWidth * (maxHeight / newHeight);
            newHeight = maxHeight;
          }
        }

        canvas.width = newWidth;
        canvas.height = newHeight;
        const context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, newWidth, newHeight);
        if (canvas.msToBlob) {
          const blob = canvas.msToBlob();
          return done(blob); // msToBlob will always output a blob with type image/png
        }
        canvas.toBlob(done, file.type, 1);
      };

      image.onerror = (error) => {
        console.error(error);
      };
    };

    reader.onerror = (error) => {
      console.error(error);
    };

    reader.readAsDataURL(file);

    function done(blob) {
      canvas = null;
      if (!blob) {
        return reject();
      }

      blob['name'] = file.name;
      blob['lastModifiedDate'] = new Date();
      resolve(blob);
    }
  });
}

export function shortenFileName(name, maxLength) {
  if (name.length < maxLength) {
    return name;
  }

  const lastPart = name.substring(name.length - 5, name.length);
  const separator = '...';
  const firstPart = name.substring(
    0,
    Math.max(maxLength, lastPart.length + separator.length + 1) -
      lastPart.length -
      separator.length
  );
  return `${firstPart}${separator}${lastPart}`;
}
