import { connect } from 'react-redux';
import Report from './Report';
import reportActions from 'actions/admin/report';
import inspectionActions from 'actions/admin/inspection';

function mapStateToProps(state, ownProps) {
  return {
    error: state.getIn(['admin', 'reports', 'error']),
    loading: state.getIn(['admin', 'reports', 'loading']),
    report: state.getIn(['admin', 'reports', 'content']),
    creatingFollowUp: state.getIn(['admin', 'reports', 'creatingFollowUp']),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    load: () =>
      dispatch(reportActions.getAuditQA(ownProps.match.params.inspectionId)),
    onCreateFollowUp: (id) => {
      dispatch(inspectionActions.createFollowUp(id));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Report);
