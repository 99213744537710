import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import translate from 'utils/translate';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useOrganizationHotels } from 'hooks/useOrganizationHotels';

export default function LinkToHotelDialog({
  open,
  onClose,
  onSubmit,
  organizationId,
}) {
  const hotels = useOrganizationHotels(organizationId);
  const [selectedHotel, setSelectedHotel] = useState('');

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{translate('users.edit.linkToHotel')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translate('users.edit.linkToHotelText')}
        </DialogContentText>
        <FormControl variant='outlined' fullWidth>
          <InputLabel id='user-form-role-input-label'>
            {translate('hotel')}
          </InputLabel>
          <Select
            fullWidth
            required
            disabled={!hotels || hotels.length === 0}
            labelId='user-form-role-input-label'
            value={selectedHotel}
            onChange={(event) => setSelectedHotel(event.target.value)}
            label={translate('hotel')}
          >
            {(hotels || []).map((h) => (
              <MenuItem key={h.id} value={h.id}>
                {h.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          {translate('cancel')}
        </Button>
        <Button
          onClick={() => onSubmit(selectedHotel)}
          color='primary'
          disabled={!hotels || hotels.length === 0 || !selectedHotel}
        >
          {translate('link')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
