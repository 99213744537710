import React, { useEffect, useRef } from 'react';
import prism from 'prismjs';
import 'prismjs/themes/prism.css';

export function Prism({ children }) {
  const code = useRef(null);
  useEffect(() => {
    if (!code.current) {
      return;
    }
    prism.highlightElement(code.current);
  }, [children, code]);
  return (
    <pre>
      <code ref={code} className='language-html'>
        {children}
      </code>
    </pre>
  );
}
