import colors from 'globals/colors';
import styled from 'styled-components';

export default styled.textarea`
  padding: 20px;
  border-radius: 3px;
  font-size: 14px;
  width: ${props => (props.autoWidth ? 'auto' : '300px')};
  height: 150px;
  border: 1px solid ${colors.border};
  margin-bottom: 5px;
  outline: none;
`;
