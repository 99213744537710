import {request} from 'actions/utils';

export default function setExternalScores(inspectionId, externalScores) {
  const dto = {};
  Object.entries(externalScores).forEach(([key, value]) => {
    if (value !== '') {
      dto[key] = parseFloat(value, 10);
    }
  });
  return request({
    action: 'SET_EXTERNAL_SCORES',
    method: 'POST',
    path: `/api/inspections/${inspectionId}/externalScores`,
    body: dto
  });
}
