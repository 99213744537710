import React from 'react';
import urls from 'globals/urls';
import { Redirect, Route } from 'react-router-dom';
import LoginForm from 'components/Login/Container';
import RequestPasswordResetForm from 'components/Login/RequestPasswordReset/Container';
import ResetPasswordForm from 'components/Login/ResetPassword/Container';
import SessionRoutes from './session';
import PrivateRoute from './PrivateRoute';
import DashboardView from 'components/dashboards/View/index';

export default function routes() {
  return [
    <Route
      exact
      key={urls.dashboardsView}
      path={urls.dashboardsView}
      component={DashboardView}
    />,
    <Route exact path='/' key='/'>
      <Redirect to={urls.login} />
    </Route>,
    <Route path={urls.login} component={LoginForm} key={urls.login} />,
    <Route
      path={urls.passwordResetRequest}
      component={RequestPasswordResetForm}
      key={urls.passwordResetRequest}
    />,
    <Route
      path={urls.resetPassword}
      component={ResetPasswordForm}
      key={urls.resetPassword}
    />,
    <PrivateRoute path='/' component={SessionRoutes} key='privateRoutes' />,
  ];
}
