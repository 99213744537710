import React, { useEffect, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import translate from 'utils/translate';

export default function Comment({
  required,
  variant = 'standard',
  focus,
  comment,
  setComment,
  onChange,
  label,
  error,
  onBlur,
  minmize = true,
  maxLength = 24,
}) {
  comment = comment || '';
  const [uncommited, setUncommited] = useState(null);
  const [isFocused, setIsFocused] = useState(focus);
  const [hasChanged, setHasChanged] = useState(false);
  const blurTimeout = useRef();
  const inputRef = useRef(null);

  useEffect(() => {
    if (focus) inputRef.current && inputRef.current.focus();
    setIsFocused(focus);
  }, [focus]);

  useEffect(() => {
    setUncommited(comment);
  }, [comment]);

  useEffect(() => {
    if (
      (comment === uncommited || uncommited === null) &&
      typeof onChange !== 'function'
    )
      return;
    if (typeof onChange === 'function' && !hasChanged) {
      return;
    }
    const timer = setTimeout(() => {
      setComment(uncommited);
      setHasChanged(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [comment, uncommited, setComment, onChange, hasChanged]);

  const minimized = (uncommited ?? '')
    .replace(/(?:\r\n|\r|\n)/g, ' ')
    .substring(0, maxLength);

  return (
    <div style={{ minWidth: '15rem' }}>
      <TextField
        variant={variant}
        multiline
        fullWidth
        inputRef={inputRef}
        required={required}
        error={error}
        FormHelperTextProps={{ style: { color: 'red' } }}
        helperText={
          error || (required && !uncommited)
            ? translate('questions.commentRequired')
            : undefined
        }
        label={label || translate('questions.comment')}
        value={
          isFocused || !minmize
            ? uncommited ?? ''
            : minimized.length < (uncommited ?? '').length
            ? `${minimized}...`
            : minimized
        }
        onChange={(event) => {
          setUncommited(event.target.value);
          if (typeof onChange === 'function') {
            onChange(event.target.value);
          }
          setHasChanged(event.target.value !== comment);
        }}
        onClick={() => setIsFocused(true)}
        onFocus={() => clearTimeout(blurTimeout.current)}
        onBlur={() => {
          if (onBlur) onBlur(uncommited);
          if (focus) return;
          blurTimeout.current = setTimeout(() => setIsFocused(false));
        }}
      />
    </div>
  );
}
