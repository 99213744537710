import { push } from 'connected-react-router';
import urls from 'globals/urls';
import get from './get';
import getAuditQA from './getAuditQA';
import getActionPlan from './getActionPlan';
import setFollowUp from './setFollowUp';
import setModuleFollowUp from './setModuleFollowUp';

export default {
  get,
  getAuditQA,
  openReport,
  getActionPlan,
  openReportAuditQA,
  setFollowUp,
  setModuleFollowUp,
};

function openReport(id) {
  return push(urls.report.replace(':inspectionId', id));
}

function openReportAuditQA(id) {
  return push(urls.reportAuditQA.replace(':inspectionId', id));
}
