import load from './load';
import {request} from 'actions/utils';

export default function removeQuestion({id, moduleTypeId}) {
  return async dispatch => {
    let success = await dispatch(request({
      action: 'DELETE_QUESTION',
      path: `/api/admin/questions/${id}`,
      method: 'DELETE'
    }));
    if (success) {
      dispatch(load(moduleTypeId));
    }
  };
}
