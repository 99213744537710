import { List } from 'immutable';
import { push, goBack } from 'connected-react-router';
import { connect } from 'react-redux';
import Control from './Control';
import inspectionActions from 'actions/inspection';
import adminEndTime from 'actions/admin/inspection/setEndTime';
import inspectionQuestionActions from 'actions/inspectionQuestions';
import urls from 'globals/urls';
const { openComfortZoneModal, saveClusterComments, updateExtraQuestion } =
  inspectionQuestionActions;

function mapStateToProps(state, ownProps) {
  const id = parseInt(ownProps.match.params.id, 10);
  return {
    id,
    userRole: state.getIn(['currentUser', 'role']),
    inspection: state.getIn(['inspections', 'current']),
    loading: state.getIn(['inspections', 'loading']),
    error: state.getIn(['inspections', 'error']),
    user: state.get('currentUser'),
    agentEndTime: state.getIn(['inspections', 'current', 'agentEndTime']),
    allowUnansweredQuestions: state.getIn([
      'inspections',
      'current',
      'inspectionType',
      'allowUnansweredQuestions',
    ]),
    commentCategories: state.getIn(
      ['inspections', 'current', 'comfortZones'],
      List()
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    load: (id) => dispatch(inspectionActions.loadControl(id)),
    submit: async ({ inspection, user }) => {
      const setEndTime = inspection.get('agentEndTime')
        ? adminEndTime
        : inspectionActions.setEndTime;
      await dispatch(setEndTime(inspection.get('id')));
      if (inspection.getIn(['inspectionType', 'hotel', 'id'])) {
        dispatch(
          push(
            urls.hotelDetails.replace(
              ':hotelId',
              inspection.getIn(['inspectionType', 'hotel', 'id'])
            )
          )
        );
      } else {
        dispatch(push(urls.start));
      }
    },
    goBack: () => dispatch(goBack()),
    openGeneralCommentsModal: () =>
      dispatch(inspectionActions.openGeneralCommentsModal()),
    openExternalScoresModal: (focusField) =>
      dispatch(inspectionActions.openExternalScoresModal(focusField)),
    openComfortZoneModal: (focusField) =>
      dispatch(openComfortZoneModal(focusField)),
    saveClusterComments: (data) => dispatch(saveClusterComments(data)),
    updateExtraQuestion: (params) => dispatch(updateExtraQuestion(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Control);
