import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import translate from 'utils/translate';
import Wrapper from 'components/general/InspectionWrapper';
import ModuleCard from './ModuleCard';
import { Link } from 'react-router-dom';
import SubmitModal from './SubmitModal';
import GeneralCommentsModal from './GeneralCommentsModal';
import ExternalScoresModal from './ExternalScoresModal';
import ComfortZoneModal from 'components/inspection/Questions/ComfortZoneModal';
import urls from 'globals/urls';
import commentIcon from 'open-iconic/svg/comment-square.svg';
import externalScoresIcon from 'open-iconic/svg/signal.svg';
import ComfortZoneModalIcon from 'components/inspection/Questions/ComfortZoneModal/icon';
import { rawRequest } from 'actions/utils';
import {
  CommentButtonWrapper,
  CommentIcon,
} from 'components/general/CommentModalButton';
import Typography from '@material-ui/core/Typography';

// TODO: Make non-mandatory modules skippable and possible to revert this action

export default function InspectionModulePicker({
  reportType,
  inspectionModules,
  hotelName,
  lastEditedByUser,
  inspectionType,
  id,
  loading,
  loadInspection,
  openSubmitModal,
  openExternalScoresModal,
  openGeneralCommentsModal,
  goBack,
  onSaveComments,
  extraQuestions,
  openComfortZoneModal,
  allowUnansweredQuestions,
  inspectionDescriptions = [],
}) {
  const [dialogModule, setDialogModule] = useState(null);

  const loadInspectionRef = useRef(); // loadInspection is changing unintentionally between initial renders
  useEffect(() => {
    if (loadInspectionRef.current) return;
    loadInspectionRef.current = loadInspection;
    loadInspection();
  }, [loadInspection]);

  const domModules = inspectionModules.map((module, i) => (
    <ModuleCardWrapper
      key={i}
      inspectionId={id}
      moduleId={module.get('id')}
      isSkipped={module.get('isSkipped')}
    >
      <ModuleCard
        answered={module.get('answered')}
        name={module.get('name')}
        started={module.get('started')}
        isSkipped={module.get('isSkipped')}
        onToggleSkip={
          module.get('isMandatory')
            ? null
            : (event) => {
                event.preventDefault();
                event.stopPropagation();
                setDialogModule(module);
              }
        }
      />
    </ModuleCardWrapper>
  ));
  const description = inspectionDescriptions.map((description) => (
    <React.Fragment key={description.get('id')}>
      <h4>{description.get('header')}</h4>
      {description
        .get('body')
        .split('\n')
        .map((part, i) => (
          <p key={i}>{part}</p>
        ))}
    </React.Fragment>
  ));

  const answeredExtras = extraQuestions.filter(
    (q) => typeof q.get('score') === 'number'
  );

  return (
    <Wrapper>
      {!loading && (
        <CommentButtonWrapper>
          <CommentIcon onClick={() => openGeneralCommentsModal()}>
            <img src={commentIcon} alt='comment' />
          </CommentIcon>
          {reportType !== 'auditQA' && (
            <CommentIcon onClick={() => openComfortZoneModal()}>
              <ComfortZoneModalIcon />
            </CommentIcon>
          )}
          {!allowUnansweredQuestions && reportType !== 'auditQA' && (
            <CommentIcon onClick={() => openExternalScoresModal()}>
              <img src={externalScoresIcon} alt='external-scores' />
            </CommentIcon>
          )}
        </CommentButtonWrapper>
      )}
      {description.length > 0 && <h3>{translate('inspection.description')}</h3>}
      <div>{description}</div>
      <h3>
        {inspectionType} - {hotelName}
      </h3>
      {lastEditedByUser && (
        <Typography>
          {translate('lastEditedBy')}: {lastEditedByUser}
        </Typography>
      )}
      <div>{domModules}</div>
      {extraQuestions.size > 0 && (
        <div>
          <Link to={urls.extraInspectionQuestions.replace(':id', id)}>
            <ModuleCard
              answered={answeredExtras.size === extraQuestions.size}
              started={answeredExtras.size > 0}
              name={translate('extraQuestions')}
            />
          </Link>
        </div>
      )}
      <div style={{ marginTop: '2rem' }}>
        <Button variant='contained' onClick={(id) => goBack()}>
          {translate('back')}
        </Button>
        <Button
          style={{ marginLeft: '2rem' }}
          variant='contained'
          color='primary'
          onClick={() => openSubmitModal()}
        >
          {translate('inspection.continue')}
        </Button>
      </div>
      {dialogModule && (
        <Dialog
          open
          onClose={() => setDialogModule(null)}
          maxWidth='md'
          scroll='paper'
        >
          <DialogTitle>
            {translate(
              `inspection.module.${
                dialogModule.get('isSkipped') ? 'isSkipped' : 'notIsSkipped'
              }.title`
            )}
          </DialogTitle>
          <DialogContent>
            <p>
              {translate(
                `inspection.module.${
                  dialogModule.get('isSkipped') ? 'isSkipped' : 'notIsSkipped'
                }.content`
              )}
            </p>
            <p>
              {!dialogModule.get('isSkipped') &&
                translate('inspection.module.notIsSkipped.warning')}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='primary'
              onClick={async () => {
                try {
                  await rawRequest({
                    method: 'POST',
                    path: `/api/inspections/${id}/modules/${dialogModule.get(
                      'id'
                    )}/toggleSkipped`,
                  });
                  loadInspection();
                  setDialogModule(null);
                } catch (error) {
                  // TODO improve error handling, also disable button while saving
                  console.error(error);
                }
              }}
            >
              {translate('ok')}
            </Button>
            <Button onClick={() => setDialogModule(null)} color='secondary'>
              {translate('cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <SubmitModal />
      <GeneralCommentsModal onSave={onSaveComments} />
      <ExternalScoresModal />
      <ComfortZoneModal inspectionId={id} skipLoad onSave={onSaveComments} />
    </Wrapper>
  );
}

function ModuleCardWrapper({ inspectionId, moduleId, isSkipped, children }) {
  if (isSkipped) return <div>{children}</div>;
  return (
    <Link
      to={urls.inspectionQuestions
        .replace(':id', inspectionId)
        .replace(':moduleId', moduleId)}
    >
      {children}
    </Link>
  );
}
