import React from 'react';
import translate from 'utils/translate';
import { Map } from 'immutable';
import { QuestionAnswer } from './styled';
import { classifications } from 'globals/classifications';

export default function ComfortZoneComments({ data = Map() }) {
  return (
    <div className="page-break-after">
      {classifications.map((classification) => (
        <div className="page-break-inside" key={classification}>
          <h3>{translate(`generalCommentsCategories.${classification}`)}</h3>
          <QuestionAnswer>
            {data.get(classification) ||
              ''.split('\n').map((t, i) => <p key={i}>{t}</p>)}
          </QuestionAnswer>
        </div>
      ))}
    </div>
  );
}
