import { fromJS } from 'immutable';

const defaultState = fromJS({
  visible: false,
  loading: false,
  error: '',
});

export default function (state = defaultState, action = { type: '' }) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      return defaultState;
    }
    case 'OPEN_SUBMIT_INSPECTION_MODAL': {
      return state.set('visible', true);
    }
    case 'CLOSE_SUBMIT_INSPECTION_MODAL': {
      return state.set('visible', false);
    }
    case 'FINISH_INSPECTION_STARTED':
    case 'SET_END_TIME_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'FINISH_INSPECTION_FAILED':
    case 'SET_END_TIME_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'FINISH_INSPECTION_DONE':
    case 'SET_END_TIME_DONE': {
      return state.set('loading', false);
    }
    default: {
      return state;
    }
  }
}
