import React from 'react';
import styled from 'styled-components';
import ActionPlanQuestions from './ActionPlanQuestions';
import colors from 'globals/colors';
import media from 'globals/media';
import { connect } from 'react-redux';
import reportActions from 'actions/admin/report';
import inspectionQuestionsActions from 'actions/inspectionQuestions';
import EditableComment from 'components/inspection/Questions/QuestionRow/Comment';
import translate from 'utils/translate';
import { getClusterTitle } from './utils';

const ModuleWrapper = styled.div`
  margin-bottom: 20px;
  margin-top: 10px;

  ${media.handheld`
    h2 {
      margin-top: 2rem;
      font-size: 1.5rem;
    }
  `}
`;

const QuestionsWrapper = styled.div`
  font-size: 0.875rem;

  .referenceNumber {
    color: ${colors.qultivatorBlueDark};
    &:not(first-child) {
      margin-top: 10px;
    }
  }
  .content {
    font-weight: 400;
    padding: 5px 0;
  }
  .followUpComment {
    color: ${colors.errorRed};
    margin: 0 0 5px;
    padding: 5px 0 5px;
    border-bottom: 1px solid ${colors.errorRed};
    white-space: pre-line;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 20px;
    }
    > li {
      .content {
        font-weight: 500;
      }
    }
    ul {
      padding: 0 30px;
      list-style: none;
      .referenceNumber {
        display: none;
      }
      .content {
        font-weight: 500;
      }
    }
  }
`;

function mapDispatchToProps(dispatch, ownProps) {
  return {
    setFollowUp: (followUp) => {
      dispatch(reportActions.setFollowUp(followUp));
    },
    setModuleFollowUp: (followUp) => {
      dispatch(reportActions.setModuleFollowUp(followUp));
    },
    updateModuleFollowUpComment: ({ moduleId, comment }) => {
      dispatch({
        type: `SET_REPORT_MODULE_FOLLOW_UP_DONE`,
        payload: {},
        meta: {
          moduleId,
          comment,
        },
      });
    },
    uploadFile: ({ moduleId, questionId, file }) => {
      dispatch(
        inspectionQuestionsActions.uploadFile({
          inspectionId: ownProps.inspectionId,
          moduleId,
          questionId,
          file,
          isFollowUp: true,
        })
      );
    },
    removeFile: ({ questionId, moduleId, fileId }) => {
      dispatch(
        inspectionQuestionsActions.deleteFile({
          inspectionId: ownProps.inspectionId,
          moduleId,
          questionId,
          fileId,
        })
      );
    },
  };
}

function ActionPlanModule({
  inspectionId,
  followUpModule,
  setFollowUp,
  originalClusters,
  setModuleFollowUp,
  updateModuleFollowUpComment,
  uploadFile,
  removeFile,
}) {
  const clusters = followUpModule.clusters;
  return (
    <ModuleWrapper>
      <h2>{followUpModule.name}</h2>
      <EditableComment
        label={translate('questions.followUp.moduleFollowUp')}
        comment={followUpModule.followUp}
        multiline
        minmize={false}
        variant='filled'
        fullWidth
        setComment={(newComment) =>
          setModuleFollowUp({
            inspectionId,
            moduleId: followUpModule.id,
            comment: newComment,
          })
        }
        onChange={(newComment) =>
          updateModuleFollowUpComment({
            inspectionId,
            moduleId: followUpModule.id,
            comment: newComment,
          })
        }
      />
      {clusters.map((cluster, i) => (
        <div key={i}>
          <h3>{getClusterTitle(cluster, originalClusters, followUpModule)}</h3>
          <QuestionsWrapper>
            <ActionPlanQuestions
              questions={cluster.questions}
              setFollowUp={setFollowUp}
              uploadFile={uploadFile}
              removeFile={removeFile}
            />
          </QuestionsWrapper>
        </div>
      ))}
    </ModuleWrapper>
  );
}

export default connect(null, mapDispatchToProps)(ActionPlanModule);
