import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
  getAccumulatedScore,
  getDaysData,
  getPercentage,
} from 'components/admin/Hotel/WeeklyReport/utils';

import translate from 'utils/translate';

export default function HotelScores({ selectedHotelIds, hotels, day, week }) {
  if (!hotels.length || !selectedHotelIds.length) return null;

  const hotelsWithScore = hotels
    .map((hotel) => {
      const score = getAccumulatedScore({
        hotels: [hotel],
        weeks: [week],
        noRounding: true,
      });
      const { completeDays, incompleteDays, unansweredDays } = getDaysData({
        hotel,
        weeks: [week],
      });

      const answerRate = getPercentage(
        completeDays.length + incompleteDays.length,
        completeDays.length + incompleteDays.length + unansweredDays.length,
        true
      );

      const inactive =
        hotel.inactiveStartDate &&
        moment(hotel.inactiveStartDate).isBefore(day) &&
        (!hotel.inactiveEndDate || moment(hotel.inactiveEndDate).isAfter(day));

      return {
        ...hotel,
        inactive,
        score: (score * answerRate) / 100,
        completeDays,
        incompleteDays,
        unansweredDays,
      };
    })
    .sort((a, b) => (a.inactive ? 1 : b.inactive ? -1 : b.score - a.score))
    .map((hotel) => ({ ...hotel, score: Math.ceil(hotel.score) }));

  const average = Math.ceil(
    hotelsWithScore.reduce((acc, hotel) => acc + hotel.score, 0) / hotels.length
  );

  return (
    <div style={{ marginTop: '4rem' }}>
      <div className='page-break-before' />
      <div style={{ marginBottom: '1rem' }}>
        <Typography variant='h5'>
          {translate(`safeQReport.hotelScores`).toUpperCase()}
        </Typography>
        <Typography variant='subtitle2'>{`${translate('date')}: ${moment(day)
          .startOf('isoWeek')
          .format('YYYY-MM-DD')} - ${day}`}</Typography>
      </div>
      <ScoreThresholds average={average} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{translate('safeQReport.rank')}</TableCell>
            <TableCell style={{ whiteSpace: 'nowrap' }}>
              {translate('safeQReport.qIndex')}
            </TableCell>
            <TableCell>{translate('safeQReport.answered')}</TableCell>
            <TableCell>{translate('hotel')}</TableCell>
            <TableCell>{translate('classifications.hygiene')}</TableCell>
            <TableCell>{translate('classifications.socialDistance')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {hotelsWithScore
            .filter((hotel) => selectedHotelIds.includes(hotel.id))
            .map((hotel, index) => (
              <TableRow key={hotel.id}>
                <TableCell>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FiberManualRecordIcon
                      style={{
                        fontSize: '12px',
                        color: hotel.inactive
                          ? 'grey'
                          : hotel.score < average - 5
                          ? 'red'
                          : hotel.score > average + 5
                          ? 'green'
                          : 'yellow',
                      }}
                    />
                    <span style={{ marginLeft: '3px' }}>{1 + index}</span>
                  </div>
                </TableCell>
                <TableCell>{hotel.score}%</TableCell>
                <TableCell>{formatFullfillment(hotel)}</TableCell>
                <TableCell
                  style={{
                    whiteSpace: 'nowrap',
                    maxWidth: '20rem',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {hotel.name}
                </TableCell>
                <TableCell>
                  {getAccumulatedScore({
                    hotels: [hotel],
                    weeks: [week],
                    comfortZones: ['hygiene'],
                    noRounding: true,
                  }).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                  %
                </TableCell>
                <TableCell>
                  {getAccumulatedScore({
                    hotels: [hotel],
                    weeks: [week],
                    comfortZones: ['socialDistance'],
                    noRounding: true,
                  }).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                  %
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
}

function ScoreThresholds({ average }) {
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FiberManualRecordIcon style={{ color: 'green' }} />
        <Typography variant='subtitle2'>
          {translate('safeQReport.qIndex')
            .concat(' > ')
            .concat(average + 5)
            .concat('%')}
        </Typography>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FiberManualRecordIcon style={{ color: 'yellow' }} />
        <Typography variant='subtitle2'>
          {`${translate('safeQReport.qIndex')} ${average + 5}% - ${
            average - 5
          }%`}
        </Typography>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FiberManualRecordIcon style={{ color: 'red' }} />
        <Typography variant='subtitle2'>
          {translate('safeQReport.qIndex')
            .concat(' < ')
            .concat(average - 5)
            .concat('%')}
        </Typography>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FiberManualRecordIcon style={{ color: 'grey' }} />
        <Typography variant='subtitle2'>
          {translate('safeQReport.inactiveHotel').toLowerCase()}
        </Typography>
      </div>
    </div>
  );
}

function formatFullfillment({ unansweredDays, completeDays, incompleteDays }) {
  const answeredCount = completeDays.length + incompleteDays.length;
  const totalCount = answeredCount + unansweredDays.length;
  return `${answeredCount} ${translate(
    'of'
  ).toLowerCase()} ${totalCount}, ${translate('whereof').toLowerCase()} ${
    completeDays.length
  } ${
    completeDays.length === 1
      ? translate('completeSingular')
      : translate('complete')
  }`;
}
