import { connect } from 'react-redux';
import List from './List';
import inspectionTypeActions from 'actions/admin/inspectionType';
import organizationActions from 'actions/admin/organization';
import inspectionModuleActions from 'actions/admin/inspectionModule';

function mapStateToProps(state, ownProps) {
  return {
    role: state.getIn(['currentUser', 'role']),
    error: state.getIn(['organization', 'error']),
    loading: state.getIn(['organization', 'loading']),
    organization: state.getIn(['organization', 'current']),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const loadOrganization = () => {
    dispatch(
      organizationActions.loadOrganization.inspectionTypes(
        ownProps.organizationId
      )
    );
  };
  return {
    openModuleAdministration: (inspectionTypeId) =>
      dispatch(inspectionModuleActions.openView(inspectionTypeId)),
    openCreateModal: () => dispatch(inspectionTypeActions.openCreateModal()),
    openLinkToHotelModal: () =>
      dispatch(inspectionTypeActions.openLinkToHotelModal()),
    openEditModal: (inspectionType) =>
      dispatch(inspectionTypeActions.openEditModal(inspectionType)),
    openDeleteModal: (inspectionType) =>
      dispatch(inspectionTypeActions.openDeleteModal(inspectionType)),
    load: loadOrganization,
    onCreated: loadOrganization,
    onDeleted: loadOrganization,
    onUpdated: loadOrganization,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
