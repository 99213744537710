import colors from './colors';
import grey from '@material-ui/core/colors/grey';

export default {
  fontFamily: 'Roboto, sans-serif',
  palette: {
    primary: {
      main: colors.qultivatorPurple,
    },
    secondary: {
      main: colors.qultivatorBlueDark,
    },
    info: {
      main: colors.qultivatorBlueDark,
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        color: '#333',
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: grey['200'],
        },
      },
    },
  },
};
