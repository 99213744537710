import React from 'react';
import DonutChart from './DonutChart';
import translate from 'utils/translate';

export default function ByArea({ stats = {}, secondary, color, language }) {
  if (Object.keys(stats).length === 0) {
    return null;
  }

  const total = Object.values(stats.modules).reduce(
    (prev, curr) => curr.total + prev,
    0
  );

  return (
    <DonutChart
      textColor={color}
      total={total}
      language={language}
      secondary={secondary}
      labels={Object.keys(stats.modules).map(
        (key) =>
          `${translate(`moduleTypeAreas.${key}`, {
            forceLanguage: language,
          })}\n ${stats.modules[key].total} ${translate(
            'dashboards.view.checkpoints',
            { forceLanguage: language }
          )}`
      )}
      data={Object.values(stats.modules)}
    />
  );
}
