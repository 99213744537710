import React from 'react';
import { connect } from 'react-redux';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import ModalWrapper from 'components/general/ModalWrapper';
import InnerModal from 'components/general/InnerModal';
import Button from 'components/general/Button';
import userActions from 'actions/admin/user';

function DeleteModal({
  visible,
  loading,
  error,
  deleteUser,
  closeModal,
  user,
}) {
  if (!visible) {
    return null;
  }

  return (
    <ModalWrapper onClose={closeModal}>
      <InnerModal
        onSubmit={(e) => {
          e.preventDefault();
          deleteUser(user.get('id'));
        }}
      >
        <h4>{translate('users.delete.header')}</h4>
        <div>
          {translate('users.delete.confirmText').replace(
            '{name}',
            user.get('name')
          )}
        </div>
        <Button>{translate('users.delete')}</Button>
        {loading && <Loader />}
        {error && <Error>{translate(error)}</Error>}
      </InnerModal>
    </ModalWrapper>
  );
}

export default connect(
  (state) => ({
    visible: state.getIn(['admin', 'users', 'deleteModal', 'visible']),
    loading: state.getIn(['admin', 'users', 'deleteModal', 'loading']),
    error: state.getIn(['admin', 'users', 'deleteModal', 'error']),
    user: state.getIn(['admin', 'users', 'deleteModal', 'user']),
  }),
  (dispatch) => ({
    deleteUser: (id) =>
      dispatch(
        userActions.deleteUser(id, () => dispatch(userActions.loadUsers()))
      ),
    closeModal: () => dispatch(userActions.closeDeleteModal()),
  })
)(DeleteModal);
