import {fromJS} from 'immutable';

const defaultState = fromJS({
  visible: false,
  loading: false,
  unansweredQuestions: [],
  error: ''
});

export default function(state = defaultState, action = {type: ''}) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      return defaultState;
    }
    case 'MARK_MODULE_FINISHED_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'MARK_MODULE_FINISHED_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'MARK_MODULE_FINISHED_DONE': {
      return state.set('loading', false);
    }
    case 'OPEN_CONFIRM_FINISH_MODULE_MODAL': {
      return state
        .set('unansweredQuestions', fromJS(action.payload))
        .set('visible', true)
        .set('loading', false)
        .set('error', '');
    }
    case 'CLOSE_CONFIRM_FINISH_MODULE_MODAL': {
      return state.set('visible', false)
        .set('unansweredQuestions', fromJS([]));
    }
    default: {
      return state;
    }
  }
}
