import * as React from 'react';

import translate from '../../../utils/translate';
import QuestionsList from './QuestionsList';
import uploadJson from './actions/uploadJson';
import styled from 'styled-components';
import { makeStyles, AppBar, Tabs, Tab, Button, CircularProgress, Badge, Dialog, Backdrop, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { useDispatchContext } from './QuestionsUpload';
import TabPanel from './TabPanel';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

const Wrapper = styled.div`
  margin-top: 16px;
`;

export default function QuestionTabs(props) {
  const classes = useStyles();
  const { dispatch, state } = useDispatchContext();
  const [ value, setValue ] = React.useState(0);
  const { questions } = props;
  const { importing, showImportResult, importResult } = state;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [];
  for (const prop in questions) {
    tabs.push(prop);
  }
  
  return (
    <div className={classes.root}>
      
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {
            tabs.map((tab,i) => {
              const errors = questions[tab].filter(q => q.errors && q.errors.length > 0);
              if (errors.length > 0) {
                return (
                  <Tab label={(
                    <Badge badgeContent={errors.length} max={999} color="error">{`${tab} - ${questions[tab].length}`}</Badge>
                  )} {...a11yProps(i)} key={i} />
                );
              } else {
                return (
                  <Tab label={`${tab} - ${questions[tab].length}`} {...a11yProps(i)} key={i} />
                );
              }
            })
          }
        </Tabs>
      </AppBar>
      {
        tabs.map((tab,i) => (
          <TabPanel value={value} index={i} key={i}>
            <QuestionsList questions={questions[tab]} />
            <Wrapper>
              <Backdrop className={classes.backdrop} open={importing}>
                <CircularProgress color={'inherit'} />
              </Backdrop>
              <Dialog open={showImportResult} onClose={() => dispatch({type: 'CLOSE_IMPORT_RESULT'})} aria-labelledby={'form-dialog-title'}>
                <DialogTitle id="form-dialog-title">{translate('questions.upload.completed')}</DialogTitle>
                <DialogContent>
                  <DialogContentText component={'span'}>
                    {translate('questions.upload.completed.message')}
                    <ul>
                      <li>
                        {importResult ? importResult.filter(q=>q.import === 'update').length : 0} {translate('questions.upload.completed.updated')}
                      </li>
                      <li>
                        {importResult ? importResult.filter(q=>q.import === 'create').length : 0} {translate('questions.upload.completed.created')}
                      </li>
                    </ul>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => dispatch({type: 'CLOSE_IMPORT_RESULT'})} color={'primary'}>
                    {translate('questions.upload.completed.close')}
                  </Button>
                </DialogActions>
              </Dialog>
              <Button
                variant={'contained'}
                component={'label'}
                color={'primary'}
                onClick={() => uploadJson(questions[tab], dispatch)}
                disabled={importing}
              >
                {`${translate('questions.upload.import')} ${tab}`}
              </Button>
            </Wrapper>
          </TabPanel>
        ))
      }
    </div>
  );
}
