import styled from 'styled-components';

export default styled.div`
  font-size: 11px;
  padding: 2px 7px;
  border-radius: 3px;
  color: white;
  letter-spacing: 1px;
  font-weight: 400;
  background: ${({ theme }) => theme.palette.primary.main};
  display: inline-block;
  font-size: 0.75rem;
`;
