import { connect } from 'react-redux';
import Create from './Edit';
import { replace } from 'connected-react-router';
import urls from 'globals/urls';
import qs from 'qs';

function mapStateToProps(state, ownProps) {
  const hotelId = qs.parse(ownProps.location.search.substring(1)).hotelId;

  return {
    hotelId,
    id: ownProps.match.params.id,
    role: state.getIn(['currentUser', 'role']),
    userId: state.getIn(['currentUser', 'id']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onCreated: (id) => dispatch(replace(urls.dashboards.replace(':id', id))),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Create);
