import { connect } from 'react-redux';
import InspectionList from './InspectionList';
import reportActions from 'actions/admin/report';
import inspectionActions from 'actions/admin/inspection';
import adminEndTime from 'actions/admin/inspection/setEndTime';
import { goToControl } from 'actions/inspection';

function mapStateToProps(state, ownProps) {
  return {
    error: state.getIn(['admin', 'inspections', 'error']),
    loading: state.getIn(['admin', 'inspections', 'loading']),
    inspections: state.getIn(['admin', 'inspections', 'list']),
    hotelId: ownProps.match.params.hotelId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    load: (hotelId) => dispatch(inspectionActions.listHotel(hotelId)),
    openReport: (reportId, type) => {
      if (type === 'auditQA') {
        dispatch(reportActions.openReportAuditQA(reportId));
        return;
      }
      dispatch(reportActions.openReport(reportId));
    },
    openControlView: (inspectionId) => dispatch(goToControl(inspectionId)),
    revertReportToControl: (inspectionId, revert) => {
      dispatch(adminEndTime(inspectionId, revert));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionList);
