import React from 'react';
import translate from 'utils/translate';
import {Wrapper, NoHeader} from './styled';
import Button from 'components/general/Button';
import GeneralCommentsModal from 'components/inspection/ModulePicker/GeneralCommentsModal';

export default function GeneralComments({data, openModal}) {
  data = data || '';
  return (
    <Wrapper>
      <NoHeader>{data.split('\n').map((t, i) => <div key={i}>{t}</div>)}</NoHeader>
      <Button color='white' textColor='textGray' onClick={openModal}>
        {translate('edit')}
      </Button>
      <GeneralCommentsModal reloadOnSave />
    </Wrapper>
  );
}
