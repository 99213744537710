import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { List } from 'immutable';
import moment from 'moment';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TodayIcon from '@material-ui/icons/Today';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import NotificationsIcon from '@material-ui/icons/Notifications';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DatePicker, Day } from '@material-ui/pickers';
import Loader from 'components/general/Loader';
import translate from 'utils/translate';
import urls from 'globals/urls';
import SelectHotelsModal from './SelectHotelsModal';
import NotificationSettings from './NotificationSettings';
import organizationActions from 'actions/admin/organization';

export default connect(
  (state, ownProps) => ({
    loading: state.getIn(['organization', 'loading']),
    organization: state.getIn(['organization', 'current']),
  }),
  (dispatch, ownProps) => ({
    load: () => {
      dispatch(
        organizationActions.loadOrganization.reportDays(
          ownProps.organizationId || null
        )
      );
    },
  })
)(Statistics);

function Statistics({ organization, load, loading }) {
  const [showModal, setShowModal] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [excludeUnansweredHotels, setExcludeUnansweredHotels] = useState(false);
  const hotels = organization.get('hotels', List());
  const [selectedHotels, setSelectedHotels] = useState(null);
  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (hotels.size) setSelectedHotels(hotels);
  }, [hotels]);

  const reportDays = hotels.reduce(reduceHotelReportDays, []);
  const currentWeek = moment().isoWeek();
  const currentWeekYear = moment().isoWeekYear();
  const lastWeek = moment().subtract(1, 'week').isoWeek();
  const lastWeekYear = moment().subtract(1, 'week').isoWeekYear();

  const weeks = reportDays.reduce((acc, day) => {
    const week = moment(day).isoWeek();
    const year = moment(day).isoWeekYear();
    if (week === currentWeek && year === currentWeekYear) {
      return acc;
    }
    if (acc.some((prev) => prev.week === week && prev.year === year)) {
      return acc;
    }
    return acc.concat({ week, year });
  }, []);

  const hasReport = weeks.some(
    ({ week, year }) => week === lastWeek && year === lastWeekYear
  );

  if (!weeks.length) {
    return (
      <Paper>
        {showNotifications && (
          <NotificationSettings onClose={() => setShowNotifications(false)} />
        )}
        <div style={{ padding: '1rem 2rem' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='h5' gutterBottom>
              {translate('statistics.reports')}
            </Typography>
            <Button
              variant='contained'
              color='primary'
              onClick={() => setShowNotifications(true)}
              disabled={!organization.get('id')}
              startIcon={<NotificationsIcon />}
            >
              {translate('statistics.notifications')}
            </Button>
          </div>
          {(!organization.get('id') || loading) && (
            <div style={{ textAlign: 'center' }}>
              <Loader />
            </div>
          )}
          {organization.get('id') && !loading && (
            <Typography color='textSecondary'>
              {translate('safeQ.noEarlierDays')}
            </Typography>
          )}
        </div>
      </Paper>
    );
  }

  const reportState = {
    hotelIds:
      selectedHotels && selectedHotels.map((hotel) => hotel.get('id')).toJS(),
    excludeUnansweredHotels,
  };

  return (
    <div>
      {showModal && (
        <SelectHotelsModal
          loading={!organization}
          onClose={() => setShowModal(false)}
          allHotels={hotels}
          selectedHotels={selectedHotels || List()}
          setSelectedHotels={setSelectedHotels}
        />
      )}
      {showNotifications && (
        <NotificationSettings onClose={() => setShowNotifications(false)} />
      )}
      <Paper>
        <div style={{ padding: '1rem 2rem' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='h5' gutterBottom>
              {translate('statistics.reports')}
            </Typography>
            <Button
              variant='contained'
              color='primary'
              onClick={() => setShowNotifications(true)}
              disabled={!organization.get('id')}
              startIcon={<NotificationsIcon />}
            >
              {translate('statistics.notifications')}
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '2rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Paper
                elevation={2}
                style={{
                  padding: '1rem 2rem',
                  width: '250px',
                  height: 'min-content',
                  marginBottom: '3rem',
                }}
              >
                <Typography style={{ marginBottom: '1rem' }}>
                  {translate('statistics.selectedHotels')
                    .concat(': ')
                    .concat(
                      !selectedHotels || selectedHotels.size === hotels.size
                        ? translate('statistics.allHotels')
                        : !selectedHotels.size
                        ? translate('statistics.noHotels')
                        : `${selectedHotels.size} ${translate('hotels')}`
                    )}
                </Typography>
                <Button variant='contained' onClick={() => setShowModal(true)}>
                  {translate('statistics.selectHotels')}
                </Button>
                <div style={{ marginTop: '1rem' }}>
                  <FormControlLabel
                    label={translate('statistics.excludeUnansweredHotels')}
                    control={
                      <Checkbox
                        color='primary'
                        checked={excludeUnansweredHotels || false}
                        onChange={(event) =>
                          setExcludeUnansweredHotels(event.target.checked)
                        }
                      />
                    }
                  />
                </div>
              </Paper>
              <Paper
                elevation={2}
                style={{
                  padding: '1rem 2rem',
                  width: '250px',
                  height: 'min-content',
                }}
              >
                <Typography
                  variant='subtitle1'
                  component='h4'
                  style={{ marginBottom: '1rem' }}
                >
                  {translate('safeQ.currentReports')}
                </Typography>
                {hasReport && (
                  <Link
                    to={{
                      pathname: urls.organizationReport.replace(
                        ':id',
                        organization.get('id')
                      ),
                      state: reportState,
                    }}
                  >
                    <Button
                      variant='outlined'
                      color='primary'
                      startIcon={<TodayIcon />}
                      disabled={!selectedHotels || !selectedHotels.size}
                    >
                      {translate('statistics.showReport')}
                    </Button>
                  </Link>
                )}
                {!hasReport && (
                  <Typography color='textSecondary'>
                    {translate('statistics.noReport')}
                  </Typography>
                )}
              </Paper>
            </div>
            <div style={{ paddingRight: '2rem', alignSelf: 'center' }}>
              <ReportCalendar
                reportState={reportState}
                organizationId={organization.get('id')}
                weeks={weeks}
                minDate={reportDays.reduce(
                  (prev, day) =>
                    !prev || moment(day).isBefore(prev) ? day : prev,
                  null
                )}
              />
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}

function Week({ children, to, disabled }) {
  const WeekNumber = disabled
    ? styled.div`
        position: absolute;
        left: 1px;
        font-size: 8px;
        font-weight: 400;
      `
    : styled(Link)`
        position: absolute;
        left: 1px;
        font-size: 9px;
        font-weight: 600;
        &:hover {
          background: #f5f5f5;
        }
      `;
  return <WeekNumber to={to}>{children}</WeekNumber>;
}

function ReportCalendar({ minDate, weeks, organizationId, reportState }) {
  if (!weeks.length)
    return (
      <Typography color='textSecondary'>
        {translate('safeQ.noEarlierDays')}
      </Typography>
    );

  return (
    <DatePicker
      disableToolbar
      disableFuture
      minDate={minDate}
      renderDay={(day, _selectedDate, _dayInCurrentMonth, dayComponent) => (
        <div>
          {day.day() === 1 && (
            <Week
              disabled={
                !weeks.some(
                  ({ week, year }) =>
                    week === day.isoWeek() && year === day.isoWeekYear()
                )
              }
              to={{
                pathname: urls.organizationReport.replace(
                  ':id',
                  organizationId
                ),
                search: `?day=${day.endOf('isoWeek').format('YYYY-MM-DD')}`,
                state: reportState,
              }}
            >
              v{day.isoWeek()}
            </Week>
          )}
          <Day {...dayComponent.props} selected={false} disabled />
        </div>
      )}
      autoOk
      onChange={() => null}
      value={moment()}
      readOnly
      variant='static'
    />
  );
}

function reduceHotelReportDays(acc, hotel) {
  return hotel.get('reportDays', List()).reduce(reduceReportDays, acc);
}

function reduceReportDays(acc, reportDay) {
  return acc.includes(reportDay) ? acc : acc.concat(reportDay);
}
