import * as React from 'react';
import { Backdrop, Button, CircularProgress, makeStyles } from '@material-ui/core';
import { Centered } from '../Question/styled';
import translate from '../../../utils/translate';

import reducer, { INITIAL_STATE } from './reducer';
import uploadXlsx from './actions/uploadXlsx';
import QuestionTabs from './QuestionTabs';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 16px;
`;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

const DispatchContext = React.createContext({});

export default function QuestionsUpload() {
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);
  const classes = useStyles();
  const { questions, file, uploading } = state;

  return (
    <DispatchContext.Provider value={{dispatch, state}}>
      <div>
        <Backdrop className={classes.backdrop} open={uploading}>
          <CircularProgress color={'inherit'} />
        </Backdrop>
        <Centered>
          <h1>{translate('questions.upload.title')}</h1>
        </Centered>
        
        <Wrapper>
          <Button variant={'contained'} component={'label'}>
            {translate('questions.upload.selectFile')}
            <input type={'file'} hidden onChange={(event) => uploadXlsx(event, dispatch)}/>
          </Button>
          {file ? ` ${file}` : ''}
        </Wrapper>

        { questions ? (
          <QuestionTabs questions={questions}/>
        ) : null }
      </div>
    </DispatchContext.Provider>
  );

}

export const useDispatchContext = () => React.useContext(DispatchContext);
