import {fromJS} from 'immutable';

const defaultState = fromJS({
  visible: false,
  loading: false,
  focusField: undefined,
  error: ''
});

export default function(state = defaultState, action = {type: ''}) {
  switch (action.type) {
    case 'OPEN_EXTERNAL_SCORES_MODAL': {
      return state
        .set('visible', true)
        .set('focusField', action.payload.focusField)
        .set('loading', false)
        .set('error', '');
    }
    case 'CLOSE_EXTERNAL_SCORES_MODAL': {
      return state.set('visible', false);
    }
    case 'SET_EXTERNAL_SCORES_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'SET_EXTERNAL_SCORES_DONE': {
      return state.set('visible', false).set('loading', false);
    }
    case 'SET_EXTERNAL_SCORES_FAILED': {
      return state.set('error', action.payload).set('loading', false);
    }
    default: {
      return state;
    }
  }
}
