import React from 'react';
import urls from 'globals/urls';
import translate from 'utils/translate';
import HotelIcon from '@material-ui/icons/Hotel';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import AssignmentIcon from '@material-ui/icons/Assignment';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import Publish from '@material-ui/icons/Publish';
import Ballot from '@material-ui/icons/Ballot';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

export const adminMenuMap = {
  user: [
    {
      url: urls.inspectionActionPlanList,
      label: translate('actionPlan.list'),
      icon: <AssignmentTurnedInIcon />,
    },
  ],
  hotelAdmin: [
    {
      url: urls.organizationHotels,
      label: translate('hotels'),
      icon: <HotelIcon />,
    },
    {
      url: urls.organizationUsers,
      label: translate('users'),
      icon: <PeopleIcon />,
    },
    {
      url: urls.inspectionActionPlanList,
      label: translate('actionPlan.list'),
      icon: <AssignmentTurnedInIcon />,
    },
  ],
  companyAdmin: [
    {
      url: urls.organizationHotels,
      label: translate('hotels'),
      icon: <HotelIcon />,
    },
    {
      url: urls.organizationUsers,
      label: translate('users'),
      icon: <PeopleIcon />,
    },
    {
      url: urls.organizationInspectionTypes,
      label: translate('inspectionTypes'),
      icon: <AssignmentIcon />,
    },
    {
      url: urls.organizationStatistics,
      label: translate('statistics'),
      icon: <BarChartIcon />,
    },
    {
      url: urls.organizationSettings,
      label: translate('settings'),
      icon: <SettingsIcon />,
    },
    {
      url: urls.listAuditQA,
      label: translate('auditQA'),
      icon: <Ballot />,
    },
    {
      url: urls.inspectionActionPlanList,
      label: translate('actionPlan.list'),
      icon: <AssignmentTurnedInIcon />,
    },
  ],
  superUser: [
    {
      url: urls.adminInspections,
      label: translate('inspections'),
      icon: <AssignmentIcon />,
    },
    { url: urls.hotels, label: translate('hotels'), icon: <HotelIcon /> },
    {
      url: urls.organizations,
      label: translate('organizations'),
      icon: <BusinessIcon />,
    },
    {
      url: urls.users,
      label: translate('users'),
      icon: <PeopleIcon />,
    },
    {
      url: urls.questionAdmin.replace(':moduleTypeId', 'default'),
      label: translate('questions'),
      icon: <QuestionAnswerIcon />,
    },
    {
      url: urls.questionsUpload,
      label: translate('questions.upload.title'),
      icon: <Publish />,
    },
    {
      url: urls.listAuditQA,
      label: translate('auditQA'),
      icon: <Ballot />,
    },
    {
      url: urls.inspectionActionPlanList,
      label: translate('actionPlan.list'),
      icon: <AssignmentTurnedInIcon />,
    },
  ],
};
