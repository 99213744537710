import load from './load';
import {request} from 'actions/utils';

export default function deleteInspectionModule({inspectionTypeId, id}) {
  return async dispatch => {
    const success = await dispatch(request({
      action: 'DELETE_INSPECTION_MODULE',
      path: `/api/admin/inspection-types/${inspectionTypeId}/inspection-modules/${id}`,
      method: 'DELETE'
    }));
    if (success) {
      dispatch(load(inspectionTypeId));
    }
  };
}
