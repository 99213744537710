import React, { Component } from 'react';
import styled from 'styled-components';
import translate from 'utils/translate';
import parseStage, { stages } from 'utils/parseStage';
import colors from 'globals/colors';

const IndicatorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Span = styled.span`
  :before {
    content: ${(props) =>
      props.currentIndex > props.triggerIndex ? "'✔'" : ''};
    margin-right: ${(props) =>
      props.currentIndex > props.triggerIndex ? '10px' : '0'};
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 0.8rem;
  }
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.currentIndex < props.triggerIndex ? colors.textGray : 'inherit'};
  font-weight: ${(props) =>
    props.currentIndex === props.triggerIndex ? 'bold' : 'normal'};
`;

export default class StepsIndicator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'inspections',
    };
  }

  componentDidMount() {
    const currentStage = parseStage(this.props.location.pathname);
    if (!(currentStage === this.state.stage)) {
      this.setState({ stage: currentStage });
    }
  }

  componentWillReceiveProps(nextProps) {
    const pathname = this.props.location.pathname;
    const currentpath = nextProps.location.pathname;
    if (currentpath !== pathname) {
      this.setState({ stage: parseStage(currentpath) });
    }
  }

  render() {
    const { stage } = this.state;
    const indexOfStage = stages.indexOf(stage);
    return (
      <IndicatorWrapper>
        <Span
          currentIndex={indexOfStage}
          triggerIndex={stages.indexOf('inspections')}
        >
          {translate('stepsIndicator.session')}
        </Span>
        <Span
          currentIndex={indexOfStage}
          triggerIndex={stages.indexOf('inspectionQuestions')}
        >
          {translate('stepsIndicator.agentQ')}
        </Span>
        <Span
          currentIndex={indexOfStage}
          triggerIndex={stages.indexOf('inspectionControl')}
        >
          {translate('stepsIndicator.control')}
        </Span>
      </IndicatorWrapper>
    );
  }
}
