import moment from 'moment';

export function getPercentage(fraction, total, noRounding) {
  if (total === 0) return 0;
  const percentage = 100 * (fraction / total);
  return noRounding ? percentage : Math.ceil(percentage);
}

function getHotelAccumulatedScore({
  fullfillment,
  hotel,
  weeks,
  areas,
  comfortZones,
}) {
  const inspections = hotel.inspections
    .filter(
      (inspection) => !weeks || weeks[weeks.length - 1] === inspection.startWeek
    )
    .filter((inspection) =>
      inspection.moduleTypes.some(
        (moduleType) =>
          (!areas || areas.includes(moduleType.area)) &&
          (!comfortZones ||
            moduleType.questions.some((question) =>
              comfortZones.includes(question.classification)
            ))
      )
    );
  if (inspections.length === 0) {
    return null;
  }
  if (fullfillment) {
    const { completeDays, incompleteDays, unansweredDays } = getDaysData({
      hotel,
      weeks,
      areas,
      comfortZones,
    });

    return getPercentage(
      completeDays.length + incompleteDays.length,
      completeDays.length + incompleteDays.length + unansweredDays.length,
      true
    );
  }

  const { answeredScores, unansweredCount } = hotel.inspections.reduce(
    reduceInspectionData,
    {
      answeredScores: [],
      unansweredCount: 0,
    }
  );

  const totalQuestions = answeredScores.length + unansweredCount;
  if (!totalQuestions) return 0;

  const score = fullfillment
    ? answeredScores.length
    : answeredScores.reduce((acc, score) => acc + 3 - score, 0);

  const maxScore = fullfillment ? totalQuestions : 3 * totalQuestions;
  return getPercentage(score, maxScore, true);

  function reduceInspectionData(acc, inspection) {
    if (weeks && !weeks.includes(inspection.startWeek)) {
      return acc;
    }
    return inspection.moduleTypes.reduce(reduceModuleTypeData, acc);
  }

  function reduceModuleTypeData(acc, moduleType) {
    if (areas && !areas.includes(moduleType.area)) {
      return acc;
    }
    return moduleType.questions.reduce(reduceQuestionData, acc);
  }

  function reduceQuestionData(acc, question) {
    if (comfortZones && !comfortZones.includes(question.classification)) {
      return acc;
    }

    return question.scores.length
      ? {
          unansweredCount: acc.unansweredCount,
          answeredScores: acc.answeredScores.concat(question.scores[0]),
        }
      : {
          answeredScores: acc.answeredScores,
          unansweredCount: acc.unansweredCount + 1,
        };
  }
}

export function getAccumulatedScore({
  hotels,
  noRounding,
  ensureNumbers = true,
  ...params
}) {
  const hotelScores = hotels
    .map((hotel) => getHotelAccumulatedScore({ ...params, hotel }))
    .filter((score) => score !== null);
  if (hotelScores.length === 0) return ensureNumbers ? 0 : null;
  const aggregatedScore =
    hotelScores.reduce((acc, curr) => acc + curr, 0) / hotelScores.length;
  return noRounding ? aggregatedScore : Math.ceil(aggregatedScore);
}

export function getLineData({
  bestPerWeek,
  worstPerWeek,
  fullfillment,
  startDay,
  hotels,
  weeks,
  areas,
  comfortZones,
}) {
  return weeks.reduce((acc, _, offset) => {
    const x = moment(startDay)
      .add(offset, 'week')
      .startOf('isoWeek')
      .format('YYYY-MM-DD');

    if (!bestPerWeek && !worstPerWeek) {
      const hotelsScore = getAccumulatedScore({
        fullfillment,
        hotels,
        weeks: getLastWeeks(weeks, offset),
        areas,
        comfortZones,
        ensureNumbers: false,
      });
      if (hotelsScore === null) {
        return acc;
      }

      return acc.concat({ x, y: hotelsScore });
    }

    const { bestScore, worstScore } = hotels.reduce(
      (prev, hotel) => {
        const score = getAccumulatedScore({
          fullfillment: true,
          hotels: [hotel],
          weeks: getLastWeeks(weeks, offset),
          areas,
          comfortZones,
          ensureNumbers: false,
        });

        if (score === null) {
          return prev;
        }
        let { bestScore, worstScore } = prev;
        if (bestScore < score) bestScore = score;
        if (worstScore > score) worstScore = score;
        return { bestScore, worstScore };
      },
      { bestScore: 0, worstScore: 100 }
    );

    if (bestPerWeek) return acc.concat({ x, y: bestScore });
    return acc.concat({ x, y: worstScore });
  }, []);
}

export function getLastWeeks(weeks, index) {
  const count = 4;
  const startWeek = Math.max(index - (count - 1), 0);
  const endWeek = index + 1;
  const subset = weeks.slice(startWeek, endWeek);

  return subset;
}

export function getStartWeekOffset({
  offset = 0,
  startWeek,
  hotels,
  maxWeeks,
}) {
  if (
    offset >= maxWeeks ||
    hotels.some((hotel) =>
      hotel.inspections.some(
        (inspection) => inspection.startWeek === (startWeek + offset) % maxWeeks
      )
    )
  ) {
    return offset;
  }

  return getStartWeekOffset({
    offset: offset + 1,
    startWeek,
    hotels,
    maxWeeks,
  });
}

export function getDaysData({ hotel, weeks, areas, comfortZones }) {
  return hotel.inspections
    .filter((inspection) => !weeks || weeks.includes(inspection.startWeek))
    .filter((inspection) =>
      inspection.moduleTypes.some(
        (moduleType) =>
          (!areas || areas.includes(moduleType.area)) &&
          (!comfortZones ||
            moduleType.questions.some((question) =>
              comfortZones.includes(question.classification)
            ))
      )
    )
    .reduce(
      (acc, inspection) => {
        const day = moment(inspection.startTime).day();
        const isComplete = inspection.moduleTypes
          .filter((moduleType) => !areas || areas.includes(moduleType.area))
          .every((moduleType) =>
            moduleType.questions
              .filter(
                (question) =>
                  !comfortZones ||
                  comfortZones.includes(question.classification)
              )
              .every((question) => question.scores.length)
          );
        const isIncomplete =
          !isComplete &&
          inspection.moduleTypes.some(
            (moduleType) =>
              (!areas || areas.includes(moduleType.area)) &&
              moduleType.questions.some(
                (question) =>
                  (!comfortZones ||
                    comfortZones.includes(question.classification)) &&
                  question.scores.length
              )
          );

        const isUnanswered = !isComplete && !isIncomplete;

        return {
          unansweredDays:
            !isUnanswered || acc.unansweredDays.includes(day)
              ? acc.unansweredDays
              : acc.unansweredDays.concat(day),
          completeDays:
            !isComplete || acc.completeDays.includes(day)
              ? acc.completeDays
              : acc.completeDays.concat(day),
          incompleteDays:
            !isIncomplete || acc.incompleteDays.includes(day)
              ? acc.incompleteDays
              : acc.incompleteDays.concat(day),
        };
      },
      {
        unansweredDays: [],
        completeDays: [],
        incompleteDays: [],
      }
    );
}

export function formatHotelData({ hotels, inspectionModuleTypes }) {
  return hotels.map((hotel) => ({
    id: hotel.id,
    name: hotel.name,
    address: hotel.address,
    inactiveStartDate: hotel.inactiveStartDate,
    inactiveEndDate: hotel.inactiveEndDate,
    inspections: hotel.inspectionTypes
      .map((inspectionType) =>
        inspectionType.inspections.map((inspection) => ({
          startTime: inspection.startTime,
          startWeek: moment(inspection.startTime).isoWeek(),
          hygiene: inspection.hygiene,
          socialDistance: inspection.socialDistance,
          moduleTypes: inspectionModuleTypes
            .filter((inspectionModuleType) =>
              inspectionModuleType.inspectionModules.some(
                (inspectionModule) =>
                  inspectionModule.inspectionTypeId ===
                  inspectionType.templateId
              )
            )
            .map((inspectionModuleType) => {
              const clusterIds = inspectionModuleType.questionClusters.map(
                (cluster) => cluster.id
              );
              return {
                name: inspectionModuleType.name,
                area: inspectionModuleType.area,
                questionClusterComments: inspection.questionClusterComments
                  .filter((clusterComment) =>
                    clusterIds.includes(clusterComment.questionClusterId)
                  )
                  .map((clusterComment) => clusterComment.comment),
                questions: inspectionModuleType.questionClusters
                  .map((cluster) => cluster.questions)
                  .flat()
                  .map((question) => ({
                    id: question.id,
                    content: question.content,
                    referenceNumber: question.referenceNumber,
                    classification: question.classification,
                    scores: inspection.answers
                      .filter((answer) => answer.questionId === question.id)
                      .map((answer) => answer.score),
                  }))
                  .filter((question) => question.scores[0] !== -1),
              };
            })
            .filter((moduleType) => moduleType.questions.length),
        }))
      )
      .flat(),
  }));
}

export function filterAnsweredHotels({ hotels, weeks, areas, comfortZones }) {
  return hotels.filter((hotel) =>
    hotel.inspections.some(
      (inspection) =>
        (!weeks || weeks.includes(inspection.startWeek)) &&
        inspection.moduleTypes.some(
          (moduleType) =>
            (!areas || areas.includes(moduleType.area)) &&
            moduleType.questions.some(
              (question) =>
                (!comfortZones ||
                  comfortZones.includes(question.classification)) &&
                question.scores.length
            )
        )
    )
  );
}
