import list from './list';
import {request} from 'actions/utils';

export default function deleteInspection(id) {
  return async dispatch => {
    let success = await dispatch(request({
      action: 'DELETE_INSPECTION',
      path: `/api/admin/inspections/${id}`,
      method: 'DELETE'
    }));
    if (success) {
      dispatch(list());
    }
  };
}
