import { List, fromJS, Map } from 'immutable';

const defaultState = fromJS({
  content: {
    answers: [],
  },
  loading: false,
  creatingFollowUp: false,
  error: '',
});

export default function (state = defaultState, action = { type: '' }) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      return defaultState;
    }
    case 'FOLLOW_UP_SUMMARY_UPLOAD_FILE_DONE': {
      let summary = state.getIn(['content', 'followUpSummary']) || Map();
      summary = summary.set('content', summary.get('content') || '');
      summary = summary.set(
        'attachments',
        summary.get('attachments', List()).concat(fromJS([action.payload]))
      );

      return state.setIn(['content', 'followUpSummary'], summary);
    }
    case 'FOLLOW_UP_SUMMARY_DELETE_FILE_DONE': {
      let summary = state.getIn(['content', 'followUpSummary']) || Map();
      summary = summary.set('content', summary.get('content') || '');
      summary = summary.set(
        'attachments',
        summary
          .get('attachments', List())
          .filter((file) => file.get('id') !== action.meta.fileId)
      );

      return state.setIn(['content', 'followUpSummary'], summary);
    }
    case 'UPLOAD_FILE_DONE': {
      const { questionIndex, clusterIndex, moduleIndex } = findQuestion({
        state,
        questionId: action.meta.questionId,
      });

      if (clusterIndex === -1) {
        return state;
      }

      return state.setIn(
        [
          'content',
          'modules',
          moduleIndex,
          'clusters',
          clusterIndex,
          'questions',
          ...questionIndex,
          'answers',
          0,
          'attachments',
        ],
        state
          .getIn(
            [
              'content',
              'modules',
              moduleIndex,
              'clusters',
              clusterIndex,
              'questions',
              ...questionIndex,
              'answers',
              0,
              'attachments',
            ],
            List()
          )
          .concat(fromJS([action.payload]))
      );
    }
    case 'DELETE_FILE_DONE': {
      const { questionIndex, clusterIndex, moduleIndex } = findQuestion({
        state,
        questionId: action.meta.questionId,
      });

      if (clusterIndex === -1) {
        return state;
      }

      return state.setIn(
        [
          'content',
          'modules',
          moduleIndex,
          'clusters',
          clusterIndex,
          'questions',
          ...questionIndex,
          'answers',
          0,
          'attachments',
        ],
        state
          .getIn(
            [
              'content',
              'modules',
              moduleIndex,
              'clusters',
              clusterIndex,
              'questions',
              ...questionIndex,
              'answers',
              0,
              'attachments',
            ],
            List()
          )
          .filter((file) => file.get('id') !== action.meta.fileId)
      );
    }
    case 'UPDATE_INSPECTION_DONE': {
      let tmpState = state;

      if (action.meta.bi) {
        tmpState = tmpState.setIn(['content', 'bi'], action.meta.bi);
      }
      if (action.meta.bs) {
        tmpState = tmpState.setIn(['content', 'bs'], action.meta.bs);
      }
      return tmpState;
    }
    case 'LOAD_REPORT_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'LOAD_REPORT_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'LOAD_REPORT_DONE': {
      return state.set('content', fromJS(action.payload)).set('loading', false);
    }
    case 'CREATE_FOLLOW_UP_INSPECTION_STARTED': {
      return state.set('creatingFollowUp', true);
    }
    case 'CREATE_FOLLOW_UP_INSPECTION_DONE': {
      if (action.payload.id) {
        return state.set('creatingFollowUp', false).setIn(
          ['content', 'followUp'],
          fromJS({
            id: action.payload.id,
            startTime: action.meta.startTime,
            agentEndTime: null,
            adminEndTime: null,
          })
        );
      }
      return state
        .set('creatingFollowUp', false)
        .setIn(['content', 'enableActionPlan'], true);
    }
    case 'SET_REPORT_FOLLOW_UP_SUMMARY_DONE': {
      return state.setIn(
        ['content', 'followUpSummary', 'content'],
        action.meta.summary
      );
    }
    case 'SET_REPORT_FOLLOW_UP_STARTED': {
      const questionId = parseInt(action.payload.questionId, 10);
      const { questionIndex, clusterIndex, moduleIndex } = findQuestion({
        state,
        questionId,
      });

      if (clusterIndex === -1) {
        return state;
      }
      return state
        .setIn(
          [
            'content',
            'modules',
            moduleIndex,
            'clusters',
            clusterIndex,
            'questions',
            ...questionIndex,
            'loading',
          ],
          true
        )
        .setIn(
          [
            'content',
            'modules',
            moduleIndex,
            'clusters',
            clusterIndex,
            'questions',
            ...questionIndex,
            'error',
          ],
          ''
        );
    }
    case 'SET_REPORT_FOLLOW_UP_DONE': {
      const questionId = parseInt(action.payload.questionId, 10);
      const { questionIndex, clusterIndex, moduleIndex } = findQuestion({
        state,
        questionId,
      });

      if (clusterIndex === -1) {
        return state;
      }
      return state
        .mergeIn(
          [
            'content',
            'modules',
            moduleIndex,
            'clusters',
            clusterIndex,
            'questions',
            ...questionIndex,
            'answers',
          ],
          fromJS([action.payload])
        )
        .setIn(
          [
            'content',
            'modules',
            moduleIndex,
            'clusters',
            clusterIndex,
            'questions',
            ...questionIndex,
            'loading',
          ],
          false
        );
    }
    case 'SET_REPORT_MODULE_FOLLOW_UP_DONE': {
      const moduleId = parseInt(action.meta.moduleId, 10);
      const comment = action.meta.comment;
      const moduleIndex = state
        .getIn(['content', 'modules'])
        .toJS()
        .findIndex((m) => m.id === moduleId);

      return state.setIn(
        ['content', 'modules', moduleIndex, 'followUp'],
        fromJS([
          {
            comment,
          },
        ])
      );
    }
    default: {
      return state;
    }
  }
}

function findQuestion({ state, questionId }) {
  let questionIndex = [];
  let moduleIndex = -1;
  let clusterIndex = -1;

  state.getIn(['content', 'modules'], List()).some((module, index) => {
    let ci = module.get('clusters').findIndex((cluster) => {
      questionIndex = findInQuestionTree(
        cluster.get('questions'),
        questionId,
        []
      );
      return questionIndex.length > 0;
    });

    if (ci !== -1) {
      moduleIndex = index;
      clusterIndex = ci;
      return true;
    }
    return false;
  });

  const questionIndexPath = [];

  questionIndex.forEach((index, pathIndex) => {
    questionIndexPath.push(index);
    if (questionIndex.length > 1 && pathIndex !== questionIndex.length - 1) {
      questionIndexPath.push('children');
    }
  });

  return {
    questionIndex: questionIndexPath,
    clusterIndex,
    moduleIndex,
  };

  function findInQuestionTree(questions, id, path) {
    if (!questions || questions.size === 0) {
      return [];
    }

    const index = questions.findIndex((q) => q.get('id') === id);
    if (index !== -1) {
      return path.concat(index);
    }

    for (let i = 0; i < questions.size; i++) {
      const question = questions.getIn([i]);
      const subTree = findInQuestionTree(
        question.get('children'),
        id,
        path.concat(i)
      );
      if (subTree.length > 0) {
        return subTree;
      }
    }
    return [];
  }
}
