import swedish from './sv_SE';
import english from './en_GB';
import englishUS from './en_US';
import norwegian from './nb_NO';
import danish from './da_DK';
import finnish from './fi_FI';
import spanish from './es_ES.json';
import french from './fr_FR.json';
import german from './de_DE.json';
import italian from './it_IT.json';
import { SELECTED_LANGUAGE } from 'globals/localStorage';

export default function (key, options = {}) {
  const { forceLanguage, replacements } = options;
  let result = swedish;

  const selectedLanguage = forceLanguage
    ? forceLanguage
    : (JSON.parse(localStorage.getItem(SELECTED_LANGUAGE)) || {}).code;

  switch (selectedLanguage) {
    case 'sv_SE': {
      result = swedish;
      break;
    }
    case 'en_US': {
      result = englishUS;
      break;
    }
    case 'en_GB': {
      result = english;
      break;
    }
    case 'nb_NO': {
      result = norwegian;
      break;
    }
    case 'da_DK': {
      result = danish;
      break;
    }
    case 'fi_FI': {
      result = finnish;
      break;
    }
    case 'es_ES': {
      result = spanish;
      break;
    }
    case 'de_DE': {
      result = german;
      break;
    }
    case 'fr_FR': {
      result = french;
      break;
    }
    case 'it_IT': {
      result = italian;
      break;
    }
    default: {
      result = swedish;
    }
  }

  try {
    key.split('.').some((keyPart) => {
      if (typeof result[keyPart] === 'string') {
        result = result[keyPart];
        return true;
      }
      result = result[keyPart];
      return false;
    });
    if (typeof result === 'object') {
      if (typeof result._ === 'string') {
        return doReplacements(result._);
      } else {
        console.error(`Missing language key ${key}`);
        return key;
      }
    } else if (typeof result === 'string') {
      return doReplacements(result);
    } else {
      console.error(`Missing language key ${key}`);
      return key;
    }
  } catch (err) {
    console.error(err);
    return key;
  }

  function doReplacements(key) {
    if (
      !replacements ||
      typeof replacements !== 'object' ||
      Object.keys(replacements).length === 0
    ) {
      return key;
    }

    let keyWithReplacements = key;

    Object.keys(replacements).forEach((key) => {
      keyWithReplacements = keyWithReplacements.replaceAll(
        `{${key}}`,
        replacements[key]
      );
    });

    return keyWithReplacements;
  }
}
