import { useState, useEffect } from 'react';
import { rawRequest } from 'actions/utils';

export function useUserInspectionTypes(hotelId) {
  const [allInspectionTypes, setAllInspectionTypes] = useState(null);
  const [inspectionTypes, setInspectionTypes] = useState(null);

  useEffect(() => {
    if (allInspectionTypes !== null) {
      setInspectionTypes(
        allInspectionTypes.filter((i) => i.hotelId === hotelId)
      );
      return;
    }
    try {
      rawRequest({
        path: `/api/inspection-types`,
      }).then((response) => {
        setInspectionTypes(response);
        setAllInspectionTypes(response);
      });
    } catch (error) {
      console.error(error);
    }
  }, [allInspectionTypes, hotelId]);

  return inspectionTypes;
}
