import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import urls from 'globals/urls';
import qs from 'qs';
import AuditQA from './AuditQA';
import auditQAActions from 'actions/admin/auditQA';

function mapStateToProps(state, ownProps) {
  const activeTab = qs.parse(ownProps.location.search.substring(1)).tab;

  return {
    list: state.getIn(['admin', 'auditQA', 'list']),
    error: state.getIn(['admin', 'auditQA', 'error']),
    loading: state.getIn(['admin', 'auditQA', 'loading']),
    activeTab,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onTabChange: (newTab) => {
      dispatch(
        push({
          pathname: urls.listAuditQA,
          search: `?tab=${newTab}`,
        })
      );
    },
    onRowClick: (inspection) => {
      const id = inspection.get('id');

      if (!inspection.get('agentEndTime') && !inspection.get('adminEndTime')) {
        return dispatch(push(urls.inspectionStart.replace(':id', id)));
      }

      if (!inspection.get('adminEndTime')) {
        return dispatch(push(urls.inspectionControl.replace(':id', id)));
      }

      return dispatch(push(urls.reportAuditQA.replace(':inspectionId', id)));
    },
    load: () => dispatch(auditQAActions.list()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditQA);
