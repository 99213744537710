import styled from 'styled-components';

export const Select = styled.select`
  width: 210px;
  outline: none;
  border: none;
  height: 30px;
`;
export const Label = styled.label`
  display: block;
  text-align: center;
  font-size: 0.7rem;
`;
export const SubFormWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Wrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const FieldError = styled.div`
  margin-left: 10px;
`;

export const ChoiceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 400px;
  justify-content: space-between;
  margin: 0.5rem;

  > span {
    font-weight: bold;
    align-self: center;
  }
`;
