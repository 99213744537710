import { push } from 'connected-react-router';
import axios from 'axios';
import { rescaleImage } from 'utils/file';
import { INTERNAL_UPLOAD_PREFIX } from 'reducers/fileUpload';

export function request({
  action,
  method = 'GET',
  path,
  body,
  redirect,
  failRedirect,
  extraPayload,
}) {
  const isFormData = body instanceof FormData;
  return async (dispatch) => {
    dispatch({
      type: `${action}_STARTED`,
      payload: extraPayload,
      meta: extraPayload,
    });
    let responseBody;
    const headers = {};

    if (!isFormData) {
      body = body && JSON.stringify(body);
      headers['Content-Type'] = 'application/json';
    }

    try {
      const response = await fetch(path, {
        method,
        body,
        headers,
        credentials: 'same-origin',
      });
      responseBody = await response.json();
      if (!response.ok) {
        dispatch({
          type: `${action}_FAILED`,
          payload: responseBody.msg,
          meta: extraPayload,
          error: responseBody.errors,
        });
        if (failRedirect) {
          dispatch(push(failRedirect));
        }
        return false;
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: `${action}_FAILED`,
        payload: 'errors.connection',
        meta: extraPayload,
        error: {},
      });
      if (failRedirect) {
        dispatch(push(failRedirect));
      }
      return false;
    }
    dispatch({
      type: `${action}_DONE`,
      payload: responseBody,
      meta: extraPayload,
    });
    if (redirect) {
      dispatch(push(redirect));
    }
    return true; //Use booleans to avoid try-catching
  };
}

export async function rawRequest({ method = 'GET', path, body }) {
  const isFormData = body instanceof FormData;

  const headers = {};

  if (!isFormData) {
    body = body && JSON.stringify(body);
    headers['Content-Type'] = 'application/json';
  }

  const response = await fetch(path, {
    method,
    body,
    headers,
    credentials: 'same-origin',
  });
  return await response.json();
}

export async function rawRequestComplete({ method = 'GET', path, body }) {
  const isFormData = body instanceof FormData;

  const headers = {};

  if (!isFormData) {
    body = body && JSON.stringify(body);
    headers['Content-Type'] = 'application/json';
  }

  const response = await fetch(path, {
    method,
    body,
    headers,
    credentials: 'same-origin',
  });
  return await response.json();
}

export async function uploadAttachment({ path, file, isFollowUp, dispatch }) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const extraPayload = {
    source,
    name: file && file.name,
  };

  dispatch({
    type: `${INTERNAL_UPLOAD_PREFIX}_STARTED`,
    payload: extraPayload,
    meta: extraPayload,
  });

  const body = new FormData();
  try {
    const rescaledFile = await rescaleImage(file, {
      maxWidth: 1280,
      maxHeight: 1280,
    });

    body.append('file', rescaledFile, rescaledFile.name);
  } catch (error) {
    console.error(error);
    body.append('file', file);
  }
  body.append('isFollowUp', !!isFollowUp);

  let responseBody;
  try {
    responseBody = await axios.post(path, body, {
      cancelToken: source.token,
      onUploadProgress: (progress) => {
        dispatch({
          type: `${INTERNAL_UPLOAD_PREFIX}_PROGRESS`,
          payload: {
            progress: progress.loaded / progress.total,
          },
          meta: extraPayload,
        });
      },
    });
    dispatch({
      type: `${INTERNAL_UPLOAD_PREFIX}_DONE`,
      payload: responseBody.data,
      meta: extraPayload,
    });
    setTimeout(() => {
      dispatch({
        type: `${INTERNAL_UPLOAD_PREFIX}_CLOSE_MODAL`,
        payload: '',
        meta: '',
        error: '',
      });
    }, 1200);
  } catch (error) {
    if (error.message === 'canceled') {
      dispatch({
        type: `${INTERNAL_UPLOAD_PREFIX}_CANCELED`,
        payload: 'upload.canceled',
        meta: extraPayload,
        error: '',
      });
      throw error;
    }
    dispatch({
      type: `${INTERNAL_UPLOAD_PREFIX}_FAILED`,
      payload: error.response && error.response.data && error.response.data.msg,
      meta: extraPayload,
      error:
        error.response && error.response.data && error.response.data.errors,
    });
    throw error;
  }

  return responseBody;
}
