import { css } from 'styled-components';

export const breakpoints = {
  handheld: 420,
  largeHandheld: 520,
  smallTablet: 600,
  tablet: 768,
  smallDesktop: 960,
  desktop: 1280,
  largeDesktop: 1920,
};

export default {
  ...Object.keys(breakpoints).reduce((prev, curr) => {
    prev[curr] = (...args) => css`
      @media (max-width: ${breakpoints[curr]}px) {
        ${css(...args)};
      }
    `;
    return prev;
  }, {}),
};
