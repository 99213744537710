import moment from 'moment';

export function getActiveModules(days) {
  const modules = [];

  days[0].modules.forEach((module) => {
    if (modules.includes(module.area)) {
      return;
    }
    modules.push(module.area);
  });

  return modules;
}

export function getPercentage(fraction, total) {
  return Math.ceil((fraction / total) * 100);
}

export function getAccumulatedFullfillment(days, area, comfortZone) {
  let values = days;

  if (area) {
    let filteredModules = days.map((day) =>
      day.modules.filter((m) => m.area === area)
    );
    filteredModules = filteredModules.reduce(
      (prev, curr) => prev.concat(curr),
      []
    );
    if (comfortZone) {
      filteredModules = filteredModules.map(
        (m) => m.questionsByComfortZones[comfortZone]
      );
      filteredModules = filteredModules.filter((m) => !!m);
    }
    values = filteredModules;
  }

  const total = values.reduce((prev, curr) => prev + curr.totalQuestions, 0);
  const answered = values.reduce(
    (prev, curr) => prev + curr.totalAnsweredQuestions,
    0
  );

  return getPercentage(answered, total);
}

export function getLineData(days, area) {
  return days.reduce((prev, current) => {
    const matchingModules = current.modules.filter(
      (m) => m.area === area && m.finishedAt
    );

    if (matchingModules.length === 0) {
      return prev;
    }

    const totalQuestions = matchingModules.reduce(
      (prev, curr) => prev + curr.totalQuestions,
      0
    );

    const totalAnsweredQuestions = matchingModules.reduce(
      (prev, curr) => prev + curr.totalAnsweredQuestions,
      0
    );

    return prev.concat({
      x: moment(current.date).format('YYYY-MM-DD'),
      y: getPercentage(totalAnsweredQuestions, totalQuestions),
    });
  }, []);
}

export function getAverage(data) {
  data = data.filter((series) => series.length !== 0);
  const longest = data.reduce(
    (prev, curr) => (prev.length < curr.length ? curr : prev),
    data[0] || []
  );

  return longest.map((point, index) => {
    return {
      y:
        data.reduce(
          (prev, curr) => prev + (curr[index] ? curr[index].y : 0),
          0
        ) / data.reduce((prev, curr) => prev + (curr[index] ? 1 : 0), 0),
      x: point.x,
    };
  });
}
