import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ListItemText from '@material-ui/core/ListItemText';
import translate from 'utils/translate';
import { getPercentage, filterAnsweredHotels } from './utils';

export default function WorstQuestionsDetailed({ hotels, week, area }) {
  hotels = filterAnsweredHotels({
    hotels,
    weeks: [week],
    areas: [area],
  });
  const questionsData = getQuestionsData({
    hotels,
    week,
    area,
  })
    .sort((a, b) => {
      const scorePercentageA = getPercentage(a.score, a.maxScore);
      const scorePercentageB = getPercentage(b.score, b.maxScore);
      return scorePercentageA > scorePercentageB
        ? 1
        : scorePercentageA < scorePercentageB
        ? -1
        : 0;
    })
    .slice(0, 5);

  if (!questionsData.length) return null;
  return (
    <div style={{ marginTop: '2rem' }}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>{translate('safeQReport.rank')}</TableCell>
            <TableCell>{translate('safeQReport.score')}</TableCell>
            <TableCell>{translate('safeQReport.answered')}</TableCell>
            <TableCell>{translate('safeQReport.referenceNumber')}</TableCell>
            <TableCell>{translate('safeQReport.oftenCount')}</TableCell>
            <TableCell>{translate('safeQReport.rarelyCount')}</TableCell>
            <TableCell>{translate('safeQReport.neverCount')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questionsData.map((questionData, index) => (
            <TableRow key={questionData.id}>
              <TableCell>{1 + index}</TableCell>
              <TableCell>
                {getPercentage(questionData.score, questionData.maxScore)}%
              </TableCell>
              <TableCell>
                {`${questionData.answeredCount} ${translate(
                  'of'
                ).toLowerCase()} ${
                  questionData.answeredCount + questionData.unansweredCount
                }`}
              </TableCell>
              <TableCell>
                <ListItemText
                  primary={questionData.referenceNumber}
                  secondary={questionData.content}
                />
              </TableCell>
              <TableCell>{questionData.oftenCount}</TableCell>
              <TableCell>{questionData.rarelyCount}</TableCell>
              <TableCell>{questionData.neverCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

function getQuestionsData({ hotels, week, area }) {
  return hotels.reduce(
    (acc, hotel) =>
      hotel.inspections
        .filter((inspection) => week === inspection.startWeek)
        .map((inspection) =>
          inspection.moduleTypes
            .filter((module) => module.area === area)
            .map((module) => module.questions)
        )
        .flat(2)
        .reduce(reduceQuestionData, acc),
    []
  );
}

function reduceQuestionData(acc, question) {
  const isAnswered = question.scores.length;
  const prev = acc.find((match) => match.id === question.id);

  const data = isAnswered
    ? formatAnsweredData(prev, question)
    : formatUnansweredData(prev, question);

  if (!prev) return acc.concat(data);
  return acc.filter((prev) => prev.id !== question.id).concat(data);
}

function formatAnsweredData(prev, question) {
  const answeredCount = prev ? (prev.answeredCount || 0) + 1 : 1;
  const unansweredCount = prev ? prev.unansweredCount || 0 : 0;
  const questionScore = question.scores
    .slice(0, 1)
    .reduce((total, score) => total + 3 - score, 0);
  const score = prev ? prev.score + questionScore : questionScore;
  const maxScore = prev ? prev.maxScore + 3 : 3;
  const oftenCount =
    (questionScore === 2 ? 1 : 0) + (prev ? prev.oftenCount || 0 : 0);

  const rarelyCount =
    (questionScore === 1 ? 1 : 0) + (prev ? prev.rarelyCount || 0 : 0);

  const neverCount =
    (questionScore === 0 ? 1 : 0) + (prev ? prev.neverCount || 0 : 0);

  return {
    id: question.id,
    score,
    maxScore,
    content: question.content,
    referenceNumber: question.referenceNumber,
    answeredCount,
    unansweredCount,
    oftenCount,
    rarelyCount,
    neverCount,
  };
}

function formatUnansweredData(prev, question) {
  const answeredCount = prev ? prev.answeredCount || 0 : 0;
  const unansweredCount = prev ? (prev.unansweredCount || 0) + 1 : 1;
  const score = prev ? prev.score : 0;
  const maxScore = prev ? prev.maxScore + 3 : 3;
  const oftenCount = prev ? prev.oftenCount || 0 : 0;
  const rarelyCount = prev ? prev.rarelyCount || 0 : 0;
  const neverCount = prev ? prev.neverCount || 0 : 0;

  return {
    id: question.id,
    score,
    maxScore,
    content: question.content,
    referenceNumber: question.referenceNumber,
    answeredCount,
    unansweredCount,
    oftenCount,
    rarelyCount,
    neverCount,
  };
}
