import React from 'react';
import QuestionRow from 'components/inspection/Questions/QuestionRow';
import Score from 'components/inspection/Questions/QuestionRow/Score';
import Radio from 'components/inspection/Questions/QuestionRow/Radio';
import { fromJS } from 'immutable';
import { ClusterWrapper } from '../RegularInspectionQuestions/styled';

export default function Questions({
  questions,
  inspectionId,
  user,
  updateQuestion,
}) {
  return (
    <ClusterWrapper>
      {questions.map((question, i) => (
        <QuestionRow
          question={question}
          key={question.get('id')}
          inspectionId={inspectionId}
          score={question.get('score')}
          comment={question.get('comment')}
          requiredCommentMinScore={question.get('requiredCommentMinScore')}
          questionContent={question.get('content')}
          questionId={question.get('id')}
          requestParams={{ id: question.get('id') }}
          loading={question.get('loading')}
          error={question.get('error')}
          files={
            question.get('attachment')
              ? fromJS([{ path: question.get('attachment'), id: 'unused' }])
              : fromJS([])
          }
          deleteFile={() =>
            updateQuestion({
              inspectionId,
              questionId: question.get('id'),
              payload: {},
              deleteFile: true,
            })
          }
          uploadFile={({ file }) =>
            updateQuestion({
              inspectionId,
              questionId: question.get('id'),
              payload: { file },
            })
          }
          renderScore={({
            score,
            requestParams,
            key,
            requiredCommentMinScore,
            onRequiredComment,
          }) => {
            if (question.get('type') === 'radio') {
              return (
                <Radio key={key} score={score} requestParams={requestParams} />
              );
            }
            return (
              <Score
                key={key}
                user={user}
                score={score}
                requestParams={requestParams}
                setScore={({ score }) =>
                  updateQuestion({
                    inspectionId,
                    questionId: question.get('id'),
                    payload: { score },
                  })
                }
              />
            );
          }}
        />
      ))}
    </ClusterWrapper>
  );
}
