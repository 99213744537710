import React, { useEffect } from 'react';
import translate from 'utils/translate';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';
import { useUserHotels } from 'hooks/useUserHotels';
import { useUserInspectionTypes } from 'hooks/useUserInspectionTypes';
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Form = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-flow: dense;
  grid-column-gap: 10px;
`;

// export default function ComboBox() {
//   return (
//     <Autocomplete
//       id='combo-box-demo'
//       options={top100Films}
//       getOptionLabel={(option) => option.title}
//       style={{ width: 300 }}
//       renderInput={(params) => (
//         <TextField {...params} label='Combo box' variant='outlined' />
//       )}
//     />
//   );
// }

export default function InspectionForm({
  inspection,
  onChange,
  disabled,
  hotels,
  inspectionTypes,
  users,
}) {
  const handleChange = (name, value) => {
    onChange({
      inspection: {
        ...inspection,
        [name]: value,
      },
      field: name,
      value,
    });
  };

  let internalHotels = useUserHotels();
  let internalInspectionTypes = useUserInspectionTypes(inspection.hotel);
  const inputMargin = 'dense';

  if (hotels) {
    internalHotels = hotels;
  }
  if (inspectionTypes) {
    internalInspectionTypes = inspectionTypes;
  }

  useEffect(() => {
    if (internalHotels && internalHotels.length === 1) {
      handleChange('hotel', internalHotels[0].id);
    }
    // We don't want a dependency on handle change since this would cause
    // excessive updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalHotels]);

  return (
    <Form>
      <FormControl variant='outlined' fullWidth margin={inputMargin} required>
        <Autocomplete
          options={internalHotels || []}
          getOptionLabel={(option) => {
            if (internalHotels && typeof option !== 'object') {
              const selectedHotel = internalHotels.find((h) => h.id === option);
              return selectedHotel ? selectedHotel.name : '';
            }
            return option.name;
          }}
          style={{ width: 300 }}
          value={inspection.hotel}
          disableClearable
          onChange={(event, newValue) => {
            if (newValue) {
              handleChange('hotel', newValue.id);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              margin='dense'
              label={translate('inspections.create.hotel')}
              variant='outlined'
            />
          )}
        />
      </FormControl>
      <FormControl variant='outlined' fullWidth margin={inputMargin} required>
        <InputLabel id='inspection-form-type-label'>
          {translate('inspections.choose.type')}
        </InputLabel>
        <Select
          fullWidth
          required
          disabled={
            disabled ||
            !internalInspectionTypes ||
            internalInspectionTypes.length === 0
          }
          labelId='inspection-form-type-label'
          value={inspection.inspectionType}
          onChange={(event) =>
            handleChange('inspectionType', event.target.value)
          }
          label={translate('inspections.choose.type')}
        >
          {(internalInspectionTypes || []).map((inspectionType) => (
            <MenuItem key={inspectionType.id} value={inspectionType.id}>
              {inspectionType.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <KeyboardDatePicker
        required
        variant='inline'
        margin={inputMargin}
        inputVariant='outlined'
        label={translate('inspections.create.date')}
        format='yyyy-MM-DD'
        value={inspection.startTime}
        onChange={(date) => handleChange('startTime', date)}
      />
      <KeyboardTimePicker
        required
        margin={inputMargin}
        variant='inline'
        inputVariant='outlined'
        label={translate('inspections.create.time')}
        value={inspection.startTimeHour}
        onChange={(date) => handleChange('startTimeHour', date)}
      />
      {users && (
        <FormControl variant='outlined' fullWidth margin={inputMargin}>
          <Autocomplete
            options={users}
            getOptionLabel={(option) => {
              if (typeof option !== 'object') {
                const selectedUser = users.find((u) => u.id === option);
                return selectedUser ? selectedUser.name : '';
              }
              return option.name;
            }}
            style={{ width: 300 }}
            value={inspection.user ? inspection.user : null}
            disableClearable
            onChange={(event, newValue) => {
              if (newValue) {
                handleChange('user', newValue.id);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                margin='dense'
                label={translate('inspections.choose.user')}
                variant='outlined'
              />
            )}
          />
        </FormControl>
      )}
      <FormControlLabel
        label={translate('inspections.shouldSendNotification')}
        labelPlacement='right'
        control={
          <Checkbox
            name='shouldSendNotification'
            checked={!!inspection.shouldSendNotification}
            onChange={() =>
              handleChange(
                'shouldSendNotification',
                !inspection.shouldSendNotification
              )
            }
          />
        }
      />
    </Form>
  );
}
