import urls from 'globals/urls';

export const stages = ['inspections', 'inspectionStart', 'inspectionQuestions', 'inspectionControl'];

const defaultStage = 'inspections';
const stagePatterns = {};
stagePatterns.inspections = new RegExp(`^${urls.inspections}$`);
stagePatterns.inspectionQuestions = new RegExp(
  `^${urls.inspectionQuestions
    .replace(':id', '[0-9]+')
    .replace(':moduleId', '[0-9]+')}$|^${urls.inspectionStart.replace(':id', '[0-9]+')}$`
);
stagePatterns.inspectionControl = new RegExp(`^${urls.inspectionControl.replace(':id', '[0-9]+')}$`);

export default function parseStage(path) {
  const matchedStages = Object.entries(stagePatterns).find(([key, pattern]) => pattern.test(path));

  return matchedStages ? matchedStages[0] : defaultStage;
}
