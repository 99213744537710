import React from 'react';
import translate from 'utils/translate';
import TextField from '@material-ui/core/TextField';
import SimpleSelect from './SimpleSelect';
import styled from 'styled-components';

const Form = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-flow: dense;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
`;

export default function ModuleTypeForm({
  moduleType = {},
  onChange,
  disabled,
  areas,
  classifications,
}) {
  const handleChange = (name, value) => {
    onChange({
      ...moduleType,
      [name]: value,
    });
  };

  return (
    <Form>
      <TextField
        disabled={disabled}
        margin='dense'
        label={translate('questions.editModuleType.name')}
        value={moduleType.name || ''}
        variant='outlined'
        onChange={(event) => handleChange('name', event.target.value)}
      />
      <SimpleSelect
        disabled={disabled}
        label={translate('questions.editModuleType.classification')}
        value={moduleType.classification}
        onChange={(event) => handleChange('classification', event.target.value)}
        items={classifications.map((classification) => ({
          value: classification,
          label: translate(
            `moduleTypeClassifications.${classification.toLowerCase()}`
          ),
        }))}
      />
      <SimpleSelect
        disabled={disabled}
        label={translate('questions.editModuleType.area')}
        value={moduleType.area}
        onChange={(event) => handleChange('area', event.target.value)}
        items={areas.map((area) => ({
          value: area,
          label: translate(`moduleTypeAreas.${area}`),
        }))}
      />
    </Form>
  );
}
