import React from 'react';
import Typography from '@material-ui/core/Typography';
import translate from 'utils/translate';
import Error from 'components/general/Error';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import { breakpoints } from 'globals/media';
import { useScreenSize } from 'hooks/useScreenSize';
import LoadingOverlay from 'components/general/LoadingOverlay';
import Zoom from '@material-ui/core/Zoom';
import LinkIcon from '@material-ui/icons/Link';

const Wrapper = styled.div`
  position: relative;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RowButton = styled(IconButton)`
  padding: 4px !important;
  margin: 4px !important;
`;

const StyledRow = styled(TableRow)`
  cursor: ${(props) => (props.hover ? 'pointer' : 'default')};
`;

export default function InspectionTypes({
  error,
  loading,
  inspectionTypes,
  onRenderRowActions,
  onRenderHeaderActions,
  openLinkModal,
  onClick,
  openCreateModal,
  openEditModal,
  openDeleteModal,
}) {
  const screenSize = useScreenSize();
  const theme = useTheme();

  return (
    <Wrapper>
      <LoadingOverlay show={loading} />
      <Header>
        <Typography variant='h5' component='h2' gutterBottom>
          {translate('inspectionTypes.administration')}
        </Typography>
        {onRenderHeaderActions ? (
          onRenderHeaderActions()
        ) : (
          <Zoom
            in={true}
            timeout={{
              enter: theme.transitions.duration.enteringScreen,
              exit: theme.transitions.duration.leavingScreen,
            }}
            unmountOnExit
          >
            <Fab
              variant={
                screenSize.width < breakpoints.tablet ? 'round' : 'extended'
              }
              style={{
                position:
                  screenSize.width < breakpoints.tablet ? 'fixed' : 'relative',
                bottom:
                  screenSize.width < breakpoints.tablet ? theme.spacing(2) : 0,
                right:
                  screenSize.width < breakpoints.tablet ? theme.spacing(2) : 0,
              }}
              color='primary'
              onClick={() => openCreateModal()}
              label={translate('inspectionTypes.create')}
            >
              <AddIcon />
              {screenSize.width > breakpoints.tablet &&
                translate('inspectionTypes.create')}
            </Fab>
          </Zoom>
        )}
      </Header>
      {error && <Error>{translate(error)}</Error>}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{translate('inspectionTypes.create.name')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inspectionTypes.map((inspectionType) => (
            <StyledRow
              key={inspectionType.get('id')}
              hover={!!onClick}
              onClick={(event) => onClick && onClick(inspectionType.get('id'))}
            >
              <TableCell>{inspectionType.get('name')}</TableCell>
              <TableCell style={{ width: '120px' }} padding='none'>
                {onRenderRowActions ? (
                  onRenderRowActions(inspectionType)
                ) : (
                  <>
                    {openLinkModal && (
                      <Tooltip title={translate('inspectionTypes.link.hotel')}>
                        <RowButton
                          size='small'
                          onClick={(event) => {
                            event.stopPropagation();
                            openLinkModal(inspectionType);
                          }}
                        >
                          <LinkIcon />
                        </RowButton>
                      </Tooltip>
                    )}
                    {openEditModal && (
                      <Tooltip title={translate('edit')}>
                        <RowButton
                          size='small'
                          onClick={(event) => {
                            event.stopPropagation();
                            openEditModal(inspectionType);
                          }}
                        >
                          <EditIcon />
                        </RowButton>
                      </Tooltip>
                    )}
                    <Tooltip title={translate('inspectionTypes.delete')}>
                      <RowButton
                        size='small'
                        onClick={(event) => {
                          event.stopPropagation();
                          openDeleteModal(inspectionType.toJS());
                        }}
                      >
                        <DeleteOutlineIcon />
                      </RowButton>
                    </Tooltip>
                  </>
                )}
              </TableCell>
            </StyledRow>
          ))}
        </TableBody>
      </Table>
    </Wrapper>
  );
}
