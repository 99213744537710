import done from './done';
import list from './list';
import load from './load';
import update from './update';
import loadControl from './loadControl';
import setExternalScores from './setExternalScores';
import setGeneralComments from './setGeneralComments';
import setEndTime from './setEndTime';
import { push } from 'connected-react-router';
import urls from 'globals/urls';
import followUpSummary from './followUpSummary';

export default {
  done,
  list,
  load,
  update,
  followUpSummary,
  loadControl,
  setGeneralComments,
  setExternalScores,
  setEndTime,
  openSubmitModal,
  closeSubmitModal,
  openExternalScoresModal,
  closeExternalScoresModal,
  openGeneralCommentsModal,
  closeGeneralCommentsModal,
  goToControl,
  openInspection,
};

export function goToControl(inspectionId) {
  return push(urls.inspectionControl.replace(':id', inspectionId));
}

export function openInspection(inspectionId) {
  return push(urls.inspectionStart.replace(':id', inspectionId));
}

export function openSubmitModal() {
  return {
    type: 'OPEN_SUBMIT_INSPECTION_MODAL',
  };
}

export function closeSubmitModal() {
  return {
    type: 'CLOSE_SUBMIT_INSPECTION_MODAL',
  };
}
export function openExternalScoresModal(focusField) {
  return {
    type: 'OPEN_EXTERNAL_SCORES_MODAL',
    payload: { focusField },
  };
}

export function closeExternalScoresModal() {
  return {
    type: 'CLOSE_EXTERNAL_SCORES_MODAL',
  };
}

export function openGeneralCommentsModal() {
  return {
    type: 'OPEN_GENERAL_COMMENTS_MODAL',
  };
}

export function closeGeneralCommentsModal() {
  return {
    type: 'CLOSE_GENERAL_COMMENTS_MODAL',
  };
}
