import { fromJS } from 'immutable';

const defaultState = fromJS({
  current: {
    id: 0,
    name: '',
    agentCode: '',
    email: '',
    password: '',
    role: '',
    profile: '',
    hotels: [],
  },
  loading: false,
  error: '',
});

export default function (state = defaultState, action = { type: '' }) {
  switch (action.type) {
    case 'LOAD_USER_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'LOAD_USER_DONE': {
      return state.set('current', fromJS(action.payload)).set('loading', false);
    }
    case 'EDIT_USER_STARTED':
    case 'LINK_USER_TO_HOTEL_STARTED':
    case 'LINK_USER_TO_INSPECTION_TYPE_STARTED':
    case 'UNLINK_USER_FROM_INSPECTION_TYPE_STARTED':
    case 'UNLINK_USER_FROM_HOTEL_STARTED': {
      return state.set('loading', true);
    }
    case 'LOAD_USER_FAILED':
    case 'EDIT_USER_FAILED':
    case 'LINK_USER_TO_HOTEL_FAILED':
    case 'LINK_USER_TO_INSPECTION_TYPE_FAILED':
    case 'UNLINK_USER_FROM_INSPECTION_TYPE_FAILED':
    case 'UNLINK_USER_FROM_HOTEL_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'EDIT_USER_DONE':
    case 'LINK_USER_TO_HOTEL_DONE':
    case 'LINK_USER_TO_INSPECTION_TYPE_DONE':
    case 'UNLINK_USER_FROM_INSPECTION_TYPE_DONE':
    case 'UNLINK_USER_FROM_HOTEL_DONE': {
      return state.set('loading', false);
    }
    default: {
      return state;
    }
  }
}
