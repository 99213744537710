import React, { useCallback } from 'react';
import QuestionSummary from './QuestionSummary';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import translate from 'utils/translate';
import styled from 'styled-components';
import PointCircle from './PointCircle';

const Container = styled.div`
  page-break-inside: avoid;
`;

const Header = styled.div`
  @media print {
    page-break-inside: avoid;
  }
`;

const Legend = styled.ul`
  padding: 0;
  li {
    display: flex;
    align-items: center;

    margin-top: 5px;
    &:first-child {
      margin-top: 0;
    }
    span {
      margin-left: 0.4rem;
    }
  }
`;

const titleMap = {
  '-1': translate('NA'),
  null: translate('safeQReport.notAnswered'),
};

export default function ModuleSummary({ report, area, title, show, first }) {
  const flattenModules = useCallback(() => {
    let modules = report.map((day) =>
      day.modules.filter((m) => m.area === area)
    );

    modules = modules.map((moduleList, index) => {
      let questions = [];
      let comments = [];

      moduleList.forEach((m) =>
        m.clusters.forEach((cluster) => {
          questions = questions.concat(cluster.questions);
          comments = comments.concat(cluster.comments.map((c) => c.comment));
        })
      );
      return {
        comments,
        questions: questions.map((q) => ({ ...q, date: report[index].date })),
      };
    });
    return modules;
  }, [report, area]);

  if (!show) {
    return null;
  }

  const startDay = report[0].date;
  const flattenedReports = flattenModules();

  const comments = flattenedReports[0].comments.join('\n');
  return (
    <Container>
      {first && (
        <>
          <h2 className='centered'>{translate('remarks').toUpperCase()}</h2>
          <Legend>
            <li>
              <PointCircle score={3} />
              <span>{translate('questions.radioOptions.never')}</span>
            </li>
            <li>
              <PointCircle score={2} />
              <span>{translate('questions.radioOptions.rarely')}</span>
            </li>
            <li>
              <PointCircle score={1} />
              <span>{translate('questions.radioOptions.often')}</span>
            </li>
            <li>
              <PointCircle score={-1} />
              <span>{translate('NA')}</span>
            </li>
            <li>
              <PointCircle score={null} />
              <span>{translate('safeQReport.notAnswered')}</span>
            </li>
          </Legend>
        </>
      )}
      <Header>
        <h3>{title}</h3>
        <Typography variant='body1'>
          {translate('date')}: {moment(report[0].date).format('YYYY-MM-DD')}
        </Typography>
        {comments && (
          <Typography variant='body1'>
            {translate('comments')}: {comments}
          </Typography>
        )}
      </Header>
      <div style={{ marginTop: '60px' }}>
        {flattenedReports[0].questions
          .filter((q) => q.score !== 0)
          .sort((qA, qB) => {
            if (qA.score === qB.score) {
              return 0;
            }

            return qA.score > qB.score ? -1 : 1;
          })
          .map((question, index, questions) => {
            const allDays = flattenedReports
              .map(
                (report) =>
                  report.questions.filter((q) => q.id === question.id)[0]
              )
              .filter((q) => !!q);

            const shouldInsertSubTitle =
              !questions[index - 1] ||
              questions[index - 1].score !== question.score;

            let subTitle = null;

            if (shouldInsertSubTitle) {
              subTitle = titleMap[String(question.score)] || null;
            }

            return (
              <>
                {subTitle && (
                  <Typography variant='h5' component='h4' color='primary'>
                    {subTitle}
                  </Typography>
                )}
                <QuestionSummary
                  key={question.id}
                  index={index}
                  startDay={startDay}
                  referenceNumber={question.referenceNumber || '-'}
                  content={question.content}
                  days={allDays}
                  files={question.attachments}
                  comment={question.comment}
                />
              </>
            );
          })}
      </div>
    </Container>
  );
}
