import React from 'react';
import translate from 'utils/translate';
import {QuestionAnswer} from './styled';

export default function GeneralComments({data}) {
  return (
    <div className='page-break-after page-break-inside'>
      <h3 className='centered'>GENERAL COMMENTS</h3>
      <QuestionAnswer>{data || translate('report.unanswered')}</QuestionAnswer>
    </div>
  );
}
