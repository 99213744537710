import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import translate from 'utils/translate';
import Message from 'components/general/Message';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LoadingOverlay from 'components/general/LoadingOverlay';
import Logo from 'components/general/Logo';
import { requestPasswordReset } from './actions';
import {
  Container,
  Form,
  Header,
  LogoContainer,
  InnerWrapper,
} from '../styled/Form';

const initialState = {
  email: '',
  loading: false,
  success: false,
  error: '',
};

export default function RequestPasswordResetForm() {
  const [state, setState] = useState(initialState);
  const { email, loading, error, success } = state;

  return (
    <Container>
      <InnerWrapper>
        <Paper>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              if (!email) {
                return setState({
                  ...state,
                  error: 'login.missingEmail',
                  success: false,
                });
              }
              setState((oldState) => ({
                ...oldState,
                loading: true,
                success: false,
                error: '',
              }));
              try {
                const response = await requestPasswordReset(email);
                if (response.msg === 'missing.parameters') {
                  return setState((oldState) => ({
                    ...oldState,
                    error: 'login.missingEmail',
                  }));
                }
              } catch (error) {
                console.error(error);
                return setState((oldState) => ({
                  ...oldState,
                  error: 'errors.server',
                }));
              }
              setState((oldState) => ({
                ...oldState,
                loading: false,
                email: '',
                success: true,
              }));
            }}
          >
            <Header>
              <LogoContainer>
                <Logo height={'40'} text />
              </LogoContainer>
              <Typography variant='h6' component='h1' color='primary'>
                {translate('site.name')}
              </Typography>
            </Header>
            <Typography
              variant='h6'
              component='h2'
              style={{ alignSelf: 'flex-start' }}
            >
              {translate('login.forgotPassword')}
            </Typography>
            <Typography style={{ alignSelf: 'flex-start' }} gutterBottom>
              {translate('login.requestResetInstructions')}
            </Typography>
            <TextField
              fullWidth
              type='text'
              value={email}
              label={translate('login.email')}
              onChange={(e) => {
                setState({
                  ...state,
                  email: e.target.value,
                });
              }}
            />
            <Button color='primary' variant='contained' type='submit'>
              {translate('login.sendRecoveryLink')}
            </Button>
          </Form>
        </Paper>
        <LoadingOverlay show={loading} />
        <Message
          type={error ? 'error' : 'success'}
          style={{ width: 'initial' }}
          show={error || success}
        >
          {success
            ? translate('login.requestResetSuccess')
            : error && translate(error)}
        </Message>
      </InnerWrapper>
    </Container>
  );
}
