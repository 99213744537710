import React, { useState, useEffect } from 'react';
import { List } from 'immutable';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Error from 'components/general/Error';
import LoadingOverlay from 'components/general/LoadingOverlay';
import ClearIcon from '@material-ui/icons/Clear';
import translate from 'utils/translate';

export default function SelectHotelsModal({
  visible = true,
  loading,
  error,
  onClose,
  allHotels,
  selectedHotels,
  setSelectedHotels,
}) {
  const [localSelectedHotels, setLocalSelectedHotels] = useState(List());
  const [filterText, setFilterText] = useState('');
  useEffect(() => {
    setLocalSelectedHotels(selectedHotels);
  }, [selectedHotels]);

  const hotels = (allHotels || List()).filter((hotel) =>
    hotel.get('name').toLowerCase().includes(filterText.toLowerCase())
  );

  function onHotelSelected(selectedHotel) {
    const hotelIndex = localSelectedHotels.findIndex(
      (hotel) => hotel.get('id') === selectedHotel.get('id')
    );
    if (hotelIndex === -1) {
      setLocalSelectedHotels(localSelectedHotels.concat([selectedHotel]));
      return;
    }
    setLocalSelectedHotels(
      localSelectedHotels
        .slice(0, hotelIndex)
        .concat(localSelectedHotels.slice(hotelIndex + 1))
    );
  }

  return (
    <Dialog open={visible} onClose={onClose} maxWidth='md' scroll='paper'>
      <DialogTitle>{translate('statistics.selectHotels')}</DialogTitle>
      <LoadingOverlay show={loading} minDisplayTime={500} />
      <DialogContent dividers>
        {error && <Error>{translate(error)}</Error>}
        <TextField
          fullWidth
          value={filterText}
          placeholder={translate('hotel.find')}
          onChange={(event) => setFilterText(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: filterText ? (
              <InputAdornment position='end'>
                <IconButton size='small' onClick={() => setFilterText('')}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        />
        <div style={{ height: 'min-content', overflowY: 'scroll' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={hotels && hotels.size === localSelectedHotels.size}
                    onChange={() => {
                      if (hotels.size === localSelectedHotels.size) {
                        setLocalSelectedHotels(List());
                      } else {
                        setLocalSelectedHotels(hotels);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>{translate('hotels.create.name')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hotels.map((hotel) => {
                const isItemSelected = localSelectedHotels.some(
                  (selectedHotel) => selectedHotel.get('id') === hotel.get('id')
                );
                return (
                  <TableRow
                    key={hotel.get('id')}
                    hover
                    onClick={() => onHotelSelected(hotel)}
                    selected={isItemSelected}
                  >
                    <TableCell padding='checkbox'>
                      <Checkbox checked={isItemSelected} />
                    </TableCell>
                    <TableCell>{hotel.get('name')}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          {translate('back')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            setSelectedHotels(localSelectedHotels);
            onClose();
          }}
        >
          {translate('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
