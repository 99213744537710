import { useEffect, useState } from 'react';
import { rawRequest } from 'actions/utils';
import { formatHotelData } from 'components/admin/Hotel/WeeklyReport/utils';
import { getSelectedLanguage } from 'utils/language';

export default function useWeeklyData(hotelId, day) {
  const [hotels, setHotels] = useState(null);
  const language = getSelectedLanguage();

  useEffect(() => {
    if (!hotelId || hotelId < 0) return;
    rawRequest({
      path: `/api/admin/hotels/${hotelId}/safeq-report/weekly?${[
        day ? `day=${day}` : '',
        language.shortCode ? `languageShortCode=${language.shortCode}` : '',
      ]
        .filter((p) => p)
        .join('&')}`,
    })
      .then((response) => {
        if (response.msg) {
          setHotels([]);
          throw new Error(response.msg);
        }
        setHotels(formatHotelData(response));
      })
      .catch(console.error);
  }, [hotelId, day, language.shortCode]);

  return hotels;
}
