import load from './loadTypes';
import { request } from 'actions/utils';

export default function (moduleType) {
  return async (dispatch) => {
    let success = await dispatch(
      request({
        action: 'CREATE_MODULE_TYPE',
        path: `/api/admin/inspection-module-types`,
        method: 'POST',
        body: {
          ...moduleType,
        },
      })
    );
    if (success) {
      dispatch(load());
    }
  };
}
