const INITIAL_STATE = {
  name: '',
  ordinal: 0,
  id: 0,
  loading: false,
  error: ''
};

export default function reducer(state = INITIAL_STATE, action = {type: ''}) {
  switch (action.type) {
    case 'INITIALIZE_FROM_PROPS': {
      return {...state, ...action.payload};
    }
    case 'UPDATE_CLUSTER_STARTED': {
      return {...state, loading: true, error: ''};
    }
    case 'UPDATE_CLUSTER_FAILED': {
      return {...state, loading: false, error: action.payload};
    }
    case 'UPDATE_CLUSTER_DONE': {
      return {...state, loading: false};
    }
    case 'CHANGE_INPUT': {
      const {field, value} = action.payload;
      return {...state, [field]: value};
    }
    default:
      return state;
  }
}
