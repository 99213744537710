import React from 'react';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import translate from 'utils/translate';
import SimpleSelect from './SimpleSelect';
import InspectionTypeAutomationForm from './InspectionTypeAutomationForm';
import InspectionTypeNotificationForm from './InspectionTypeNotificationForm';

const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;

  .MuiAccordion-root:not(.Mui-expanded),
  .MuiAccordion-root.Mui-expanded {
    margin: 5px 0 8px;
  }

  .MuiAccordionSummary-root {
    &.Mui-expanded {
      min-height: auto;
    }
    .MuiIconButton-root {
      padding: 6px;
    }
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 5px 0;
  }
`;

export default function InspectionTypeForm(props) {

  const {
    onChange,
    name,
    role,
    limited,
    reportType,
    allowUnansweredQuestions,
    isSelfServe,
  } = props;
  
  return (
    <Form>
      {!limited && (
        <TextField
          required
          margin='normal'
          variant='outlined'
          label={translate('inspectionTypes.create.name')}
          value={name || ''}
          onChange={(event) => onChange('name', event.target.value)}
        />
      )}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            {translate('inspectionTypes.create.autoSettings')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <InspectionTypeAutomationForm {...props}/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            {translate('inspectionTypes.notifications.notificationSettings')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <InspectionTypeNotificationForm {...props}/>
        </AccordionDetails>
      </Accordion>
      {!limited && (
        <>
          <SimpleSelect
            disabled={!['superUser'].includes(role)}
            label={translate('inspectionTypes.reportType.label')}
            value={reportType}
            onChange={(event) => onChange('reportType', event.target.value)}
            items={['agentQ', 'auditQA', 'safeQ'].map((type) => ({
              value: type,
              label: translate(`inspectionTypes.reportType.${type}`),
            }))}
          />
          <FormControlLabel
            label={translate('inspectionTypes.allowUnansweredQuestions')}
            labelPlacement='start'
            control={
              <Checkbox
                color='primary'
                name='allowUnansweredQuestions'
                checked={allowUnansweredQuestions || false}
                onChange={(event) =>
                  onChange('allowUnansweredQuestions', event.target.checked)
                }
              />
            }
          />
          <FormControlLabel
            label={translate('inspectionTypes.isSelfServe')}
            labelPlacement='start'
            control={
              <Checkbox
                color='primary'
                name='isSelfServe'
                checked={isSelfServe || false}
                onChange={(event) => onChange('isSelfServe', event.target.checked)}
              />
            }
          />
        </>
      )}
    </Form>
  );
}
