import React, { useState, useEffect } from 'react';
import Fade from '@material-ui/core/Fade';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: ${(props) => (props.block ? 'all' : 'none')};
  z-index: 10;
  top: 0;
  left: 0;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  outline: none;
  background: rgba(255, 255, 255, 0.4);
`;

export default function LoadingOverlay({ show, minDisplayTime }) {
  const [openedTime, setOpenedTime] = useState(0);
  const [internalShow, setInternalShow] = useState(false);

  useEffect(() => {
    if (internalShow === show) {
      return;
    }
    if (show) {
      setOpenedTime(new Date().getTime());
      setInternalShow(show);
      return;
    }
    if (!minDisplayTime) {
      setInternalShow(show);
      return;
    }
    const diff = new Date().getTime() - openedTime;
    if (diff < minDisplayTime) {
      setTimeout(() => {
        setInternalShow(show);
      }, minDisplayTime);
    }
  }, [show, minDisplayTime, openedTime, internalShow]);

  return (
    <Overlay block={internalShow}>
      <Fade in={internalShow}>
        <Content>
          <CircularProgress />
        </Content>
      </Fade>
    </Overlay>
  );
}
