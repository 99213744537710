import {request} from 'actions/utils';

export default function deleteFile({inspectionId, questionId, moduleId, fileId}) {
  return request({
    action: 'DELETE_FILE',
    path: `/api/inspections/${inspectionId}/modules/${moduleId}/questions/${questionId}/file/${fileId}`,
    method: 'DELETE',
    extraPayload: {
      questionId,
      fileId,
      moduleId
    }
  });
}
