import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import urls from 'globals/urls';
import ActionPlanList from './ActionPlanList';
import auditQAActions from 'actions/admin/auditQA';

function mapStateToProps(state, ownProps) {
  return {
    list: state.getIn(['admin', 'auditQA', 'actionPlans']),
    error: state.getIn(['admin', 'auditQA', 'error']),
    loading: state.getIn(['admin', 'auditQA', 'loading']),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onRowClick: (inspection) => {
      const id = inspection.get('id');

      return dispatch(push(urls.inspectionActionPlan.replace(':id', id)));
    },
    load: () => dispatch(auditQAActions.listActionPlans()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionPlanList);
