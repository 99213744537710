import styled from 'styled-components';
import media, { breakpoints } from 'globals/media';

export default styled.span`
  margin: ${({ slim, verySlim }) => (verySlim ? '0px' : slim ? '5px' : '10px')}
    auto;
  ${media.handheld`
    flex: inherit;
  `};
  @media (min-width: ${breakpoints.tablet}px) {
    ${({ rightAligned }) =>
      rightAligned ? 'margin-left: 0; margin-right: auto;' : ''}
  }
`;
