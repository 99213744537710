import { request } from 'actions/utils';

export default {
  hotels: loadHotels,
  users: loadUsers,
  inspectionTypes: loadInspectionTypes,
  reportDays: loadReportDays,
  settings: loadSettings,
};

export function loadHotels(id) {
  return request({
    action: 'LOAD_ORGANIZATION',
    path: `/api/admin/organizations/${id === null ? 'my' : id}/hotels`,
  });
}

export function loadUsers(id) {
  return request({
    action: 'LOAD_ORGANIZATION',
    path: `/api/admin/organizations/${id === null ? 'my' : id}/users`,
  });
}

export function loadReportDays(id) {
  return request({
    action: 'LOAD_ORGANIZATION',
    path: `/api/admin/organizations/${id === null ? 'my' : id}/report-days`,
  });
}

export function loadInspectionTypes(id) {
  return request({
    action: 'LOAD_ORGANIZATION',
    path: `/api/admin/organizations/${id === null ? 'my' : id}/inspectionTypes`,
  });
}

export function loadSettings(id) {
  return request({
    action: 'LOAD_ORGANIZATION',
    path: `/api/admin/organizations/${id === null ? 'my' : id}/inspectionTypes`,
  });
}
