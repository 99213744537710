import React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import inspectionActions from 'actions/inspection';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

function SubmitModal({
  visible,
  loading,
  error,
  closeSubmitModal: closeModal,
  goToControl,
  inspectionId,
  showSubmit,
  extraQuestions,
  modules = List(),
  allowUnansweredQuestions,
  done,
}) {
  if (!visible) {
    return null;
  }
  const hasUnansweredModules = !!(
    modules.find((m) => !m.get('answered') && !m.get('isSkipped')) ||
    extraQuestions.find((q) => typeof q.get('score') !== 'number')
  );
  const content = hasUnansweredModules
    ? allowUnansweredQuestions
      ? translate('inspection.submit.allowUnansweredModals')
      : translate('inspection.submit.unansweredModals')
    : translate('inspection.submit.modalFooter');
  const footer = hasUnansweredModules ? (
    allowUnansweredQuestions ? (
      <DialogActions>
        <Button color='primary' onClick={closeModal}>
          {translate('back')}
        </Button>
        <Button
          color='primary'
          onClick={(e) => {
            e.preventDefault();
            if (!allowUnansweredQuestions) return goToControl(inspectionId);
            done(inspectionId).then((success) => {
              if (success) goToControl(inspectionId);
            });
          }}
        >
          {translate('inspection.continue')}
        </Button>
      </DialogActions>
    ) : (
      <DialogActions>
        <Button color='primary' onClick={closeModal}>
          {translate('back')}
        </Button>
      </DialogActions>
    )
  ) : (
    <DialogActions>
      <Button color='primary' onClick={closeModal}>
        {translate('back')}
      </Button>
      <Button
        color='primary'
        disabled={loading}
        onClick={(e) => {
          e.preventDefault();
          if (!allowUnansweredQuestions) return goToControl(inspectionId);
          done(inspectionId).then((success) => {
            if (success) goToControl(inspectionId);
          });
        }}
      >
        {translate('inspection.continue')}
      </Button>
    </DialogActions>
  );
  return (
    <Dialog open onClose={closeModal}>
      <DialogContent>
        {loading && <Loader />}
        {error && <Error>{translate(error)}</Error>}
        {content}
      </DialogContent>
      {footer}
    </Dialog>
  );
}

export default connect(
  (state, ownProps) => ({
    visible: state.getIn(['inspections', 'submitModal', 'visible']),
    loading: state.getIn(['inspections', 'submitModal', 'loading']),
    error: state.getIn(['inspections', 'submitModal', 'error']),
    inspectionId: state.getIn(['inspections', 'current', 'id']),
    modules: state.getIn(['inspections', 'current', 'modules']),
    extraQuestions: state.getIn(['inspections', 'current', 'extraQuestions']),
    allowUnansweredQuestions: state.getIn([
      'inspections',
      'current',
      'inspectionType',
      'allowUnansweredQuestions',
    ]),
    autoStartTime: state.getIn([
      'inspections',
      'current',
      'inspectionType',
      'autoStartTime',
    ]),
    autoCloseTime: state.getIn([
      'inspections',
      'current',
      'inspectionType',
      'autoCloseTime',
    ]),
    autoCloseWarningTime: state.getIn([
      'inspections',
      'current',
      'inspectionType',
      'autoCloseWarningTime',
    ]),
    autoCloseDelayDays: state.getIn([
      'inspections',
      'current',
      'inspectionType',
      'autoCloseDelayDays',
    ]),
    autoStartDelayDays: state.getIn([
      'inspections',
      'current',
      'inspectionType',
      'autoStartDelayDays',
    ]),
  }),
  (dispatch) => ({
    done: (inspectionId) => dispatch(inspectionActions.done(inspectionId)),
    goToControl: (inspectionId, moduleData) =>
      dispatch(inspectionActions.goToControl(inspectionId)),
    closeSubmitModal: () => dispatch(inspectionActions.closeSubmitModal()),
  })
)(SubmitModal);
