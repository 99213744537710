import { request } from 'actions/utils';
import moment from 'moment';

export function createInspection(inspection, dispatch) {
  inspection.inspectionDescriptions = inspection.inspectionDescriptions.filter(
    Boolean
  );

  return request({
    action: 'CREATE_INSPECTION',
    path: `/api/inspections`,
    method: 'POST',
    body: {
      ...inspection,
      startTime: moment(inspection.startTime).format('YYYY-MM-DD'),
      startTimeHour: moment(inspection.startTimeHour).format('kk:mm'),
    },
  })(dispatch);
}
