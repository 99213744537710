import load from './load';
import { request } from 'actions/utils';

export default function ({
  id,
  ordinal,
  isMandatory,
  isDefaultNA,
  inspectionTypeId,
  useTabs,
}) {
  return async (dispatch) => {
    const success = await dispatch(
      request({
        action: 'UPDATE_INSPECTION_MODULE',
        path: `/api/admin/inspection-types/${inspectionTypeId}/inspection-modules/${id}`,
        body: { ordinal, isMandatory, isDefaultNA, useTabs },
        method: 'PUT',
      })
    );
    if (success) {
      dispatch(load(inspectionTypeId));
    }
  };
}
