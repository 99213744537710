import { request } from 'actions/utils';

const uploadFile = async (questions, dispatch) => {
  return request({
    action: 'UPLOAD_JSON',
    path: '/api/admin/questions/upload',
    method: 'POST',
    body: questions
  })(dispatch);
};

export default uploadFile;