const INITIAL_STATE = {
  question: {
    content: '',
    ordinal: 0,
    questionClusterId: 0,
    notifyHotelResponsible: false,
    isMandatory: false,
    requiredCommentMinScore: null,
    id: 0,
  },
  loading: false,
  error: '',
};

export default function reducer(state = INITIAL_STATE, action = { type: '' }) {
  switch (action.type) {
    case 'INITIALIZE_FROM_PROPS': {
      return { ...state, question: { ...action.payload.question } };
    }
    case 'UPDATE_QUESTION_STARTED': {
      return { ...state, loading: true, error: '' };
    }
    case 'UPDATE_QUESTION_FAILED': {
      return { ...state, loading: false, error: action.payload };
    }
    case 'UPDATE_QUESTION_DONE': {
      return { ...state, loading: false };
    }
    case 'CHANGE': {
      return { ...state, question: { ...action.payload } };
    }
    default:
      return state;
  }
}
