import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

const CountText = styled.p`
  color: ${(props) => props.color} !important;
  font-size: 3em;
  margin: 5px 0%;
  font-weight: 500;
`;

const Label = styled.p`
  margin: 0;
  font-size: 1em;
  text-align: center;
`;

export default function Total({ color, children, label, style }) {
  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      justify='center'
      style={style}
    >
      <Label>{label}</Label>
      <CountText color={color}>{children}</CountText>
    </Grid>
  );
}
