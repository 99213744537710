import React from 'react';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import isImagePath from 'utils/isImagePath';

export default function FileLink({ path, name, variant, color }) {
  if (isImagePath(path)) return null;
  const fileName = name.replace(/^\d+-/, '');
  return (
    <Button
      variant={variant || 'contained'}
      color={color || 'default'}
      startIcon={<OpenInNewIcon />}
      onClick={async () => {
        const response = await fetch('/files/' + path);
        const fileBlob = await response.blob();
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(fileBlob);
          return;
        }

        const url = URL.createObjectURL(fileBlob);

        if (fileBlob.type === 'application/pdf') {
          window.open(url);
          return;
        }
        const a = document.createElement('a');
        a.href = url;
        if (a.download != null) a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }}
    >
      {fileName}
    </Button>
  );
}
