import { List, fromJS } from 'immutable';
import urls from 'globals/urls';

export const MODULE_NAME_PREFIXES = {
  KEY_INFORMATION: 'Key Information',
  GUEST_ROOM: 'Guest Room',
  PUBLIC_AREA: 'Public Area',
  SUPPLEMENTAL: 'Supplemental',
};

const ROOM_NUMBER_REFERENCE_PREFIXES = [
  '4099',
  '4199',
  '4299',
  '5199',
  '399',
  '3099',
];
const SUPPLEMENTAL_TYPE_PREFIXES = ['899'];

export function getInspectionLink(followUpInspection) {
  const id = followUpInspection.get('id');

  if (
    !followUpInspection.get('agentEndTime') &&
    !followUpInspection.get('adminEndTime')
  ) {
    return urls.inspectionStart.replace(':id', id);
  }

  if (!followUpInspection.get('adminEndTime')) {
    return urls.inspectionControl.replace(':id', id);
  }

  return urls.reportAuditQA.replace(':inspectionId', id);
}

export function buildSortedModules(report) {
  const {
    questions: brandStandardQuestions,
    updatedReport: reportNoBrandStandard,
  } = filterOutQuestions(report, 'bs', [MODULE_NAME_PREFIXES.GUEST_ROOM]);

  const {
    questions: brandIdentityQuestions,
    updatedReport: reportNoBrandIdentity,
  } = filterOutQuestions(reportNoBrandStandard, 'bi');

  const brandStandardModule = fromJS({
    name: 'Brand Standard',
    clusters: [
      {
        name: '',
        questions: brandStandardQuestions,
      },
    ],
  });

  const keyInformation = getModule(
    reportNoBrandIdentity,
    MODULE_NAME_PREFIXES.KEY_INFORMATION
  );
  const guestRoom = getModule(
    reportNoBrandIdentity,
    MODULE_NAME_PREFIXES.GUEST_ROOM
  );
  const publicArea = getModule(
    reportNoBrandIdentity,
    MODULE_NAME_PREFIXES.PUBLIC_AREA
  );
  const supplemental = getModule(
    reportNoBrandIdentity,
    MODULE_NAME_PREFIXES.SUPPLEMENTAL
  );

  const brandIdentiyModule = fromJS({
    name: 'Brand Identity',
    clusters: [
      {
        name: '',
        questions: brandIdentityQuestions,
      },
    ],
  });

  const unmatchedModules = getUnmatchedModules(reportNoBrandIdentity);

  const modules = fromJS(
    [
      keyInformation,
      brandStandardModule,
      publicArea,
      guestRoom,
      supplemental,
      brandIdentiyModule,
      ...unmatchedModules,
    ].filter((m) => m)
  );

  return modules.map((module) => sortModule(module, 'bs'));
}

function getUnmatchedModules(report) {
  return report
    .get('modules')
    .filter(
      (m) =>
        !Object.keys(MODULE_NAME_PREFIXES).some((prefix) =>
          moduleMatches(m.get('name'), MODULE_NAME_PREFIXES[prefix])
        )
    );
}

function getModule(report, name) {
  return report.get('modules').find((m) => moduleMatches(m.get('name'), name));
}

export function moduleMatches(nameA, nameB) {
  return nameA.toLowerCase().startsWith(nameB.toLowerCase());
}

function filterOutQuestions(report, classification, skipModules = []) {
  let updatedReport = report;
  let questions = List();
  let modules = report.get('modules');

  modules = modules.map((module) => {
    if (skipModules.some((m) => moduleMatches(m, module.get('name')))) {
      return module;
    }
    const updatedClusters = module.get('clusters').update((clusters) => {
      return clusters.map((cluster) => {
        const { filtered, rest } = filterByClassification(
          cluster.get('questions')
        );
        questions = questions.concat(filtered);

        return cluster.set('questions', rest);
      });
    });

    return module.set('clusters', updatedClusters);
  });

  updatedReport = updatedReport.set('modules', modules);

  return { updatedReport, questions };

  function filterByClassification(questions) {
    const filtered = questions.filter(
      (q) => q.get('classification') === classification
    );
    const rest = questions.filter(
      (q) => q.get('classification') !== classification
    );

    return { filtered, rest };
  }
}

function sortModule(module, prioritizedClassification) {
  const updatedClusters = module.get('clusters').update((clusters) => {
    const sortedClusters = clusters.map((cluster) => {
      const sortedQuestions = sortCluster(
        cluster.get('questions'),
        prioritizedClassification
      );
      return cluster.set('questions', sortedQuestions);
    });

    return sortedClusters;
  });

  return module.set('clusters', updatedClusters);
}

function sortCluster(questions, prioritizedClassification) {
  const sortedQuestions = questions.sort((questionA, questionB) =>
    sortQuestion(questionA, questionB, prioritizedClassification)
  );

  return sortedQuestions.map((question) =>
    question.set(
      'children',
      sortCluster(question.get('children'), prioritizedClassification)
    )
  );
}

function sortQuestion(questionA, questionB, prioritizedClassification) {
  if (isRoomNumberQuestion(questionA.get('referenceNumber'))) {
    return -1;
  }

  if (isRoomNumberQuestion(questionB.get('referenceNumber'))) {
    return 1;
  }

  if (
    questionA.get('classification') === prioritizedClassification &&
    questionB.get('classification') !== prioritizedClassification
  ) {
    return -1;
  }

  if (
    questionB.get('classification') === prioritizedClassification &&
    questionA.get('classification') !== prioritizedClassification
  ) {
    return 1;
  }

  const valueA = parseInt(
    (questionA.get('referenceNumber') || '0').replace('.', ''),
    10
  );
  const valueB = parseInt(
    (questionB.get('referenceNumber') || '0').replace('.', ''),
    10
  );

  if (valueA < valueB) {
    return -1;
  }
  if (valueA > valueB) {
    return 1;
  }

  const valueAText = (questionA.get('referenceNumber') || '0').match(/[a-z]+/);
  const valueBText = (questionB.get('referenceNumber') || '0').match(/[a-z]+/);

  if (valueAText === null && valueBText === null) {
    return 0;
  }

  if (valueAText && !valueBText) {
    return -1;
  }

  if (!valueAText && valueBText) {
    return 1;
  }

  if (valueAText[0] < valueBText[0]) {
    return -1;
  }

  if (valueAText[0] > valueBText[0]) {
    return 1;
  }

  return 0;
}

export function isRoomNumberQuestion(referenceNumber) {
  referenceNumber = String(referenceNumber);

  return ROOM_NUMBER_REFERENCE_PREFIXES.some((prefix) =>
    referenceNumber.startsWith(prefix)
  );
}

export function isSupplementalTypeQuestion(referenceNumber) {
  referenceNumber = String(referenceNumber);

  return SUPPLEMENTAL_TYPE_PREFIXES.some((prefix) =>
    referenceNumber.startsWith(prefix)
  );
}

export function getControlledRooms(report) {
  if (!report || !report.get('modules')) {
    return [];
  }

  let rooms = [];
  report.get('modules').forEach((m) => {
    m.get('clusters').forEach((cluster) => {
      rooms = rooms.concat(
        cluster
          .get('questions')
          .filter((q) => isRoomNumberQuestion(q.get('referenceNumber')))
          .map((q) => q.getIn(['answers', 0, 'score']))
          .toJS()
      );
    });
  });

  return rooms.filter((r) => r !== null && r !== undefined);
}
