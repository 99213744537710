import React, { Component } from 'react';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import Button from '@material-ui/core/Button';
import localReducer from './reducer';
import update from './update';
import LocalFlux from 'components/general/LocalFlux';
import QuestionForm from 'components/general/QuestionForm';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class EditModal extends Component {
  update = async (state, dispatch) => {
    const success = await update(
      { data: state.question },
      this.props.language ? this.props.language.shortCode : '',
      dispatch
    );
    if (success) {
      this.props.closeModal();
    }
  };
  render() {
    const { closeModal, questionClusters, questionTypes } = this.props;

    return (
      <LocalFlux reducer={localReducer} {...this.props}>
        {({ state, _changeInput, dispatch }) => (
          <Dialog open onClose={closeModal}>
            <DialogTitle>{translate('questions.edit.header')}</DialogTitle>
            <DialogContent>
              <QuestionForm
                question={state.question}
                questionTypes={questionTypes}
                onChange={(question) =>
                  dispatch({ type: 'CHANGE', payload: question })
                }
                questionClusters={questionClusters.toJS()}
              />
              {state.loading && <Loader />}
              {state.error && <Error>{translate(state.error)}</Error>}
            </DialogContent>
            <DialogActions>
              <Button color='primary' onClick={closeModal}>
                {translate('cancel')}
              </Button>
              <Button
                color='primary'
                onClick={() => this.update(state, dispatch)}
              >
                {translate('questions.edit.button')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </LocalFlux>
    );
  }
}
