import React, { useEffect, useState } from 'react';
import translate from 'utils/translate';
import GeneralComments from './GeneralComments';
import ExternalScores from './ExternalScores';
import { ButtonRow, ViewWrapper, StartTimeTitle } from './styled';
import Button from '@material-ui/core/Button';
import Error from 'components/general/Error';
import Loader from 'components/general/Loader';
import ComfortZones from './ComfortZones';
import moment from 'moment';
import Module from './Module';
import ExtraQuestions from 'components/inspection/Questions/ExtraInspectionQuestions/Questions';
import LoadingOverlay from 'components/general/LoadingOverlay';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled from 'styled-components';
import { List } from 'immutable';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { breakpoints } from 'globals/media';

import { useScreenSize } from 'hooks/useScreenSize';

const StyledTabs = styled(Tabs)``;

const StyledTab = styled(Tab)`
  .MuiTab-wrapper {
    flex-direction: row;
  }
`;

export default function InspectionControl({
  inspection,
  id,
  load,
  submit,
  loading,
  error,
  goBack,
  user,
  openGeneralCommentsModal,
  openComfortZoneModal,
  saveClusterComments,
  commentCategories,
  openExternalScoresModal,
  updateExtraQuestion,
  userRole,
  agentEndTime,
}) {
  const [currentClusterIndex, setCurrentClusterIndex] = useState(0);
  const activeModules = inspection
    .getIn(['inspectionType', 'inspectionModules'], List())
    .filter(
      (inspectionModule) =>
        !inspectionModule.getIn(['moduleDatas', 0, 'isSkipped'])
    );

  const [activeTab, setActiveTab] = useState(0);
  const screenSize = useScreenSize();

  useEffect(() => {
    load(id);
  }, [load, id, agentEndTime]);

  const externalScores = inspection.get('externalScores');
  const reportType = inspection.getIn(['inspectionType', 'reportType']);
  const activeModule = activeModules.get(activeTab);
  const someModuleIsUsingTabs = activeModules.some((m) => m.get('useTabs'));
  const numberOfClusters = !activeModule
    ? 0
    : activeModule.getIn(['inspectionModuleType', 'questionClusters']).size;

  const showNextModuleButton =
    someModuleIsUsingTabs && currentClusterIndex === numberOfClusters - 1;
  const showNextClusterButton = !showNextModuleButton && someModuleIsUsingTabs;
  const activeModuleIsUsingTabs = activeModule && activeModule.get('useTabs');

  if (!inspection.get('id')) {
    return (
      <div style={{ height: '200px', position: 'relative' }}>
        <LoadingOverlay show />
      </div>
    );
  }

  return (
    <ViewWrapper>
      <h1>
        {inspection.getIn(['inspectionType', 'hotel', 'name'])}
        {' - '}
        {inspection.getIn(['inspectionType', 'name'])}
      </h1>
      <StartTimeTitle>
        {`${translate('inspection.control.startTime')}: ${moment(
          inspection.get('startTime')
        ).format('YYYY-MM-DD, HH:mm')}`}
      </StartTimeTitle>
      {someModuleIsUsingTabs && (
        <ButtonRow style={{ marginTop: '1rem' }}>
          <Button
            onClick={() => submit({ inspection, user })}
            color='primary'
            variant='contained'
          >
            {translate('inspection.submit')}
          </Button>
        </ButtonRow>
      )}
      <h3>{translate('inspection.control.generalComments')}</h3>
      <GeneralComments
        data={inspection.get('generalComments')}
        openModal={openGeneralCommentsModal}
      />
      {inspection.getIn(['inspectionType', 'reportType']) !== 'auditQA' && (
        <>
          <h3>{translate('comfortZones')}</h3>
          <ComfortZones
            commentCategories={commentCategories}
            inspection={inspection}
            openModal={openComfortZoneModal}
          />
        </>
      )}
      {inspection.getIn(['inspectionType', 'reportType']) === 'agentQ' && (
        <>
          <h3>{translate('inspection.externalScores')}</h3>
          <ExternalScores
            data={externalScores}
            openModal={openExternalScoresModal}
          />
        </>
      )}
      {someModuleIsUsingTabs && (
        <>
          <StyledTabs
            value={activeTab}
            onChange={(_, newValue) => {
              setActiveTab(newValue);
            }}
            textColor='secondary'
            variant='scrollable'
            scrollButtons='on'
          >
            {activeModules.map((module, index) => (
              <StyledTab
                key={module.get('id')}
                label={
                  <>
                    <span>{module.get('name')}</span>
                  </>
                }
                value={index}
              />
            ))}
          </StyledTabs>
          {activeModule && (
            <Module
              userRole={userRole}
              key={activeModule.get('id')}
              module={activeModule}
              showNextClusterButton={showNextClusterButton}
              saveClusterComments={saveClusterComments}
              inspectionId={id}
              isUsingModuleTabs={someModuleIsUsingTabs}
              useTabs
              onClusterChange={(index) => setCurrentClusterIndex(index)}
              picturesOpenByDefault={reportType === 'auditQA'}
              showFollowUp={
                (user.get('role') === 'companyAdmin' ||
                  user.get('role') === 'superUser') &&
                agentEndTime
              }
            />
          )}
        </>
      )}
      {!someModuleIsUsingTabs &&
        activeModules.map((module) => (
          <Module
            userRole={userRole}
            key={module.get('id')}
            module={module}
            saveClusterComments={saveClusterComments}
            inspectionId={id}
            picturesOpenByDefault={reportType === 'auditQA'}
            showFollowUp={
              (user.get('role') === 'companyAdmin' ||
                user.get('role') === 'superUser') &&
              agentEndTime
            }
          />
        ))}
      {inspection.get('extraQuestions').size > 0 && (
        <React.Fragment>
          <h3>{translate('extraQuestions')}</h3>
          <ExtraQuestions
            questions={inspection.get('extraQuestions')}
            inspectionId={id}
            user={user}
            updateQuestion={updateExtraQuestion}
          />
        </React.Fragment>
      )}
      {loading && <Loader />}
      {error && <Error>{translate(error)}</Error>}
      {!someModuleIsUsingTabs && (
        <ButtonRow>
          <Button variant='contained' onClick={() => goBack(id)}>
            {translate('back')}
          </Button>
          <Button
            onClick={() => submit({ inspection, user })}
            color='primary'
            variant='contained'
          >
            {translate('inspection.submit')}
          </Button>
        </ButtonRow>
      )}
      {showNextModuleButton && (
        <Button
          variant='contained'
          onClick={() => {
            window.scrollTo(0, 0);
            setActiveTab(activeTab + 1);
          }}
          endIcon={<NavigateNextIcon />}
          style={{
            marginLeft:
              screenSize.width < breakpoints.tablet || !activeModuleIsUsingTabs
                ? '0'
                : '171px',
          }}
        >
          {translate('inspectionQuestions.nextModule')}
        </Button>
      )}
    </ViewWrapper>
  );
}
