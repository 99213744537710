import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import translate from 'utils/translate';
import inspectionTypeActions from 'actions/admin/inspectionType';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { useOrganizationHotels } from 'hooks/useOrganizationHotels';
import { useScreenSize } from 'hooks/useScreenSize';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Error from 'components/general/Error';
import LoadingOverlay from 'components/general/LoadingOverlay';
import ClearIcon from '@material-ui/icons/Clear';

function LinkToHotelModal({
  visible,
  loading,
  error,
  link,
  onClose,
  inspectionTypeId,
  organizationId,
}) {
  const screenSize = useScreenSize();
  const allHotels = useOrganizationHotels(organizationId);
  const [filterText, setFilterText] = useState('');
  const [selectedHotels, setSelectedHotels] = useState([]);
  useEffect(() => {
    if (!allHotels) {
      return;
    }

    setSelectedHotels(
      allHotels
        .filter((hotel) =>
          hotel.inspectionTypes.find((i) => i.templateId === inspectionTypeId)
        )
        .map((hotel) => hotel.id)
    );
  }, [allHotels, inspectionTypeId]);

  const hotels = (allHotels || []).filter((hotel) =>
    hotel.name.toLowerCase().includes(filterText.toLowerCase())
  );

  function onHotelSelected(id) {
    const hotelIndex = selectedHotels.indexOf(id);
    if (hotelIndex === -1) {
      setSelectedHotels(selectedHotels.concat(id));
      return;
    }
    setSelectedHotels(
      selectedHotels
        .slice(0, hotelIndex)
        .concat(selectedHotels.slice(hotelIndex + 1))
    );
  }

  return (
    <Dialog open={visible} onClose={onClose} maxWidth='md' scroll='paper'>
      <DialogTitle>{translate('inspectionTypes.link.hotel')}</DialogTitle>
      <LoadingOverlay show={loading} minDisplayTime={500} />
      <DialogContent dividers>
        {error && <Error>{translate(error)}</Error>}
        <TextField
          fullWidth
          value={filterText}
          placeholder={translate('hotel.find')}
          onChange={(event) => setFilterText(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: filterText ? (
              <InputAdornment position='end'>
                <IconButton size='small' onClick={() => setFilterText('')}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        />
        <div
          style={{
            height: `${Math.min(screenSize.height - 244, 350)}px`,
            overflowY: 'scroll',
          }}
        >
          <Table style={{ width: '320px' }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={hotels && hotels.length === selectedHotels.length}
                    onChange={() => {
                      if (hotels.length === selectedHotels.length) {
                        setSelectedHotels([]);
                      } else {
                        setSelectedHotels(hotels.map((hotel) => hotel.id));
                      }
                    }}
                  />
                </TableCell>
                <TableCell>{translate('hotels.create.name')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(hotels || []).map((hotel) => {
                const isItemSelected = selectedHotels.indexOf(hotel.id) !== -1;

                return (
                  <TableRow
                    key={hotel.id}
                    hover
                    onClick={() => onHotelSelected(hotel.id)}
                    selected={isItemSelected}
                  >
                    <TableCell padding='checkbox'>
                      <Checkbox checked={isItemSelected} />
                    </TableCell>
                    <TableCell>{hotel.name}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          {translate('back')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() =>
            link({ hotelId: selectedHotels, id: inspectionTypeId })
          }
        >
          {translate('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default connect(
  (state, ownProps) => ({
    role: state.getIn(['currentUser', 'role']),
    organizationId: state.getIn(['hotel', 'current', 'organizationId']),
    loading: state.getIn([
      'admin',
      'inspectionTypes',
      'createModal',
      'loading',
    ]),
    error: state.getIn(['admin', 'inspectionTypes', 'createModal', 'error']),
    ...ownProps,
  }),
  (dispatch, ownProps) => ({
    link: (inspectionData) =>
      dispatch(inspectionTypeActions.link(inspectionData, ownProps.onCreated)),
  })
)(LinkToHotelModal);
