export const INITIAL_STATE = {
  inspection: {
    startTime: new Date(),
    startTimeHour: new Date(),
    hotel: -1,
    inspectionType: -1,
    inspectionDescriptions: [],
    extraQuestions: [],
    shouldSendNotification: true,
  },
  loading: false,
  error: '',
  fieldErrors: null,
};

export default function reducer(state, action = { type: '' }) {
  switch (action.type) {
    case 'CREATE_INSPECTION_STARTED': {
      return { ...state, loading: true, error: '', fieldErrors: null };
    }
    case 'CREATE_INSPECTION_FAILED': {
      return {
        ...state,
        loading: false,
        error: action.payload,
        fieldErrors: action.error,
      };
    }
    case 'CREATE_INSPECTION_DONE': {
      return { ...state, loading: false };
    }
    case 'CHANGE_INSPECTION': {
      return { ...state, inspection: action.payload };
    }
    default:
      return state;
  }
}
