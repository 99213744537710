import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import translate from 'utils/translate';
import Error from 'components/general/Error';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LoadingOverlay from '../general/LoadingOverlay';
import Logo from 'components/general/Logo';
import {
  Container,
  Form,
  Header,
  LogoContainer,
  InnerWrapper,
} from './styled/Form';
import urls from 'globals/urls';

export default class LoginForm extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
    };
  }

  inputChange = ({ value, input }) => {
    const newState = {};
    newState[input] = value;
    this.setState(newState);
  };

  componentDidMount() {
    this.props.checkSession();
  }

  render() {
    const { email, password } = this.state;
    const { login, error, loading } = this.props;
    return (
      <Container>
        <InnerWrapper>
          <Paper>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                login(this.state);
              }}
            >
              <Header>
                <LogoContainer>
                  <Logo height={'40'} text />
                </LogoContainer>
                <Typography variant='h6' component='h1' color='primary'>
                  {translate('site.name')}
                </Typography>
              </Header>

              <TextField
                fullWidth
                type='text'
                value={email}
                label={translate('login.email')}
                onChange={(e) =>
                  this.inputChange({ value: e.target.value, input: 'email' })
                }
              />
              <TextField
                fullWidth
                type='password'
                margin='normal'
                value={password}
                label={translate('login.password')}
                onChange={(e) =>
                  this.inputChange({ value: e.target.value, input: 'password' })
                }
              />
              <a
                href={urls.passwordResetRequest}
                style={{ alignSelf: 'flex-start' }}
              >
                <Typography gutterBottom color='primary' variant='caption'>
                  {translate('login.forgotPassword')}
                </Typography>
              </a>
              <Button color='primary' variant='contained' type='submit'>
                {translate('login.submit')}
              </Button>
            </Form>
          </Paper>
          <LoadingOverlay show={loading} />
          <Error style={{ width: 'initial' }} show={!!error}>
            {error && translate(error)}
          </Error>
        </InnerWrapper>
      </Container>
    );
  }
}
