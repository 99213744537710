import React from 'react';
import translate from 'utils/translate';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { roles } from 'globals/roles';
import { Form, Row } from 'components/general/Form';

export default function UserForm({
  user,
  minRole,
  maxRole,
  onChange,
  disabled,
  includePasswordRepeat,
}) {
  const maxRoleIndex = roles.indexOf(maxRole) + 1;
  const minRoleIndex = roles.indexOf(minRole) + 1 || roles.length - 1;
  const filteredRoles = roles.slice(maxRoleIndex, minRoleIndex);

  const handleChange = (name, value) => {
    onChange({
      ...user,
      [name]: value,
    });
  };

  return (
    <Form>
      <Row>
        <TextField
          required
          disabled={disabled}
          label={translate('users.edit.name')}
          value={user.name || ''}
          variant='outlined'
          onChange={(event) => handleChange('name', event.target.value)}
        />
        <TextField
          required
          disabled={disabled}
          label={translate('users.edit.email')}
          value={user.email || ''}
          variant='outlined'
          onChange={(event) => handleChange('email', event.target.value)}
        />
      </Row>
      <Row>
        <TextField
          required
          disabled={disabled}
          label={translate('users.edit.agentCode')}
          value={user.agentCode || ''}
          variant='outlined'
          onChange={(event) => handleChange('agentCode', event.target.value)}
        />
        <FormControl variant='outlined'>
          <InputLabel id='user-form-role-input-label' required>
            {translate('users.edit.role')}
          </InputLabel>
          <Select
            required
            disabled={disabled}
            labelId='user-form-role-input-label'
            value={user.role || ''}
            onChange={(event) => handleChange('role', event.target.value)}
            label={translate('users.edit.role')}
          >
            {filteredRoles.map((role) => (
              <MenuItem key={role} value={role}>
                {translate(`users.edit.role.${role}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Row>
      <Row>
        <TextField
          required={includePasswordRepeat}
          disabled={disabled}
          type='password'
          label={translate('users.edit.password')}
          value={user.password || ''}
          variant='outlined'
          onChange={(event) => handleChange('password', event.target.value)}
        />
        {includePasswordRepeat ? (
          <TextField
            required
            disabled={disabled}
            type='password'
            label={translate('users.edit.passwordRepeat')}
            value={user.passwordRepeat || ''}
            variant='outlined'
            onChange={(event) =>
              handleChange('passwordRepeat', event.target.value)
            }
          />
        ) : (
          <div />
        )}
      </Row>
      <Row>
        <TextField
          disabled={disabled}
          multiline
          rows={3}
          style={{ gridColumn: '1 / -1' }}
          label={translate('users.edit.profile')}
          value={user.profile || ''}
          variant='outlined'
          onChange={(event) => handleChange('profile', event.target.value)}
        />
      </Row>
    </Form>
  );
}
