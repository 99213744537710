import {connect} from 'react-redux';
import InspectionQuestions from './InspectionQuestions';
import inspectionQuestionsActions from 'actions/inspectionQuestions';
import inspectionActions from 'actions/inspection';
import {List} from 'immutable';

function mapStateToProps(state, ownProps) {
  const inspectionId = parseInt(ownProps.match.params.id, 10);
  return {
    inspectionId,
    questions: state.getIn(['inspections', 'current', 'extraQuestions']) || List(),
    user: state.get('currentUser')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadInspection: inspectionId => dispatch(inspectionActions.load(inspectionId)),
    goBack: id => dispatch(inspectionActions.openInspection(id)),
    updateQuestion: params => dispatch(inspectionQuestionsActions.updateExtraQuestion(params)),
    openComfortZoneModal: () => dispatch(inspectionQuestionsActions.openComfortZoneModal()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionQuestions);
