import { List } from 'immutable';

const INITIAL_STATE = {
  startTime: new Date(),
  startTimeHour: new Date(),
  hotel: -1,
  inspectionType: -1,
  shouldSendNotification: true,
  inspectionDescriptions: [],
  extraQuestions: [],
  prefilledQuestions: List(),
  loading: false,
  error: '',
  fieldErrors: null,
};

export default function reducer(state = INITIAL_STATE, action = { type: '' }) {
  switch (action.type) {
    case 'CREATE_INSPECTION_STARTED': {
      return { ...state, loading: true, error: '', fieldErrors: null };
    }
    case 'CREATE_INSPECTION_FAILED': {
      return {
        ...state,
        loading: false,
        error: action.payload,
        fieldErrors: action.error,
      };
    }
    case 'CREATE_INSPECTION_DONE': {
      return { ...state, loading: false };
    }
    case 'CHANGE_INPUT_STARTTIME': {
      const { field, value } = action.payload;
      return { ...state, [field]: value };
    }
    case 'CHANGE_INPUT': {
      const { field, value } = action.payload;

      return { ...state, [field]: value };
    }
    case 'UPDATE_PREFILLED_QUESTIONS': {
      const { index, comment } = action.payload;

      return {
        ...state,
        prefilledQuestions: state.prefilledQuestions.setIn(
          [index, 'comment'],
          comment
        ),
      };
    }
    case 'SET_PREFILLED_QUESTIONS': {
      return {
        ...state,
        prefilledQuestions: action.payload,
      };
    }
    case 'CHANGE_DESCRIPTION_INPUT': {
      const { field, value, index } = action.payload;
      let inspectionDescriptions = [].concat(state.inspectionDescriptions);
      inspectionDescriptions[index] = inspectionDescriptions[index] || {
        header: '',
        body: '',
        ordinal: 0,
      };
      inspectionDescriptions[index][field] = value;
      if (
        !inspectionDescriptions[index].header &&
        !inspectionDescriptions[index].body &&
        !inspectionDescriptions[index].ordinal
      ) {
        delete inspectionDescriptions[index];
      }
      inspectionDescriptions = inspectionDescriptions.filter(Boolean);
      return { ...state, inspectionDescriptions };
    }
    case 'CHANGE_EXTRA_QUESTION_INPUT': {
      const { field, value, index } = action.payload;
      let extraQuestions = [].concat(state.extraQuestions);
      extraQuestions[index] = extraQuestions[index] || {
        content: '',
        ordinal: 0,
      };
      extraQuestions[index][field] = value;
      if (!extraQuestions[index].content && !extraQuestions[index].ordinal) {
        delete extraQuestions[index];
      }
      extraQuestions = extraQuestions.filter(Boolean);
      return { ...state, extraQuestions };
    }
    default:
      return state;
  }
}
