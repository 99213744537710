import React, { Component } from 'react';
import GridRow from 'components/admin/general/GridRow';
import { Wrapper, Hotel } from './styled';
import Button from 'components/general/Button';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import CreateModal from './CreateModal';
import EditModal from './EditModal';

export default class HotelAdmin extends Component {
  state = {
    editModalOpen: false,
    hotelToEdit: null,
  };
  componentDidMount() {
    this.props.loadHotels();
  }
  openEditModal = (hotel) => {
    this.setState({
      editModalOpen: true,
      hotelToEdit: hotel,
    });
  };
  closeEditModal = () => {
    this.setState({
      editModalOpen: false,
      hotelToEdit: null,
    });
    this.props.loadHotels();
  };

  render() {
    const {
      hotels,
      error,
      loading,
      role,
      openCreateModal,
      deleteHotel,
      openHotelAdministration,
    } = this.props;

    const domHotels = hotels.map((hotel) => (
      <React.Fragment key={hotel.get('id')}>
        <Hotel>{hotel.get('name')}</Hotel>
        <Button
          small
          color='white'
          textColor='textGray'
          type='button'
          onClick={() => this.openEditModal(hotel.toJS())}
        >
          {translate('edit')}
        </Button>
        <Button
          small
          color='white'
          textColor='textGray'
          onClick={() => deleteHotel(hotel)}
        >
          {translate('hotels.delete')}
        </Button>
        <Button
          small
          type='button'
          onClick={() => openHotelAdministration(hotel.get('id'))}
        >
          {translate('hotels.open')}
        </Button>
      </React.Fragment>
    ));
    return (
      <Wrapper>
        <GridRow>
          <h1>{translate('hotels.administration')}</h1>
        </GridRow>
        <GridRow>
          <Button type='button' onClick={openCreateModal}>
            {translate('hotels.create')}
          </Button>
        </GridRow>
        <GridRow>
          {loading && <Loader />}
          {error && <Error>{translate(error)}</Error>}
        </GridRow>
        {domHotels}
        <CreateModal onHotelCreated={this.props.loadHotels} />
        {this.state.editModalOpen && (
          <EditModal
            role={role}
            loadHotels={this.props.loadHotels}
            closeModal={this.closeEditModal}
            data={this.state.hotelToEdit}
          />
        )}
      </Wrapper>
    );
  }
}
