import React, { useState, useEffect } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import translate from 'utils/translate';

export default function FollowUpDeadline({
  value,
  onChange,
  label,
  errorIfMissing,
}) {
  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const missingError = errorIfMissing && !internalValue;

  return (
    <KeyboardDatePicker
      variant='inline'
      margin={'dense'}
      error={missingError || undefined}
      helperText={
        missingError
          ? translate('questions.followUp.missingDeadline')
          : undefined
      }
      inputVariant='standard'
      label={label}
      format='yyyy-MM-DD'
      fullWidth
      autoOk
      value={internalValue}
      onChange={(date) => {
        setInternalValue(date);
        onChange(date);
      }}
    />
  );
}
