import {fromJS} from 'immutable';

const defaultState = fromJS({
  loading: false,
  data: {},
  error: ''
});

export default function(state = defaultState, action = {type: ''}) {
  switch (action.type) {
    case 'LOAD_INSPECTION_TYPE_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'LOAD_INSPECTION_TYPE_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'LOAD_INSPECTION_TYPE_DONE': {
      return state.set('loading', false).set('data', fromJS(action.payload));
    }
    default: {
      return state;
    }
  }
}
