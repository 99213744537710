import { request } from 'actions/utils';

export default function ({ data }, dispatch) {
  return request({
    action: 'UPDATE_INSPECTION',
    path: `/api/admin/inspections/${data.id}`,
    method: 'PUT',
    body: data,
    extraPayload: data,
  })(dispatch);
}
