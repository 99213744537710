import React from 'react';
import translate from 'utils/translate';
import {
  ClusterName,
  QuestionHeader,
  QuestionAnswer,
  QuestionWrapper,
  Img,
  FilesWrapper,
  FileTexts,
  Files,
  Department as Wrapper,
  Canvas,
  ObservationsChartWrapper,
} from './styled';
import ContextQuestions from './ContextQuestions';
import isImagePath from 'utils/isImagePath';

export default function Department({ data, canvasRef, index }) {
  if (!data) {
    return null;
  }

  const numberOfClusters = data.getIn(
    ['inspectionModuleType', 'questionClusters'],
    { size: 0 }
  ).size;
  const questionIdOrdinal = {};
  const clusters = data
    .getIn(['inspectionModuleType', 'questionClusters'])
    .map((cluster) => {
      let anyAnswers =
        cluster
          .get('questions')
          .some((question) => question.getIn(['answers', 0, 'score']) >= 0) ===
        true;

      if (!anyAnswers) {
        return '';
      }

      const questions = cluster
        .get('questions')
        .filter((question) => question.getIn(['answers', 0, 'comment']))
        .sort((a, b) => {
          const scoreDiff =
            b.getIn(['answers', 0, 'score']) - a.getIn(['answers', 0, 'score']);
          if (scoreDiff !== 0) {
            return scoreDiff;
          }
          return b.get('id') - a.get('id');
        })
        .map((question, index) => {
          questionIdOrdinal[question.get('id')] = index + 1;
          return (
            <QuestionWrapper key={question.get('id')}>
              <QuestionHeader score={question.getIn(['answers', 0, 'score'])}>
                <div>
                  {question
                    .get('content')
                    .split('\n')
                    .map((c, i) => (
                      <div key={i}>
                        {i === 0 && `${index + 1}. `}
                        {c}
                      </div>
                    ))}
                </div>
              </QuestionHeader>
              <QuestionAnswer>
                {question.getIn(['answers', 0, 'comment'])}
              </QuestionAnswer>
            </QuestionWrapper>
          );
        });
      const fileQuestions = cluster
        .get('questions')
        .filter(
          (question) =>
            question.getIn(['answers', 0, 'attachments'], { size: 0 }).size > 0
        )
        .sort((a, b) => {
          return (
            questionIdOrdinal[a.get('id')] - questionIdOrdinal[b.get('id')]
          );
        });
      const files = fileQuestions.map((question) => (
        <React.Fragment key={question.get('id')}>
          {question
            .getIn(['answers', 0, 'attachments'])
            .filter((file) => isImagePath(file.get('path')))
            .map((file) => (
              <div key={file.get('id')}>
                <span>
                  {questionIdOrdinal[question.get('id')]}
                  {questionIdOrdinal[question.get('id')] && '. '}
                </span>
                <Img
                  src={`/files/${file.get(
                    'path'
                  )}?timestamp=${new Date().toISOString()}`}
                  alt='answer'
                />
              </div>
            ))}
        </React.Fragment>
      ));
      const fileTexts = fileQuestions.map((question) => (
        <div key={question.get('id')}>
          <div>
            {questionIdOrdinal[question.get('id')]}
            {questionIdOrdinal[question.get('id')] && '. '}
          </div>
          <div>
            {(question.getIn(['answers', 0, 'comment']) || '')
              .split('\n')
              .map((c, i) => (
                <div key={i}>{c}</div>
              ))}
          </div>
        </div>
      ));

      const contextQuestionsContent = cluster
        .get('contextQuestions')
        .filter((question) => question.getIn(['contextAnswers', 0, 'content']));

      const questionClusterComment = cluster
        .get('comments')
        .filter(
          (clusterComment) =>
            clusterComment.get('inspectionModuleId') === data.get('id')
        );

      let renderQuestionClusterComment;

      if (questionClusterComment.size === 1) {
        renderQuestionClusterComment = questionClusterComment.map((comment) =>
          comment.get('comment')
        );
      } else {
        renderQuestionClusterComment = cluster.getIn([
          'comments',
          0,
          'comment',
        ]);
      }

      return (
        <div key={cluster.get('id')}>
          {/* Empty content affects pdf-formatting.. */}
          {contextQuestionsContent.size === 0 ? (
            <React.Fragment>
              <div className={'page-break-inside'}>
                {numberOfClusters > 1 && (
                  <ClusterName>{cluster.get('name')}</ClusterName>
                )}
                <ContextQuestions
                  data={cluster.get('contextQuestions')}
                  inspectionModuleId={data.get('id')}
                />
                <h4>COMMENTS</h4>
                <QuestionAnswer>
                  {renderQuestionClusterComment ||
                    translate('report.unanswered')}
                </QuestionAnswer>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className={'page-break-inside'}>
                {numberOfClusters > 1 && (
                  <ClusterName>{cluster.get('name')}</ClusterName>
                )}
                <ContextQuestions
                  data={cluster.get('contextQuestions')}
                  inspectionModuleId={data.get('id')}
                />
              </div>
              <div className={'page-break-inside'}>
                <h4>COMMENTS</h4>
                <QuestionAnswer>
                  {renderQuestionClusterComment ||
                    translate('report.unanswered')}
                </QuestionAnswer>
              </div>
            </React.Fragment>
          )}
          <div className={'page-break-inside'}>
            <h4>OBSERVATIONS</h4>
            {questions.size > 0 ? (
              questions
            ) : (
              <QuestionAnswer>{translate('report.unanswered')}</QuestionAnswer>
            )}
          </div>
          {files.size > 0 && (
            <FilesWrapper>
              <h4>PICTURES</h4>
              <FileTexts>{fileTexts}</FileTexts>
              <Files>{files}</Files>
            </FilesWrapper>
          )}
        </div>
      );
    });
  return (
    <Wrapper index={index}>
      <ObservationsChartWrapper>
        <h3>{data.get('name')}</h3>
        <Canvas>
          <canvas ref={canvasRef} />
        </Canvas>
      </ObservationsChartWrapper>
      {clusters}
    </Wrapper>
  );
}
