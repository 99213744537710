import { connect } from 'react-redux';
import UserAdmin from './UserAdmin';
import userActions from 'actions/admin/user';

function mapStateToProps(state) {
  return {
    error: state.getIn(['admin', 'users', 'error']),
    loading: state.getIn(['admin', 'users', 'loading']),
    users: state.getIn(['admin', 'users', 'users']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadUsers: () => dispatch(userActions.loadUsers()),
    openDeleteModal: (user) => dispatch(userActions.openDeleteModal(user)),
    openCreateModal: () => dispatch(userActions.openCreateModal()),
    openEditModal: (user) => dispatch(userActions.openEditModal(user)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAdmin);
