import React, { useState } from 'react';
import ReactDom from 'react-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import translate from 'utils/translate';
import DialogTitle from '@material-ui/core/DialogTitle';
import mainTheme from 'globals/theme';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from 'styled-components';

export default function confirm(message, options = {}) {
  const container = document.createElement('div');
  document.body.appendChild(container);

  return new Promise((resolve) => {
    function ConfirmDialog() {
      const [open, setOpen] = useState(true);
      const theme = createMuiTheme(mainTheme);

      return (
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={theme}>
            <Dialog
              open={open}
              onExited={() => {
                ReactDom.unmountComponentAtNode(container);
                container.remove();
              }}
              onClose={() => {
                setOpen(false);
                resolve(false);
              }}
              {...options.dialogProps}
            >
              {options.title && <DialogTitle>{options.title}</DialogTitle>}
              <DialogContent>
                <DialogContentText>
                  {options.onRenderContent
                    ? options.onRenderContent(message, options)
                    : message}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  onClick={() => {
                    setOpen(false);
                    resolve(false);
                  }}
                >
                  {options.cancelText || translate('cancel')}
                </Button>
                <Button
                  color='primary'
                  onClick={() => {
                    setOpen(false);
                    resolve(true);
                  }}
                >
                  {options.confirmText || translate('ok')}
                </Button>
              </DialogActions>
            </Dialog>
          </MuiThemeProvider>
        </ThemeProvider>
      );
    }

    ReactDom.render(<ConfirmDialog />, container);
  });
}
