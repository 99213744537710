import loadOrganizations from './loadOrganizations';
import { request } from 'actions/utils';

export default function createOrganization(organization) {
  return async (dispatch) => {
    let success = await dispatch(
      request({
        action: 'CREATE_ORGANIZATION',
        path: '/api/admin/organizations',
        method: 'POST',
        body: organization,
      })
    );
    if (success) {
      dispatch(loadOrganizations());
    }
  };
}
