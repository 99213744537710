import React from 'react';
import styled from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import translate from 'utils/translate';
import { useOrganizationInspectionTypes } from 'hooks/useOrganizationInspectionTypes';

const Form = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-flow: dense;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

export default function InspectionTypeLinkForm({
  inspectionType,
  onChange,
  organizationId,
}) {
  const inspectionTypes = useOrganizationInspectionTypes(organizationId);

  return (
    <Form>
      <FormControl variant='outlined' fullWidth>
        <InputLabel id='inspection-types-form-input-label'>
          {translate('inspectionTypes')}
        </InputLabel>
        <Select
          fullWidth
          required
          disabled={!inspectionTypes || inspectionTypes.length === 0}
          labelId='inspection-types-form-input-label'
          value={inspectionType}
          onChange={(event) => onChange(event.target.value)}
          label={translate('inspectionTypes')}
        >
          {(inspectionTypes || []).map((i) => (
            <MenuItem key={i.id} value={i.id}>
              {i.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Form>
  );
}
