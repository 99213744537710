import { List, fromJS } from 'immutable';
import comfortZoneModal from './comfortZoneModal';
import confirmFinishModal from './confirmFinishModal';
import errorModal from './errorModal';

const defaultState = fromJS({
  modules: {},
  loading: false,
  error: '',
  confirmFinishModal: confirmFinishModal(),
});

export default function (state = defaultState, action = { type: '' }) {
  state = state.set(
    'comfortZoneModal',
    comfortZoneModal(state.get('comfortZoneModal'), action)
  );
  state = state.set(
    'confirmFinishModal',
    confirmFinishModal(state.get('confirmFinishModal'), action)
  );
  state = state.set('errorModal', errorModal(state.get('errorModal'), action));

  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      return defaultState;
    }
    case 'LOAD_INSPECTION_QUESTIONS_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'LOAD_INSPECTION_QUESTIONS_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'LOAD_INSPECTION_QUESTIONS_DONE': {
      return state
        .setIn(
          ['modules', action.meta.moduleId, 'list'],
          fromJS(action.payload)
        )
        .set('loading', false);
    }
    case 'SET_FOLLOW_UP_FAILED':
    case 'SET_COMMENT_FAILED':
    case 'UPLOAD_FILE_FAILED':
    case 'DELETE_FILE_FAILED':
    case 'SET_SCORE_FAILED': {
      const questionId = parseInt(action.meta.questionId, 10);
      const moduleId = action.meta.moduleId;
      const { questionIndex, clusterIndex } = findQuestion({
        state,
        questionId,
        moduleId,
      });
      if (clusterIndex === -1) {
        return state;
      }
      return state
        .setIn(
          [
            'modules',
            moduleId,
            'list',
            clusterIndex,
            'questions',
            ...questionIndex,
            'loading',
          ],
          false
        )
        .setIn(
          [
            'modules',
            moduleId,
            'list',
            clusterIndex,
            'questions',
            ...questionIndex,
            'error',
          ],
          action.payload
        );
    }
    case 'SET_FOLLOW_UP_STARTED':
    case 'SET_COMMENT_STARTED':
    case 'DELETE_FILE_STARTED':
    case 'UPLOAD_FILE_STARTED':
    case 'SET_SCORE_STARTED': {
      const questionId = parseInt(action.payload.questionId, 10);
      const moduleId = action.meta.moduleId;
      const { questionIndex, clusterIndex } = findQuestion({
        state,
        questionId,
        moduleId,
      });
      if (clusterIndex === -1) {
        return state;
      }
      return state
        .setIn(
          [
            'modules',
            moduleId,
            'list',
            clusterIndex,
            'questions',
            ...questionIndex,
            'loading',
          ],
          true
        )
        .setIn(
          [
            'modules',
            moduleId,
            'list',
            clusterIndex,
            'questions',
            ...questionIndex,
            'error',
          ],
          ''
        );
    }
    case 'UPLOAD_FILE_CANCELED': {
      const { questionId, moduleId } = action.meta;
      const { questionIndex, clusterIndex } = findQuestion({
        state,
        questionId,
        moduleId,
      });
      if (clusterIndex === -1) {
        return state;
      }

      return state.setIn(
        [
          'modules',
          moduleId,
          'list',
          clusterIndex,
          'questions',
          ...questionIndex,
          'loading',
        ],
        false
      );
    }
    case 'UPLOAD_FILE_DONE': {
      const questionId = parseInt(action.meta.questionId, 10);
      const moduleId = action.meta.moduleId;
      const { questionIndex, clusterIndex } = findQuestion({
        state,
        questionId,
        moduleId,
      });
      if (clusterIndex === -1) {
        return state;
      }
      const filePath = [
        'modules',
        moduleId,
        'list',
        clusterIndex,
        'questions',
        ...questionIndex,
        'answer',
        'attachments',
      ];
      const oldList = state.getIn(filePath) || fromJS([]);
      const newList = oldList.push(fromJS(action.payload));
      return state
        .setIn(filePath, newList)
        .setIn(
          [
            'modules',
            moduleId,
            'list',
            clusterIndex,
            'questions',
            ...questionIndex,
            'loading',
          ],
          false
        );
    }
    case 'DELETE_FILE_DONE': {
      const { questionId, fileId, moduleId } = action.meta;
      const { questionIndex, clusterIndex } = findQuestion({
        state,
        questionId,
        moduleId,
      });
      if (clusterIndex === -1) {
        return state;
      }
      const filePath = [
        'modules',
        moduleId,
        'list',
        clusterIndex,
        'questions',
        ...questionIndex,
        'answer',
        'attachments',
      ];
      const fileIndex = state
        .getIn(filePath)
        .findIndex((img) => img.get('id') === fileId);
      return state
        .deleteIn([...filePath, fileIndex])
        .setIn(
          [
            'modules',
            moduleId,
            'list',
            clusterIndex,
            'questions',
            ...questionIndex,
            'loading',
          ],
          false
        );
    }
    case 'SET_FOLLOW_UP_DONE':
    case 'SET_COMMENT_DONE':
    case 'SET_SCORE_DONE': {
      const questionId = parseInt(action.payload.questionId, 10);
      const moduleId = action.meta.moduleId;
      const { questionIndex, clusterIndex } = findQuestion({
        state,
        questionId,
        moduleId,
      });

      if (clusterIndex === -1) {
        return state;
      }
      return state
        .mergeIn(
          [
            'modules',
            moduleId,
            'list',
            clusterIndex,
            'questions',
            ...questionIndex,
            'answer',
          ],
          fromJS(action.payload)
        )
        .setIn(
          [
            'modules',
            moduleId,
            'list',
            clusterIndex,
            'questions',
            ...questionIndex,
            'loading',
          ],
          false
        );
    }
    case 'SAVE_CLUSTER_COMMENTS_STARTED': {
      const clusterId = action.payload.id;
      const moduleId = action.meta.moduleId;
      const clusterIndex = state
        .getIn(['modules', moduleId, 'list'])
        .findIndex((cluster, clusterIndex) => cluster.get('id') === clusterId);
      if (clusterIndex === -1) {
        return state;
      }
      return state
        .setIn(['modules', moduleId, 'list', clusterIndex, 'loading'], true)
        .setIn(['modules', moduleId, 'list', clusterIndex, 'error'], '');
    }
    case 'SAVE_CLUSTER_COMMENTS_FAILED': {
      const clusterId = parseInt(action.meta.id, 10);
      const moduleId = action.meta.moduleId;
      const clusterIndex = state
        .getIn(['modules', moduleId, 'list'])
        .findIndex((cluster, clusterIndex) => cluster.get('id') === clusterId);
      if (clusterIndex === -1) {
        return state;
      }
      return state
        .setIn(['modules', moduleId, 'list', clusterIndex, 'loading'], false)
        .setIn(
          ['modules', moduleId, 'list', clusterIndex, 'error'],
          action.payload
        );
    }
    case 'SAVE_CLUSTER_COMMENTS_DONE': {
      const clusterId = parseInt(action.payload.questionClusterId, 10);
      const moduleId = action.meta.moduleId;
      const clusterIndex = state
        .getIn(['modules', moduleId, 'list'])
        .findIndex((cluster, clusterIndex) => cluster.get('id') === clusterId);
      if (clusterIndex === -1) {
        return state;
      }
      return state
        .setIn(['modules', moduleId, 'list', clusterIndex, 'loading'], false)
        .setIn(
          ['modules', moduleId, 'list', clusterIndex, 'comments'],
          action.payload.comment
        );
    }
    case 'SET_VALIDATION_ERRORS': {
      const moduleId = action.meta.moduleId;
      const errors = action.meta.errors;
      const clusters = {};
      for (const qId in errors) {
        const questionId = parseInt(qId, 10);
        const errorArray = errors[questionId];
        const { clusterIndex, questionIndex } = findQuestion({
          state,
          questionId,
          moduleId,
        });

        clusters[clusterIndex] =
          (clusters[clusterIndex] || 0) + errorArray.length;
        if (clusterIndex > -1) {
          state = state.setIn(
            [
              'modules',
              moduleId,
              'list',
              clusterIndex,
              'questions',
              ...questionIndex,
              'error',
            ],
            errorArray
          );
        }
      }
      Object.keys(clusters).forEach((clusterIndex) => {
        state = state.setIn(
          ['modules', moduleId, 'list', clusterIndex, 'errors'],
          clusters[clusterIndex]
        );
      });
      return state;
    }
    default: {
      return state;
    }
  }
}

function findQuestion({ state, questionId, moduleId }) {
  let questionIndex = [];
  const clusterIndex = state
    .getIn(['modules', moduleId, 'list'], List())
    .findIndex((cluster) => {
      questionIndex = findInQuestionTree(
        cluster.get('questions'),
        questionId,
        []
      );
      return questionIndex.length > 0;
    });

  const questionIndexPath = [];

  questionIndex.forEach((index, pathIndex) => {
    questionIndexPath.push(index);
    if (questionIndex.length > 1 && pathIndex !== questionIndex.length - 1) {
      questionIndexPath.push('children');
    }
  });

  return {
    questionIndex: questionIndexPath,
    clusterIndex,
  };

  function findInQuestionTree(questions, id, path) {
    if (!questions || questions.size === 0) {
      return [];
    }

    const index = questions.findIndex((q) => q.get('id') === id);
    if (index !== -1) {
      return path.concat(index);
    }

    for (let i = 0; i < questions.size; i++) {
      const question = questions.getIn([i]);
      const subTree = findInQuestionTree(
        question.get('children'),
        id,
        path.concat(i)
      );
      if (subTree.length > 0) {
        return subTree;
      }
    }
    return [];
  }
}
