import styled from 'styled-components';
import colors from 'globals/colors';

export default styled.input`
  max-height: 30px;
  align-self: center;
  text-align: center;
  background: ${(props) => {
    switch (props.status) {
      case 'updating':
        return colors.rating2;
      case 'updated':
        return colors.rating0;
      default:
        return 'white';
    }
  }};
`;
