import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import QuestionCluster from 'components/inspection/Questions/RegularInspectionQuestions/QuestionCluster';
import inspectionQuestionActions from 'actions/inspectionQuestions';
import inspectionQuestionsActions from 'actions/inspectionQuestions';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled from 'styled-components';
import { useScreenSize } from 'hooks/useScreenSize';
import { breakpoints } from 'globals/media';
import { List } from 'immutable';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import translate from 'utils/translate';
import TabContainer from '../Questions/RegularInspectionQuestions/TabContainer';

const StyledTabs = styled(Tabs)`
  width: 100%;
`;

const StyledTab = styled(Tab)`
  .MuiTab-wrapper {
    flex-direction: row;
  }
  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    .MuiTab-wrapper {
      color: white;
    }
  }
`;

const { load } = inspectionQuestionActions;

function Module({
  module,
  inspectionId,
  clusters,
  showFollowUp,
  saveClusterComments,
  picturesOpenByDefault,
  setScore,
  setFollowUp,
  setComment,
  uploadFile,
  deleteFile,
  unhideQuestion,
  userRole,
  isUsingModuleTabs,
  loadInspectionQuestions,
  onClusterChange,
  showNextClusterButton,
}) {
  const moduleId = module.get('id');
  const useTabs = module.get('useTabs');

  useEffect(() => {
    loadInspectionQuestions(inspectionId, moduleId);
  }, [loadInspectionQuestions, inspectionId, moduleId]);

  const screenSize = useScreenSize();
  const [activeTab, setActiveTab] = useState(0);

  const activeCluster = clusters.get(activeTab);

  return (
    <div>
      {!isUsingModuleTabs && <h3>{module.get('name')}</h3>}
      {useTabs && (
        <>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'flex-start',
              flexDirection:
                screenSize.width < breakpoints.tablet ? 'column' : 'row',
            }}
          >
            <TabContainer>
              <StyledTabs
                value={activeTab}
                onChange={(_, newValue) => {
                  window.scrollTo(0, 0);
                  setActiveTab(newValue);
                  if (typeof onClusterChange === 'function') {
                    onClusterChange(newValue);
                  }
                }}
                aria-label='simple tabs example'
                orientation={
                  screenSize.width < breakpoints.tablet
                    ? 'horizontal'
                    : 'vertical'
                }
                textColor='secondary'
                variant='scrollable'
                scrollButtons='on'
              >
                {clusters.map((questionCluster, index) => (
                  <StyledTab
                    label={
                      <>
                        <span>{questionCluster.get('name')}</span>
                      </>
                    }
                    value={index}
                  />
                ))}
              </StyledTabs>
            </TabContainer>
            {activeCluster && (
              <div
                style={{
                  flex: '1 0 0',
                  width: '100%',
                }}
              >
                <QuestionCluster
                  userRole={userRole}
                  useScroll={useTabs || isUsingModuleTabs}
                  isControl
                  showFollowUp={showFollowUp}
                  picturesOpenByDefault={picturesOpenByDefault}
                  questionCluster={activeCluster}
                  moduleId={module.get('id')}
                  inspectionId={inspectionId}
                  saveClusterComments={saveClusterComments}
                  setScore={setScore}
                  setFollowUp={setFollowUp}
                  setComment={setComment}
                  uploadFile={uploadFile}
                  deleteFile={deleteFile}
                  unhideQuestion={unhideQuestion}
                />
              </div>
            )}
          </div>
          {showNextClusterButton && (
            <Button
              variant='contained'
              onClick={() => {
                window.scrollTo(0, 0);
                setActiveTab(activeTab + 1);
                if (typeof onClusterChange === 'function') {
                  onClusterChange(activeTab + 1);
                }
              }}
              endIcon={<NavigateNextIcon />}
            >
              {translate('inspectionQuestions.nextCluster')}
            </Button>
          )}
        </>
      )}
      {!useTabs &&
        clusters.map((cluster) => (
          <QuestionCluster
            userRole={userRole}
            isControl
            useScroll={isUsingModuleTabs}
            showFollowUp={showFollowUp}
            picturesOpenByDefault={picturesOpenByDefault}
            key={cluster.get('id')}
            questionCluster={cluster}
            moduleId={module.get('id')}
            inspectionId={inspectionId}
            saveClusterComments={saveClusterComments}
            setScore={setScore}
            setFollowUp={setFollowUp}
            setComment={setComment}
            uploadFile={uploadFile}
            deleteFile={deleteFile}
            unhideQuestion={unhideQuestion}
          />
        ))}
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  const moduleId = ownProps.module.get('id');
  return {
    clusters: state.getIn(
      ['inspections', 'questions', 'modules', moduleId, 'list'],
      List()
    ),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const moduleId = ownProps.module.get('id');
  const inspectionId = ownProps.inspectionId;

  return {
    loadInspectionQuestions: (inspectionId, moduleId) =>
      dispatch(load(inspectionId, moduleId)),
    saveClusterComments: (data) =>
      dispatch(inspectionQuestionActions.saveClusterComments(data)),
    setScore: ({ questionId, score, comment }) => {
      dispatch(
        inspectionQuestionsActions.setScore({
          inspectionId,
          questionId,
          moduleId,
          score,
          comment,
        })
      );
    },
    setFollowUp: ({ questionId, needsFollowUp, followUpComment }) => {
      dispatch(
        inspectionQuestionsActions.setFollowUp({
          inspectionId,
          questionId,
          moduleId,
          needsFollowUp,
          followUpComment,
        })
      );
    },
    setComment: ({ questionId, comment }) =>
      dispatch(
        inspectionQuestionsActions.setComment({
          inspectionId,
          questionId,
          moduleId,
          comment,
        })
      ),
    uploadFile: ({ questionId, file }) => {
      dispatch(
        inspectionQuestionsActions.uploadFile({
          inspectionId,
          moduleId,
          questionId,
          file,
        })
      );
    },
    deleteFile: ({ questionId, fileId }) => {
      dispatch(
        inspectionQuestionsActions.deleteFile({
          inspectionId,
          moduleId,
          questionId,
          fileId,
        })
      );
    },
    unhideQuestion: ({ questionId }) => {
      dispatch(
        inspectionQuestionsActions.setScore({
          inspectionId,
          questionId,
          moduleId,
          score: null,
        })
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Module);
