import React from 'react';
import translate from 'utils/translate';
import { Wrapper, Header, InlineHeader } from './styled';
import { Map } from 'immutable';
import ComfortZoneModal from 'components/inspection/Questions/ComfortZoneModal';
import Button from 'components/general/Button';
import { classifications } from 'globals/classifications';

const expectedCommentCategories = classifications;

export default function ComfortZones({
  commentCategories,
  inspection = Map(),
  openModal,
}) {
  const comments = (commentCategories
    ? commentCategories.toJS()
    : expectedCommentCategories
  ).map((category) => (
    <React.Fragment key={category}>
      <InlineHeader>
        {translate(`generalCommentsCategories.${category}`)}
      </InlineHeader>
      <span>
        {(inspection.get(category, '') || '')
          .split('\n')
          .map((t, i) => <div key={i}>{t}</div>) || translate('unanswered')}
      </span>
      <Button
        color='white'
        textColor='textGray'
        onClick={() => openModal(category)}
      >
        {translate('edit')}
      </Button>
    </React.Fragment>
  ));
  return (
    <Wrapper>
      <Header>{translate('inspection.control.comments.category')}</Header>
      <Header>{translate('inspection.control.comments.comment')}</Header>
      {comments}
      <ComfortZoneModal
        inspectionId={inspection.get('id')}
        skipLoad
        reloadOnSave
      />
    </Wrapper>
  );
}
