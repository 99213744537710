import { request } from 'actions/utils';

export default function setFollowUp({
  inspectionId,
  moduleId,
  questionId,
  needsFollowUp,
  followUpComment,
}) {
  return async (dispatch) => {
    const action = await request({
      action: 'SET_FOLLOW_UP',
      path: `/api/inspections/${inspectionId}/modules/${moduleId}/questions/${questionId}/setFollowUp`,
      method: 'POST',
      body: { needsFollowUp, followUpComment },
      extraPayload: {
        questionId,
        moduleId,
      },
    });
    await dispatch(action);
  };
}
