import React from 'react';
import Typography from '@material-ui/core/Typography';
import translate from 'utils/translate';
import moment from 'moment';

export default function FollowUpInfo({
  show,
  comment,
  deadline,
  needsFollowUp,
}) {
  if (!show || !needsFollowUp) {
    return null;
  }

  const commonTypographyProps = {
    display: 'block',
    variant: 'body2',
  };

  return (
    <>
      {deadline && (
        <Typography {...commonTypographyProps}>
          <strong>{translate('questions.followUp.followUpDeadline')}</strong>:{' '}
          {moment(deadline).format('YYYY-MM-DD')}
        </Typography>
      )}
      <Typography {...commonTypographyProps}>
        <strong>{translate('comment')}</strong>: {comment}
      </Typography>
    </>
  );
}
