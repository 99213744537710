import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import translate from 'utils/translate';

export default function ClusterComments({ hotels, area }) {
  const comments = hotels.reduce(reduceHotelComments, []);
  if (!comments.length) return null;

  return (
    <div>
      <Typography variant='h6' style={{ marginTop: '3rem' }}>
        {translate('safeQReport.clusterComments')}
      </Typography>
      <List>
        {comments
          .sort((a, b) =>
            moment(a.date).isBefore(b.date)
              ? -1
              : moment(b.date).isBefore(a.date)
              ? 1
              : 0
          )
          .map(({ date, text }) => (
            <ListItemText
              primary={text}
              secondary={moment(date).format('YYYY-MM-DD')}
            />
          ))}
      </List>
    </div>
  );

  function reduceHotelComments(acc, hotel) {
    return hotel.inspections.reduce(reduceInspectionComments, acc);
  }

  function reduceInspectionComments(acc, inspection) {
    const inspectionModule = inspection.moduleTypes.find(
      (module) => module.area === area
    );
    if (!inspectionModule) return acc;
    return acc.concat(
      inspectionModule.questionClusterComments.map((comment) => ({
        text: comment,
        date: inspection.startTime,
      }))
    );
  }
}
