import { connect } from 'react-redux';
import HotelAdmin from './HotelAdmin';
import hotelActions from 'actions/admin/hotel';
import inspectionTypeActions from 'actions/admin/inspectionType';
import confirm from 'utils/confirm';
import translate from 'utils/translate';

function mapStateToProps(state) {
  return {
    role: state.getIn(['currentUser', 'role']),
    error: state.getIn(['admin', 'hotels', 'error']),
    loading: state.getIn(['admin', 'hotels', 'loading']),
    hotels: state.getIn(['admin', 'hotels', 'hotels']),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    loadHotels: () => dispatch(hotelActions.loadHotels()),
    openCreateModal: () => dispatch(hotelActions.openCreateModal()),
    openHotelAdministration: (id) =>
      dispatch(inspectionTypeActions.openView(id)),
    deleteHotel: async (hotel) => {
      const shouldDelete = await confirm(
        translate('hotels.delete.confirmText').replace(
          '{name}',
          hotel.get('name')
        ),
        {
          title: translate('hotels.delete.header'),
          confirmText: translate('hotels.delete'),
        }
      );
      if (shouldDelete) {
        dispatch(
          hotelActions.deleteHotel(hotel.get('id'), () =>
            dispatch(hotelActions.loadHotels())
          )
        );
      }
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HotelAdmin);
