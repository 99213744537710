import { request } from 'actions/utils';

export default function deleteUser(id, onSuccess) {
  return async (dispatch) => {
    let success = await dispatch(
      request({
        action: 'DELETE_USER',
        path: `/api/admin/users/${id}`,
        method: 'DELETE',
      })
    );
    if (success && typeof onSuccess === 'function') {
      onSuccess();
    }
  };
}
