import React, { useEffect, useState } from 'react';
import Wrapper from 'components/general/InspectionWrapper';
import ComfortZoneModal from 'components/inspection/Questions/ComfortZoneModal';
import ComfortZoneModalIcon from 'components/inspection/Questions/ComfortZoneModal/icon';
import ConfirmFinishModal from 'components/inspection/Questions/ConfirmFinishModal';
import ErrorModal from '../ErrorModal';
import translate from 'utils/translate';
import QuestionCluster from './QuestionCluster';
import { List } from 'immutable';
import {
  CommentButtonWrapper,
  CommentIcon,
} from 'components/general/CommentModalButton';
import { validateQuestions } from './utils/validationUtils';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled from 'styled-components';
import ErrorIcon from '@material-ui/icons/Error';
import { useScreenSize } from 'hooks/useScreenSize';
import { breakpoints } from 'globals/media';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import TabContainer from './TabContainer';

const StyledTabs = styled(Tabs)`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 130px);
`;

const StyledTab = styled(Tab)`
  .MuiTab-wrapper {
    flex-direction: row;
  }
  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    .MuiTab-wrapper {
      color: white;
    }
  }
`;

const ErrorDisplay = styled.span`
  color: ${({ theme }) => theme.palette.error.main};
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export default function InspectionQuestions({
  loadInspectionQuestions,
  inspectionId,
  moduleId,
  clusters,
  moduleName,
  useTabs,
  openComfortZoneModal,
  goBack,
  saveClusterComments,
  isFollowUpInspection,
  userRole,
  setScore,
  setComment,
  reportType,
  uploadFile,
  deleteFile,
  unhideQuestion,
  finishModule,
  openConfirmFinishModal,
  validateModule,
  openErrorModal,
}) {
  const screenSize = useScreenSize();
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    loadInspectionQuestions(inspectionId, moduleId);
  }, [inspectionId, loadInspectionQuestions, moduleId]);

  const onFinishClick = () => {
    let unansweredQuestions = List();
    clusters.forEach((cluster) => {
      unansweredQuestions = unansweredQuestions.concat(
        cluster
          .get('questions')
          .filter((q) => q.get('type') !== 'none')
          .filter(
            (q) => !q.get('answer') || q.getIn(['answer', 'score']) === null
          )
      );
    });

    let errors = {};
    if (userRole !== 'companyAdmin' && userRole !== 'superUser') {
      clusters.forEach((cluster) => {
        const questions = cluster
          .get('questions')
          .filter((question) =>
            isFollowUpInspection ? question.get('answer') : true
          );
        errors = { ...errors, ...validateQuestions(questions) };
      });
    }

    if (Object.keys(errors).length > 0) {
      openErrorModal({ errors });
      validateModule({ moduleId, errors });
    } else if (unansweredQuestions.length === 0) {
      finishModule({ inspectionId, moduleId });
    } else {
      openConfirmFinishModal(unansweredQuestions);
    }
  };

  const activeCluster = clusters && clusters.get(activeTab);
  const showFinishButton = !useTabs || activeTab === clusters.size - 1;
  const showNextClusterButton = useTabs && activeTab !== clusters.size - 1;
  const showCommentButton =
    typeof reportType === 'string' && reportType !== 'auditQA';

  let marginBottom = useTabs && showCommentButton ? 128 : useTabs ? 70 : 80;

  const showTopBackButton = useTabs && screenSize.width > breakpoints.tablet;

  return (
    <Wrapper marginBottom={marginBottom}>
      {showCommentButton && (
        <CommentButtonWrapper useTabs={useTabs}>
          <CommentIcon onClick={() => openComfortZoneModal(inspectionId)}>
            {reportType}
            <ComfortZoneModalIcon />
          </CommentIcon>
        </CommentButtonWrapper>
      )}
      <Title>
        {showTopBackButton && (
          <Button
            variant='outlined'
            onClick={() => goBack(inspectionId)}
            color='secondary'
            startIcon={<NavigateBeforeIcon />}
          >
            {translate('back')}
          </Button>
        )}
        <h1>{moduleName}</h1>
        {showTopBackButton && <div />}
      </Title>
      {useTabs && moduleName && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection:
              screenSize.width < breakpoints.tablet ? 'column' : 'row',
            alignItems: 'flex-start',
          }}
        >
          <TabContainer>
            <StyledTabs
              value={activeTab}
              onChange={(_, newValue) => {
                window.scrollTo(0, 0);
                setActiveTab(newValue);
              }}
              orientation={
                screenSize.width < breakpoints.tablet
                  ? 'horizontal'
                  : 'vertical'
              }
              textColor='secondary'
              variant='scrollable'
              scrollButtons='on'
            >
              {clusters.map((questionCluster, index) => (
                <StyledTab
                  label={
                    <>
                      <span>{questionCluster.get('name')}</span>
                      {questionCluster.get('errors') && (
                        <ErrorDisplay>
                          ({questionCluster.get('errors')}
                          <ErrorIcon fontSize='small' />)
                        </ErrorDisplay>
                      )}
                    </>
                  }
                  value={index}
                />
              ))}
            </StyledTabs>
          </TabContainer>
          {activeCluster && (
            <div
              style={{
                flex: '1 0 0',
                width: '100%',
              }}
            >
              <QuestionCluster
                useScroll={useTabs}
                userRole={userRole}
                questionCluster={activeCluster}
                isFollowUpInspection={isFollowUpInspection}
                moduleId={moduleId}
                inspectionId={inspectionId}
                saveClusterComments={saveClusterComments}
                setScore={setScore}
                setComment={setComment}
                uploadFile={uploadFile}
                deleteFile={deleteFile}
                unhideQuestion={unhideQuestion}
              />
            </div>
          )}
        </div>
      )}
      {!useTabs && (
        <>
          {clusters.map((questionCluster) => (
            <QuestionCluster
              userRole={userRole}
              key={questionCluster.get('id')}
              questionCluster={questionCluster}
              isFollowUpInspection={isFollowUpInspection}
              moduleId={moduleId}
              inspectionId={inspectionId}
              saveClusterComments={saveClusterComments}
              setScore={setScore}
              setComment={setComment}
              uploadFile={uploadFile}
              deleteFile={deleteFile}
              unhideQuestion={unhideQuestion}
            />
          ))}
        </>
      )}
      {moduleName ? (
        <div>
          {!useTabs && (
            <Button variant='contained' onClick={() => goBack(inspectionId)}>
              {translate('back')}
            </Button>
          )}
          {showNextClusterButton && (
            <Button
              variant='contained'
              onClick={() => {
                window.scrollTo(0, 0);
                setActiveTab(activeTab + 1);
              }}
              endIcon={<NavigateNextIcon />}
              style={{ marginLeft: '15px' }}
            >
              {translate('inspectionQuestions.nextCluster')}
            </Button>
          )}
          {showFinishButton && (
            <Button
              onClick={onFinishClick}
              variant='contained'
              color='primary'
              style={{ marginLeft: '15px' }}
            >
              {translate('inspectionQuestions.finish')}
            </Button>
          )}
        </div>
      ) : (
        <span>{translate('loading')}...</span>
      )}
      <ErrorModal />
      <ComfortZoneModal moduleId={moduleId} inspectionId={inspectionId} />
      <ConfirmFinishModal
        moduleId={moduleId}
        inspectionId={inspectionId}
        reportType={reportType}
      />
    </Wrapper>
  );
}
