import React, { Component } from 'react';
import Wrapper from 'components/general/InspectionWrapper';
import translate from 'utils/translate';
import { BackButton } from '../RegularInspectionQuestions/styled';
import ComfortZoneModal from 'components/inspection/Questions/ComfortZoneModal';
import ComfortZoneModalIcon from 'components/inspection/Questions/ComfortZoneModal/icon';
import Questions from './Questions';
import {
  CommentButtonWrapper,
  CommentIcon,
} from 'components/general/CommentModalButton';

export default class InspectionQuestions extends Component {
  componentDidMount() {
    const { loadInspection, inspectionId } = this.props;
    loadInspection(inspectionId);
  }

  render() {
    const {
      questions,
      inspectionId,
      goBack,
      user,
      updateQuestion,
      openComfortZoneModal,
    } = this.props;

    return (
      <Wrapper>
        <h1>{translate('extraQuestions')}</h1>
        <Questions
          questions={questions}
          inspectionId={inspectionId}
          user={user}
          updateQuestion={updateQuestion}
        />
        <div>
          <BackButton onClick={() => goBack(inspectionId)}>
            {translate('back')}
          </BackButton>
        </div>
        <CommentButtonWrapper>
          <CommentIcon onClick={() => openComfortZoneModal(inspectionId)}>
            <ComfortZoneModalIcon />
          </CommentIcon>
        </CommentButtonWrapper>
        <ComfortZoneModal inspectionId={inspectionId} />
      </Wrapper>
    );
  }
}
