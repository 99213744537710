import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Tooltip from '@material-ui/core/Tooltip';
import LinkIcon from '@material-ui/icons/Link';
import IconButton from '@material-ui/core/IconButton';
import userActions from 'actions/admin/user';
import hotelActions from 'actions/hotel';
import organizationActions from 'actions/admin/organization';
import translate from 'utils/translate';

const RowButton = styled(IconButton)`
  padding: 4px !important;
  margin: 4px !important;
`;

const StyledRow = styled(TableRow)`
  cursor: pointer;
`;

function Users({
  hotel,
  organization,
  loadOrganization,
  openUser,
  linkToHotel,
}) {
  const organizationId = hotel.get('organizationId');
  useEffect(() => {
    loadOrganization(organizationId);
  }, [organizationId, loadOrganization]);

  const hotelUsers = hotel.get('users', List());
  const users = organization
    .get('users', List())
    .filter(
      (user) =>
        !hotelUsers.some((hotelUser) => user.get('id') === hotelUser.get('id'))
    );

  if (!users.size)
    return <Typography>{translate('hotel.noUnlinkedUsers')}</Typography>;

  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell>{translate('users.edit.name')}</TableCell>
          <TableCell>{translate('users.edit.email')}</TableCell>
          <TableCell>{translate('users.edit.agentCode')}</TableCell>
          <TableCell>{translate('users.edit.role')}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((user) => (
          <StyledRow
            key={user.get('id')}
            hover
            onClick={() => openUser(user.get('id'))}
          >
            <TableCell>{user.get('name')}</TableCell>
            <TableCell>{user.get('email')}</TableCell>
            <TableCell>{user.get('agentCode')}</TableCell>
            <TableCell>
              {translate(`users.edit.role.${user.get('role')}`)}
            </TableCell>
            <TableCell style={{ width: '85px' }}>
              <Tooltip title={translate('users.edit.linkToHotel')}>
                <RowButton
                  size='medium'
                  onClick={(event) => {
                    event.stopPropagation();
                    linkToHotel(user.get('id'), hotel.get('id'));
                  }}
                >
                  <LinkIcon />
                </RowButton>
              </Tooltip>
            </TableCell>
          </StyledRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default connect(
  (state) => ({
    hotel: state.getIn(['hotel', 'current']),
    organization: state.getIn(['organization', 'current']),
  }),
  (dispatch) => ({
    loadOrganization: (organizationId) =>
      dispatch(organizationActions.loadOrganization.users(organizationId)),
    openUser: (userId) => dispatch(userActions.openView(userId)),
    linkToHotel: async (id, hotelId) => {
      await dispatch(userActions.linkToHotel(id, hotelId));
      dispatch(hotelActions.loadHotel(hotelId));
    },
  })
)(Users);
