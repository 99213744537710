import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { breakpoints } from 'globals/media';
import translate from 'utils/translate';
import Users from './Users';
import OrganizationUsers from './OrganizationUsers';

export default function UsersDialog({ visible, role, onClose }) {
  const [showOrganizationUsers, setShowOrganizationUsers] = useState(false);

  useEffect(() => {
    if (!visible) setShowOrganizationUsers(false);
  }, [visible]);

  if (!visible) return null;
  return (
    <Dialog
      open={visible}
      onClose={onClose}
      maxWidth='md'
      fullWidth
      fullScreen={window.innerWidth < breakpoints.handheld}
    >
      <DialogTitle>
        <Typography variant='h5'>
          {translate(
            showOrganizationUsers ? 'hotel.linkUsers' : 'hotel.manageUsers'
          )}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {showOrganizationUsers && (
          <>
            <OrganizationUsers />
            <Button
              style={{ marginTop: '1rem' }}
              color='secondary'
              startIcon={<ArrowBackIcon />}
              onClick={() => setShowOrganizationUsers(false)}
            >
              {translate('back')}
            </Button>
          </>
        )}
        {!showOrganizationUsers && (
          <>
            <Users />
            {['superUser', 'companyAdmin'].includes(role) && (
              <Button
                style={{ marginTop: '1rem' }}
                color='secondary'
                startIcon={<LinkIcon />}
                onClick={() => setShowOrganizationUsers(true)}
              >
                {translate('hotel.linkUsers')}
              </Button>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
