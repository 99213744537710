import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import VirtualizedList from './VirtualizedList';
import styled from 'styled-components';
import { Divider, IconButton, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import translate from '../../../utils/translate';

const ThePaper = styled(Paper)`
  width: 100%;
  height: calc(100vh - 430px);
`;

const HR = styled(Divider)`
  margin: 5px 0 !important;
`;

const HeaderRow = styled.div`
  white-space: normal;
  word-break: break-word;
  font-size: 12px;
  font-weight: 600;
  padding: 10px;
`;

const ListRow = styled.div`
  white-space: normal;
  word-break: break-word;
  font-size: 14px;
  padding: 10px 10px 10px 6px;
  border-left: 4px solid #54ca5e;
  background-color: hsla(0, 0%, ${props => props.odd ? '92%' : '98%'}, 1);
  > div.content {
    font-weight: 600;
  }
  &.errors {
    border-left: 4px solid #f44336;
    ul.error {
      margin: 0 0 0 20px;
      padding: 0;
      color: #f44336;
    }
  }
`;

const TableRow = styled.div`
  display: grid;
  align-items: left;
  grid-column-gap: .5rem;
  grid-template-columns: 75px 60px 60px 130px 80px 60px 70px 60px 60px 60px 60px 40px;
  > :not(:first-child) {
    text-align: center;
  }
`;

export default function QuestionsList(props) {
  const { questions=[] } = props;
  return (
    <>
      <HeaderRow>
        <TableRow>
          <div>Reference number</div>
          <div>Part number</div>
          <div>Comfort Zone</div>
          <div>Module</div>
          <div>Cluster</div>
          <div>Grade</div>
          <div>Mandatory</div>
          <div>Comment Pos</div>
          <div>Comment Neg</div>
          <div>Picture Pos</div>
          <div>Picture Neg</div>
          <div>Info</div>
        </TableRow>
      </HeaderRow>
      <ThePaper>
        <VirtualizedList
          rowCount={questions.length}
          renderRow={(index) => {
            const question = questions[index];
            const errors = question.errors;
            return (
              <ListRow odd={index % 2} className={errors && errors.length > 0 ? 'errors' : ''}>
                <TableRow>
                  <div>{question.referenceNumber}</div>
                  <div>{question.partNumber}</div>
                  <div>{question.comfortZone}</div>
                  <div>{question.module}</div>
                  <div>{question.cluster}</div>
                  <div>{question.grade}</div>
                  <div>{question.isMandatory}</div>
                  <div>{question.isCommentMandatoryPos}</div>
                  <div>{question.isCommentMandatoryNeg}</div>
                  <div>{question.isPictureMandatoryPos}</div>
                  <div>{question.isPictureMandatoryNeg}</div>
                  <div>{question.information ? (
                    <Tooltip title={question.information}>
                      <IconButton aria-label={'Show information'} style={{padding:0,marginTop:'-4px'}} color={'primary'}>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null}</div>
                </TableRow>
                { errors && errors.length > 0 ? (
                  <ul className={'error'}>
                  { errors.map((error,i) => (<li key={i}>{translate(`questions.upload.errors.${error.field}.${error.error}`)}</li>)) }
                  </ul>
                ) : null }
                <HR />
                <div className={'content'}>{question.content}</div>
              </ListRow>
            );
          }}
        />
      </ThePaper>
    </>
  );
}