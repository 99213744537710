import moment from 'moment';

export function borderStyles(screenWidth, color, styleDefinition) {
  const result = {};
  let borders = styleDefinition.lg;

  if (screenWidth < 960) {
    borders = styleDefinition.md || {};
  }

  if (borders.top) {
    result.borderTop = `${borders.top}px solid ${color}`;
  }
  if (borders.bottom) {
    result.borderBottom = `${borders.bottom}px solid ${color}`;
  }
  if (borders.left) {
    result.borderLeft = `${borders.left}px solid ${color}`;
  }
  if (borders.right) {
    result.borderRight = `${borders.right}px solid ${color}`;
  }
  return { style: result };
}

export function weeklyAverages(inspections) {
  const byWeek = {};
  inspections.forEach((inspection) => {
    const start = moment(inspection.date).startOf('week').format('YYYY-MM-DD');
    if (!byWeek[start]) {
      byWeek[start] = [];
    }
    byWeek[start].push(inspection);
  });

  return Object.keys(byWeek)
    .sort()
    .map((week, index, weeks) => {
      const count = 4;
      const startWeek = Math.max(index - (count - 1), 0);
      const endWeek = index + 1;
      const subset = weeks.slice(startWeek, endWeek);

      let joined = [];
      subset.forEach((week) => (joined = joined.concat(byWeek[week])));

      return {
        x: week,
        y: average(joined),
      };
    });
}

export function average(inspections) {
  if (inspections.length === 0) {
    return 0;
  }

  let sum = 0;
  inspections.forEach((inspection) => {
    sum += getPercentage(
      inspection.totalAnsweredQuestions,
      inspection.totalQuestions
    );
  });

  return sum / inspections.length;
}

export function getPercentage(fraction, total) {
  return Math.ceil((fraction / total) * 100);
}

export function getStats(inspections = [], zeroed) {
  let average = 0;
  let moduleStats = {};

  inspections.forEach((inspection) => {
    average += inspection.totalAnsweredQuestions / inspection.totalQuestions;
    inspection.modules.forEach((m) => {
      if (!moduleStats[m.area]) {
        moduleStats[m.area] = {
          fullfillment: [],
          total: 0,
        };
      }
      moduleStats[m.area].fullfillment.push(
        m.totalAnsweredQuestions / m.totalQuestions
      );
      moduleStats[m.area].total = m.totalQuestions;
    });
  });

  if (inspections.length !== 0) {
    average /= inspections.length;
  }

  Object.keys(moduleStats).forEach((key) => {
    const total = moduleStats[key].fullfillment.length;
    const sum = moduleStats[key].fullfillment.reduce(
      (prev, curr) => prev + curr,
      0
    );
    moduleStats[key].fullfillment = sum / total;
  });

  if (inspections.length === 0) {
    moduleStats = zeroed;
  }

  return {
    average,
    modules: moduleStats,
  };
}

export function formatHotelData({ inspectionTypes, inspectionModuleTypes }) {
  let inspections = inspectionTypes
    .map((inspectionType) =>
      inspectionType.inspections.map((inspection) => {
        const formattedInspection = {
          date: inspection.startTime,
          totalQuestions: 0,
          totalAnsweredQuestions: 0,
          modules: [],
        };

        formattedInspection.modules = inspectionModuleTypes
          .filter((inspectionModuleType) =>
            inspectionModuleType.inspectionModules.some(
              (inspectionModule) =>
                inspectionModule.inspectionTypeId ===
                  inspectionType.templateId &&
                inspection.moduleData.some(
                  (data) => data.inspectionModuleId === inspectionModule.id
                )
            )
          )
          .map((inspectionModuleType) => {
            const questions = inspectionModuleType.questionClusters
              .map((cluster) => cluster.questions)
              .flat()
              .map((question) => ({
                id: question.id,
                content: question.content,
                referenceNumber: question.referenceNumber,
                classification: question.classification,
                scores: inspection.answers.filter(
                  (answer) => answer.questionId === question.id
                ),
              }));

            const inspectionModule = {
              name: inspectionModuleType.name,
              area: inspectionModuleType.area,
              totalQuestions: questions.filter(
                (q) => q.scores.length === 0 || q.scores[0] !== -1
              ).length,
              totalAnsweredQuestions: questions
                .filter((q) => q.scores.length === 0 || q.scores[0] !== -1)
                .filter(
                  (q) =>
                    q.scores.length !== 0 &&
                    q.scores.find((score) => score !== null)
                ).length,
            };
            formattedInspection.totalQuestions +=
              inspectionModule.totalQuestions;
            formattedInspection.totalAnsweredQuestions +=
              inspectionModule.totalAnsweredQuestions;

            return inspectionModule;
          });

        return formattedInspection;
      })
    )
    .flat();

  inspections.sort((a, b) => {
    const dateA = moment(a.date).unix();
    const dateB = moment(b.date).unix();

    if (dateA === dateB) {
      return 0;
    }

    if (dateA < dateB) {
      return 1;
    }

    return -1;
  });

  return inspections;
}
