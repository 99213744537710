import load from './load';
import urls from 'globals/urls';
import loadTypes from './loadTypes';
import create from './create';
import remove from './remove';
import createType from './createType';
import removeType from './removeType';
import copyType from './copyType';
import update from './update';
import { push } from 'connected-react-router';

export default {
  load,
  create,
  remove,
  createType,
  removeType,
  copyType,
  update,
  loadTypes,
  openCreateModal,
  closeCreateModal,
  openDeleteModal,
  closeDeleteModal,
  openView,
  openDeleteTypeModal,
  openCopyTypeModal,
  closeCopyTypeModal,
  openEditTypeModal,
  closeEditTypeModal,
  closeDeleteTypeModal,
  openCreateTypeModal,
  closeCreateTypeModal,
};

export function openView(inspectionTypeId) {
  return push(
    urls.inspectionModules.replace(':inspectionTypeId', inspectionTypeId)
  );
}

export function openCreateModal() {
  return {
    type: 'OPEN_CREATE_INSPECTION_MODULE_MODAL',
  };
}

export function closeCreateModal() {
  return {
    type: 'CLOSE_CREATE_INSPECTION_MODULE_MODAL',
  };
}

export function openDeleteModal({ name, id }) {
  return {
    type: 'OPEN_DELETE_INSPECTION_MODULE_MODAL',
    payload: { name, id },
  };
}

export function closeDeleteModal() {
  return {
    type: 'CLOSE_DELETE_INSPECTION_MODULE_MODAL',
  };
}

export function openCreateTypeModal() {
  return {
    type: 'OPEN_CREATE_MODULE_TYPE_MODAL',
  };
}

export function closeCreateTypeModal() {
  return {
    type: 'CLOSE_CREATE_MODULE_TYPE_MODAL',
  };
}

export function openDeleteTypeModal() {
  return {
    type: 'OPEN_DELETE_MODULE_TYPE_MODAL',
  };
}

export function closeCopyTypeModal() {
  return {
    type: 'CLOSE_COPY_MODULE_TYPE_MODAL',
  };
}

export function openCopyTypeModal() {
  return {
    type: 'OPEN_COPY_MODULE_TYPE_MODAL',
  };
}

export function openEditTypeModal() {
  return {
    type: 'OPEN_EDIT_MODULE_TYPE_MODAL',
  };
}

export function closeEditTypeModal() {
  return {
    type: 'CLOSE_EDIT_MODULE_TYPE_MODAL',
  };
}

export function closeDeleteTypeModal() {
  return {
    type: 'CLOSE_DELETE_MODULE_TYPE_MODAL',
  };
}
