import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useTheme } from '@material-ui/core/styles';
import { INSPECTION_STATUS } from '../utils';

export default function StatusIcon({ status }) {
  const theme = useTheme();
  let color = theme.palette.success.main;

  switch (status) {
    case INSPECTION_STATUS.overdue: {
      color = theme.palette.error.main;
      break;
    }
    case INSPECTION_STATUS.warning: {
      color = theme.palette.warning.main;
      break;
    }
    default: {
      color = theme.palette.success.main;
      break;
    }
  }

  return <FiberManualRecordIcon style={{ color }} />;
}
