import { request } from 'actions/utils';
import moment from 'moment';

export default function createFollowUp({
  id,
  startTime,
  startTimeHour,
  user,
  sendCCToCreator,
}) {
  return request({
    action: 'CREATE_FOLLOW_UP_INSPECTION',
    path: `/api/inspections/${id}/follow-up`,
    method: 'POST',
    body: {
      startTime: moment(startTime).format('YYYY-MM-DD'),
      startTimeHour: moment(startTimeHour).format('kk:mm'),
      user,
      sendCCToCreator,
    },
    extraPayload: {
      startTime: `${moment(startTime).format('YYYY-MM-DD')} ${moment(
        startTimeHour
      ).format('kk:mm')}`,
    },
  });
}
