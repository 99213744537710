import { connect } from 'react-redux';
import inspectionActions from 'actions/inspection';
import GeneralCommentsModal from 'components/general/GeneralCommentsModal';

export default connect(
  (state) => ({
    visible: state.getIn(['inspections', 'generalCommentsModal', 'visible']),
    loading: state.getIn(['inspections', 'generalCommentsModal', 'loading']),
    error: state.getIn(['inspections', 'generalCommentsModal', 'error']),
    savedComments: state.getIn([
      'inspections',
      'generalCommentsModal',
      'comments',
    ]),
    serverParams: {
      inspectionId: state.getIn(['inspections', 'current', 'id']),
    },
    commentCategories: ['global'],
  }),
  (dispatch, ownProps) => ({
    setGeneralComments: async ({
      inspectionId,
      comments: { global },
      reload,
    }) => {
      await dispatch(
        inspectionActions.setGeneralComments({
          inspectionId,
          comments: global,
          type: 'general',
        })
      );
      if (typeof ownProps.onSave === 'function') {
        ownProps.onSave();
      }
    },
    closeModal: () => {
      dispatch(inspectionActions.closeGeneralCommentsModal());
    },
  })
)(GeneralCommentsModal);
