const INITIAL_STATE = {
  moduleType: {},
  loading: false,
  error: '',
};

export default function reducer(state = INITIAL_STATE, action = { type: '' }) {
  switch (action.type) {
    case 'INITIALIZE_FROM_PROPS': {
      return { ...state, ...action.payload };
    }
    case 'UPDATE_MODULETYPE_STARTED': {
      return { ...state, loading: true, error: '' };
    }
    case 'UPDATE_MODULETYPE_FAILED': {
      return { ...state, loading: false, error: action.payload };
    }
    case 'UPDATE_MODULETYPE_DONE': {
      return { ...state, loading: false };
    }
    case 'CHANGE_INPUT': {
      const moduleType = action.payload;
      return { ...state, moduleType };
    }
    default:
      return state;
  }
}
