import load from './load';
import { request } from 'actions/utils';

export default function createInspectionModule({
  name,
  type,
  isMandatory,
  isDefaultNA,
  useTabs,
  inspectionTypeId,
}) {
  return async (dispatch) => {
    let success = await dispatch(
      request({
        action: 'CREATE_INSPECTION_MODULE',
        path: `/api/admin/inspection-types/${inspectionTypeId}/inspection-modules`,
        method: 'POST',
        body: { name, type, isMandatory, isDefaultNA, useTabs },
      })
    );
    if (success) {
      dispatch(load(inspectionTypeId));
    }
  };
}
