import { connect } from 'react-redux';
import Form from './Form';

function mapStateToProps(state, ownProps) {
  return {
    error: state.getIn(['currentUser', 'login', 'error']),
    loading: state.getIn(['currentUser', 'login', 'loading']),
  };
}

export default connect(mapStateToProps, () => ({}))(Form);
