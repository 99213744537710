import { fromJS } from 'immutable';
import createModal from './createModal';
import deleteModal from './deleteModal';
import moduleTypes from './moduleTypes';

const defaultState = fromJS({
  list: [],
  loading: false,
  error: '',
  createModal: createModal(),
  deleteModal: deleteModal(),
  moduleTypes: moduleTypes(),
});

export default function (state = defaultState, action = { type: '' }) {
  state = state.set(
    'createModal',
    createModal(state.get('createModal'), action)
  );
  state = state.set(
    'deleteModal',
    deleteModal(state.get('deleteModal'), action)
  );
  state = state.set(
    'moduleTypes',
    moduleTypes(state.get('moduleTypes'), action)
  );
  switch (action.type) {
    case 'LOAD_INSPECTION_MODULES_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'LOAD_INSPECTION_MODULES_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'LOAD_INSPECTION_MODULES_DONE': {
      return state.set('list', fromJS(action.payload)).set('loading', false);
    }
    default: {
      return state;
    }
  }
}
