import load from './load';
import create from './create';
import remove from './remove';
import update from './update';
import createCluster from './createCluster';
import removeCluster from './removeCluster';
import updateCluster from './updateCluster';
import loadTypes from './loadTypes';

export default {
  load,
  create,
  remove,
  update,
  createCluster,
  removeCluster,
  updateCluster,
  openCreateQuestionModal,
  closeCreateQuestionModal,
  openDeleteQuestionModal,
  closeDeleteQuestionModal,
  openCreateClusterModal,
  closeCreateClusterModal,
  openDeleteClusterModal,
  closeDeleteClusterModal,
  loadTypes,
};

export function openCreateQuestionModal() {
  return {
    type: 'OPEN_CREATE_QUESTION_MODAL',
  };
}
export function closeCreateQuestionModal() {
  return {
    type: 'CLOSE_CREATE_QUESTION_MODAL',
  };
}
export function openDeleteQuestionModal({ content, id }) {
  return {
    type: 'OPEN_DELETE_QUESTION_MODAL',
    payload: { content, id },
  };
}
export function closeDeleteQuestionModal() {
  return {
    type: 'CLOSE_DELETE_QUESTION_MODAL',
  };
}

export function openCreateClusterModal() {
  return {
    type: 'OPEN_CREATE_QUESTION_CLUSTER_MODAL',
  };
}
export function closeCreateClusterModal() {
  return {
    type: 'CLOSE_CREATE_QUESTION_CLUSTER_MODAL',
  };
}

export function openDeleteClusterModal({ name, id }) {
  return {
    type: 'OPEN_DELETE_QUESTION_CLUSTER_MODAL',
    payload: { name, id },
  };
}
export function closeDeleteClusterModal() {
  return {
    type: 'CLOSE_DELETE_QUESTION_CLUSTER_MODAL',
  };
}
