import loadOrganizations from './loadOrganizations';
import createOrganization from './createOrganization';
import loadOrganization from './loadOrganization';
import { push } from 'connected-react-router';
import urls from 'globals/urls';

export default {
  loadOrganizations,
  createOrganization,
  loadOrganization,
  openCreateModal,
  closeCreateModal,
  openView,
};

export function openView(organizationId) {
  return push(urls.organizationStart.replace(':id', organizationId));
}

export function openCreateModal() {
  return {
    type: 'OPEN_CREATE_ORGANIZATION_MODAL',
  };
}
export function closeCreateModal() {
  return {
    type: 'CLOSE_CREATE_ORGANIZATION_MODAL',
  };
}
