import { useEffect, useState, useRef } from 'react';

const UPDATE_THRESHOLD = 50;

export function useScreenSize() {
  const timeout = useRef(0);
  const isInIframe = window.location !== window.parent.location;
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    window.addEventListener('resize', onScreenResizeChange);
    if (isInIframe) {
      postSizeMessage();
    }

    return () => {
      window.removeEventListener('resize', onScreenResizeChange);
    };

    function onScreenResizeChange() {
      clearTimeout(timeout.current);
      if (isInIframe) {
        postSizeMessage();
      }
      timeout.current = setTimeout(
        () =>
          setScreenSize({
            width: window.innerWidth,
            height: window.innerHeight,
          }),
        UPDATE_THRESHOLD
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return screenSize;
}

function postSizeMessage() {
  const boundingClientRect = window.document.body.getBoundingClientRect();
  window.parent.postMessage(
    JSON.stringify({
      width: boundingClientRect.width,
      height: window.document.body.scrollHeight,
    }),
    '*'
  );
}
