import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import translate from 'utils/translate';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;

  button {
    min-height: 36px;
  }
`;

export default function HotelForm({ onChange, hotel, role }) {
  return (
    <RegularSettings
      hotel={hotel}
      onChange={onChange}
      role={role}
    />
  );
}

function RegularSettings({ onChange, hotel, role }) {
  return (
    <Form>
      <TextField
        required
        margin='normal'
        variant='outlined'
        label={translate('hotels.create.name')}
        value={hotel.name || ''}
        onChange={(event) => onChange('name', event.target.value)}
      />
      <TextField
        variant='outlined'
        label={translate('hotels.create.address')}
        value={hotel.address || ''}
        onChange={(event) => onChange('address', event.target.value)}
      />
      {role === 'superUser' && (
        <FormControlLabel
          label={translate('hotels.dashboardEnabled')}
          control={
            <Checkbox
              name='dashboardEnabled'
              checked={hotel.dashboardEnabled}
              onChange={(event) =>
                onChange('dashboardEnabled', !hotel.dashboardEnabled)
              }
            />
          }
        />
      )}
      {role !== 'hotelAdmin' && (
        <>
          <KeyboardDatePicker
            margin='dense'
            variant='dialog'
            format='yyyy-MM-DD'
            inputVariant='outlined'
            clearable={!!hotel.inactiveStartDate}
            label={translate('hotels.create.inactiveStartDate')}
            value={hotel.inactiveStartDate || null}
            onChange={(date) =>
              onChange(
                'inactiveStartDate',
                date ? moment(date).format('YYYY-MM-DD') : null
              )
            }
          />
          <KeyboardDatePicker
            margin='dense'
            variant='dialog'
            format='yyyy-MM-DD'
            inputVariant='outlined'
            clearable={!!hotel.inactiveEndDate}
            label={translate('hotels.create.inactiveEndDate')}
            value={hotel.inactiveEndDate || null}
            onChange={(date) =>
              onChange(
                'inactiveEndDate',
                date ? moment(date).format('YYYY-MM-DD') : null
              )
            }
          />
        </>
      )}
    </Form>
  );
}
