import React from 'react';
import moment from 'moment';
import PointCircle from './PointCircle';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import isImagePath from 'utils/isImagePath';

const Container = styled.div`
  margin-bottom: 60px;
  @media print {
    page-break-inside: avoid;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const PrevPoints = styled.div`
  display: flex;
  margin-left: 50px;
  > div:not(:first-child) {
    margin-left: 7px;
  }
`;

const Files = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    max-height: 250px;
    &:not(:first-child) {
      margin-top: 7px;
    }
  }
`;

export default function QuestionSummary({
  index,
  referenceNumber,
  comment,
  files = [],
  content,
  startDay,
  days,
}) {
  return (
    <Container>
      <Header>
        <PointCircle score={days[0].score} style={{ marginRight: '7px' }} />
        <Typography variant='h6'>
          {index + 1}. {referenceNumber}
        </Typography>
        <PrevPoints>
          {days.slice(1).map((day, index) => (
            <PointCircle key={index} day={moment(day.date)} score={day.score} />
          ))}
        </PrevPoints>
      </Header>
      <Typography variant='h6'>{content}</Typography>
      <Typography variant='body1' style={{ marginTop: '7px' }}>
        {comment}
      </Typography>
      <Files>
        {files
          .filter((file) => isImagePath(file.path))
          .map((file) => (
            <img
              key={file.path}
              src={`/files/${file.path}?timestamp=${new Date().toISOString()}`}
              alt='answerPhoto'
            />
          ))}
      </Files>
    </Container>
  );
}
