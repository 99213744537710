import React, { Component } from 'react';
import Loader from 'components/general/Loader';
import styled from 'styled-components';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import ModalWrapper from 'components/general/ModalWrapper';
import InnerModal from 'components/general/InnerModal';
import Input from 'components/general/Input';
import Button from 'components/general/Button';
import ModalButtonRow from 'components/general/ModalButtonRow';

const Span = styled.span`
  margin-right: 10px;
  display: inline-block;
  font-size: 14px;
  width: 75px;
  white-space: normal;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const INITIAL_STATE = {
  name: '',
  id: -1,
};

export default class OrganizationFormModal extends Component {
  constructor() {
    super();
    this.state = INITIAL_STATE;
  }

  inputChange = ({ field, value }) => {
    const newState = {};
    newState[field] = value;
    this.setState(newState);
  };

  componentWillReceiveProps({ organization, visible }) {
    if (organization) {
      this.setState(organization.toJS());
    }
    if (!visible) {
      this.setState(INITIAL_STATE);
    }
  }

  render() {
    const {
      visible,
      loading,
      error,
      submit,
      closeModal,
      creating,
    } = this.props;
    if (!visible) {
      return null;
    }
    return (
      <ModalWrapper onClose={closeModal}>
        <InnerModal
          onSubmit={(e) => {
            e.preventDefault();
            submit(this.state);
          }}
        >
          <h4>{translate('organizations.create')}</h4>
          <Row>
            <Span>{translate('organizations.edit.name')}</Span>
            <Input
              type='text'
              value={this.state.name}
              onChange={(e) =>
                this.inputChange({ field: 'name', value: e.target.value })
              }
            />
          </Row>
          <ModalButtonRow>
            <Button color='white' textColor='textGray' onClick={closeModal}>
              {translate('back')}
            </Button>
            <Button>
              {translate(creating ? 'create' : 'organizations.edit.save')}
            </Button>
          </ModalButtonRow>
          {loading && <Loader />}
          {error && <Error>{translate(error)}</Error>}
        </InnerModal>
      </ModalWrapper>
    );
  }
}
