import styled from 'styled-components';
import media from 'globals/media';

export const Wrapper = styled.div`
  width: 100%;
  border-spacing: 5px 10px;
  margin: 20px 0;
  > * {
    display: block;
    width: 100%;
  }
  @supports (display: grid) {
    > * {
      display: inherit;
      width: auto;
    }
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    grid-gap: 40px 20px;
  }
`;

export const StartTimeTitle = styled.span`
  display: flex;
  justify-content: center;
  font-size: 0.8rem;
`;

export const Header = styled.div`
  font-weight: bold;
`;

export const NoHeader = styled.div`
  grid-column: 1 / -2;
`;

export const InlineHeader = styled.div`
  grid-column: 1;
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > button:not(:last-child) {
    margin-right: 15px;
  }
`;

export const ViewWrapper = styled.div`
  margin: 20px auto 50px auto;
  min-width: 280px;

  h1 {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 5px;
    margin-top: 3rem;
  }
  h3 {
    font-size: 20px;
    margin-top: 3rem;
    ${media.tablet`
      margin-top: 0;
      margin-top: 1rem;
    `};
  }
`;
