import React, { Component } from 'react';
import DeleteButton from 'components/general/DeleteButton';
import Button from 'components/general/Button';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import EditModal from './EditModal';
import DeleteModal from './DeleteModal';
import moment from 'moment';
import { Link } from 'react-router-dom';
import urls from 'globals/urls';
import { Wrapper, GridElement, Row, Centered, EditButton } from './styled';

export default class InspectionAdmin extends Component {
  state = {
    editModalOpen: false,
    inspectionToEdit: null,
    all: false,
  };

  componentDidMount() {
    this.props.load();
  }

  openEditModal = (inspection) => {
    this.setState({
      editModalOpen: true,
      inspectionToEdit: inspection,
    });
  };
  closeEditModal = () => {
    this.setState({
      editModalOpen: false,
      inspectionToEdit: null,
    });
    this.props.load();
  };

  render() {
    const { error, loading, openDeleteModal, inspections } = this.props;
    const getDomInspection = (inspection) => (
      <Row key={inspection.get('id')}>
        <GridElement>
          <Link
            to={urls.inspectionStart.replace(':id', inspection.get('id'))}
            target='_blank'
            rel='noreferrer noopener'
          >
            {inspection.getIn(['inspectionType', 'hotel', 'name'])}
          </Link>
        </GridElement>
        <GridElement>
          {inspection.getIn(['inspectionType', 'name'])}
        </GridElement>
        <GridElement>{inspection.getIn(['user', 'name'])}</GridElement>
        <GridElement>
          {moment(inspection.get('startTime')).format('YYYY-MM-DD')}
        </GridElement>
        <EditButton
          small
          color='white'
          textColor='textGray'
          type='button'
          onClick={() => this.openEditModal(inspection.toJS())}
        >
          {translate('edit')}
        </EditButton>
        <DeleteButton
          onClick={() =>
            openDeleteModal({
              user: inspection.getIn(['user', 'name']),
              startTime: inspection.get('startTime'),
              id: inspection.get('id'),
            })
          }
        >
          {translate('inspections.delete')}
        </DeleteButton>
      </Row>
    );
    const domCurrentInspections = inspections
      .filter((i) => !i.get('adminEndTime'))
      .map(getDomInspection);
    const domOldInspections = inspections
      .filter((i) => i.get('adminEndTime'))
      .map(getDomInspection);
    return (
      <Wrapper>
        <Centered>
          <h1>{translate('inspections.administration')}</h1>
        </Centered>
        <Centered>
          <Link to={urls.adminInspectionCreation}>
            <Button type='button'>{translate('inspections.create')}</Button>
          </Link>
        </Centered>
        <Centered>
          {loading && <Loader />}
          {error && <Error>{translate(error)}</Error>}
        </Centered>
        <Centered>
          <h4>{translate('inspections.current')}</h4>
        </Centered>
        {domCurrentInspections}
        <Centered>
          <hr />
        </Centered>
        <Centered>
          <h4>{translate('inspections.old')}</h4>
        </Centered>
        {domOldInspections}
        {!this.state.all && (
          <Centered>
            <Button
              type='button'
              onClick={() => {
                this.setState({
                  ...this.state,
                  all: true,
                });
                this.props.load(true);
              }}
            >
              Load older
            </Button>
          </Centered>
        )}
        {loading && this.state.all && (
          <Centered>
            <Loader />
          </Centered>
        )}
        {this.state.editModalOpen && (
          <EditModal
            closeModal={this.closeEditModal}
            data={this.state.inspectionToEdit}
          />
        )}
        <DeleteModal />
      </Wrapper>
    );
  }
}
