import React, { useState, useRef } from 'react';
import Error from 'components/general/Error';
import QuestionRow from 'components/inspection/Questions/QuestionRow';
import translate from 'utils/translate';
import ContextQuestions from './ContextQuestions';
import { ClusterCommentWrapper, Input, ClusterWrapper } from './styled';
import _debounce from 'lodash/debounce';
import { useScrolledQuestions } from './hooks';

export default function QuestionCluster({
  useScroll,
  isControl,
  questionCluster,
  moduleId,
  inspectionId,
  setScore,
  setFollowUp,
  setComment,
  showFollowUp,
  userRole,
  isFollowUpInspection,
  uploadFile,
  deleteFile,
  unhideQuestion,
  saveClusterComments,
  picturesOpenByDefault,
}) {
  const changed = useRef(_debounce(saveClusterComments, 1000));
  const [comment, setClusterComment] = useState(
    questionCluster.get('comments') || ''
  );

  const onChange = (e) => {
    const val = e.target.value;
    setClusterComment(val);
    changed.current({
      id: questionCluster.get('id'),
      inspectionId,
      moduleId,
      value: val,
    });
  };

  const clusterComment = (
    <>
      <h5>{translate('inspection.clusterComments')}</h5>
      <ClusterCommentWrapper>
        <Input value={comment} onChange={onChange} />
        {questionCluster.get('error') && (
          <Error autoWidth>{translate(questionCluster.get('error'))}</Error>
        )}
      </ClusterCommentWrapper>
    </>
  );

  const clusterQuestions = useScrolledQuestions(questionCluster, useScroll);

  const domQuestions = clusterQuestions.map((question, i) => (
    <QuestionRow
      isFollowUpInspection={isFollowUpInspection}
      userRole={userRole}
      picturesOpenByDefault={picturesOpenByDefault}
      showFollowUp={showFollowUp}
      setScore={setScore}
      setFollowUp={setFollowUp}
      setComment={setComment}
      uploadFile={uploadFile}
      deleteFile={deleteFile}
      unhideQuestion={unhideQuestion}
      key={question.get('id')}
      question={question}
      isControl={isControl}
    />
  ));

  return (
    <>
      <ClusterWrapper key={questionCluster.get('name')}>
        <h4>{questionCluster.get('name')}</h4>
        <ContextQuestions
          moduleId={moduleId}
          inspectionId={inspectionId}
          questions={questionCluster.get('contextQuestions')}
          clusterId={questionCluster.get('id')}
        />
        {questionCluster.get('commentPosition') === 'top' && clusterComment}
        {domQuestions}
        {questionCluster.get('commentPosition') === 'bottom' && clusterComment}
      </ClusterWrapper>
    </>
  );
}
