import { request } from 'actions/utils';

export default function ({ hotelId, id }, onSuccess) {
  let path = `/api/admin/inspection-types/${id}/hotels/${hotelId}/fromTemplate`;
  let body;
  if (Array.isArray(hotelId)) {
    path = `/api/admin/inspection-types/${id}/fromTemplate`;
    body = {
      hotelIds: hotelId,
    };
  }

  return async (dispatch) => {
    let success = await dispatch(
      request({
        action: 'LINK_INSPECTION_TYPE',
        path,
        method: 'POST',
        body,
      })
    );
    if (success && typeof onSuccess === 'function') {
      onSuccess();
    }
  };
}
