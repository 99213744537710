import { List } from 'immutable';
import { connect } from 'react-redux';
import inspectionActions from 'actions/inspection';
import { closeComfortZoneModal } from 'actions/inspectionQuestions';
import GeneralCommentsModal from 'components/general/GeneralCommentsModal';

export default connect(
  (state, ownProps) => ({
    visible: state.getIn([
      'inspections',
      'questions',
      'comfortZoneModal',
      'visible',
    ]),
    loading: state.getIn([
      'inspections',
      'questions',
      'comfortZoneModal',
      'loading',
    ]),
    error: state.getIn([
      'inspections',
      'questions',
      'comfortZoneModal',
      'error',
    ]),
    focusField: state.getIn([
      'inspections',
      'questions',
      'comfortZoneModal',
      'focusField',
    ]),
    savedComments: state.getIn([
      'inspections',
      'questions',
      'comfortZoneModal',
      'comments',
    ]),
    serverParams: {
      inspectionId: ownProps.inspectionId,
    },
    commentCategories: state.getIn(
      ['inspections', 'current', 'comfortZones'],
      List()
    ),
  }),
  (dispatch, ownProps) => ({
    load: ({ inspectionId }) => dispatch(inspectionActions.load(inspectionId)),
    setGeneralComments: async ({ inspectionId, comments, reload }) => {
      await dispatch(
        inspectionActions.update({
          inspectionId,
          ...comments,
          type: 'comfortZones',
        })
      );
      if (typeof ownProps.onSave === 'function') {
        ownProps.onSave();
      }
    },
    closeModal: () => {
      dispatch(closeComfortZoneModal());
    },
  })
)(GeneralCommentsModal);
