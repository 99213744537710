import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
  position: absolute;
  bottom: 1rem;
  right: 20px;
  > button:not(:last-child) {
    margin-right: 1rem;
  }
`;
