import {request} from 'actions/utils';

export default function getModuleData({
  inspectionId,
  moduleId
}) {
  return request({
    action: 'LOAD_MODULE_DATA',
    path: `/api/inspections/${inspectionId}/modules/${moduleId}/general-comments`,
    method: 'GET'
  });
}
