import { connect } from 'react-redux';
import Create from './Create';
import { push } from 'connected-react-router';
import urls from 'globals/urls';

function mapStateToProps(state, ownProps) {
  return {
    role: state.getIn(['currentUser', 'role']),
    userId: state.getIn(['currentUser', 'id']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onCreated: () => dispatch(push(urls.start)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Create);
