import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import translate from 'utils/translate';
import Error from 'components/general/Error';
import Loader from 'components/general/Loader';
import inspectionTypeActions from 'actions/admin/inspectionType';
import InspectionTypeForm from 'components/general/InspectionTypeForm';

const initialState = {
  id: -1,
  name: '',
  isSelfServe: false,
  allowUnansweredQuestions: false,
  autoStartTime: null,
  autoCloseTime: null,
  autoCloseWarningTime: null,
  autoCloseDelayDays: '',
  autoStartDelayDays: '',
  reportType: 'agentQ',
};

function EditModal({
  role,
  inspectionType,
  visible,
  loading,
  limited,
  error,
  save,
  closeModal,
}) {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (inspectionType.get('id') !== state.id) setState(inspectionType.toJS());
  }, [state.id, inspectionType]);

  if (!visible) return null;

  return (
    <Dialog open={visible} onClose={closeModal}>
      <DialogTitle>{translate('inspectionTypes.edit.header')}</DialogTitle>
      <DialogContent>
        <InspectionTypeForm
          {...state}
          role={role}
          limited={limited}
          onChange={(field, value) => setState({ ...state, [field]: value })}
        />
        {loading && <Loader />}
        {error && (
          <Error>{translate(error).replace('{name}', state.name)}</Error>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color='primary'>
          {translate('back')}
        </Button>
        {['superUser', 'companyAdmin', 'hotelAdmin'].includes(role) && (
          <Button
            variant='contained'
            color='primary'
            disabled={state.id === -1}
            onClick={() => save(state)}
          >
            {translate('save')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default connect(
  (state, ownProps) => ({
    role: state.getIn(['currentUser', 'role']),
    inspectionType: state.getIn([
      'admin',
      'inspectionTypes',
      'editModal',
      'inspectionType',
    ]),
    visible: state.getIn(['admin', 'inspectionTypes', 'editModal', 'visible']),
    loading: state.getIn(['admin', 'inspectionTypes', 'editModal', 'loading']),
    error: state.getIn(['admin', 'inspectionTypes', 'editModal', 'error']),
    ...ownProps,
  }),
  (dispatch, ownProps) => ({
    save: (inspectionData) =>
      dispatch(inspectionTypeActions.save(inspectionData, ownProps.onUpdated)),
    closeModal: () => dispatch(inspectionTypeActions.closeEditModal()),
  })
)(EditModal);
