import { fromJS } from 'immutable';
import submitModal from './submitModal';
import externalScoresModal from './externalScoresModal';
import questions from './questions';
import generalCommentsModal from './generalCommentsModal';
import list from './list';

const defaultState = fromJS({
  current: {
    comfortZones: [],
    extraQuestions: [],
  },
  loading: false,
  error: '',
  questions: questions(),
  generalCommentsModal: generalCommentsModal(),
  list: list(),
  submitModal: submitModal(),
  externalScoresModal: externalScoresModal(),
});

export default function (state = defaultState, action = { type: '' }) {
  state = state.set('questions', questions(state.get('questions'), action));
  state = state.set(
    'generalCommentsModal',
    generalCommentsModal(state.get('generalCommentsModal'), action)
  );
  state = state.set('list', list(state.get('list'), action));
  state = state.set(
    'submitModal',
    submitModal(state.get('submitModal'), action)
  );
  state = state.set(
    'externalScoresModal',
    externalScoresModal(state.get('externalScoresModal'), action)
  );

  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      return defaultState;
    }
    case 'SET_GENERAL_COMMENTS_DONE': {
      return state.setIn(['current', 'generalComments'], action.meta.comments);
    }
    case 'LOAD_INSPECTION_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'LOAD_INSPECTION_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'LOAD_INSPECTION_DONE': {
      return state.set('current', fromJS(action.payload)).set('loading', false);
    }
    case 'SET_END_TIME_STARTED':
    case 'SET_ADMIN_END_TIME_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'SET_END_TIME_FAILED':
    case 'SET_ADMIN_END_TIME_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'SET_END_TIME_DONE':
    case 'SET_ADMIN_END_TIME_DONE': {
      return state.set('loading', false);
    }
    case 'UPDATE_EXTRA_QUESTION_STARTED': {
      const questionId = action.payload.questionId;
      const questionIndex = state
        .getIn(['current', 'extraQuestions'])
        .findIndex((q) => q.get('id') === questionId);
      if (questionIndex === -1) {
        console.error(`Could not find question with id ${questionId}`);
        return state;
      }
      return state.setIn(
        ['current', 'extraQuestions', questionIndex, 'loading'],
        true
      );
    }
    case 'UPDATE_EXTRA_QUESTION_DONE': {
      const questionId = action.meta.questionId;
      const questionIndex = state
        .getIn(['current', 'extraQuestions'])
        .findIndex((q) => q.get('id') === questionId);
      if (questionIndex === -1) {
        console.error(`Could not find question with id ${questionId}`);
        return state;
      }
      return state
        .setIn(
          ['current', 'extraQuestions', questionIndex],
          fromJS(action.payload)
        )
        .setIn(['current', 'extraQuestions', questionIndex, 'loading'], false);
    }
    case 'UPDATE_EXTRA_QUESTION_FAILED': {
      const questionId = action.meta.questionId;
      const questionIndex = state
        .getIn(['current', 'extraQuestions'])
        .findIndex((q) => q.get('id') === questionId);
      if (questionIndex === -1) {
        console.error(`Could not find question with id ${questionId}`);
        return state;
      }
      return state
        .setIn(['current', 'extraQuestions', questionIndex, 'loading'], false)
        .setIn(
          ['current', 'extraQuestions', questionIndex, 'error'],
          action.payload
        );
    }
    default: {
      return state;
    }
  }
}
