import React from 'react';
import styled from 'styled-components';
import List from '@material-ui/core/List';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography } from '@material-ui/core';
import translate from 'utils/translate';
import { getPercentage, filterAnsweredHotels } from './utils';

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  > :nth-child(2) {
    cursor: help;
    margin-left: 4px;
    font-size: 14px;
  }
`;

export default function WorstQuestions({ hotels, weeks, area }) {
  hotels = filterAnsweredHotels({ hotels, weeks, areas: [area] });
  const questionsData = getQuestionsData({
    hotels,
    weeks,
    area,
  })
    .sort((a, b) => {
      const scorePercentageA = getPercentage(a.score, a.maxScore);
      const scorePercentageB = getPercentage(b.score, b.maxScore);
      return scorePercentageA > scorePercentageB
        ? 1
        : scorePercentageA < scorePercentageB
        ? -1
        : 0;
    })
    .slice(0, 5);

  if (!questionsData.length) return null;

  return (
    <div style={{ marginTop: '2rem' }}>
      <Header>
        <Typography variant='h6'>
          {translate('safeQReport.accumulatedObservation')}
        </Typography>
        <Tooltip title={translate('safeQReport.accumulatedObservationHelp')}>
          <HelpIcon />
        </Tooltip>
      </Header>
      <List>
        {questionsData.map((questionData, index) => (
          <ListItem key={index}>
            <ListItemIcon>{index + 1}</ListItemIcon>
            <ListItemText
              primary={`${questionData.referenceNumber || ''} (${getPercentage(
                questionData.score,
                questionData.maxScore
              )}% ${translate('safeQReport.score').toLowerCase()})`}
              secondary={questionData.content}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

function getQuestionsData({ hotels, weeks, area }) {
  return hotels.reduce(
    (acc, hotel) =>
      hotel.inspections
        .filter((inspection) => weeks.includes(inspection.startWeek))
        .map((inspection) =>
          inspection.moduleTypes
            .filter((module) => module.area === area)
            .map((module) => module.questions)
        )
        .flat(2)
        .reduce(reduceQuestionData, acc),
    []
  );
}

function reduceQuestionData(acc, question) {
  const isAnswered = question.scores.length;
  const prev = acc.find((match) => match.id === question.id);
  const data = isAnswered
    ? formatAnsweredData(prev, question)
    : formatUnansweredData(prev, question);

  if (!prev) return acc.concat(data);
  return acc.filter((prev) => prev.id !== question.id).concat(data);
}

function formatAnsweredData(prev, question) {
  const questionScore = question.scores
    .slice(0, 1)
    .reduce((total, score) => total + 3 - score, 0);
  const score = prev ? prev.score + questionScore : questionScore;
  const maxScore = prev ? prev.maxScore + 3 : 3;

  return {
    id: question.id,
    score,
    maxScore,
    content: question.content,
    referenceNumber: question.referenceNumber,
  };
}

function formatUnansweredData(prev, question) {
  const score = prev ? prev.score : 0;
  const maxScore = prev ? prev.maxScore + 3 : 3;

  return {
    id: question.id,
    score,
    maxScore,
    content: question.content,
    referenceNumber: question.referenceNumber,
  };
}
