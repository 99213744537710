import { withStyles } from '@material-ui/core/styles';
import MUIMenuItem from '@material-ui/core/MenuItem';
import MUIMenuList from '@material-ui/core/MenuList';
import MUICheckBox from '@material-ui/core/Checkbox';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiIconButton from '@material-ui/core/IconButton';

export const MenuItem = withStyles({
  root: {
    padding: '2px',
    minHeight: 'unset'
  }
})(MUIMenuItem);

export const MenuList = withStyles({
  root: {
    padding: 0
  }
})(MUIMenuList);

export const Checkbox = withStyles((theme) => ({
  root: {
    padding: 0
  },
  checked: {
    color: theme.palette.error.dark + ' !important'
  }
}))(MUICheckBox);

export const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  }
}))(MuiDialogActions);

export const IconButton = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
  }
}))(MuiIconButton);
