import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiIconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import HelpOutlineIcon from '@material-ui/icons/HelpOutlined';
import Typography from '@material-ui/core/Typography';
import translate from 'utils/translate';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    minWidth: 350,
    padding: theme.spacing(2),
    '& p': {
      whiteSpace: 'pre-wrap',
    },
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const IconButton = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiIconButton);

export default function InformationDialog({ content, className, question }) {
  const [open, setOpen] = React.useState(false);

  if (content) {
    return (
      <div className={className}>
        <IconButton
          aria-label='delete'
          color='primary'
          onClick={() => setOpen(true)}
        >
          <HelpOutlineIcon />
        </IconButton>
        <Dialog
          maxWidth={'lg'}
          onClose={() => setOpen(false)}
          open={open}
        >
          <DialogTitle onClose={() => setOpen(false)}>{question}</DialogTitle>
          <DialogContent dividers>
            <Typography>{content}</Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => setOpen(false)} color='primary'>
              {translate('inspectionQuestions.informationDialog.close')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  return <div className={className}></div>;
}
