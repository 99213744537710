const INITIAL_STATE = {
  id: 0,
  name: '',
  address: '',
  emailContact: '',
  reminderEmail: '',
  reportEmail: '',
  autoCloseWarningEmail: '',
  inactiveStartDate: null,
  dashboardEnabled: false,
  inactiveEndDate: null,
  error: '',
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action = { type: '' }) {
  switch (action.type) {
    case 'INITIALIZE_FROM_PROPS': {
      const { data } = action.payload;
      return {
        ...state,
        id: data.id,
        name: data.name || '',
        address: data.address || '',
        emailContact: data.emailContact || '',
        reminderEmail: data.reminderEmail || '',
        reportEmail: data.reportEmail || '',
        dashboardEnabled: data.dashboardEnabled || false,
        autoCloseWarningEmail: data.autoCloseWarningEmail || '',
        inactiveStartDate: data.inactiveStartDate || '',
        inactiveEndDate: data.inactiveEndDate || '',
      };
    }
    case 'UPDATE_HOTEL_STARTED': {
      return { loading: true, error: '' };
    }
    case 'UPDATE_HOTEL_FAILED': {
      return { loading: false, error: action.payload };
    }
    case 'UPDATE_HOTEL_DONE': {
      return { loading: false };
    }
    case 'CHANGE_INPUT': {
      const { field, value } = action.payload;
      return { [field]: value };
    }
    default:
      return state;
  }
}
