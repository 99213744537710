import { fromJS } from 'immutable';

const defaultState = fromJS({
  visible: false,
  loading: false,
  error: '',
  organizationId: -1,
});

export default function (
  state = defaultState,
  action = { type: '', payload: {} }
) {
  switch (action.type) {
    case 'CREATE_USER_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'CREATE_USER_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'CREATE_USER_DONE': {
      return state.set('loading', false).set('visible', false);
    }
    case 'OPEN_CREATE_USER_MODAL': {
      const organizationId = action.payload && action.payload.organizationId;
      return state
        .set('visible', true)
        .set('organizationId', organizationId || -1);
    }
    case 'CLOSE_CREATE_USER_MODAL': {
      return state.set('visible', false);
    }
    case 'OPEN_DELETE_USER_MODAL': {
      return state.set('visible', false);
    }
    default: {
      return state;
    }
  }
}
