import React, { useEffect, useState, useRef } from 'react';
import LoadingOverlay from 'components/general/LoadingOverlay';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import TextField from '@material-ui/core/TextField';
import _debounce from 'lodash/debounce';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useScreenSize } from 'hooks/useScreenSize';
import { breakpoints } from 'globals/media';
import styled from 'styled-components';
const InputWrapper = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
`;

export default function GeneralCommentsModal({
  visible,
  loading,
  error,
  closeModal,
  serverParams,
  commentCategories,
  focusField = commentCategories && commentCategories[0],
  setGeneralComments,
  savedComments,
  load,
  skipLoad,
}) {
  const screenSize = useScreenSize();
  const save = useRef(_debounce(setGeneralComments, 2000));
  const [comments, setComments] = useState(null);
  useEffect(() => {
    if (load && !skipLoad) {
      load(serverParams);
    }
    // Only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (savedComments) {
      setComments(savedComments.toJS());
    }
  }, [savedComments, visible]);

  if (!visible) {
    return null;
  }

  const onInputChange = (e, saveParams) => {
    const newComments = {
      ...comments,
      [e.target.id]: e.target.value,
    };
    setComments(newComments);

    if (save.current) {
      save.current({ ...saveParams, comments: newComments });
    }
  };

  const inputs = commentCategories.map((category) => [
    <TextField
      disabled={!comments}
      key={`${category}Header`}
      label={translate(`generalCommentsCategories.${category}`)}
      autoFocus={focusField === category}
      value={comments ? comments[category] : ''}
      multiline
      margin='normal'
      id={category}
      onChange={(e) =>
        onInputChange(e, {
          ...serverParams,
          comments,
        })
      }
    />,
  ]);

  return (
    <Dialog
      open
      onClose={closeModal}
      fullScreen={screenSize.width < breakpoints.handheld}
    >
      <DialogContent>
        <InputWrapper>{inputs}</InputWrapper>
        <LoadingOverlay show={loading} />
        {error && <Error>{translate(error)}</Error>}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          color='primary'
          onClick={() => {
            setGeneralComments({
              ...serverParams,
              comments,
            });
            closeModal();
            if (save.current) {
              save.current.cancel();
            }
          }}
        >
          {translate('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
