import styled from 'styled-components';

export default styled.header`
  display: flex;
  justify-content: space-between;
  > * {
    flex: 1 0 0;
  }
  h1 {
    margin: 0;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
  }
`;
