import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import translate from 'utils/translate';
import Message from 'components/general/Message';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LoadingOverlay from 'components/general/LoadingOverlay';
import Logo from 'components/general/Logo';
import { resetPassword } from './actions';
import {
  Container,
  Form,
  Header,
  LogoContainer,
  InnerWrapper,
} from '../styled/Form';
import urls from 'globals/urls';

const initialState = {
  token: '',
  password: '',
  passwordConfirmation: '',
  loading: false,
  success: false,
  error: '',
};

export default function ResetPasswordForm() {
  const [state, setState] = useState(initialState);
  const {
    token,
    password,
    passwordConfirmation,
    loading,
    error,
    success,
  } = state;

  return (
    <Container>
      <InnerWrapper>
        <Paper>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              if (success) {
                return;
              }
              if (!token) {
                return setError('login.missingToken');
              }
              if (!password || !passwordConfirmation) {
                return setError('login.missingPassword');
              }
              if (password !== passwordConfirmation) {
                return setError('login.passwordMismatch');
              }
              if (password.length < 12) {
                return setError('login.passwordLength');
              }
              setState((oldState) => ({
                ...oldState,
                loading: true,
                success: false,
                error: '',
              }));

              try {
                const response = await resetPassword({ token, password });
                if (response.msg) {
                  return setError(response.msg);
                }
              } catch (error) {
                return setError(error.message);
              }
              setState((oldState) => ({
                ...oldState,
                loading: false,
                success: true,
              }));

              function setError(e) {
                setState((oldState) => ({
                  ...oldState,
                  error: e,
                  loading: false,
                  success: false,
                }));
              }
            }}
          >
            <Header>
              <LogoContainer>
                <Logo height={'40'} text />
              </LogoContainer>
              <Typography variant='h6' component='h1' color='primary'>
                {translate('site.name')}
              </Typography>
            </Header>
            {success ? (
              <>
                <Typography gutterBottom>
                  {translate('login.resetSuccess')}
                </Typography>
                <a href={urls.login}>
                  <Typography gutterBottom color='primary'>
                    {translate('login.resetSuccessLink')}
                  </Typography>
                </a>
              </>
            ) : (
              <>
                <Typography
                  variant='h6'
                  component='h2'
                  style={{ alignSelf: 'flex-start' }}
                >
                  {translate('login.resetPassword')}
                </Typography>
                <Typography style={{ alignSelf: 'flex-start' }} gutterBottom>
                  {translate('login.resetInstructions')}
                </Typography>
                <TextField
                  fullWidth
                  type='text'
                  value={token}
                  label={translate('login.token')}
                  onChange={(e) => {
                    setState({
                      ...state,
                      token: e.target.value,
                    });
                  }}
                />
                <Typography
                  style={{ alignSelf: 'flex-start', marginTop: '15px' }}
                >
                  {translate('login.passwordLength')}
                </Typography>
                <TextField
                  fullWidth
                  type='password'
                  margin='normal'
                  value={password}
                  label={translate('login.newPassword')}
                  onChange={(e) => {
                    setState({
                      ...state,
                      password: e.target.value,
                    });
                  }}
                />
                <TextField
                  fullWidth
                  type='password'
                  margin='normal'
                  value={passwordConfirmation}
                  label={translate('login.confirmPassword')}
                  onChange={(e) => {
                    setState({
                      ...state,
                      passwordConfirmation: e.target.value,
                    });
                  }}
                />
                <Button color='primary' variant='contained' type='submit'>
                  {translate('login.resetPassword')}
                </Button>
              </>
            )}
          </Form>
        </Paper>
        <LoadingOverlay show={loading} />
        <Message type={'error'} style={{ width: 'initial' }} show={error}>
          {error && translate(error)}
        </Message>
      </InnerWrapper>
    </Container>
  );
}
