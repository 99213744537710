import React, { useEffect } from 'react';
import translate from 'utils/translate';
import Wrapper from 'components/general/InspectionWrapper';
import InspectionCard from './InspectionCard';
import urls from 'globals/urls';
import { Link } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import { breakpoints } from 'globals/media';
import { useScreenSize } from 'hooks/useScreenSize';
import { useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

export default function ({ inspections, load, canCreateSessions, create }) {
  useEffect(() => {
    load();
  }, [load]);
  const theme = useTheme();
  const screenSize = useScreenSize();

  const domInspections = inspections.map((inspection, i) => [
    <div key={i}>
      <Link to={urls.inspectionStart.replace(':id', inspection.get('id'))}>
        <InspectionCard inspection={inspection} />
      </Link>
    </div>,
  ]);

  return (
    <Wrapper>
      {canCreateSessions && (
        <Fab
          variant={screenSize.width < breakpoints.tablet ? 'round' : 'extended'}
          style={{
            position:
              screenSize.width < breakpoints.tablet ? 'fixed' : 'relative',
            bottom:
              screenSize.width < breakpoints.tablet ? theme.spacing(2) : 0,
            right: screenSize.width < breakpoints.tablet ? theme.spacing(2) : 0,
          }}
          color='primary'
          label={translate('inspections.create.header')}
          onClick={create}
        >
          <AddIcon />
          {screenSize.width > breakpoints.tablet &&
            translate('inspections.create.header')}
        </Fab>
      )}
      <h1>{translate('inspections.choose')}</h1>
      <div>{domInspections}</div>
    </Wrapper>
  );
}
