import React, {Component} from 'react';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import Input from 'components/general/Input';
import translate from 'utils/translate';
import ModalWrapper from 'components/general/ModalWrapper';
import InnerModal from 'components/general/InnerModal';
import Button from 'components/general/Button';
import localReducer from './reducer';
import update from './update';

export default class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = localReducer(undefined, {
      type: 'INITIALIZE_FROM_PROPS',
      payload: {
        name: props.cluster.name,
        id: props.cluster.id,
        ordinal: props.cluster.ordinal
      }
    });
  }
  dispatch = action => {
    this.setState(prevState => localReducer(prevState, action));
  };
  update = async e => {
    e.preventDefault();
    const success = await update({data: this.state}, this.dispatch);
    if (success) {
      this.props.closeModal();
    }
  };
  changeInput = payload =>
    this.dispatch({
      type: 'CHANGE_INPUT',
      payload
    });

  render() {
    const {closeModal} = this.props;
    const {loading, error} = this.state;
    return (
      <ModalWrapper onClose={closeModal}>
        <InnerModal onSubmit={this.update}>
          <h4>{translate('questions.editCluster.header')}</h4>
          <Input
            type='text'
            value={this.state.name}
            onChange={e => this.changeInput({field: 'name', value: e.target.value})}
            placeholder={translate('questions.createCluster.name')}
          />
          <Input
            type='number'
            value={this.state.ordinal}
            onChange={e => this.changeInput({field: 'ordinal', value: e.target.value})}
            placeholder={translate('ordinal')}
          />
          <Button>{translate('questions.editCluster.button')}</Button>
          {loading && <Loader />}
          {error && <Error>{translate(error)}</Error>}
        </InnerModal>
      </ModalWrapper>
    );
  }
}
