import React from 'react';
import translate from 'utils/translate';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { breakpoints } from 'globals/media';
import { useScreenSize } from 'hooks/useScreenSize';
import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';
import NACheckbox from '../NACheckbox';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Checkboxes = styled.div`
  display: flex;
`;

export default function GradeBtoEScore({
  type,
  score,
  isMandatory,
  setScore,
  requiredCommentMinScore,
  onRequiredComment,
}) {
  const screenSize = useScreenSize();

  const onChange = (event) => {
    const score = parseInt(event.target.value, 10);
    if (
      requiredCommentMinScore !== null &&
      requiredCommentMinScore <= score &&
      onRequiredComment
    ) {
      return onRequiredComment(score);
    }

    setScore(score);
  };

  const marginal = {
    value: 1,
    label: translate('questions.gradeBtoE.marginal'),
  };
  const low = { value: 2, label: translate('questions.gradeBtoE.low') };
  const medium = { value: 3, label: translate('questions.gradeBtoE.medium') };
  const high = { value: 4, label: translate('questions.gradeBtoE.high') };
  const veryHigh = {
    value: 5,
    label: translate('questions.gradeBtoE.veryHigh'),
  };

  let radios = [marginal, medium];

  if (type === 'gradeB') {
    radios = radios.concat([low, high, veryHigh]);
  }
  if (type === 'gradeC') {
    radios = radios.concat([low, high]);
  }
  if (type === 'gradeD') {
    radios = radios.concat([high]);
  }
  radios.sort((radioA, radioB) => (radioA.value < radioB.value ? -1 : 1));

  return (
    <Wrapper>
      <FormControl component='fieldset'>
        <RadioGroup
          row={screenSize.width > breakpoints.handheld}
          value={score}
          onChange={onChange}
        >
          {radios.map((radio) => (
            <FormControlLabel
              key={radio.value}
              value={radio.value}
              control={<Radio />}
              label={radio.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <Checkboxes>
        <FormControlLabel
          control={
            <Checkbox
              checked={score === 0}
              onChange={(event) => (event.target.checked ? setScore(0) : null)}
            />
          }
          label={translate('NoDeviation')}
        />
        <NACheckbox
          score={score}
          isMandatory={isMandatory}
          setScore={setScore}
        />
      </Checkboxes>
    </Wrapper>
  );
}
