import load from './loadTypes';
import urls from 'globals/urls';
import {request} from 'actions/utils';

export default function(id) {
  return async dispatch => {
    let success = await dispatch(request({
      action: 'DELETE_MODULE_TYPE',
      path: `/api/admin/inspection-module-types/${id}`,
      method: 'DELETE',
      redirect: urls.questionAdmin.replace(':moduleTypeId', 'default')
    }));
    if (success) {
      dispatch(load());
    }
  };
}
