import colors from 'globals/colors';
import Chart from 'chart.js';
import translate from 'utils/translate';

Chart.pluginService.register({
  beforeDraw: function(chart, easing) {
    if (
      chart.config.options.chartArea &&
      chart.config.options.chartArea.backgroundColor
    ) {
      const ctx = chart.chart.ctx;
      const chartArea = chart.chartArea;

      ctx.save();
      const colors = chart.config.options.chartArea.backgroundColor;
      const gradientBackground = ctx.createLinearGradient(
        0,
        chartArea.top,
        0,
        chartArea.bottom
      );
      const lengthPerColor = 1 / colors.length;
      colors.forEach((color, i) =>
        gradientBackground.addColorStop(lengthPerColor * i, color)
      );
      ctx.fillStyle = gradientBackground;
      ctx.fillRect(
        chartArea.left,
        chartArea.top,
        chartArea.right - chartArea.left,
        chartArea.bottom - chartArea.top
      );
      ctx.restore();
    }
  }
});

export function renderTotalScore({canvas, labels, data}) {
  const ctx = canvas.getContext('2d');
  return new Chart(ctx, {
    type: 'bar',
    data: {
      labels,
      datasets: [
        {
          data,
          backgroundColor: ['#808080', '#D3D3D3'],
          borderWidth: 0
        }
      ]
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: true,
        labels: {
          boxWidth: 20,
          generateLabels: function(chart) {
            return data.map((e, i) => ({
              fillStyle: isNaN(e)
                ? ''
                : `${colors[`totalScore${labels[i].toLowerCase()}`]}`,
              text: isNaN(e) ? '' : `${e}%`
            }));
          }
        }
      },
      scales: {
        xAxes: [
          {
            type: 'category',
            labels,
            categoryPercentage: 0.8,
            barPercentage: 1.0
          }
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100
            }
          }
        ]
      }
    }
  });
}

export function renderExternalScoresChart({canvas, data, labelList}) {
  const ctx = canvas.getContext('2d');
  data = data.sort((a, b) => new Date(a.x) - new Date(b.x));
  const datasets = [
    getDataset(data, 'facebook'),
    getDataset(data, 'tripAdvisor'),
    getDataset(data, 'hotels'),
    getDataset(data, 'booking'),
    getDataset(data, 'custom')
  ];
  return new Chart(ctx, {
    type: 'bar',
    data: {
      labels: labelList || data.map(d => d.x),
      datasets
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        labels: {
          boxWidth: 20
        }
      },
      scales: {
        xAxes: [
          {
            maxBarThickness: 20,
            barPercentage: 1.0,
            categoryPercentage: 0.55,
            type: 'time',
            ticks: {
              source: 'labels'
            },
            time: {
              unit: 'month',
              displayFormats: {
                month: 'MMM-YY'
              }
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 10
            }
          }
        ]
      }
    }
  });
}

const externalScoreColors = {
  facebook: '#4266b2',
  booking: '#003580',
  hotels: '#d32f2f',
  custom: 'black',
  tripAdvisor: '#00af86'
};

function getDataset(data, subY) {
  return {
    backgroundColor: externalScoreColors[subY],
    borderColor: externalScoreColors[subY],
    pointBackgroundColor: externalScoreColors[subY],
    label: translate(`inspection.externalScores.${subY}`),
    data: data.map(d => ({x: d.x, y: d.y[subY]})),
    lineTension: false,
    borderWidth: 1
  };
}

const lineColors = [
  'hsl(180, 60%, 60%)',
  'hsl(90, 60%, 30%)',
  'hsl(255, 60%, 60%)',
  'hsl(180, 60%, 30%)',
  'hsl(255, 60%, 30%)',
  'hsl(255, 60%, 15%)'
];

function getRandomColor(i) {
  return lineColors[i % lineColors.length];
}

function roundDown(nr) {
  return nr - nr % 10;
}

export function renderLineChart({
  canvas,
  data,
  labelList,
  colors = [],
  forceSize,
  fixedLabels = {}
}) {
  const ctx = canvas.getContext('2d');
  let minScore = 100;
  let maxScore = 100;

  /* manipulate data-obj so it has fixed labels */
  if (!(Object.keys(fixedLabels).length === 0)) {
    let new_obj = {};
    Object.keys(fixedLabels).forEach(key => {
      if (data.hasOwnProperty(key)) new_obj[key] = data[key];
    });
    data = new_obj;
  }

  const datasets = Object.entries(data).map(([key, lineData], i) => {
    lineData = lineData.sort((a, b) => new Date(a.x) - new Date(b.x));
    const minY = lineData.reduce((min, p) => (p.y < min ? p.y : min), 100);
    const maxY = lineData.reduce((max, p) => (p.y < max ? max : p.y), 0);

    minScore = minScore > minY ? minY : minScore;
    maxScore = maxScore > maxY ? maxScore : 110;

    return {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: colors[i] || getRandomColor(i),
      pointBackgroundColor: colors[i] || getRandomColor(i),
      label: key,
      pointRadius: lineData.length > 1 ? 3 : 6,
      data: lineData,
      lineTension: false,
      borderWidth: 3
    };
  });

  return new Chart(ctx, {
    type: 'line',
    data: {
      datasets,
      labels: labelList || Object.values(data)[0].map(d => d.x)
    },
    options: {
      maintainAspectRatio: !forceSize,
      scales: {
        xAxes: [
          {
            type: 'time',
            ticks: {
              source: 'labels'
            },
            time: {
              unit: 'month',
              displayFormats: {
                month: 'MMM-YY'
              }
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              stepSize: 10,
              max: maxScore,
              min:
                roundDown(minScore) >= 10
                  ? minScore - roundDown(minScore) === 0
                    ? roundDown(minScore - 10)
                    : roundDown(minScore)
                  : 0,
              callback: value => {
                return value + '%';
              }
            }
          }
        ]
      },
      chartArea: {
        backgroundColor: [
          'hsl(120, 100%, 35%)',
          'hsl(120, 100%, 45%)',
          'hsl(60, 100%, 50%)',
          'hsl(45, 100%, 53%)',
          'hsl(0, 100%, 45%)'
        ]
      }
    }
  });
}
