import qs from 'qs';
import moment from 'moment';
import { connect } from 'react-redux';
import Report from './Report';

function mapStateToProps(state, ownProps) {
  const queryDay = qs.parse(ownProps.location.search.substring(1)).day;
  const queryInspectionType = qs.parse(ownProps.location.search.substring(1))
    .inspectionType;
  const day =
    queryDay && moment(queryDay).isValid()
      ? moment(queryDay).format('YYYY-MM-DD')
      : moment().subtract(1, 'day').format('YYYY-MM-DD');
  return {
    error: state.getIn(['admin', 'reports', 'error']),
    loading: state.getIn(['admin', 'reports', 'loading']),
    hotelId: ownProps.match.params.id,
    day,
    inspectionType: queryInspectionType,
  };
}

export default connect(mapStateToProps, {})(Report);
