import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import {
  Hotel,
  HotelTitle,
  HotelAddress,
  MetaData,
} from 'components/admin/Report/styled';
import translate from 'utils/translate';
import UnansweredDays from './UnansweredDays';
import IncompleteDays from './IncompleteDays';

export default function HeaderInformation({ week, hotel, day }) {
  const startDay = moment(day).startOf('isoWeek').format('YYYY-MM-DD');
  return (
    <MetaData>
      <Hotel>
        <HotelTitle>{hotel.name}</HotelTitle>
        <HotelAddress>{hotel.address}</HotelAddress>
      </Hotel>
      <div style={{ display: 'flex', margin: '2rem 0' }}>
        <div>
          <Typography style={{ fontWeight: 'bold' }} gutterBottom>
            {translate('safeQReport.startDate').concat(':')}
          </Typography>
          <Typography style={{ fontWeight: 'bold' }}>
            {translate('safeQReport.endDate').concat(':')}
          </Typography>
        </div>
        <div style={{ marginLeft: '0.5rem' }}>
          <Typography style={{ fontWeight: 'bold' }} gutterBottom>
            {startDay}
          </Typography>
          <Typography style={{ fontWeight: 'bold' }}>{day}</Typography>
        </div>
      </div>
      <UnansweredDays week={week} hotel={hotel} />
      <IncompleteDays week={week} hotel={hotel} />
    </MetaData>
  );
}
