import { request } from 'actions/utils';

export default function createHotel(params, organizationId, onSuccess) {
  const body = {
    ...params,
  };
  if (organizationId > 0) {
    body.organizationId = organizationId;
  }
  return async (dispatch) => {
    let success = await dispatch(
      request({
        action: 'CREATE_HOTEL',
        path: '/api/admin/hotels',
        method: 'POST',
        body,
      })
    );
    if (success && typeof onSuccess === 'function') {
      onSuccess();
    }
  };
}
