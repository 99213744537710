import { request } from 'actions/utils';

export function getDashboardData(uuid, dispatch) {
  return request({
    action: 'GET_DASHBOARD_DATA',
    path: `/api/dashboards/view/${uuid}`,
    method: 'GET',
  })(dispatch);
}

export function changeDashboardLanguage(languageIndex, dashboard, dispatch) {
  if (!dashboard) {
    return;
  }

  if (!dashboard.rotateLanguages) {
    return;
  }

  if (!dashboard.language || dashboard.language.length < 2) {
    return;
  }

  const availableLanguages = dashboard.language;
  const nextIndex = (languageIndex + 1) % availableLanguages.length;

  dispatch({
    type: 'UPDATE_LANGUAGE',
    payload: nextIndex,
  });
}
