import React from 'react';
import translate from 'utils/translate';
import { getAccumulatedScore } from './utils';
import BarChart from '../SafeQReport/BarChart';

export default function ModuleBarCharts({ areas, week, lastWeek, hotels }) {
  let max = 0;
  const moduleTypeData = areas.map((area) => {
    const currentData = getAccumulatedScore({
      fullfillment: true,
      hotels,
      weeks: [week],
      areas: [area],
    });
    const lastWeekData = getAccumulatedScore({
      fullfillment: true,
      hotels,
      weeks: [lastWeek],
      areas: [area],
    });

    if (currentData > max) max = currentData;
    if (lastWeekData > max) max = lastWeekData;
    return [currentData, lastWeekData];
  });

  max = Math.min(100, Math.ceil((max + 1) / 10) * 10);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {moduleTypeData.map((data, index) => (
        <div key={index} style={{ maxWidth: '12rem' }}>
          <BarChart
            max={max}
            maintainAspectRatio
            data={data}
            labels={['CURRENT', 'PREVIOUS']}
            title={{
              display: true,
              text: translate(`moduleTypeAreas.${areas[index]}`),
            }}
          />
        </div>
      ))}
    </div>
  );
}
