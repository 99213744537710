import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import ClearIcon from '@material-ui/icons/Clear';
import RemoveIcon from '@material-ui/icons/Remove';

const Circle = styled.div`
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 50%;
  background: ${(props) => props.background || 'white'};
  height: 20px;
  width: 20px;

  svg {
    font-size: 20px;
  }
`;

export default function PointCircle({ score, day, style, className }) {
  const theme = useTheme();

  let background = theme.palette.success.main;
  let icon = null;

  switch (score) {
    case -1: {
      background = 'white';
      icon = <RemoveIcon />;
      break;
    }
    case 0: {
      background = theme.palette.success.main;
      break;
    }
    case 1: {
      background = yellow[600];
      break;
    }
    case 2: {
      background = orange[600];
      break;
    }
    case 3: {
      background = red[600];
      break;
    }
    default: {
      background = theme.palette.grey[100];
      icon = <ClearIcon />;
      break;
    }
  }

  return (
    <div style={{ textAlign: 'center' }}>
      {day && (
        <Typography variant='subtitle2'>{moment(day).format('D/M')}</Typography>
      )}
      <Circle
        className={className}
        style={style}
        borderColor={
          score === null || score === -1 ? theme.palette.grey[300] : background
        }
        background={background}
      >
        {icon}
      </Circle>
    </div>
  );
}
