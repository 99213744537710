import { request } from 'actions/utils';

export default function setScore({
  inspectionId,
  moduleId,
  questionId,
  score,
  comment,
}) {
  return async (dispatch) => {
    const action = await request({
      action: 'SET_SCORE',
      path: `/api/inspections/${inspectionId}/modules/${moduleId}/questions/${questionId}/score`,
      method: 'POST',
      body: { score, comment },
      extraPayload: {
        questionId,
        moduleId,
      },
    });
    await dispatch(action);
  };
}
