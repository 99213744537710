import React from 'react';
import translate from 'utils/translate';
import { renderLineChart } from './getChart';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 350px;
  padding: 0 0 2rem 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const Canvas = styled.div`
  position: relative;
  flex: 1;
`;

const OverviewChartTitle = styled.h3`
  text-align: center;
  margin: 10px 0 5px 0;
`;

export default class OverviewChart extends React.Component {
  componentDidUpdate(prevProps) {
    const { data, currentHotel, chartsTimeFrame } = this.props;
    if (prevProps.data !== data && data) {
      const chartData = {
        [currentHotel]: reformatData(data, 'currentHotelAverage'),
        [translate('report.highestScores')]: reformatData(
          data,
          'highestScores'
        ),
        [translate('report.otherHotelsAverage')]: reformatData(
          data,
          'otherHotelsAverage'
        ),
        [translate('report.lowestScores')]: reformatData(data, 'lowestScores'),
      };
      const colors = [
        '#002',
        'hsl(112, 29%, 45%)',
        'hsl(20, 70%, 63%)',
        'hsl(0, 59%, 63%)',
      ];

      renderLineChart({
        canvas: this.canvas,
        data: chartData,
        labelList: chartsTimeFrame,
        colors,
        forceSize: true,
      });
    }
  }
  render() {
    return (
      <Wrapper>
        <OverviewChartTitle>OVERVIEW</OverviewChartTitle>
        <Canvas>
          <canvas ref={(c) => (this.canvas = c)} />
        </Canvas>
      </Wrapper>
    );
  }
}

function reformatData(objectBased, key) {
  const returnVal = [];
  objectBased
    .get(key)
    .entrySeq()
    .forEach(([date, value]) => returnVal.push({ x: date, y: value }));
  return returnVal;
}
