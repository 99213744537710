import React, { useRef } from 'react';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { useScreenSize } from 'hooks/useScreenSize';
import media, { breakpoints } from 'globals/media';
import useStickyPosition from 'hooks/useStickyPosition';

const TabContainer = styled(Paper)`
  width: 100%;
  margin-top: 20px;
  max-height: calc(100vh - 130px);

  &.stick {
    max-width: 200px;
    margin-top: 0;
    position: fixed;
    top: ${({ top }) => (top ? `${top}px` : 'initial')};
  }

  ${media.tablet`
    width: 100%;
    top: initial;
    margin-top: 0;
    bottom: 0;
    left: 0;
    background: white;
    z-index: 1;
    position: fixed;
  `};

  .MuiTabs-indicator {
    display: none;
  }
`;

const Wrapper = styled.div`
  position: relative;
  z-index: 1201;
  width: 200px;
  padding-right: 15px;
`;

export default function InspectionQuestions({ children }) {
  const container = useRef(null);
  const screenSize = useScreenSize();

  const { y, isStuck } = useStickyPosition(container.current, {
    spacingTop: 84,
    offsetTop: 0,
  });

  return (
    <Wrapper>
      <TabContainer
        className={
          isStuck && screenSize.width > breakpoints.tablet ? 'stick' : ''
        }
        innerRef={container}
        top={y}
        elevation={screenSize.width < breakpoints.tablet ? 8 : 2}
      >
        {children}
      </TabContainer>
    </Wrapper>
  );
}
