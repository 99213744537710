import styled from 'styled-components';
import colors from 'globals/colors';

export default styled.label.attrs({
  role: 'button'
})`
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 10px 15px;
  border-radius: 3px;
  font-size: 14px;
  width: auto;
  color: ${colors.textGray};
  display: inline-block;
  outline: none;
  cursor: pointer;
  background-color: ${colors.white};
`;
