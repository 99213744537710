import load from './load';
import list from './list';
import listHotel from './listHotel';
import remove from './remove';
import createFollowUp from './createFollowUp';

export default {
  load,
  list,
  listHotel,
  remove,
  createFollowUp,
  openDeleteModal,
  closeDeleteModal,
};

export function openDeleteModal(inspection) {
  return {
    type: 'OPEN_DELETE_INSPECTION_MODAL',
    payload: inspection,
  };
}

export function closeDeleteModal() {
  return {
    type: 'CLOSE_DELETE_INSPECTION_MODAL',
  };
}
