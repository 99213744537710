import styled from 'styled-components';

export default styled.div`
  margin: 20px auto 0 auto;
  display: grid;
  grid-template-columns: 1fr 70px 70px;
  min-width: 280px;
  max-width: 60rem;
  width: 70%;
  white-space: nowrap;
  grid-gap: 20px;
`;
