import React from 'react';
import translate from 'utils/translate';
import { classifications } from 'globals/classifications';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import SimpleSelect from './SimpleSelect';
import styled from 'styled-components';

const Form = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-flow: dense;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
`;

export default function QuestionForm({
  question,
  onChange,
  disabled,
  questionTypes,
  questionClusters,
}) {
  const handleChange = (name, value) => {
    onChange({
      ...question,
      [name]: value,
    });
  };

  return (
    <Form>
      <TextField
        disabled={disabled}
        margin='dense'
        multiline
        rows={4}
        label={translate('questions.form.content')}
        value={question.content || ''}
        variant='outlined'
        onChange={(event) => handleChange('content', event.target.value)}
        helperText={
          question.defaultContent ? `SE: ${question.defaultContent}` : undefined
        }
      />
      <TextField
        disabled={disabled}
        margin='dense'
        label={translate('questions.form.referenceNumber')}
        value={question.referenceNumber || ''}
        variant='outlined'
        onChange={(event) =>
          handleChange('referenceNumber', event.target.value)
        }
      />
      <TextField
        disabled={disabled}
        margin='dense'
        label={translate('questions.form.ordinal')}
        value={question.ordinal || ''}
        variant='outlined'
        onChange={(event) => handleChange('ordinal', event.target.value)}
      />
      <FormControl>
        <InputLabel style={{ whiteSpace: 'nowrap' }}>
          {translate('questions.create.requiredCommentMinScore')}
        </InputLabel>
        <Select
          value={
            question.requiredCommentMinScore === null
              ? -1
              : question.requiredCommentMinScore
          }
          onChange={(event) =>
            handleChange(
              'requiredCommentMinScore',
              event.target.value === -1 ? null : event.target.value
            )
          }
        >
          <MenuItem value={-1}>{translate('none')}</MenuItem>
          <MenuItem value={3}>
            3 ({translate('questions.radioOptions.never')})
          </MenuItem>
          <MenuItem value={2}>
            2 ({translate('questions.radioOptions.rarely')})
          </MenuItem>
          <MenuItem value={1}>
            1 ({translate('questions.radioOptions.often')})
          </MenuItem>
          <MenuItem value={0}>
            0 ({translate('questions.radioOptions.always')})
          </MenuItem>
        </Select>
      </FormControl>
      <FormControlLabel
        label={translate('questions.create.isMandatory')}
        control={
          <Checkbox
            name='isMandatory'
            checked={!!question.isMandatory}
            onChange={() => handleChange('isMandatory', !question.isMandatory)}
          />
        }
      />
      <FormControlLabel
        label={translate('questions.create.notify')}
        control={
          <Checkbox
            name='notifyHotelResponsible'
            checked={!!question.notifyHotelResponsible}
            onChange={() =>
              handleChange(
                'notifyHotelResponsible',
                !question.notifyHotelResponsible
              )
            }
          />
        }
      />
      <SimpleSelect
        disabled={disabled}
        label={translate('questions.form.type')}
        value={question.type}
        onChange={(event) => handleChange('type', event.target.value)}
        items={questionTypes.map((type) => ({
          value: type,
          label: translate(`questions.types.${type}`),
        }))}
      />
      <SimpleSelect
        disabled={disabled}
        label={translate('questions.form.classification')}
        value={question.classification}
        onChange={(event) => handleChange('classification', event.target.value)}
        items={classifications.map((classification) => ({
          value: classification,
          label: translate(`classifications.${classification}`),
        }))}
      />
      <SimpleSelect
        disabled={disabled}
        label={translate('questions.form.cluster')}
        value={question.questionClusterId}
        onChange={(event) =>
          handleChange('questionClusterId', parseInt(event.target.value, 10))
        }
        items={questionClusters.map((cluster) => ({
          value: cluster.id,
          label: cluster.name,
        }))}
      />
    </Form>
  );
}
