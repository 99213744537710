import styled from 'styled-components';
import colors from 'globals/colors';

export default styled.form`
  min-height: 200px;
  min-width: 280px;
  z-index: 2;
  background-color: ${colors.modalBackground};
  display: flex;
  padding: 20px;
  border-radius: 9px;
  max-height: 90%;
  overflow: scroll;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  position: relative;
  :after {
    margin-bottom: 6rem;
    content: ' ';
  }
  > * {
    flex-shrink: 0;
  }
`;
