import { dashboardLanguages } from 'globals/languages';

export const INITIAL_STATE = {
  dashboard: {
    uuid: null,
    backgroundColor: null,
    textColor: null,
    language: '',
  },
  newId: null,
  loading: false,
  error: '',
  fieldErrors: null,
};

export default function reducer(state, action = { type: '' }) {
  switch (action.type) {
    case 'CREATE_DASHBOARD_STARTED': {
      return { ...state, loading: true, error: '', fieldErrors: null };
    }
    case 'CREATE_DASHBOARD_FAILED': {
      return {
        ...state,
        loading: false,
        error: action.payload,
        fieldErrors: action.error,
      };
    }
    case 'CREATE_DASHBOARD_DONE': {
      return { ...state, newId: action.payload.id, loading: false };
    }
    case 'GET_DASHBOARD_STARTED': {
      return { ...state, loading: true, error: '', fieldErrors: null };
    }
    case 'GET_DASHBOARD_FAILED': {
      return {
        ...state,
        loading: false,
        error: action.payload,
        fieldErrors: action.error,
      };
    }
    case 'GET_DASHBOARD_DONE': {
      return {
        ...state,
        dashboard: {
          ...action.payload,
          showOrganizationData: !!action.payload.showOrganizationData,
          rotateLanguages: !!action.payload.rotateLanguages,
          language: action.payload.language
            ? action.payload.language
            : dashboardLanguages[0].code,
        },
        loading: false,
      };
    }
    case 'SAVE_DASHBOARD_STARTED': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'SAVE_DASHBOARD_DONE': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'REGENERATE_DASHBOARD_UUID_STARTED': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'REGENERATE_DASHBOARD_UUID_DONE': {
      return {
        ...state,
        loading: false,
        dashboard: {
          ...state.dashboard,
          uuid: action.payload.uuid,
        },
      };
    }
    case 'DASHBOARD_CHANGE': {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          [action.payload.field]: action.payload.value,
        },
      };
    }
    default:
      return state;
  }
}
