import React from 'react';
import Score from './Score';
import Radio from './Radio';
import GradeA from './GradeA';
import GradeBtoE from './GradeBtoE';
import GradeF from './GradeF';
import GradeG from './GradeG';
import GradeH from './GradeH';
import GradeK from './GradeK';

export function ScoreContainer({
  setScore,
  score,
  requestParams,
  requiredCommentMinScore,
  onRequiredComment,
  isMandatory,
  type,
  content,
}) {
  const scoreProps = {
    setScore,
    content,
    type,
    score,
    isMandatory,
    requiredCommentMinScore,
    onRequiredComment,
    requestParams,
  };

  if (type === 'radio') {
    return <Radio {...scoreProps} />;
  }
  if (type === 'gradeA') {
    return <GradeA {...scoreProps} />;
  }
  const marginalTypes = ['gradeB', 'gradeC', 'gradeD', 'gradeE'];
  if (marginalTypes.includes(type)) {
    return <GradeBtoE {...scoreProps} />;
  }
  if (type === 'gradeF') {
    return <GradeF {...scoreProps} />;
  }
  if (type === 'gradeG') {
    return <GradeG {...scoreProps} />;
  }
  if (type === 'gradeH') {
    return <GradeH {...scoreProps} />;
  }
  if (type === 'gradeJ') {
    return null;
  }
  if (type === 'gradeK') {
    return <GradeK {...scoreProps} />;
  }

  return <Score {...scoreProps} />;
}
