import {fromJS} from 'immutable';

const defaultState = fromJS({
  visible: false,
  loading: false,
  inspection: {
    name: '',
    id: 0
  },
  error: ''
});

export default function(state = defaultState, action = {type: ''}) {
  switch (action.type) {
    case 'DELETE_INSPECTION_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'DELETE_INSPECTION_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'DELETE_INSPECTION_DONE': {
      return state.set('loading', false).set('visible', false);
    }
    case 'OPEN_DELETE_INSPECTION_MODAL': {
      return state.set('visible', true).set('inspection', fromJS(action.payload));
    }
    case 'CLOSE_DELETE_INSPECTION_MODAL': {
      return state.set('visible', false);
    }
    default: {
      return state;
    }
  }
}
