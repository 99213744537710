import React, { useEffect } from 'react';
import translate from 'utils/translate';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import LoadingOverlay from 'components/general/LoadingOverlay';
import moment from 'moment';
import InspectionList from 'components/admin/auditQA/components/InspectionList';
import StatusIcon from 'components/admin/auditQA/components/StatusIcon';

const Wrapper = styled.div`
  position: relative;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default function AuditQA({ loading, list = [], load, onRowClick }) {
  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  list = list.map((item) =>
    item
      .set('inspectionType', item.getIn(['inspectionType', 'name']))
      .set('user', item.getIn(['user', 'name']))
      .set('hotel', item.getIn(['inspectionType', 'hotel', 'name']))
  );

  const columns = {
    status: {
      name: '',
      field: 'status',
      onRender: (status) => {
        return <StatusIcon status={status} />;
      },
    },
    hotel: {
      field: 'hotel',
    },
    user: {
      field: 'user',
    },
    startTime: {
      field: 'startTime',
      onRender: (value) => moment(value).format('YYYY-MM-DD'),
    },
    lastEditedBy: {
      field: 'lastEditedByUser',
      onRender: (value) => (value ? value.get('name') : '-'),
    },
    agentEndTimeSetBy: {
      field: 'agentEndTimeSetByUser',
      onRender: (value) => (value ? value.get('name') : '-'),
    },
    adminEndTimeSetBy: {
      field: 'adminEndTimeSetByUser',
      onRender: (value) => (value ? value.get('name') : '-'),
    },
    agentEndTime: {
      field: 'agentEndTime',
      onRender: (value) => moment(value).format('YYYY-MM-DD'),
    },
    adminEndTime: {
      field: 'adminEndTime',
      onRender: (value) => moment(value).format('YYYY-MM-DD'),
    },
    inspectionType: {
      field: 'inspectionType',
    },
  };

  return (
    <Wrapper>
      <LoadingOverlay show={loading} />
      <Header>
        <Typography variant='h5' component='h1' gutterBottom>
          {translate('actionPlan.list')}
        </Typography>
      </Header>
      <InspectionList
        items={list}
        onRowClick={onRowClick}
        columns={[
          columns.startTime,
          columns.hotel,
          columns.lastEditedBy,
          columns.adminEndTime,
          columns.adminEndTimeSetBy,
          columns.inspectionType,
        ]}
      />
    </Wrapper>
  );
}
