import React, { useState } from 'react';
import translate from 'utils/translate';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styled from 'styled-components';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const StyledRow = styled(TableRow)`
  cursor: pointer;
`;

export default function InspectionList({
  items = [],
  onRowClick,
  columns,
  initialSort,
}) {
  const [orderedBy, setOrderedBy] = useState(initialSort || columns[0]);
  const [sortOrder, setSortOrder] = useState('desc');

  items = items.sort((itemA, itemB) => {
    let valueA = itemA.get(orderedBy.field) || '';
    let valueB = itemB.get(orderedBy.field) || '';

    valueA = orderedBy.getValue ? orderedBy.getValue(valueA) : valueA;
    valueB = orderedBy.getValue ? orderedBy.getValue(valueB) : valueB;

    if (valueA < valueB) {
      return sortOrder === 'asc' ? -1 : 1;
    }

    if (valueA > valueB) {
      return sortOrder === 'asc' ? 1 : -1;
    }

    return 0;
  });

  return (
    <Table>
      <TableHead>
        <TableRow>
          {columns.map((column) => {
            const isSorted = orderedBy.field === column.field;
            return (
              <TableCell sortDirection={isSorted ? sortOrder : false}>
                <TableSortLabel
                  active={isSorted}
                  direction={sortOrder}
                  onClick={() => {
                    if (isSorted) {
                      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                    }
                    setOrderedBy(column);
                  }}
                >
                  {column.name !== undefined
                    ? column.name
                    : translate(`auditQA.columns.${column.field}`)}
                </TableSortLabel>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((inspection) => (
          <StyledRow
            key={inspection.get('id')}
            hover
            onClick={() => onRowClick(inspection)}
          >
            {columns.map((column) => (
              <TableCell>
                {column.onRender
                  ? column.onRender(
                      column.getValue
                        ? column.getValue(inspection.get(column.field))
                        : inspection.get(column.field),
                      inspection
                    )
                  : inspection.get(column.field)}
              </TableCell>
            ))}
          </StyledRow>
        ))}
      </TableBody>
    </Table>
  );
}
