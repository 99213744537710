import { fromJS } from 'immutable';
import hotels from './hotels';
import inspectionModules from './inspectionModules';
import inspectionTypes from './inspectionTypes';
import inspections from './inspections';
import questions from './questions';
import reports from './reports';
import users from './users';
import organizations from './organizations';
import auditQA from './auditQA';

const defaultState = fromJS({
  hotels: hotels(),
  inspectionModules: inspectionModules(),
  inspectionTypes: inspectionTypes(),
  inspections: inspections(),
  questions: questions(),
  reports: reports(),
  users: users(),
  organizations: organizations(),
  auditQA: auditQA(),
});

export default function (state = defaultState, action = { type: '' }) {
  state = state.set('hotels', hotels(state.get('hotels'), action));
  state = state.set(
    'inspectionModules',
    inspectionModules(state.get('inspectionModules'), action)
  );
  state = state.set(
    'inspectionTypes',
    inspectionTypes(state.get('inspectionTypes'), action)
  );
  state = state.set(
    'inspections',
    inspections(state.get('inspections'), action)
  );
  state = state.set('questions', questions(state.get('questions'), action));
  state = state.set('reports', reports(state.get('reports'), action));
  state = state.set('users', users(state.get('users'), action));
  state = state.set(
    'organizations',
    organizations(state.get('organizations'), action)
  );
  state = state.set('auditQA', auditQA(state.get('auditQA'), action));
  return state;
}
