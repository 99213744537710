import { request } from 'actions/utils';

const uploadXlsx = async (event, dispatch) => {
  const body = new FormData();
  body.append('file', event.target.files[0])

  return request({
    action: 'UPLOAD_XLSX',
    path: '/api/admin/questions/upload/xlsx',
    method: 'POST',
    body,
    extraPayload: event.target.files[0].name
  })(dispatch);
};

export default uploadXlsx;