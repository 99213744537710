import { connect } from 'react-redux';
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Fade from '@material-ui/core/Fade';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import translate from 'utils/translate';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { shortenFileName } from 'utils/file';
import { INTERNAL_UPLOAD_PREFIX } from 'reducers/fileUpload';

const ContentWrapper = styled.div`
  position: relative;
  width: 283px;
  min-height: 36px;
  overflow: hidden;
`;

function FileUploadProgress({
  progress,
  failed,
  success,
  error,
  show,
  cancel,
  onCancel,
  name,
}) {
  const theme = useTheme();
  const progressPercent = Math.round(progress * 100);

  return (
    <Dialog
      open={show}
      aria-labelledby='file-upload-dialog-title'
      maxWidth='sm'
    >
      <DialogTitle id='file-upload-dialog-title'>
        {translate('inspectionQuestions.uploadFile')}
      </DialogTitle>
      <DialogContent>
        <ContentWrapper>
          <Fade in={!success && !failed}>
            <Box position='absolute'>
              <Typography variant='body2'>
                {translate('inspectionQuestions.uploadingFile', {
                  replacements: {
                    file: shortenFileName(name, 26),
                  },
                })}
              </Typography>
              <Box display='flex' alignItems='center'>
                <Box width='100%' marginRight={1} minWidth={240}>
                  <LinearProgress
                    variant='determinate'
                    value={progressPercent}
                  />
                </Box>
                <Box minWidth={35}>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                  >{`${progressPercent}%`}</Typography>
                </Box>
              </Box>
            </Box>
          </Fade>
          <Fade in={success}>
            <Box
              position='absolute'
              display='flex'
              alignItems='center'
              flexDirection='colum'
              minWidth={283}
              justifyContent='center'
            >
              <DoneIcon
                style={{ color: theme.palette.success.main }}
                fontSize='large'
              />
              <Box ml={1}>
                <Typography>
                  {translate('inspectionQuestions.uploadedFile')}
                </Typography>
              </Box>
            </Box>
          </Fade>
          <Fade in={failed}>
            <Box
              position='absolute'
              display='flex'
              alignItems='center'
              flex-direction='colum'
              minWidth={283}
              justifyContent='center'
            >
              <ErrorIcon color='error' fontSize='large' />
              <Box ml={1}>
                <Typography>{translate(error)}</Typography>
              </Box>
            </Box>
          </Fade>
        </ContentWrapper>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={success}
          color='primary'
          onClick={() => {
            cancel();
            onCancel();
          }}
        >
          {translate('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    name: state.getIn(['fileUpload', 'name']),
    show: state.getIn(['fileUpload', 'show']),
    progress: state.getIn(['fileUpload', 'progress']),
    uploading: state.getIn(['fileUpload', 'uploading']),
    failed: state.getIn(['fileUpload', 'failed']),
    success: state.getIn(['fileUpload', 'success']),
    error: state.getIn(['fileUpload', 'error']),
    cancel: () => {
      const source = state.getIn(['fileUpload', 'source']);
      if (source) {
        source.cancel('canceled');
      }
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onCancel: () => {
      dispatch({
        type: `${INTERNAL_UPLOAD_PREFIX}_CLOSE_MODAL`,
        payload: '',
        meta: '',
        error: '',
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadProgress);
