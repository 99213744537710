import React from 'react';
import translate from 'utils/translate';
import Input from 'components/general/Input';
import TextArea from 'components/general/TextArea';
import {Label, SubFormWrapper} from './styled';

export default function ExtraQuestion({content = '', ordinal = 0, index, changeExtraQuestionInput}) {
  return (
    <SubFormWrapper>
      <div>
        <Label>{translate('extraQuestions.content')}</Label>
        <TextArea
          onChange={e => changeExtraQuestionInput({value: e.target.value, field: 'content', index})}
          value={content}
        />
      </div>
      <div>
        <Label>{translate('ordinal')}</Label>
        <Input
          type='number'
          onChange={e => changeExtraQuestionInput({value: e.target.value, field: 'ordinal', index})}
          value={ordinal}
        />
      </div>
    </SubFormWrapper>
  );
}
