import { connect } from 'react-redux';
import InspectionAdmin from './InspectionAdmin';
import inspectionActions from 'actions/admin/inspection';

function mapStateToProps(state, ownProps) {
  return {
    error: state.getIn(['admin', 'inspections', 'error']),
    loading: state.getIn(['admin', 'inspections', 'loading']),
    inspections: state.getIn(['admin', 'inspections', 'list']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    load: (all) => dispatch(inspectionActions.list(all)),
    openDeleteModal: (inspection) =>
      dispatch(inspectionActions.openDeleteModal(inspection)),
    openCreateModal: () => dispatch(inspectionActions.openCreateModal()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionAdmin);
