import Button from 'components/general/Button';
import TextArea from 'components/general/TextArea';
import colors from 'globals/colors';
import styled from 'styled-components';
import media from 'globals/media';

export const ClusterWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 30px;
  margin: 20px auto;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: -2px 3px 9px rgba(0, 0, 0, 0.2);
  overflow-x: hidden;
  ${media.handheld`
    padding: 15px;
  `}
`;

export const BackButton = Button.extend`
  margin-right: 15px;
`;

export const ModuleCommentBtnWrapper = styled.div`
  position: fixed;
  left: 0;
  height: 65px;
  width: 100px;
  top: 40%;
  background-color: ${colors.modalBackground};
`;
export const ClusterCommentWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const Input = TextArea.extend`
  box-sizing: border-box;
  margin-right: 20px;
  flex: 1;
`;
