import { request } from 'actions/utils';

export default function setFollowUp({
  inspectionId,
  moduleId,
  questionId,
  needsFollowUp,
  followUpComment,
  followUpDeadline,
  followUpTip,
  followUpFeedback,
}) {
  return async (dispatch) => {
    const action = await request({
      action: 'SET_REPORT_FOLLOW_UP',
      path: `/api/inspections/${inspectionId}/modules/${moduleId}/questions/${questionId}/setFollowUp`,
      method: 'POST',
      body: {
        needsFollowUp,
        followUpComment,
        followUpDeadline,
        followUpTip,
        followUpFeedback,
      },
      extraPayload: {
        questionId,
        moduleId,
      },
    });
    await dispatch(action);
  };
}
