import React from 'react';
import styled from 'styled-components';
import colors from 'globals/colors';
import media from 'globals/media';
import translate from 'utils/translate';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import isImagePath from 'utils/isImagePath';
import { moduleMatches, MODULE_NAME_PREFIXES } from '../utils';
import { getClusterTitle, findClusters } from './utils';
import FileLink from 'components/general/FileLink';
import { SmallFileRow } from 'components/inspection/Questions/QuestionRow/styled';
import Divider from '@material-ui/core/Divider';
import ZoomableImage from 'components/general/ZoomableImage';
import Answer from '../Answer';
import Comment from '../Comment';
import { fromJS } from 'immutable';

const StyledTableRow = styled(TableRow)`
  ${({ showInPrint }) =>
    showInPrint ? '' : '@media print { display: none !important; }'}
`;

const ImageGrid = styled.div`
  display: grid;
  grid-column-gap: 7px;
  grid-row-gap: 7px;
  grid-template-columns: repeat(auto-fit, 300px);
`;

const ModuleWrapper = styled.div`
  ${media.handheld`
    h2 {
      margin-top: 2rem;
      font-size: 1.5rem;
    }
  `}

  margin-bottom: 30px;
  margin-top: 20px;

  h2 {
    text-align: left;
  }
`;

const ImageLabel = styled.p`
  font-weight: 600;
  font-style: italic;
  margin: 0;
`;

const SubQuestionContent = styled.div`
  padding-left: ${({ indent }) =>
    indent === 1 ? '20px' : indent === 2 ? '40px' : 0} !important;
  font-weight: ${({ indent }) => (indent === 0 ? '500' : '400')};
`;

const ReferenceNumber = styled.div`
  color: ${({ theme, indent }) => theme.palette.secondary.main};
`;

const QuestionsWrapper = styled.div`
  font-size: 0.875rem;

  .referenceNumber {
    color: ${colors.qultivatorBlueDark};
    &:not(first-child) {
      margin-top: 10px;
    }
  }
  .content {
    font-weight: 400;
    padding: 5px 0;
  }
  .followUpComment {
    color: ${colors.errorRed};
    margin: 0 0 5px;
    padding: 5px 0 5px;
    border-bottom: 1px solid ${colors.errorRed};
    white-space: pre-line;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 20px;
    }
    > li {
      .content {
        font-weight: 500;
      }
    }
    ul {
      padding: 0 30px;
      list-style: none;
      .referenceNumber {
        display: none;
      }
      .content {
        font-weight: 500;
      }
    }
  }
`;

export default function PrintedReport({
  summary,
  modules,
  comfortZones,
  originalModules,
}) {
  return (
    <>
      {summary && (
        <ModuleWrapper>
          {summary.content || null}
          {summary.attachments && summary.attachments.length && (
            <Divider style={{ margin: '0.5rem 0 0.5rem 0' }} />
          )}
          {summary.attachments &&
            summary.attachments
              .filter((file) => file.isFollowUp)
              .map((file) => (
                <SmallFileRow>
                  {isImagePath(file.path) ? (
                    <img
                      style={{ maxWidth: '100%' }}
                      src={`/files/${file.path}`}
                      alt=''
                    />
                  ) : (
                    <FileLink
                      color='primary'
                      variant='text'
                      path={file.path}
                      name={file.originalName || file.path}
                    />
                  )}
                </SmallFileRow>
              ))}
        </ModuleWrapper>
      )}
      {modules.map((followUpModule) => {
        if (!followUpModule.followUp && followUpModule.clusters.length === 0) {
          return null;
        }
        return (
          <>
            <ModuleWrapper>
              <h2>{followUpModule.name}</h2>
              {followUpModule.followUp}
              {followUpModule.clusters.map((cluster, i) => (
                <div key={i} style={{ pageBreakInside: 'avoid' }}>
                  <h3>
                    {getClusterTitle(
                      cluster,
                      findClusters(originalModules, followUpModule.id),
                      followUpModule
                    )}
                  </h3>
                  <QuestionsWrapper>
                    <ActionPlanQuestions questions={cluster.questions} />
                  </QuestionsWrapper>
                </div>
              ))}
            </ModuleWrapper>
            {moduleMatches(
              followUpModule.name,
              MODULE_NAME_PREFIXES.KEY_INFORMATION
            ) &&
              comfortZones.map((zone) =>
                zone.value ? (
                  <ModuleWrapper>
                    <h2>{translate(`classifications.${zone.name}`)}</h2>
                    {zone.value}
                  </ModuleWrapper>
                ) : null
              )}
          </>
        );
      })}
    </>
  );
}

function ActionPlanQuestions({ questions }) {
  if (!questions || questions.length === 0) {
    return null;
  }

  const rows = calculateRows(questions, 0);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '40%' }}>
                {translate('question')}
              </TableCell>
              <TableCell style={{ width: '30%' }}>
                {translate('comment')}
              </TableCell>
              <TableCell style={{ width: '30%' }}>
                {translate('questions.followUp.followUpDeadline')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    </>
  );

  function calculateRows(questions, indent) {
    if (!questions || questions.size === 0) {
      return [];
    }
    let result = [];

    questions.forEach((q) => {
      result.push(formatQuestion(q));
      result = result.concat(calculateRows(q.children, indent + 1));
    });

    return result;

    function formatQuestion(question) {
      return (
        <>
          <TableRow key={question.id}>
            <TableCell>
              <Typography variant='body2' component='span'>
                {question.referenceNumber && indent === 0 && (
                  <ReferenceNumber>{question.referenceNumber}</ReferenceNumber>
                )}
                <SubQuestionContent indent={indent}>
                  {question.content || question.defaultContent}
                </SubQuestionContent>
              </Typography>
            </TableCell>
            <TableCell style={{ width: '120px' }}>
              {question.needsFollowUp && (
                <Typography variant='body2'>
                  {question.followUpComment || '-'}
                </Typography>
              )}
            </TableCell>
            <TableCell style={{ width: '120px' }}>
              {question.needsFollowUp && (
                <Typography variant='body2'>
                  {question.followUpTip
                    ? translate('auditQA.tip')
                    : question.followUpFeedback
                    ? translate('auditQA.feedback')
                    : question.followUpDeadline
                    ? moment(question.followUpDeadline).format('YYYY-MM-DD')
                    : '-'}
                </Typography>
              )}
            </TableCell>
          </TableRow>
          {question.answer && (
            <StyledTableRow showInPrint>
              <TableCell colSpan={3}>
                <SubQuestionContent indent={indent}>
                  <ImageLabel>{translate('auditQA.fromInspection')}</ImageLabel>
                  <div>
                    {translate('answer')}:{' '}
                    <Answer
                      type={question.type}
                      score={question.score}
                      comment={question.comment}
                      attachments={
                        question.answer && fromJS(question.answer.attachments)
                      }
                      needsFollowUp={false}
                    />
                  </div>
                  {question.comment && (
                    <div>
                      {translate('comment')}:{' '}
                      <Comment
                        type={question.type}
                        comment={question.comment}
                      />
                    </div>
                  )}
                  {question.answer.attachments &&
                    question.answer.attachments.length !== 0 && (
                      <ImageGrid>
                        {question.answer.attachments
                          .filter((file) => !file.isFollowUp)
                          .map((file) =>
                            isImagePath(file.path) ? (
                              <ZoomableImage
                                width='300px'
                                height={'auto'}
                                src={`/files/${file.path}`}
                                alt=''
                              />
                            ) : null
                          )}
                      </ImageGrid>
                    )}
                </SubQuestionContent>
              </TableCell>
            </StyledTableRow>
          )}
          {question.answer &&
            question.answer.attachments &&
            question.answer.attachments.filter((file) => file.isFollowUp)
              .length !== 0 && (
              <StyledTableRow showInPrint>
                <TableCell colSpan={3}>
                  <SubQuestionContent indent={indent}>
                    <ImageLabel>{translate('auditQA.feedback')}</ImageLabel>
                    <ImageGrid>
                      {question.answer.attachments
                        .filter((file) => file.isFollowUp)
                        .map((file) =>
                          isImagePath(file.path) ? (
                            <ZoomableImage
                              width='300px'
                              height={'auto'}
                              src={`/files/${file.path}`}
                              alt=''
                            />
                          ) : null
                        )}
                    </ImageGrid>
                  </SubQuestionContent>
                </TableCell>
              </StyledTableRow>
            )}
        </>
      );
    }
  }
}
