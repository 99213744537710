import load from './load';
import { request } from 'actions/utils';

export default function ({ id, ordinal, commentPosition, moduleTypeId }) {
  return async (dispatch) => {
    let success = await dispatch(
      request({
        action: 'UPDATE_QUESTION_CLUSTER',
        path: `/api/admin/questions/clusters/${id}`,
        body: { ordinal, commentPosition },
        method: 'PUT',
      })
    );
    if (success) {
      dispatch(load(moduleTypeId));
    }
  };
}
