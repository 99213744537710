import React from 'react';
import translate from 'utils/translate';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import { Wrapper } from './styled';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import UndoIcon from '@material-ui/icons/Undo';
import OpenInNew from '@material-ui/icons/OpenInNew';
import urls from 'globals/urls';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Inner = styled.div`
  padding: 5px 10px;
`;

export default class InspectionList extends React.Component {
  componentDidMount() {
    const { load, hotelId } = this.props;
    load(hotelId);
  }

  render() {
    const {
      inspections,
      loading,
      error,
      openReport,
      openControlView,
      revertReportToControl,
    } = this.props;
    const domReports = inspections
      .filter((i) => i.get('adminEndTime'))
      .filter((i) => !i.getIn(['inspectionType', 'allowUnansweredQuestions'])); // SafeQ inspections

    const pendingApproval = inspections.filter(
      (i) => !i.get('adminEndTime') && i.get('agentEndTime')
    );
    const currentInspections = inspections.filter(
      (i) => !i.get('adminEndTime') && !i.get('agentEndTime')
    );
    return (
      <Wrapper>
        {loading && <Loader />}
        {error && <Error>{translate(error)}</Error>}
        {pendingApproval.size > 0 && (
          <>
            <Paper elevation={2}>
              <Inner>
                <Typography variant='h5' component='h2' gutterBottom>
                  {translate('inspections.pendingApproval')}
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{translate('performedBy')}</TableCell>
                      <TableCell>{translate('report.list.endTime')}</TableCell>
                      <TableCell>{translate('inspectionType')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pendingApproval.map((inspection) => (
                      <TableRow key={inspection.get('id')}>
                        <TableCell>
                          {inspection.getIn(['user', 'name']) || '-'}
                        </TableCell>
                        <TableCell>
                          {moment(inspection.get('agentEndTime')).format(
                            'YYYY-MM-DD HH:mm'
                          )}
                        </TableCell>
                        <TableCell>
                          {inspection.getIn(['inspectionType', 'name'])}
                        </TableCell>
                        <TableCell style={{ width: '80px', padding: 0 }}>
                          <Button
                            color='primary'
                            startIcon={<AssignmentTurnedInIcon />}
                            variant='outlined'
                            onClick={() =>
                              openControlView(inspection.get('id'))
                            }
                          >
                            {translate('inspections.control')}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Inner>
            </Paper>
            <div className='spacer'></div>
          </>
        )}
        <Paper elevation={2}>
          <Inner>
            <Typography variant='h5' component='h2' gutterBottom>
              {translate('inspections.current')}
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{translate('performedBy')}</TableCell>
                  <TableCell>{translate('inspectionType')}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentInspections.map((inspection) => (
                  <TableRow key={inspection.get('id')}>
                    <TableCell>
                      {inspection.getIn(['user', 'name']) || '-'}
                    </TableCell>
                    <TableCell>
                      {inspection.getIn(['inspectionType', 'name'])}
                    </TableCell>
                    <TableCell style={{ width: '123px', padding: 0 }} />
                    <TableCell style={{ width: '40px', padding: 0 }}>
                      <Link
                        to={urls.inspectionStart.replace(
                          ':id',
                          inspection.get('id')
                        )}
                        target='_blank'
                        rel='noreferrer noopener'
                      >
                        <IconButton size='small'>
                          <OpenInNew />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Inner>
        </Paper>
        <div className='spacer'></div>
        {domReports.size > 0 && (
          <Paper elevation={2}>
            <Inner>
              <Typography variant='h5' component='h2' gutterBottom>
                {translate('report.list')}
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{translate('report.list.agent')}</TableCell>
                    <TableCell>{translate('report.list.startTime')}</TableCell>
                    <TableCell>{translate('report.list.endTime')}</TableCell>
                    <TableCell>
                      {translate('report.list.inspectionType')}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {domReports.map((report) => (
                    <TableRow
                      key={report.get('id')}
                      hover
                      onClick={() =>
                        openReport(
                          report.get('id'),
                          report.getIn(['inspectionType', 'reportType'])
                        )
                      }
                    >
                      <TableCell>{report.getIn(['user', 'name'])}</TableCell>
                      <TableCell>
                        {moment(report.get('startTime')).format(
                          'YYYY-MM-DD kk:mm'
                        )}
                      </TableCell>
                      <TableCell>
                        {moment(report.get('adminEndTime')).format(
                          'YYYY-MM-DD'
                        )}
                      </TableCell>
                      <TableCell>
                        {report.getIn(['inspectionType', 'name'])}
                      </TableCell>
                      <TableCell style={{ width: '40px', padding: 0 }}>
                        <Tooltip title={translate('inspections.undoToControl')}>
                          <IconButton
                            size='small'
                            onClick={(event) => {
                              event.stopPropagation();
                              revertReportToControl(report.get('id'), true);
                            }}
                          >
                            <UndoIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Inner>
          </Paper>
        )}
      </Wrapper>
    );
  }
}
