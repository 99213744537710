import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js';
import colors from 'globals/colors';

export default function BarChart({
  labels,
  data,
  backgroundColor,
  limitMax,
  max,
  title,
  maintainAspectRatio = false,
  aspectRatio = 1,
}) {
  const canvas = useRef(null);

  if (!max)
    max = limitMax
      ? Math.ceil(
          (1 +
            data.reduce(
              (prevMax, curr) => (prevMax > curr ? prevMax : curr),
              0
            )) /
            10
        ) * 10
      : 100;

  useEffect(() => {
    if (!canvas.current) {
      return;
    }
    const ctx = canvas.current.getContext('2d');
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            data,
            backgroundColor: backgroundColor || ['#808080', '#D3D3D3'],
            borderWidth: 0,
          },
        ],
      },
      options: {
        maintainAspectRatio,
        aspectRatio,
        title,
        legend: {
          display: true,
          labels: {
            boxWidth: 20,
            generateLabels: function (chart) {
              return data.map((e, i) => ({
                fillStyle: isNaN(e)
                  ? ''
                  : `${colors[`totalScore${labels[i].toLowerCase()}`]}`,
                text: isNaN(e) ? '' : `${e}%`,
              }));
            },
          },
        },
        scales: {
          xAxes: [
            {
              type: 'category',
              labels,
              categoryPercentage: 0.8,
              barPercentage: 1.0,
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max,
              },
            },
          ],
        },
      },
    });
  }, [
    canvas,
    data,
    backgroundColor,
    labels,
    maintainAspectRatio,
    max,
    aspectRatio,
    title,
  ]);

  return <canvas ref={canvas} />;
}
