import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import styled from 'styled-components';
import { useScreenSize } from 'hooks/useScreenSize';
import { breakpoints } from 'globals/media';
import translate from 'utils/translate';
Chart.plugins.unregister(ChartDataLabels);

const padding = {
  left: 110,
  right: 110,
  top: 30,
  bottom: 65,
};

const smallPadding = {
  left: 90,
  right: 90,
  top: 20,
  bottom: 55,
};

const Wrapper = styled.div`
  position: relative;
`;

const MiddleContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - (55px - 20px) / 2));
  width: 80px;
  font-size: 13px;
  text-align: center;
  word-break: break-word;

  ${({ theme }) => theme.breakpoints.between('md', 'md')} {
    font-size: 10px;
    width: 56px;
  }
`;

const backgroundColors = [
  '#91BD58',
  '#469E69',
  '#357974',
  '#255652',
  '#22536B',
  '#2e7192',
  '#458c92',
  '#356b6f',
  '#2c5a5e',
];
const backgroundColorsSecondary = [
  '#9EE8E8',
  '#76BBD0',
  '#588FB4',
  '#456592',
  '#3480a5',
  '#2e7192',
  '#28627e',
  '#22536b',
];

export default function BarChart({
  labels,
  data,
  total,
  title,
  textColor,
  language,
  maintainAspectRatio = false,
  aspectRatio = 1,
  secondary,
}) {
  const screenSize = useScreenSize();
  const canvas = useRef(null);
  const localTranslate = (key) => translate(key, { forceLanguage: language });
  let responsivePadding = padding;

  let width = '440px';
  let height = '275px';
  if (
    screenSize.width < breakpoints.desktop &&
    screenSize.width > breakpoints.smallDesktop
  ) {
    width = '320px';
    height = '230px';
    responsivePadding = smallPadding;
  }

  useEffect(() => {
    if (!canvas.current) {
      return;
    }
    let fontSize = data.length <= 6 ? 12 : 10.5;

    if (
      screenSize.width < breakpoints.desktop &&
      screenSize.width > breakpoints.smallDesktop
    ) {
      fontSize = 9;
    }

    const ctx = canvas.current.getContext('2d');
    new Chart(ctx, {
      type: 'doughnut',
      plugins: [ChartDataLabels],
      data: {
        labels,
        datasets: [
          {
            data: data.map((d) => d.total),
            backgroundColor: secondary
              ? backgroundColorsSecondary
              : backgroundColors,
            borderWidth: 0,
            datalabels: {
              labels: {
                fullfillment: {
                  color: 'white',
                  align: 'center',
                  formatter: (_, context) =>
                    `${Math.ceil(data[context.dataIndex].fullfillment * 100)}%`,
                },
                name: {
                  align: 'end',
                  anchor: 'end',
                  font: {
                    size: fontSize,
                  },
                  color: textColor,
                  clamp: true,
                  offset: 1,
                  formatter: function (value, ctx) {
                    return ctx.chart.data.labels[ctx.dataIndex];
                  },
                },
              },
            },
          },
        ],
      },
      options: {
        maintainAspectRatio,
        aspectRatio,
        title,
        layout: {
          padding: responsivePadding,
        },
        legend: false,
        tooltips: false,
        events: [],
      },
    });
  }, [
    canvas,
    data,
    labels,
    textColor,
    maintainAspectRatio,
    aspectRatio,
    language,
    title,
    secondary,
    screenSize.width,
    responsivePadding,
    width,
  ]);

  return (
    <Wrapper style={{ maxWidth: '70vw', width, height }}>
      <canvas ref={canvas} />
      <MiddleContainer>
        <strong>{total}</strong>{' '}
        {localTranslate('dashboards.view.checkpointsInAreas').replace(
          '{areas}',
          data.length
        )}
      </MiddleContainer>
    </Wrapper>
  );
}
