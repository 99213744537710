import { fromJS } from 'immutable';

export const INTERNAL_UPLOAD_PREFIX = '@@internal/UPLOAD_FILE';

const defaultState = fromJS({
  progress: 0,
  name: '',
  show: false,
  uploading: false,
  failed: false,
  success: false,
  error: '',
  source: null,
});

export default function (state = defaultState, action = { type: '' }) {
  switch (action.type) {
    case `${INTERNAL_UPLOAD_PREFIX}_STARTED`: {
      return state
        .set('name', action.meta.name)
        .set('show', true)
        .set('progress', 0)
        .set('uploading', true)
        .set('failed', false)
        .set('success', false)
        .set('error', '')
        .set('source', action.meta.source);
    }
    case `${INTERNAL_UPLOAD_PREFIX}_DONE`: {
      return state.set('uploading', false).set('success', true);
    }
    case `${INTERNAL_UPLOAD_PREFIX}_PROGRESS`: {
      return state.set('progress', action.payload.progress);
    }
    case `${INTERNAL_UPLOAD_PREFIX}_CANCELED`: {
      return state
        .set('uploading', false)
        .set('failed', false)
        .set('success', false)
        .set('source', null)
        .set('show', false);
    }
    case `${INTERNAL_UPLOAD_PREFIX}_FAILED`: {
      return state
        .set('uploading', false)
        .set('failed', true)
        .set('error', action.payload);
    }
    case `${INTERNAL_UPLOAD_PREFIX}_CLOSE_MODAL`: {
      return state.set('show', false);
    }
    default: {
      return state;
    }
  }
}
