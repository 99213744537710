import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import HelpIcon from '@material-ui/icons/Help';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Error from 'components/general/Error';
import organizationActions from 'actions/admin/organization';
import { rawRequest } from 'actions/utils';
import translate from 'utils/translate';

const HeaderRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  > :nth-child(2) {
    cursor: help;
    margin-left: 4px;
    font-size: 14px;
  }
`;

const EmailRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  min-width: 25rem;
  > *:first-child {
    flex-grow: 1;
  }
`;

export default connect(
  (state, ownProps) => ({
    organization: state.getIn(['organization', 'current']),
  }),
  (dispatch) => ({
    load: (id) => dispatch(organizationActions.loadOrganization(id)),
  })
)(NotificationSettings);

function NotificationSettings({ organization, load, onClose }) {
  const orgReportEmail = organization.get('reportEmail');
  const orgIncompleteEmail = organization.get('incompleteEmail');
  const [reportEmail, setReportEmail] = useState('');
  const [incompleteEmail, setIncompleteEmail] = useState('');
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    if (orgReportEmail) setReportEmail(orgReportEmail);
  }, [orgReportEmail]);

  useEffect(() => {
    if (orgIncompleteEmail) setIncompleteEmail(orgIncompleteEmail);
  }, [orgIncompleteEmail]);

  const reportEmails = reportEmail.split(',');
  const incompleteEmails = incompleteEmail.split(',');
  return (
    <Dialog open onClose={onClose} maxWidth='md' minWidth={400} scroll='paper'>
      <DialogTitle>{translate('statistics.notificationSettings')}</DialogTitle>
      <DialogContent dividers>
        <HeaderRow>
          <Typography variant='subtitle1' gutterBottom>
            {translate('statistics.reportEmail')}
          </Typography>
          <Tooltip title={translate('statistics.reportEmailHelp')}>
            <HelpIcon />
          </Tooltip>
        </HeaderRow>
        {reportEmails.map((email, index) => (
          <EmailRow key={index}>
            <TextField
              value={email}
              variant='outlined'
              label='Email'
              onChange={(event) => {
                const value = event.target.value.replace(',', '');
                setReportEmail(
                  reportEmails
                    .map((email, emailIndex) =>
                      index === emailIndex ? value : email
                    )
                    .join(',')
                );
              }}
            />
            <IconButton
              size='small'
              onClick={() =>
                setReportEmail(
                  reportEmails
                    .filter((_, emailIndex) => index !== emailIndex)
                    .join(',')
                )
              }
            >
              <DeleteIcon />
            </IconButton>
          </EmailRow>
        ))}
        <Button
          startIcon={<AddIcon />}
          onClick={() => setReportEmail(reportEmails.join(',').concat(','))}
        >
          {translate('add')}
        </Button>
        <Divider style={{ margin: '1rem 0' }} />
        <HeaderRow>
          <Typography variant='subtitle1' gutterBottom>
            {translate('statistics.incompleteEmail')}
          </Typography>
          <Tooltip title={translate('statistics.incompleteEmailHelp')}>
            <HelpIcon />
          </Tooltip>
        </HeaderRow>
        {incompleteEmails.map((email, index) => (
          <EmailRow key={index}>
            <TextField
              value={email}
              variant='outlined'
              label='Email'
              onChange={(event) => {
                const value = event.target.value.replace(',', '');
                setIncompleteEmail(
                  incompleteEmails
                    .map((email, emailIndex) =>
                      index === emailIndex ? value : email
                    )
                    .join(',')
                );
              }}
            />
            <IconButton
              size='small'
              onClick={() =>
                setIncompleteEmail(
                  incompleteEmails
                    .filter((_, emailIndex) => index !== emailIndex)
                    .join(',')
                )
              }
            >
              <DeleteIcon />
            </IconButton>
          </EmailRow>
        ))}
        <Button
          startIcon={<AddIcon />}
          onClick={() =>
            setIncompleteEmail(incompleteEmails.join(',').concat(','))
          }
        >
          {translate('add')}
        </Button>
        {error && <Error>{error}</Error>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          {translate('back')}
        </Button>
        <Button
          disabled={saving}
          variant='contained'
          color='primary'
          onClick={async () => {
            setError(null);
            setSaving(true);
            try {
              await rawRequest({
                method: 'PUT',
                path: `/api/admin/organizations/${organization.get('id')}`,
                body: {
                  name: organization.get('name'),
                  reportEmail,
                  incompleteEmail,
                },
              });
              await load(organization.get('id'));
            } catch (error) {
              setSaving(false);
              console.error(error);
              return setError(translate('errors.server'));
            }
            onClose();
          }}
        >
          {translate('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
