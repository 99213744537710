import React, { useState, useEffect, memo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import translate from 'utils/translate';
import PropTypes from 'prop-types';
import RawLoader from 'components/general/Loader';
import Error from 'components/general/Error';
import DeleteButton from 'components/general/DeleteButton';
import { List } from 'immutable';
import Comment from './Comment';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Row,
  Span,
  FileRow,
  Classification,
  Question,
  FileUpload,
  HiddenQuestion,
  UndoButton,
  Container,
} from './styled';
import FollowUpInfo from './FollowUpInfo';
import { ScoreContainer } from './ScoreContainer';
import InformationDialog from './InformationDialog';
import { validateQuestion } from '../RegularInspectionQuestions/utils/validationUtils';
import NeedsFollowUp from './NeedsFollowUp';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import IconButton from '@material-ui/core/IconButton';
import isImagePath from 'utils/isImagePath';
import FileLink from 'components/general/FileLink';
import NACheckbox from './NACheckbox';

const UploadButton = styled(IconButton)`
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 10px 15px;
  border-radius: 3px;
  font-size: 14px;
  width: auto;

  display: inline-block;
  outline: none;
  cursor: pointer;
`;

const ReferenceNumber = styled.div`
  margin-top: 3px;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const Static = styled.span`
  font-weight: 500;
`;

const Loader = () => (
  <div style={{ position: 'absolute', right: 0 }}>
    <RawLoader />
  </div>
);

const SubQuestionsIndent = styled.div`
  padding-left: 12px;
  border-left: solid 2px ${({ theme }) => theme.palette.secondary.main};
`;

const Header = styled.div`
  display: flex;
  > div:first-child {
    flex: 1 1 auto;
    ${(props) => (props.main ? 'font-size: 16px; font-weight: 500;' : '')}
  }
  > span {
    margin-right: 6px;
  }
`;

const Help = styled(InformationDialog)`
  width: 40px;
  align-self: center;
`;

const FieldError = styled.div`
  font-size: smaller;
  color: ${({ theme }) => theme.palette.error.main};
  margin-top: 4px;
  font-size: 0.75rem;
`;

function QuestionRow({
  question,
  isControl,
  showFollowUp,
  unhideQuestion,
  deleteFile,
  setScore,
  setFollowUp,
  type = 'extra',
  subQuestion,
  prefillMode,
  setComment,
  userRole,
  uploadFile,
  picturesOpenByDefault,
  isFollowUpInspection,
}) {
  const score =
    typeof question.getIn(['answer', 'score']) === 'number'
      ? question.getIn(['answer', 'score'])
      : null;
  const comment = question.getIn(['answer', 'comment']);
  const files = question
    .getIn(['answer', 'attachments'], List())
    .filter((file) => !file.get('isFollowUp'));
  const questionId = question.get('id');
  const requiredCommentMinScore = question.get('requiredCommentMinScore');
  const questionContent = question.get('content') || '';
  const referenceNumber = question.get('referenceNumber');
  const classification = question.get('classification') || '';
  const loading = question.get('loading');
  const error = question.get('error');
  const questionType = question.get('type');
  const children = question.get('children') || List();
  const [showFiles, setShowFiles] = useState(
    picturesOpenByDefault ? true : false
  );
  const [showCommentDialog, setShowCommentDialog] = useState(false);
  const [temporaryScore, setTemporaryScore] = useState(null);
  const [dialogComment, setDialogComment] = useState(null);

  const required =
    userRole === 'companyAdmin' || userRole === 'superUser'
      ? []
      : isFollowUpInspection && !question.get('answer')
      ? []
      : validateQuestion(question) || [];

  useEffect(() => {
    if (files && files.size === 0 && showFiles) {
      setShowFiles(false);
    }
  }, [files, showFiles]);

  const domFiles = files.map((file) => (
    <FileRow key={file.get('id')}>
      {isImagePath(file.get('path')) ? (
        <img src={`/files/${file.get('path')}`} alt='answerPhoto' />
      ) : (
        <FileLink
          path={file.get('path')}
          name={file.get('originalName') || file.get('path')}
        />
      )}
      <DeleteButton
        onClick={() => deleteFile({ questionId, fileId: file.get('id') })}
      >
        {translate('inspectionQuestions.deleteFile')}
      </DeleteButton>
    </FileRow>
  ));

  const uploadFileInternal = (event) => {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }
    uploadFile({
      questionId,
      file: files[0],
    });
    event.target.value = '';
  };

  const setCommentInternal = (comment) => {
    const isCommentRequired =
      temporaryScore !== null ||
      (requiredCommentMinScore !== null && requiredCommentMinScore < score);

    if (!isCommentRequired || (score !== null && comment)) {
      return setComment({ questionId, comment });
    }

    if (!temporaryScore) return;

    setScore({
      questionId,
      score: comment ? temporaryScore : null,
      comment,
    });
  };

  if (score < 0) {
    return (
      <HiddenQuestion>
        <span>
          {translate('inspectionQuestions.hidden')} (
          {questionContent.substring(0, 15)}...)
        </span>
        <UndoButton onClick={() => unhideQuestion({ questionId })} />
      </HiddenQuestion>
    );
  }

  const isCommentRequired =
    temporaryScore !== null ||
    (requiredCommentMinScore !== null && requiredCommentMinScore < score);

  return (
    <Container
      slim={questionType === 'gradeH' || questionType === 'gradeI'}
      flex={questionType === 'gradeI' && !prefillMode}
      column={
        questionType === 'gradeI' &&
        (userRole === 'superUser' || userRole === 'companyAdmin')
      }
    >
      <Question main={children.size > 0} elevation={children.size > 0 ? 2 : 0}>
        {questionType !== 'gradeH' && !subQuestion && classification && (
          <Classification>
            {translate(`classifications.${classification}`)}
          </Classification>
        )}
        {questionType !== 'gradeH' && !subQuestion && referenceNumber && (
          <ReferenceNumber>{referenceNumber}</ReferenceNumber>
        )}
        {isControl && showFollowUp && questionType !== 'gradeI' && (
          <NeedsFollowUp question={question} setFollowUp={setFollowUp} />
        )}
        <Header main={questionType === 'none' || children.size > 0}>
          <div>
            <div>
              {!prefillMode &&
              questionType === 'gradeI' &&
              userRole !== 'superUser' &&
              userRole !== 'companyAdmin' ? (
                <span>
                  {questionContent}: <Static>{comment}</Static>
                </span>
              ) : (
                questionContent
                  .split('\n')
                  .map((c, i) => <div key={i}>{c}</div>)
              )}
            </div>
          </div>
          {questionType === 'none' ? (
            <>
              <Span slim={true}>
                <div>
                  <UploadButton
                    htmlFor={`upload${type}${questionId}`}
                    component='label'
                  >
                    <PhotoCameraIcon />
                    <FileUpload
                      id={`upload${type}${questionId}`}
                      type='file'
                      name='pic'
                      onChange={uploadFileInternal}
                    />
                  </UploadButton>
                  {required.includes('picture') ? (
                    <FieldError>
                      {translate('inspectionQuestions.errors.picture')}
                    </FieldError>
                  ) : (
                    ''
                  )}
                </div>
              </Span>
              <Help
                content={question.get('information')}
                question={question.get('content')}
              />
              {loading ? <Loader /> : <div />}
            </>
          ) : null}
        </Header>
      </Question>
      {questionType !== 'none' ? (
        <Row hideBorder={showFiles} slim={questionType === 'gradeI'}>
          {questionType !== 'gradeI' && (
            <ScoreContainer
              score={temporaryScore ?? score}
              key={questionContent}
              requiredCommentMinScore={requiredCommentMinScore}
              onRequiredComment={(score) => {
                if (!comment) return setTemporaryScore(score);
                setScore({ questionId, score, comment });
              }}
              setScore={(score) => {
                setScore({ questionId, score });
              }}
              setComment={setCommentInternal}
              isMandatory={question.get('isMandatory')}
              type={questionType}
              content={questionContent}
            />
          )}
          {(prefillMode ||
            questionType !== 'gradeI' ||
            userRole === 'superUser' ||
            userRole === 'companyAdmin') && (
            <Span
              slim={subQuestion}
              verySlim={prefillMode}
              rightAligned={questionType === 'gradeJ'}
            >
              <Comment
                label={
                  questionType === 'gradeI'
                    ? translate('questions.gradeI.comment')
                    : questionType === 'gradeJ'
                    ? translate('answer')
                    : ''
                }
                required={isCommentRequired && !showCommentDialog}
                focus={isCommentRequired && !comment}
                comment={
                  showCommentDialog || dialogComment ? dialogComment : comment
                }
                onBlur={(uncommittedComment) => {
                  if (!uncommittedComment && isCommentRequired) {
                    setShowCommentDialog(true);
                  }
                }}
                setComment={setCommentInternal}
                error={required.includes('comment')}
              />
              {questionType === 'gradeJ' && (
                <NACheckbox
                  isMandatory={question.get('isMandatory')}
                  score={temporaryScore ?? score}
                  setScore={(score) => {
                    setScore({ questionId, score });
                  }}
                />
              )}
            </Span>
          )}
          {questionType !== 'gradeI' && (
            <Span slim={subQuestion} style={{ margin: '0' }}>
              <div>
                <UploadButton
                  htmlFor={`upload${type}${questionId}`}
                  component='label'
                >
                  <FileUpload
                    id={`upload${type}${questionId}`}
                    type='file'
                    accept='image/*'
                    name='pic'
                    onChange={uploadFileInternal}
                  />
                  <PhotoCameraIcon />
                </UploadButton>
                {required.includes('picture') ? (
                  <FieldError>
                    {translate('inspectionQuestions.errors.picture')}
                  </FieldError>
                ) : (
                  ''
                )}
              </div>
            </Span>
          )}
          <Help
            content={question.get('information')}
            question={question.get('content')}
          />
          {loading ? <Loader /> : <div />}
        </Row>
      ) : null}{' '}
      <FollowUpInfo
        show={isFollowUpInspection}
        needsFollowUp={question.getIn(['answer', 'needsFollowUp'])}
        comment={question.getIn(['answer', 'followUpComment'])}
        deadline={question.getIn(['answer', 'followUpDeadline'])}
      />
      {error && (
        <Error autoWidth>
          {typeof error === 'string'
            ? translate(error)
            : error
                .map((e) => translate(`inspectionQuestions.errors.${e}`))
                .join('. ')}
        </Error>
      )}
      {files.size > 0 && (
        <Accordion defaultExpanded={showFiles}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              {translate('inspectionQuestions.showFiles')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {domFiles}
          </AccordionDetails>
        </Accordion>
      )}
      {showCommentDialog && (
        <Dialog
          open
          onClose={async () => {
            await setScore({ questionId, score: null, comment: null });
            setShowCommentDialog(false);
            setTemporaryScore(null);
            setDialogComment(null);
          }}
        >
          <DialogTitle>
            {translate('inspectionQuestions.commentDialogTitle')}
          </DialogTitle>
          <DialogContent>
            <TextField
              multiline
              fullWidth
              label={translate('questions.comment')}
              value={dialogComment ?? ''}
              onChange={(event) => setDialogComment(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              onClick={async () => {
                await setScore({
                  questionId,
                  score: null,
                  comment: null,
                });
                setShowCommentDialog(false);
                setTemporaryScore(null);
                setDialogComment(null);
              }}
            >
              {translate('cancel')}
            </Button>
            <Button
              color='primary'
              disabled={!dialogComment}
              onClick={async () => {
                await setScore({
                  questionId,
                  score: temporaryScore ?? score,
                  comment: dialogComment,
                });
                setShowCommentDialog(false);
                setTemporaryScore(null);
                setDialogComment(null);
              }}
            >
              {translate('submit')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {children.size > 0 && (
        <SubQuestionsIndent>
          {children.map((child) => (
            <QuestionRow
              isFollowUpInspection={isFollowUpInspection}
              userRole={userRole}
              picturesOpenByDefault={picturesOpenByDefault}
              key={child.get('id')}
              question={child}
              showFollowUp={showFollowUp}
              isControl={isControl}
              subQuestion
              unhideQuestion={unhideQuestion}
              deleteFile={deleteFile}
              setScore={setScore}
              setFollowUp={setFollowUp}
              setComment={setComment}
              uploadFile={uploadFile}
            />
          ))}
        </SubQuestionsIndent>
      )}
    </Container>
  );
}

QuestionRow.propTypes = {
  score: PropTypes.number,
  comment: PropTypes.string,
  files: PropTypes.object,
  requiredCommentMinScore: PropTypes.number,
  classification: PropTypes.string,
  deleteFile: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
  uploadFile: PropTypes.func.isRequired,
};

export default memo(QuestionRow, (prevProps, nextProps) => {
  const propsAreEqual =
    prevProps.isFollowUpInspection === nextProps.isFollowUpInspection &&
    isSame(prevProps.question, nextProps.question) &&
    checkSubTree(prevProps.question.get('children'), []);

  return propsAreEqual;

  function isSame(prevQuestion, nextQuestion) {
    return (
      prevQuestion.getIn(['answer', 'score']) ===
        nextQuestion.getIn(['answer', 'score']) &&
      prevQuestion.getIn(['answer', 'comment']) ===
        nextQuestion.getIn(['answer', 'comment']) &&
      prevQuestion.getIn(['answer', 'attachments']) ===
        nextQuestion.getIn(['answer', 'attachments']) &&
      prevQuestion.get('error') === nextQuestion.get('error') &&
      prevQuestion.get('loading') === nextQuestion.get('loading')
    );
  }

  function checkSubTree(branch = List(), path) {
    if (branch.size === 0) {
      return true;
    }
    return branch.every((child, index) => {
      return (
        isSame(child, nextProps.question.getIn(['children', ...path, index])) &&
        checkSubTree(child.get('children'), [...path, index, 'children'])
      );
    });
  }
});
