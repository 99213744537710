import { connect } from 'react-redux';
import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import urls from 'globals/urls';
import { DatePicker, Day } from '@material-ui/pickers';
import { push } from 'connected-react-router';
import TodayIcon from '@material-ui/icons/Today';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import translate from 'utils/translate';
import Dashboards from './Dashboards';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const Inner = styled.div`
  padding: 5px 10px;

  h3 {
    text-align: left;
  }
`;

const Reports = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.35em;
`;

const Report = styled.div`
  flex: 1 0 240px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
`;

function Week({ children, to, disabled }) {
  const WeekNumber = disabled
    ? styled.div`
        position: absolute;
        left: 1px;
        font-size: 8px;
        font-weight: 400;
      `
    : styled(Link)`
        position: absolute;
        left: 1px;
        font-size: 9px;
        font-weight: 600;
        &:hover {
          background: #f5f5f5;
        }
      `;
  return <WeekNumber to={to}>{children}</WeekNumber>;
}

function SafeQInfo({ show, info, hotelId, onOpenPastReport }) {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedReportDay, setSelectedReportDay] = useState(null);
  if (!show) {
    return null;
  }

  const reportDays = info
    .getIn(['reportDays'])
    .keySeq()
    .toJS()
    .sort((a, b) => {
      if (a === b) {
        return 0;
      }
      if (a < b) {
        return 1;
      }
      return -1;
    });
  const latestDate = reportDays[0];
  const daysDiff = latestDate && moment().diff(reportDays[0], 'day');
  const hasDaily = daysDiff === 0 || daysDiff === 1;

  const currentWeek = moment().isoWeek();
  const currentWeekYear = moment().isoWeekYear();
  const lastWeek = moment().subtract(1, 'week').isoWeek();
  const lastWeekYear = moment().subtract(1, 'week').isoWeekYear();

  const weeks = reportDays.reduce((acc, day) => {
    const week = moment(day).isoWeek();
    const year = moment(day).isoWeekYear();
    if (week === currentWeek && year === currentWeekYear) {
      return acc;
    }
    if (acc.some((prev) => prev.week === week && prev.year === year)) {
      return acc;
    }
    return acc.concat({ week, year });
  }, []);

  const hasWeekly = weeks.some(
    ({ week, year }) => week === lastWeek && year === lastWeekYear
  );

  return (
    <Paper elevation={2}>
      <Inner>
        <div className='header'>
          <Typography variant='h5' component='h3' gutterBottom>
            SafeQ
          </Typography>
          <Divider light />
        </div>
        <Reports>
          <Report>
            <Typography variant='h6' component='h4'>
              {translate('safeQ.currentReports')}
            </Typography>
            {hasDaily && (
              <Link
                to={urls.safeQDailyReport
                  .replace(':id', hotelId)
                  .concat(daysDiff === 0 ? `?day=${latestDate}` : '')}
              >
                <Button
                  variant='outlined'
                  color='primary'
                  startIcon={<TodayIcon />}
                >
                  {translate('safeQ.daily')}
                </Button>
              </Link>
            )}
            {!hasDaily && (
              <Typography color='textSecondary'>
                {translate('safeQ.noDaily')}
              </Typography>
            )}
            <div style={{ marginTop: '1rem' }}>
              {hasWeekly && (
                <Link to={urls.weeklyReport.replace(':id', hotelId)}>
                  <Button
                    variant='outlined'
                    color='primary'
                    startIcon={<TodayIcon />}
                  >
                    {translate('safeQ.weekly')}
                  </Button>
                </Link>
              )}{' '}
              {!hasWeekly && (
                <Typography color='textSecondary'>
                  {translate('safeQ.noWeekly')}
                </Typography>
              )}
            </div>
          </Report>
          <Report>
            <Typography variant='h6' component='h4'>
              {translate('safeQ.earlierDays')}
            </Typography>
            {!!reportDays.length && (
              <>
                <DatePicker
                  disableToolbar
                  maxDate={reportDays[0]}
                  minDate={reportDays[reportDays.length - 1]}
                  renderDay={(
                    day,
                    _selectedDate,
                    _dayInCurrentMonth,
                    dayComponent
                  ) => {
                    if (!reportDays.includes(day.format('YYYY-MM-DD'))) {
                      return (
                        <div>
                          {day.day() === 1 && (
                            <Week
                              disabled={
                                !weeks.some(
                                  ({ week, year }) =>
                                    week === day.isoWeek() &&
                                    year === day.isoWeekYear()
                                )
                              }
                              to={urls.weeklyReport
                                .replace(':id', hotelId)
                                .concat(
                                  `?day=${day
                                    .endOf('isoWeek')
                                    .format('YYYY-MM-DD')}`
                                )}
                            >
                              v{day.isoWeek()}
                            </Week>
                          )}
                          <Day {...dayComponent.props} disabled />
                        </div>
                      );
                    }
                    return (
                      <div>
                        {day.day() === 1 && (
                          <Week
                            disabled={
                              !weeks.some(
                                ({ week, year }) =>
                                  week === day.isoWeek() &&
                                  year === day.isoWeekYear()
                              )
                            }
                            to={urls.weeklyReport
                              .replace(':id', hotelId)
                              .concat(
                                `?day=${day
                                  .clone()
                                  .endOf('isoWeek')
                                  .format('YYYY-MM-DD')}`
                              )}
                          >
                            v{day.isoWeek()}
                          </Week>
                        )}
                        <Day
                          {...dayComponent.props}
                          selected={false}
                          onClick={(event) => {
                            const reports = info.getIn([
                              'reportDays',
                              day.format('YYYY-MM-DD'),
                            ]);
                            if (reports.keySeq().size === 1) {
                              onOpenPastReport(day.format('YYYY-MM-DD'));
                            }
                            setMenuAnchorEl(event.target);
                            setSelectedReportDay({
                              day: day.format('YYYY-MM-DD'),
                              reports: reports.toJS(),
                            });
                          }}
                        />
                      </div>
                    );
                  }}
                  autoOk
                  onChange={() => null}
                  value={latestDate}
                  readOnly
                  initialFocusedDate={latestDate}
                  variant='static'
                />
                <Menu
                  anchorEl={menuAnchorEl}
                  open={menuAnchorEl !== null}
                  onClose={() => {
                    setMenuAnchorEl(null);
                  }}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  {selectedReportDay && (
                    <MenuItem disabled style={{ opacity: 1 }}>
                      <Typography color='secondary'>
                        {selectedReportDay.day}
                      </Typography>
                    </MenuItem>
                  )}
                  {selectedReportDay &&
                    Object.keys(selectedReportDay.reports).map((k) => (
                      <Link
                        to={`${urls.safeQDailyReport.replace(
                          ':id',
                          hotelId
                        )}?day=${selectedReportDay.day}&inspectionType=${k}`}
                      >
                        <MenuItem key={k}>
                          {selectedReportDay.reports[k]}
                        </MenuItem>
                      </Link>
                    ))}
                </Menu>
              </>
            )}
            {!reportDays.length && (
              <Typography color='textSecondary'>
                {translate('safeQ.noEarlierDays')}
              </Typography>
            )}
          </Report>
        </Reports>
        <Dashboards />
      </Inner>
    </Paper>
  );
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onOpenPastReport: (date) => {
      dispatch(
        push(
          `${urls.safeQDailyReport.replace(
            ':id',
            ownProps.hotelId
          )}?day=${date}`
        )
      );
    },
  };
}

export default connect(() => ({}), mapDispatchToProps)(SafeQInfo);
