import React, { useState, useEffect } from 'react';
import InspectionTypes from 'components/general/InspectionTypes';
import CreateModal from 'components/admin/InspectionType/CreateModal';
import EditModal from 'components/admin/InspectionType/EditModal';
import DeleteModal from 'components/admin/InspectionType/DeleteModal';
import LinkToHotelModal from 'components/admin/InspectionType/LinkToHotelModal';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import translate from 'utils/translate';
import styled from 'styled-components';
const RowButton = styled(IconButton)`
  padding: 4px !important;
  margin: 4px !important;
`;

export default function InspectionTypesList({
  inspectionTypes,
  title,
  openModuleAdministration,
  openCreateModal,
  openEditModal,
  openDeleteModal,
  loading,
  organizationId,
  error,
  role,
  load,
  onUpdated,
  onCreated,
  onDeleted,
}) {
  const [selectedType, setSelectedType] = useState(null);
  const [linkToHotelModalOpen, setLinkToHotelModalOpen] = useState(false);
  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <InspectionTypes
        inspectionTypes={inspectionTypes}
        title={title}
        role={role}
        openCreateModal={openCreateModal}
        openEditModal={openEditModal}
        onClick={role === 'superUser' ? openModuleAdministration : null}
        openDeleteModal={openDeleteModal}
        loading={loading}
        error={error}
        openLinkModal={(inspectionType) => {
          setLinkToHotelModalOpen(true);
          setSelectedType(inspectionType.get('id'));
        }}
        onRenderHeaderActions={role === 'superUser' ? null : () => null}
        onRenderRowActions={
          role === 'superUser'
            ? null
            : (inspectionType) => (
                <>
                  <Tooltip title={translate('inspectionTypes.link.hotel')}>
                    <RowButton
                      size='small'
                      onClick={(event) => {
                        event.stopPropagation();
                        setLinkToHotelModalOpen(true);
                        setSelectedType(inspectionType.get('id'));
                      }}
                    >
                      <LinkIcon />
                    </RowButton>
                  </Tooltip>
                </>
              )
        }
      />
      <LinkToHotelModal
        visible={linkToHotelModalOpen}
        onClose={() => setLinkToHotelModalOpen(false)}
        inspectionTypeId={selectedType}
        organizationId={organizationId}
      />
      <CreateModal
        createOnly
        organizationId={organizationId}
        onCreated={onCreated}
      />
      <EditModal onUpdated={onUpdated} />
      <DeleteModal organizationId={organizationId} onDeleted={onDeleted} />
    </>
  );
}
