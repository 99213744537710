import { fromJS } from 'immutable';

const defaultState = fromJS({
  list: [],
  actionPlans: [],
  loading: false,
  error: '',
});

export default function (state = defaultState, action = { type: '' }) {
  switch (action.type) {
    case 'LOAD_AUDIT_QA_INSPECTIONS_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'LOAD_AUDIT_QA_INSPECTIONS_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'LOAD_AUDIT_QA_INSPECTIONS_DONE': {
      return state.set('list', fromJS(action.payload)).set('loading', false);
    }
    case 'LOAD_ACTION_PLANS_INSPECTIONS_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'LOAD_ACTION_PLANS_INSPECTIONS_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'LOAD_ACTION_PLANS_INSPECTIONS_DONE': {
      return state
        .set('actionPlans', fromJS(action.payload))
        .set('loading', false);
    }
    default: {
      return state;
    }
  }
}
