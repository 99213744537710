import {request} from 'actions/utils';

export default function updateExtraQuestion({inspectionId, questionId, payload, deleteFile}) {
  if (payload.file) {
    const file = payload.file;
    payload = new FormData();
    payload.append('file', file);
  }
  return async dispatch => {
    await dispatch(request({
      action: 'UPDATE_EXTRA_QUESTION',
      path: `/api/inspections/${inspectionId}/extraQuestions/${questionId}${deleteFile ? '?deleteFile=true' : ''}`,
      method: 'POST',
      body: payload,
      extraPayload: {
        questionId
      }
    }));
  };
}