import React from 'react';
import {connect} from 'react-redux';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import ModalWrapper from 'components/general/ModalWrapper';
import InnerModal from 'components/general/InnerModal';
import Button from 'components/general/Button';
import inspectionActions from 'actions/admin/inspection';
import moment from 'moment';

function DeleteModal({visible, loading, error, remove, closeModal, inspection}) {
  if (!visible) {
    return null;
  }
  return (
    <ModalWrapper onClose={closeModal}>
      <InnerModal
        onSubmit={e => {
          e.preventDefault();
          remove(inspection.get('id'));
        }}
      >
        <h4>{translate('inspections.delete.header')}</h4>
        <div>
          {translate('inspections.delete.confirmText')
            .replace('{startTime}', moment(inspection.get('startTime')).format('YYYY-MM-DD'))
            .replace('{name}', inspection.get('user'))}
        </div>
        <Button>{translate('inspections.delete')}</Button>
        {loading && <Loader />}
        {error && <Error>{translate(error)}</Error>}
      </InnerModal>
    </ModalWrapper>
  );
}

export default connect(
  (state, ownProps) => ({
    visible: state.getIn(['admin', 'inspections', 'deleteModal', 'visible']),
    loading: state.getIn(['admin', 'inspections', 'deleteModal', 'loading']),
    error: state.getIn(['admin', 'inspections', 'deleteModal', 'error']),
    inspection: state.getIn(['admin', 'inspections', 'deleteModal', 'inspection'])
  }),
  dispatch => ({
    remove: id => dispatch(inspectionActions.remove(id)),
    closeModal: () => dispatch(inspectionActions.closeDeleteModal())
  })
)(DeleteModal);
