import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import translate from 'utils/translate';

export default function IncompleteDays({ week, hotel }) {
  const incompleteModulesByDay = hotel.inspections.reduce((acc, inspection) => {
    if (inspection.startWeek !== week) return acc;
    const day = moment(inspection.startTime).day();
    const incompleteModules = inspection.moduleTypes
      .filter((moduleType) =>
        moduleType.questions.some((question) => !question.scores.length)
      )
      .map((moduleType) => moduleType.area)
      .filter((moduleName) => !(acc[day] || []).includes(moduleName));
    if (
      !incompleteModules.length ||
      incompleteModules.length === inspection.moduleTypes.length
    ) {
      return acc;
    }
    return { ...acc, [day]: (acc[day] || []).concat(incompleteModules) };
  }, {});

  if (!Object.keys(incompleteModulesByDay).length) return null;

  return (
    <Typography>
      <strong>{translate('safeQReport.incompleteDays')}</strong>
      {': '}
      {Object.keys(incompleteModulesByDay)
        .sort(
          (a, b) => (a === '0' ? 1 : b === '0' ? -1 : Number(a) - Number(b)) // Put Sunday last
        )
        .map((dayNumber) =>
          capitalize(moment().day(dayNumber).format('dddd')).concat(
            formatModuleNames(incompleteModulesByDay[dayNumber])
          )
        )
        .join(', ')}
    </Typography>
  );
}

function formatModuleNames(moduleTypes) {
  return ` (${moduleTypes
    .map((moduleType) => translate(`safeQReport.${moduleType}`))
    .join(', ')})`;
}

function capitalize(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
