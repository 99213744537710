import React from 'react';
import translate from 'utils/translate';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { breakpoints } from 'globals/media';
import { useScreenSize } from 'hooks/useScreenSize';
import styled from 'styled-components';
import NACheckbox from '../NACheckbox';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function RadioScore({
  score,
  isMandatory,
  setScore,
  requiredCommentMinScore,
  onRequiredComment,
}) {
  const screenSize = useScreenSize();

  const onChange = (event) => {
    const score = parseInt(event.target.value, 10);
    if (
      requiredCommentMinScore !== null &&
      requiredCommentMinScore <= score &&
      onRequiredComment
    ) {
      return onRequiredComment(score);
    }

    setScore(score);
  };

  const radios = [
    { value: 0, label: translate('questions.radioOptions.always') },
    { value: 1, label: translate('questions.radioOptions.often') },
    { value: 2, label: translate('questions.radioOptions.rarely') },
    { value: 3, label: translate('questions.radioOptions.never') },
  ];

  return (
    <Wrapper>
      <FormControl component='fieldset'>
        <RadioGroup
          row={screenSize.width > breakpoints.handheld}
          value={score}
          onChange={onChange}
        >
          {radios.map((radio) => (
            <FormControlLabel
              key={radio.value}
              value={radio.value}
              control={<Radio />}
              label={radio.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <NACheckbox score={score} isMandatory={isMandatory} setScore={setScore} />
    </Wrapper>
  );
}
