export const INITIAL_STATE = {
  file: undefined,
  uploading: false,
  questions: undefined,
  importing: false,
  showImportResult: false,
  importResult: undefined,
  error: undefined
};

export default function reducer(state, action = { type: '' }) {
  switch (action.type) {
    case 'UPLOAD_XLSX_STARTED': {
      return {
        ...INITIAL_STATE,
        uploading: true,
        file: action.payload
      };
    }
    case 'UPLOAD_XLSX_DONE': {
      return {
        ...state,
        uploading: false,
        questions: action.payload
      };
    }
    case 'UPLOAD_XLSX_FAILED': {
      return {
        ...state,
        uploading: false,
        questions: undefined,
        error: action.error
      };
    }
    case 'UPLOAD_JSON_STARTED': {
      return {
        ...state,
        importing: true
      };
    }
    case 'UPLOAD_JSON_DONE': {
      return {
        ...state,
        importing: false,
        showImportResult: true,
        importResult: action.payload
      };
    }
    case 'UPLOAD_JSON_FAILED': {
      return {
        ...state,
        importing: false,
        showImportResult: true,
        error: action.error
      };
    }
    case 'CLOSE_IMPORT_RESULT': {
      return {
        ...state,
        showImportResult: false,
        error: undefined
      };
    }
    default:
      console.error('Triggered reducer with invalid action', action);
      return state;
  }
}
