import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkSession } from 'actions/session';

const ComponentIfAllowed = ({
  isAuthenticated,
  isAdmin,
  checkSession,
  routeProps,
  triedLogin,
  admin,
  Component,
}) => {
  if (!isAuthenticated) {
    if (!triedLogin) {
      checkSession(routeProps.location.pathname + routeProps.location.search);
    }
    return null;
  }
  if (admin) {
    if (isAdmin) {
      return <Component {...routeProps} />;
    } else {
      return null;
    }
  }
  return <Component {...routeProps} />;
};

const ComponentIfAllowedWithRedux = connect(
  (state, ownProps) => {
    return {
      isAuthenticated: !!state.getIn(['currentUser', 'email']),
      triedLogin: state.getIn(['currentUser', 'login', 'triedLogin']),
      isAdmin: state.getIn(['currentUser', 'role']) === 'superUser',
      ...ownProps,
    };
  },
  (dispatch) => ({
    checkSession: (url) => dispatch(checkSession(url)),
  })
)(ComponentIfAllowed);

export default function PrivateRoute({ component: Component, admin, ...rest }) {
  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <ComponentIfAllowedWithRedux
          routeProps={routeProps}
          admin={admin}
          Component={Component}
        />
      )}
    />
  );
}
