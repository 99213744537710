import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.div`
  @media print {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0;
  }
`;

const HeaderWrapper = styled.div`
  @media print {
    position: fixed;
    top: 0;
    width: 100%;
  }
`;

const Spacer = styled.div`
  width: 100%;
  height: ${(props) => props.height};
  page-break-after: avoid;
  display: none;

  @media print {
    display: block;
  }
`;

export default function PrintWrapper({
  children,
  onRenderFooter,
  onRenderHeader,
  footerHeight,
  headerHeight,
}) {
  return (
    <>
      {onRenderHeader && <HeaderWrapper>{onRenderHeader()}</HeaderWrapper>}
      <table>
        {onRenderHeader && (
          <thead>
            <tr>
              <td>
                <Spacer height={headerHeight} />
              </td>
            </tr>
          </thead>
        )}
        <tbody>
          <tr>
            <td>{children}</td>
          </tr>
        </tbody>
        {onRenderFooter && (
          <tfoot>
            <tr>
              <td>
                <Spacer height={footerHeight} />
              </td>
            </tr>
          </tfoot>
        )}
      </table>
      {onRenderFooter && <FooterWrapper>{onRenderFooter()}</FooterWrapper>}
    </>
  );
}
