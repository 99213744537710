import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import translate from 'utils/translate';
import ModalWrapper from 'components/general/ModalWrapper';
import InnerModal from 'components/general/InnerModal';
import Input from 'components/general/Input';
import Button from 'components/general/Button';
import inspectionModuleActions from 'actions/admin/inspectionModule';

class CreateModal extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
    };
    this.changeName = this.changeName.bind(this);
  }

  changeName(e) {
    this.setState({
      name: e.target.value,
    });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      this.setState({
        name: '',
      });
    }
  }
  render() {
    const {
      visible,
      loading,
      error,
      copy,
      closeModal,
      moduleTypeId,
    } = this.props;
    if (!visible) {
      return null;
    }
    return (
      <ModalWrapper onClose={closeModal}>
        <InnerModal
          onSubmit={(e) => {
            e.preventDefault();
            const { name } = this.state;
            copy(name, moduleTypeId);
          }}
        >
          <h4>{translate('questions.copyModuleType.header')}</h4>
          <Input
            type="text"
            value={this.state.name}
            onChange={this.changeName}
            placeholder={translate('questions.copyModuleType.name')}
          />
          <Button>{translate('questions.copyModuleType')}</Button>
          {loading && <Loader />}
          {error && <Error>{translate(error)}</Error>}
        </InnerModal>
      </ModalWrapper>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    visible: state.getIn([
      'admin',
      'inspectionModules',
      'moduleTypes',
      'copyModal',
      'visible',
    ]),
    loading: state.getIn([
      'admin',
      'inspectionModules',
      'moduleTypes',
      'copyModal',
      'loading',
    ]),
    error: state.getIn([
      'admin',
      'inspectionModules',
      'moduleTypes',
      'copyModal',
      'error',
    ]),
    ...ownProps,
  }),
  (dispatch) => ({
    copy: (name, copiedModuleId) =>
      dispatch(inspectionModuleActions.copyType(name, copiedModuleId)),
    closeModal: () => dispatch(inspectionModuleActions.closeCopyTypeModal()),
  })
)(CreateModal);
