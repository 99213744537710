import React from 'react';
import styled from 'styled-components';
import translate from 'utils/translate';
import { Checkbox, MenuItem, MenuList } from './styles';
import FollowUpCommentDialog from './FollowUpCommentDialog';
import { Paper, Tooltip } from '@material-ui/core';

const StyledCheckbox = styled(Checkbox)`
  svg {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  z-index: 1200;
  top: 0px;
  left: -32px;
`;

export default function NeedsFollowUp({ question, setFollowUp }) {
  const questionId = question.get('id');
  const needsFollowUp = question.getIn(['answer', 'needsFollowUp']);

  const onCheckboxChange = (checked) => {
    setFollowUp({
      questionId: questionId,
      needsFollowUp: checked,
      followUpComment: !checked ? '' : undefined,
    });
  };

  return (
    <Wrapper>
      <Paper>
        <MenuList>
          <MenuItem>
            <Tooltip
              title={translate('questions.followUp.needsFollowUp')}
              placement={'top'}
            >
              <StyledCheckbox
                checked={needsFollowUp}
                onChange={(event) => onCheckboxChange(event.target.checked)}
              />
            </Tooltip>
          </MenuItem>
          {needsFollowUp && (
            <MenuItem>
              <FollowUpCommentDialog
                question={question}
                setFollowUp={setFollowUp}
              />
            </MenuItem>
          )}
        </MenuList>
      </Paper>
    </Wrapper>
  );
}
