import styled from 'styled-components';

export const Question = styled.span`
  min-width: 150px;
  display: inline-block;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 20px auto;
  flex-direction: column;
  box-sizing: border-box;
`;