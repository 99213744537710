import { useState, useEffect } from 'react';
import { rawRequest } from 'actions/utils';

export function useOrganizationInspectionTypes(
  organizationId,
  useMyOrganization
) {
  const [inspectionTypes, setInspectionTypes] = useState(null);

  if (useMyOrganization || organizationId < 0 || organizationId == null) {
    organizationId = 'my';
  }

  useEffect(() => {
    try {
      rawRequest({
        path: `/api/admin/organizations/${organizationId}/inspection-types`,
      }).then((inspectionTypes) => {
        setInspectionTypes(inspectionTypes);
      });
    } catch (error) {
      console.error(error);
    }
  }, [organizationId]);

  return inspectionTypes;
}
