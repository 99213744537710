import { connect } from 'react-redux';
import OrganizationAdmin from './OrganizationAdmin';
import organizationActions from 'actions/admin/organization';

function mapStateToProps(state) {
  return {
    error: state.getIn(['admin', 'organizations', 'error']),
    loading: state.getIn(['admin', 'organizations', 'loading']),
    organizations: state.getIn(['admin', 'organizations', 'organizations']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    open: (organizationId) =>
      dispatch(organizationActions.openView(organizationId)),
    loadOrganizations: () => dispatch(organizationActions.loadOrganizations()),
    openCreateModal: () => dispatch(organizationActions.openCreateModal()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationAdmin);
