import { request } from 'actions/utils';

export default function setGeneralComments({ inspectionId, comments, type }) {
  return request({
    action: 'SET_GENERAL_COMMENTS',
    method: 'PUT',
    path: `/api/inspections/${inspectionId}`,
    body: {
      generalComments: comments,
      type,
    },
    extraPayload: { comments },
  });
}
