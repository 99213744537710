import load from './load';
import get from './get';
import urls from 'globals/urls';
import create from './create';
import save from './save';
import remove from './remove';
import link from './link';
import { push } from 'connected-react-router';

export default {
  load,
  get,
  create,
  link,
  save,
  remove,
  openLinkToHotelModal,
  openCreateModal,
  closeCreateModal,
  openEditModal,
  closeEditModal,
  openDeleteModal,
  closeDeleteModal,
  openView,
};

export function openView(hotelId) {
  return push(urls.hotelDetails.replace(':hotelId', hotelId));
}
export function openLinkToHotelModal() {
  return { type: 'OPEN_LINK_INSPECTION_TYPE_MODAL' };
}
export function openCreateModal() {
  return { type: 'OPEN_CREATE_INSPECTION_TYPE_MODAL' };
}
export function closeCreateModal() {
  return { type: 'CLOSE_CREATE_INSPECTION_TYPE_MODAL' };
}
export function openEditModal(inspectionType) {
  return { type: 'OPEN_EDIT_INSPECTION_TYPE_MODAL', payload: inspectionType };
}
export function closeEditModal() {
  return { type: 'CLOSE_EDIT_INSPECTION_TYPE_MODAL' };
}
export function openDeleteModal({ name, id }) {
  return {
    type: 'OPEN_DELETE_INSPECTION_TYPE_MODAL',
    payload: { name, id },
  };
}
export function closeDeleteModal() {
  return { type: 'CLOSE_DELETE_INSPECTION_TYPE_MODAL' };
}
