import { useState, useEffect } from 'react';
import { rawRequest } from 'actions/utils';

export function useAvailableUsers(inspectionId) {
  const [users, setUsers] = useState(null);

  useEffect(() => {
    if (!inspectionId) {
      return;
    }
    try {
      rawRequest({
        path: `/api/inspections/${inspectionId}/follow-up/users`,
      }).then((response) => {
        setUsers(response.users);
      });
    } catch (error) {
      console.error(error);
    }
  }, [inspectionId]);

  return users;
}
