import { fromJS } from 'immutable';

const defaultState = fromJS({
  visible: false,
  loading: false,
  error: '',
});

export default function (state = defaultState, action = { type: '' }) {
  switch (action.type) {
    case 'COPY_MODULE_TYPE_STARTED': {
      return state.set('loading', true).set('error', '');
    }
    case 'COPY_MODULE_TYPE_FAILED': {
      return state.set('loading', false).set('error', action.payload);
    }
    case 'COPY_MODULE_TYPE_DONE': {
      return state.set('loading', false).set('visible', false);
    }
    case 'OPEN_COPY_MODULE_TYPE_MODAL': {
      return state.set('visible', true).set('error', '');
    }
    case 'CLOSE_COPY_MODULE_TYPE_MODAL': {
      return state.set('visible', false);
    }
    default: {
      return state;
    }
  }
}
