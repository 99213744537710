import React from 'react';
import translate from 'utils/translate';
import {Wrapper, Header, InlineHeader} from './styled';
import {Map} from 'immutable';
import {providers} from 'components/inspection/ModulePicker/ExternalScoresModal';
import ExternalScoresModal from 'components/inspection/ModulePicker/ExternalScoresModal';
import Button from 'components/general/Button';

export default function ExternalScores({data, openModal}) {
  data = data || Map(); // Not as default value in arguments since data can be null, and won't get matched by that
  const scores = Object.entries(providers).map(([key, value]) => {
    let score = data.get(key);
    if (score === undefined) {
      score = translate('unanswered');
    }
    return (
      <React.Fragment key={key}>
        <InlineHeader>{translate(`inspection.externalScores.${key}`)}</InlineHeader>
        <span>{score}</span>
        <Button color='white' textColor='textGray' onClick={() => openModal(key)}>
          {translate('edit')}
        </Button>
      </React.Fragment>
    );
  });
  return (
    <Wrapper>
      <Header>{translate('inspection.control.externalScores.provider')}</Header>
      <Header>{translate('inspection.control.externalScores.score')}</Header>
      {scores}
      <ExternalScoresModal reloadOnSave />
    </Wrapper>
  );
}
