import styled from 'styled-components';
import media from 'globals/media';

export const Wrapper = styled.div`
  margin: 20px auto 0 auto;
  max-width: 60rem;
  white-space: nowrap;
  grid-row-gap: 20px;
  align-items: center;
  > button {
    max-height: 40px;
  }
`;
export const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-word;
  white-space: normal;
  margin: 10px;
  > button:not(:last-child) {
    margin-right: 20px;
  }
`;

export const ContextQuestion = styled.div`
  margin: 20px;
  padding: 10px;
  border-radius: 10px;
  background-color: hsla(0, 0%, 95%, 1);
  white-space: normal;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-break: break-word;
  position: relative;
  > .ordinal {
    position: absolute;
    left: 5px;
    top: 5px;
    font-size: 10px;
  }
`;

export const ContextQuestions = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
`;

export const Select = styled.select`
  width: 210px;
  grid-column: 2 / 3;
  outline: none;
  border: none;
  height: 30px;
`;

export const Cluster = styled.div`
  min-height: 40px;
  padding: 10px 0;
`;

export const ClusterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  > * {
    overflow: hidden;
    white-space: normal;
    word-break: break-word;
  }
  > button {
    margin-right: 0;
  }
  ${media.tablet`
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    > button {
      margin-right: auto;
    }
  `};
`;
export const Row = styled.div`
  display: grid;
  align-items: center;
  grid-column-gap: 1rem;
  grid-template-columns: auto 100px 40px 130px 120px 130px 90px;
  min-height: 40px;
  padding: 10px;
  font-size: 14px;
  white-space: normal;
  word-break: break-word;
  background-color: hsla(0, 0%, 98%, 1);
  > :not(:first-child) {
    text-align: center;
  }
  > button {
    margin-right: 0;
  }
  ${media.handheld`
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    > button {
      margin-right: auto;
    }
  `};
  &:nth-child(odd) {
    background-color: hsla(0, 0%, 92%, 1);
  }
`;
